/* Level indicator alignment within tiles */
.tiles .level-indicator {
  margin-left: auto;
}

/* Removing divider between image and following grouped content */
.tiles img+div {
  border-top: 0;
}

.tiles > div {
  min-width: 0;
}
