/*---------------------------------------------------------------*/
/*---------- components/table/table-base-variables.scss ---------*/
/*---------------------------------------------------------------*/
:root {
  /* Table */
  /* --table-base-font-size: ; */
  /* --table-base-font-weight: ; */
  /* --table-base-line-height: ; */

  /* Use this variable to set a padding on all cells. */
  --table-base-cells-padding: 0.5rem;

  /* --table-base-background-color: ; */
  /* --table-base-text-color: ; */
  /* --table-base-border-width: ; */
  /* --table-base-border-style: ; */
  /* --table-base-border-color: ; */
  /* --table-base-text-align: ; */

  /* Table head */
  --table-base-head-background-color: var(--application-base-accent-color);
  --table-base-head-text-color: var(--application-base-accent-color-text-color);
  /* --table-base-head-border-width: ; */
  /* --table-base-head-border-style: ; */
  /* --table-base-head-border-color: ; */

  /* Table head cell (th) */
  /* --table-base-head-cell-border-width: ; */
  /* --table-base-head-cell-border-style: ; */
  /* --table-base-head-cell-border-color: ; */
  --table-base-head-cell-padding: var(--table-base-cells-padding);
  --table-base-head-cell-font-weight: bolder;
  /* --table-base-head-cell-font-size: ; */

  /* Table head cell (th) binnen tbody */
  --table-base-body-head-cell-border-width: 1px;
  --table-base-body-head-cell-border-style: solid;
  --table-base-body-head-cell-border-color: #ccc;
  --table-base-body-head-cell-padding: var(--table-base-cells-padding);
  --table-base-body-head-cell-font-weight: 700;
  --table-base-body-head-cell-background-color: var(
    --application-base-tint-1-background-color
  );
  /* --table-base-body-head-cell-font-size: ; */

  /* Table row */
  /* Zebra striping with opacity to keep background color. e.g for warnings or errors */
  --table-base-row-background-color-striping: rgba(148, 148, 148, 0.1);
  /* --table-base-row-border-width: ; */
  /* --table-base-row-border-style: ; */
  /* --table-base-row-border-color: ; */

  /* Table cell (td) */
  --table-base-cell-padding: var(--table-base-cells-padding);
  /* --table-base-cell-gap: ; */
  /* --table-base-cell-background-color: ; */
  /* --table-base-cell-text-color: ; */
  --table-base-cell-border-width: 1px;
  --table-base-cell-border-style: solid;
  --table-base-cell-border-color: #ccc;

  /* Table cells within the first row */
  --table-base-first-row-cell-border-width: var(--table-base-cell-border-width);
  --table-base-first-row-cell-border-style: var(--table-base-cell-border-style);
  --table-base-first-row-cell-border-color: var(--table-base-cell-border-color);

  /* Table cells within the last row */
  --table-base-last-row-cell-border-width: var(--table-base-cell-border-width);
  --table-base-last-row-cell-border-style: var(--table-base-cell-border-style);
  --table-base-last-row-cell-border-color: var(--table-base-cell-border-color);

  /* Table footer */
  --table-base-foot-background-color: #ddd;
  --table-base-foot-padding: var(--table-base-cells-padding);
  /* --table-base-foot-text-color: var(--table-base-text-color); */
  /* --table-base-foot-font-weight: var(--table-base-font-weight); */
  /* --table-base-foot-font-size: var(--table-base-font-size); */
  /* --table-base-foot-border-width: var(--table-base-border-width); */
  /* --table-base-foot-border-style: var(--table-base-border-style); */
  /* --table-base-foot-border-color: var(--table-base-border-color); */

  /* Table footer head */
  --table-base-foot-header-padding: var(--table-base-foot-padding);
  /* --table-base-foot-header-background-color: ; */
  /* --table-base-foot-header-text-color: ; */
  /* --table-base-foot-header-font-weight: ; */
  /* --table-base-foot-header-font-size: ; */
  /* --table-base-foot-header-border-width: ; */
  /* --table-base-foot-header-border-style: ; */
  /* --table-base-foot-header-border-color: ; */

  /* Table footer cell styling */
  --table-base-foot-cell-padding: var(--table-base-foot-padding);
  --table-base-foot-cell-background-color: #f0f0f0;
  /* --table-base-foot-cell-text-color: ; */
  /* --table-base-foot-cell-font-weight: ; */
  /* --table-base-foot-cell-font-size: ; */
  /* --table-base-foot-cell-border-width: ; */
  /* --table-base-foot-cell-border-style: ; */
  /* --table-base-foot-cell-border-color: ; */
}
