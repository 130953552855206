@keyframes blink {
  50% {
    opacity: 0.1;
  }
}
.cat-paw-loader {
  display: flex;
  justify-content: flex-start;
  margin: 2.5rem 0;

  > img {
    animation: 2s blink infinite;
    display: inline-block;
    margin-right: 0.75rem;
    width: 1rem;

    &:nth-child(2) {
      animation-delay: 250ms;
    }
    &:nth-child(3) {
      animation-delay: 500ms;
    }
    &:nth-child(4) {
      animation-delay: 750ms;
    }
    &:nth-child(5) {
      animation-delay: 1000ms;
    }
    &:nth-child(6) {
      animation-delay: 1250ms;
    }
  }
}
