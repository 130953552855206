/*---------------------------------------------------------------*/
/*-------------------- form-accent-color.scss -------------------*/
/*---------------------------------------------------------------*/
@use "form-accent-color-variables";

form {
  input[type="checkbox"],
  input[type="radio"],
  input[type="range"],
  progress {
    accent-color: var(--form-accent-color-color, initial);
  }
}
