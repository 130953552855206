/* theme: KAT Soft based on manon components */
:root {
  --critical-informational-labels-min-width: 8rem;
  --de-emphasized-text-color: #000;
  --h1-font-size: var(--heading-xl-font-size);
  --h2-font-size: var(--heading-large-font-size);
  --icon-base-font-family: "tabler-icons";
  --icon-base-font-size: 1.2rem;
  --nota-bene-text-color: #000;
  --section-gap: 1.5rem;
  --section-title-margin: var(--layout-base-content-block-title-margin);
  --section-subtitle-margin: var(--layout-base-content-block-subtitle-margin);
}
