/*---------------------------------------------------------------*/
/*------------ form-fieldset-visually-hidden.scss ---------------*/
/*---------------------------------------------------------------*/

form fieldset {
  padding: 0;
  border: 0;
  margin: 0;
  display: inline-block;
  position: relative;
}
