/*---------------------------------------------------------------*/
/*----------------- form-fieldset-variables.scss ----------------*/
/*---------------------------------------------------------------*/
:root {
  /* Fields within the fieldset */
  --form-fieldset-fields-margin-top: 1rem;

  /* Legend */
  --form-fieldset-legend-font-weight: bold;
  --form-fieldset-legend-margin-bottom: 1rem;
}
