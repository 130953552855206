/*---------------------------------------------------------------*/
/*-------------- table-action-buttons-variables.scss ------------*/
/*---------------------------------------------------------------*/
:root {
  /* Button - action icon */
  --table-action-buttons-button-background-color: transparent;
  --table-action-buttons-button-text-color: #6c6c6c;
  --table-action-buttons-button-font-size: 1rem;
  --table-action-buttons-button-justify-content: center;

  /* Hover */
  --table-action-buttons-button-hover-background-color: transparent;
  --table-action-buttons-button-hover-text-color: #000;

  /* Form */
  --table-action-buttons-form-display: flex;
  --table-action-buttons-flex-direction: row;
  --table-action-buttons-justify-content: center;
  --table-action-buttons-width: auto;
}
