/*---------------------------------------------------------------*/
/*-------------- notification-warning-variables.scss ------------*/
/*---------------------------------------------------------------*/
:root {
  --notification-warning-background-color: #fff2d1;
  --notification-warning-border-width: var(--notification-base-border-width);
  --notification-warning-border-style: var(--notification-base-border-style);
  --notification-warning-border-color: #f6cb5f;

  /* Icon as before or after */
  /* --notification-warning-icon-before-content: ; */
  /* --notification-warning-icon-before-content: ; */
}
