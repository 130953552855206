/*---------------------------------------------------------------*/
/*---------------------- tags-variables.scss --------------------*/
/*---------------------------------------------------------------*/
:root {
  --tags-flex-direction: row;
  --tags-align-items: center;
  --tags-justify-content: left;
  --tags-flex-wrap: wrap;
  --tags-gap: 0.5rem;
  --tags-padding-top: 0;
  --tags-padding-right: 0;
  --tags-padding-bottom: 0;
  --tags-padding-left: 0;
  --tags-list-style: none;
}
