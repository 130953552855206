/*----------------------------------------------------------------------------------*/
/*------------------------------ tabs-variables.scss -------------------------------*/
/*----------------------------------------------------------------------------------*/
:root {
  --tabs-border-width: 0 0 1px 0;
  --tabs-border-style: solid;
  --tabs-border-color: #808080;
  /* --tabs-background-color: ; */
  --tabs-gap: 1rem;

  /* List item */
  /* --tabs-item-border-width: ; */
  /* --tabs-item-border-style: ; */
  /* --tabs-item-border-color: ; */
  --tabs-item-text-color: #808080;
  /* --tabs-item-line-height: ; */
  --tabs-item-padding: 0.5rem;
  /* --tabs-item-background-color: ; */
  --tabs-item-text-decoration: none;

  /* List item hover */
  --tabs-item-hover-border-width: 0 0 1px 0;
  --tabs-item-hover-border-style: solid;
  --tabs-item-hover-border-color: var(--application-base-accent-color);
  --tabs-item-hover-text-color: var(--application-base-accent-color);
  /* --tabs-item-hover-background-color: ; */

  /* List item active */
  --tabs-item-active-border-width: 0 0 1px 0;
  --tabs-item-active-border-style: solid;
  --tabs-item-active-border-color: var(--application-base-accent-color);
  --tabs-item-active-text-color: var(--application-base-accent-color);
  /* --tabs-item-active-background-color: ; */
}
