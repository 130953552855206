@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  8% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.wait-text {
  position: relative;
  height: 4rem;
}

.wait-text span {
  animation: fadeInOut linear 10s;
  height: 3.5rem;
  left: 0;
  margin-top: 2.5rem;
  opacity: 0;
  position: absolute;
  top: 0;
}

.wait-text span:nth-child(2) {
  animation-delay: 2s;
}

.wait-text span:nth-child(3) {
  animation-delay: 4s;
}

.wait-text span:nth-child(4) {
  animation-delay: 6s;
}

.wait-text span:nth-child(5) {
  animation-name: fadeIn;
  animation-delay: 8s;
}
