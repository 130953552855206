/*---------------------------------------------------------------*/
/*---------------- layout-footer-content-block.scss ---------------*/
/*---------------------------------------------------------------*/
@use "layout-footer-content-block-variables";

body > footer {
  display: flex;
  flex-direction: column;
  min-height: var(--layout-footer-content-block-min-height);

  > section,
  > article,
  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--layout-footer-content-block-gap);
    padding-top: var(--layout-footer-content-block-padding-top);
    padding-right: var(--layout-footer-content-block-padding-right);
    padding-bottom: var(--layout-footer-content-block-padding-bottom);
    padding-left: var(--layout-footer-content-block-padding-left);
    box-sizing: border-box;
    align-self: center;
    max-width: var(--layout-footer-content-block-max-width);
  }
}
