/* Font: Open sans */

/* Open sans */
  /* Regular */
  @font-face {
    font-family: "Open Sans";
    font-weight: normal;
    font-style: normal;
    src: url("themes/soft/fonts/open-sans/OpenSans-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Open Sans";
    font-weight: normal;
    font-style: italic;
    src: url("themes/soft/fonts/open-sans/OpenSans-Italic.ttf") format("truetype");
  }

  /* Bold */
  @font-face {
    font-family: "Open Sans";
    font-weight: bold;
    font-style: normal;
    src: url("themes/soft/fonts/open-sans/OpenSans-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Open Sans";
    font-weight: bold;
    font-style: italic;
    src: url("themes/soft/fonts/open-sans/OpenSans-BoldItalic.ttf") format("truetype");
  }
