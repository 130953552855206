/*---------------------------------------------------------------*/
/*-------------- components/visually-hidden.scss -----------------*/
/*---------------------------------------------------------------*/
.visually-hidden {
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
    white-space: nowrap;
    overflow: hidden;
    width: 0;
    height: 0;
    max-width: 0;
    max-height: 0;

    /* Overwriting any specifics that might have been set.    */
    /* !important is used here to solve any specificity issues */
    min-width: 0 !important; 
    min-height: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    position: absolute !important;
}
