/*!
 * Tabler Icons 1.72.0 by tabler - https://tabler.io
 * License - https://github.com/tabler/tabler-icons/blob/master/LICENSE
 */
$ti-font-family: "tabler-icons" !default;
$ti-font-path: "themes/soft/fonts/tabler-icons" !default;
$ti-font-display: null !default;
$ti-prefix: "ti" !default;

@font-face {
  font-family: $ti-font-family;
  font-style: normal;
  font-weight: 400;
  font-display: $ti-font-display;
  src: url("#{$ti-font-path}/tabler-icons.eot");
  src: url("#{$ti-font-path}/tabler-icons.eot?#iefix")
      format("embedded-opentype"),
    url("#{$ti-font-path}/tabler-icons.woff2") format("woff2"),
    url("#{$ti-font-path}/tabler-icons.woff") format("woff"),
    url("#{$ti-font-path}/tabler-icons.ttf") format("truetype"),
    url("#{$ti-font-path}/tabler-icons.svg\##{$ti-font-family}") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: $ti-font-family;
    src: url("#{$ti-font-path}/tabler-icons.svg\##{$ti-font-family}")
      format("svg");
  }
}

.#{$ti-prefix} {
  &:before,
  :after {
    font-family: $ti-font-family !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
@function unicode($str) {
  @return unquote('"') + unquote(str-insert($str, "\\", 1)) + unquote('"');
}

$ti-icon-2fa: unicode("eca0");
$ti-icon-3d-cube-sphere: unicode("ecd7");
$ti-icon-3d-rotate: unicode("f020");
$ti-icon-a-b: unicode("ec36");
$ti-icon-a-b-off: unicode("f0a6");
$ti-icon-abacus: unicode("f05c");
$ti-icon-access-point: unicode("ed1b");
$ti-icon-access-point-off: unicode("ed1a");
$ti-icon-accessible: unicode("eba9");
$ti-icon-accessible-off: unicode("f0a7");
$ti-icon-activity: unicode("ed23");
$ti-icon-activity-heartbeat: unicode("f0db");
$ti-icon-ad: unicode("ea02");
$ti-icon-ad-2: unicode("ef1f");
$ti-icon-address-book: unicode("f021");
$ti-icon-adjustments: unicode("ea03");
$ti-icon-adjustments-alt: unicode("ec37");
$ti-icon-adjustments-horizontal: unicode("ec38");
$ti-icon-adjustments-off: unicode("f0a8");
$ti-icon-aerial-lift: unicode("edfe");
$ti-icon-affiliate: unicode("edff");
$ti-icon-alarm: unicode("ea04");
$ti-icon-alarm-off: unicode("f0a9");
$ti-icon-album: unicode("f022");
$ti-icon-alert-circle: unicode("ea05");
$ti-icon-alert-octagon: unicode("ecc6");
$ti-icon-alert-triangle: unicode("ea06");
$ti-icon-alien: unicode("ebde");
$ti-icon-align-center: unicode("ea07");
$ti-icon-align-justified: unicode("ea08");
$ti-icon-align-left: unicode("ea09");
$ti-icon-align-right: unicode("ea0a");
$ti-icon-alphabet-cyrillic: unicode("f1df");
$ti-icon-alphabet-greek: unicode("f1e0");
$ti-icon-alphabet-latin: unicode("f1e1");
$ti-icon-ambulance: unicode("ebf5");
$ti-icon-anchor: unicode("eb76");
$ti-icon-anchor-off: unicode("f0f7");
$ti-icon-angle: unicode("ef20");
$ti-icon-ankh: unicode("f1cd");
$ti-icon-antenna: unicode("f094");
$ti-icon-antenna-bars-1: unicode("ecc7");
$ti-icon-antenna-bars-2: unicode("ecc8");
$ti-icon-antenna-bars-3: unicode("ecc9");
$ti-icon-antenna-bars-4: unicode("ecca");
$ti-icon-antenna-bars-5: unicode("eccb");
$ti-icon-antenna-bars-off: unicode("f0aa");
$ti-icon-aperture: unicode("eb58");
$ti-icon-api: unicode("effd");
$ti-icon-api-app: unicode("effc");
$ti-icon-api-app-off: unicode("f0ab");
$ti-icon-api-off: unicode("f0f8");
$ti-icon-app-window: unicode("efe6");
$ti-icon-apple: unicode("ef21");
$ti-icon-apps: unicode("ebb6");
$ti-icon-apps-off: unicode("f0ac");
$ti-icon-archive: unicode("ea0b");
$ti-icon-archive-off: unicode("f0ad");
$ti-icon-armchair: unicode("ef9e");
$ti-icon-armchair-2: unicode("efe7");
$ti-icon-arrow-autofit-content: unicode("ef31");
$ti-icon-arrow-autofit-down: unicode("ef32");
$ti-icon-arrow-autofit-height: unicode("ef33");
$ti-icon-arrow-autofit-left: unicode("ef34");
$ti-icon-arrow-autofit-right: unicode("ef35");
$ti-icon-arrow-autofit-up: unicode("ef36");
$ti-icon-arrow-autofit-width: unicode("ef37");
$ti-icon-arrow-back: unicode("ea0c");
$ti-icon-arrow-back-up: unicode("eb77");
$ti-icon-arrow-bar-down: unicode("ea0d");
$ti-icon-arrow-bar-left: unicode("ea0e");
$ti-icon-arrow-bar-right: unicode("ea0f");
$ti-icon-arrow-bar-to-down: unicode("ec88");
$ti-icon-arrow-bar-to-left: unicode("ec89");
$ti-icon-arrow-bar-to-right: unicode("ec8a");
$ti-icon-arrow-bar-to-up: unicode("ec8b");
$ti-icon-arrow-bar-up: unicode("ea10");
$ti-icon-arrow-bear-left: unicode("f045");
$ti-icon-arrow-bear-left-2: unicode("f044");
$ti-icon-arrow-bear-right: unicode("f047");
$ti-icon-arrow-bear-right-2: unicode("f046");
$ti-icon-arrow-big-down: unicode("edda");
$ti-icon-arrow-big-down-line: unicode("efe8");
$ti-icon-arrow-big-down-lines: unicode("efe9");
$ti-icon-arrow-big-left: unicode("eddb");
$ti-icon-arrow-big-left-line: unicode("efea");
$ti-icon-arrow-big-left-lines: unicode("efeb");
$ti-icon-arrow-big-right: unicode("eddc");
$ti-icon-arrow-big-right-line: unicode("efec");
$ti-icon-arrow-big-right-lines: unicode("efed");
$ti-icon-arrow-big-top: unicode("eddd");
$ti-icon-arrow-big-up-line: unicode("efee");
$ti-icon-arrow-big-up-lines: unicode("efef");
$ti-icon-arrow-bottom-bar: unicode("ed98");
$ti-icon-arrow-bottom-circle: unicode("ed99");
$ti-icon-arrow-bottom-square: unicode("ed9a");
$ti-icon-arrow-bottom-tail: unicode("ed9b");
$ti-icon-arrow-curve-left: unicode("f048");
$ti-icon-arrow-curve-right: unicode("f049");
$ti-icon-arrow-down: unicode("ea16");
$ti-icon-arrow-down-circle: unicode("ea11");
$ti-icon-arrow-down-left: unicode("ea13");
$ti-icon-arrow-down-left-circle: unicode("ea12");
$ti-icon-arrow-down-right: unicode("ea15");
$ti-icon-arrow-down-right-circle: unicode("ea14");
$ti-icon-arrow-fork: unicode("f04a");
$ti-icon-arrow-forward: unicode("ea17");
$ti-icon-arrow-forward-up: unicode("eb78");
$ti-icon-arrow-left: unicode("ea19");
$ti-icon-arrow-left-bar: unicode("ed9c");
$ti-icon-arrow-left-circle: unicode("ea18");
$ti-icon-arrow-left-right: unicode("f04b");
$ti-icon-arrow-left-square: unicode("ed9d");
$ti-icon-arrow-left-tail: unicode("ed9e");
$ti-icon-arrow-loop-left: unicode("ed9f");
$ti-icon-arrow-loop-left-2: unicode("f04c");
$ti-icon-arrow-loop-right: unicode("eda0");
$ti-icon-arrow-loop-right-2: unicode("f04d");
$ti-icon-arrow-merge: unicode("f04e");
$ti-icon-arrow-narrow-down: unicode("ea1a");
$ti-icon-arrow-narrow-left: unicode("ea1b");
$ti-icon-arrow-narrow-right: unicode("ea1c");
$ti-icon-arrow-narrow-up: unicode("ea1d");
$ti-icon-arrow-ramp-left: unicode("ed3c");
$ti-icon-arrow-ramp-left-2: unicode("f04f");
$ti-icon-arrow-ramp-left-3: unicode("f050");
$ti-icon-arrow-ramp-right: unicode("ed3d");
$ti-icon-arrow-ramp-right-2: unicode("f051");
$ti-icon-arrow-ramp-right-3: unicode("f052");
$ti-icon-arrow-right: unicode("ea1f");
$ti-icon-arrow-right-bar: unicode("eda1");
$ti-icon-arrow-right-circle: unicode("ea1e");
$ti-icon-arrow-right-square: unicode("eda2");
$ti-icon-arrow-right-tail: unicode("eda3");
$ti-icon-arrow-rotary-first-left: unicode("f053");
$ti-icon-arrow-rotary-first-right: unicode("f054");
$ti-icon-arrow-rotary-last-left: unicode("f055");
$ti-icon-arrow-rotary-last-right: unicode("f056");
$ti-icon-arrow-rotary-left: unicode("f057");
$ti-icon-arrow-rotary-right: unicode("f058");
$ti-icon-arrow-rotary-straight: unicode("f059");
$ti-icon-arrow-sharp-turn-left: unicode("f05a");
$ti-icon-arrow-sharp-turn-right: unicode("f05b");
$ti-icon-arrow-top-bar: unicode("eda4");
$ti-icon-arrow-top-circle: unicode("eda5");
$ti-icon-arrow-top-square: unicode("eda6");
$ti-icon-arrow-top-tail: unicode("eda7");
$ti-icon-arrow-up: unicode("ea25");
$ti-icon-arrow-up-circle: unicode("ea20");
$ti-icon-arrow-up-left: unicode("ea22");
$ti-icon-arrow-up-left-circle: unicode("ea21");
$ti-icon-arrow-up-right: unicode("ea24");
$ti-icon-arrow-up-right-circle: unicode("ea23");
$ti-icon-arrow-wave-left-down: unicode("eda8");
$ti-icon-arrow-wave-left-up: unicode("eda9");
$ti-icon-arrow-wave-right-down: unicode("edaa");
$ti-icon-arrow-wave-right-up: unicode("edab");
$ti-icon-arrows-cross: unicode("effe");
$ti-icon-arrows-diagonal: unicode("ea27");
$ti-icon-arrows-diagonal-2: unicode("ea26");
$ti-icon-arrows-diagonal-minimize: unicode("ef39");
$ti-icon-arrows-diagonal-minimize-2: unicode("ef38");
$ti-icon-arrows-double-ne-sw: unicode("edde");
$ti-icon-arrows-double-nw-se: unicode("eddf");
$ti-icon-arrows-double-se-nw: unicode("ede0");
$ti-icon-arrows-double-sw-ne: unicode("ede1");
$ti-icon-arrows-down: unicode("edad");
$ti-icon-arrows-down-up: unicode("edac");
$ti-icon-arrows-exchange: unicode("f1f4");
$ti-icon-arrows-exchange-2: unicode("f1f3");
$ti-icon-arrows-horizontal: unicode("eb59");
$ti-icon-arrows-join: unicode("edaf");
$ti-icon-arrows-join-2: unicode("edae");
$ti-icon-arrows-left: unicode("edb1");
$ti-icon-arrows-left-down: unicode("ee00");
$ti-icon-arrows-left-right: unicode("edb0");
$ti-icon-arrows-maximize: unicode("ea28");
$ti-icon-arrows-minimize: unicode("ea29");
$ti-icon-arrows-random: unicode("f095");
$ti-icon-arrows-right: unicode("edb3");
$ti-icon-arrows-right-down: unicode("ee01");
$ti-icon-arrows-right-left: unicode("edb2");
$ti-icon-arrows-shuffle: unicode("f000");
$ti-icon-arrows-shuffle-2: unicode("efff");
$ti-icon-arrows-sort: unicode("eb5a");
$ti-icon-arrows-split: unicode("edb5");
$ti-icon-arrows-split-2: unicode("edb4");
$ti-icon-arrows-up: unicode("edb7");
$ti-icon-arrows-up-down: unicode("edb6");
$ti-icon-arrows-up-left: unicode("ee02");
$ti-icon-arrows-up-right: unicode("ee03");
$ti-icon-arrows-vertical: unicode("eb5b");
$ti-icon-artboard: unicode("ea2a");
$ti-icon-artboard-off: unicode("f0ae");
$ti-icon-article: unicode("f1e2");
$ti-icon-aspect-ratio: unicode("ed30");
$ti-icon-aspect-ratio-off: unicode("f0af");
$ti-icon-asset: unicode("f1ce");
$ti-icon-asterisk: unicode("efd5");
$ti-icon-asterisk-simple: unicode("efd4");
$ti-icon-at: unicode("ea2b");
$ti-icon-at-off: unicode("f0b0");
$ti-icon-atom: unicode("eb79");
$ti-icon-atom-2: unicode("ebdf");
$ti-icon-atom-off: unicode("f0f9");
$ti-icon-augmented-reality: unicode("f023");
$ti-icon-award: unicode("ea2c");
$ti-icon-award-off: unicode("f0fa");
$ti-icon-axe: unicode("ef9f");
$ti-icon-axis-x: unicode("ef45");
$ti-icon-axis-y: unicode("ef46");
$ti-icon-baby-carriage: unicode("f05d");
$ti-icon-backhoe: unicode("ed86");
$ti-icon-backpack: unicode("ef47");
$ti-icon-backspace: unicode("ea2d");
$ti-icon-badge: unicode("efc2");
$ti-icon-badge-off: unicode("f0fb");
$ti-icon-badges: unicode("efc3");
$ti-icon-badges-off: unicode("f0fc");
$ti-icon-ball-american-football: unicode("ee04");
$ti-icon-ball-baseball: unicode("efa0");
$ti-icon-ball-basketball: unicode("ec28");
$ti-icon-ball-bowling: unicode("ec29");
$ti-icon-ball-football: unicode("ee06");
$ti-icon-ball-football-off: unicode("ee05");
$ti-icon-ball-tennis: unicode("ec2a");
$ti-icon-ball-volleyball: unicode("ec2b");
$ti-icon-ballon: unicode("ef3a");
$ti-icon-ballon-off: unicode("f0fd");
$ti-icon-ballpen: unicode("f06e");
$ti-icon-ballpen-off: unicode("f0b1");
$ti-icon-ban: unicode("ea2e");
$ti-icon-bandage: unicode("eb7a");
$ti-icon-barbell: unicode("eff0");
$ti-icon-barbell-off: unicode("f0b2");
$ti-icon-barcode: unicode("ebc6");
$ti-icon-barcode-off: unicode("f0b3");
$ti-icon-barrel: unicode("f0b4");
$ti-icon-barrel-off: unicode("f0fe");
$ti-icon-barrier-block: unicode("f00e");
$ti-icon-barrier-block-off: unicode("f0b5");
$ti-icon-baseline: unicode("f024");
$ti-icon-basket: unicode("ebe1");
$ti-icon-basket-off: unicode("f0b6");
$ti-icon-bath: unicode("ef48");
$ti-icon-bath-off: unicode("f0ff");
$ti-icon-battery: unicode("ea34");
$ti-icon-battery-1: unicode("ea2f");
$ti-icon-battery-2: unicode("ea30");
$ti-icon-battery-3: unicode("ea31");
$ti-icon-battery-4: unicode("ea32");
$ti-icon-battery-automotive: unicode("ee07");
$ti-icon-battery-charging: unicode("ea33");
$ti-icon-battery-charging-2: unicode("ef3b");
$ti-icon-battery-eco: unicode("ef3c");
$ti-icon-battery-off: unicode("ed1c");
$ti-icon-beach: unicode("ef3d");
$ti-icon-beach-off: unicode("f0b7");
$ti-icon-bed: unicode("eb5c");
$ti-icon-bed-off: unicode("f100");
$ti-icon-beer: unicode("efa1");
$ti-icon-beer-off: unicode("f101");
$ti-icon-bell: unicode("ea35");
$ti-icon-bell-minus: unicode("ede2");
$ti-icon-bell-off: unicode("ece9");
$ti-icon-bell-plus: unicode("ede3");
$ti-icon-bell-ringing: unicode("ed07");
$ti-icon-bell-ringing-2: unicode("ede4");
$ti-icon-bell-school: unicode("f05e");
$ti-icon-bell-x: unicode("ede5");
$ti-icon-bell-z: unicode("eff1");
$ti-icon-bible: unicode("efc4");
$ti-icon-bike: unicode("ea36");
$ti-icon-bike-off: unicode("f0b8");
$ti-icon-binary: unicode("ee08");
$ti-icon-biohazard: unicode("ecb8");
$ti-icon-biohazard-off: unicode("f0b9");
$ti-icon-blockquote: unicode("ee09");
$ti-icon-bluetooth: unicode("ea37");
$ti-icon-bluetooth-connected: unicode("ecea");
$ti-icon-bluetooth-off: unicode("eceb");
$ti-icon-bluetooth-x: unicode("f081");
$ti-icon-blur: unicode("ef8c");
$ti-icon-bold: unicode("eb7b");
$ti-icon-bold-off: unicode("f0ba");
$ti-icon-bolt: unicode("ea38");
$ti-icon-bolt-off: unicode("ecec");
$ti-icon-bone: unicode("edb8");
$ti-icon-bone-off: unicode("f0bb");
$ti-icon-book: unicode("ea39");
$ti-icon-book-2: unicode("efc5");
$ti-icon-book-download: unicode("f070");
$ti-icon-book-off: unicode("f0bc");
$ti-icon-book-upload: unicode("f071");
$ti-icon-bookmark: unicode("ea3a");
$ti-icon-bookmark-off: unicode("eced");
$ti-icon-bookmarks: unicode("ed08");
$ti-icon-bookmarks-off: unicode("f0bd");
$ti-icon-books: unicode("eff2");
$ti-icon-books-off: unicode("f0be");
$ti-icon-border-all: unicode("ea3b");
$ti-icon-border-bottom: unicode("ea3c");
$ti-icon-border-horizontal: unicode("ea3d");
$ti-icon-border-inner: unicode("ea3e");
$ti-icon-border-left: unicode("ea3f");
$ti-icon-border-none: unicode("ea40");
$ti-icon-border-outer: unicode("ea41");
$ti-icon-border-radius: unicode("eb7c");
$ti-icon-border-right: unicode("ea42");
$ti-icon-border-style: unicode("ee0a");
$ti-icon-border-style-2: unicode("ef22");
$ti-icon-border-top: unicode("ea43");
$ti-icon-border-vertical: unicode("ea44");
$ti-icon-bottle: unicode("ef0b");
$ti-icon-bow: unicode("f096");
$ti-icon-box: unicode("ea45");
$ti-icon-box-margin: unicode("ee0b");
$ti-icon-box-model: unicode("ee0c");
$ti-icon-box-model-2: unicode("ef23");
$ti-icon-box-multiple: unicode("ee17");
$ti-icon-box-multiple-0: unicode("ee0d");
$ti-icon-box-multiple-1: unicode("ee0e");
$ti-icon-box-multiple-2: unicode("ee0f");
$ti-icon-box-multiple-3: unicode("ee10");
$ti-icon-box-multiple-4: unicode("ee11");
$ti-icon-box-multiple-5: unicode("ee12");
$ti-icon-box-multiple-6: unicode("ee13");
$ti-icon-box-multiple-7: unicode("ee14");
$ti-icon-box-multiple-8: unicode("ee15");
$ti-icon-box-multiple-9: unicode("ee16");
$ti-icon-box-off: unicode("f102");
$ti-icon-box-padding: unicode("ee18");
$ti-icon-braces: unicode("ebcc");
$ti-icon-braces-off: unicode("f0bf");
$ti-icon-brackets: unicode("ebcd");
$ti-icon-brackets-contain: unicode("f1e5");
$ti-icon-brackets-contain-end: unicode("f1e3");
$ti-icon-brackets-contain-start: unicode("f1e4");
$ti-icon-brackets-off: unicode("f0c0");
$ti-icon-brand-adobe: unicode("f0dc");
$ti-icon-brand-airbnb: unicode("ed68");
$ti-icon-brand-airtable: unicode("ef6a");
$ti-icon-brand-amongus: unicode("f205");
$ti-icon-brand-android: unicode("ec16");
$ti-icon-brand-angular: unicode("ef6b");
$ti-icon-brand-apple: unicode("ec17");
$ti-icon-brand-apple-arcade: unicode("ed69");
$ti-icon-brand-apple-podcast: unicode("f1e6");
$ti-icon-brand-appstore: unicode("ed24");
$ti-icon-brand-asana: unicode("edc5");
$ti-icon-brand-badoo: unicode("f206");
$ti-icon-brand-bandcamp: unicode("f207");
$ti-icon-brand-beats: unicode("f208");
$ti-icon-brand-behance: unicode("ec6e");
$ti-icon-brand-bing: unicode("edc6");
$ti-icon-brand-bitbucket: unicode("edc7");
$ti-icon-brand-booking: unicode("edc8");
$ti-icon-brand-bootstrap: unicode("ef3e");
$ti-icon-brand-chrome: unicode("ec18");
$ti-icon-brand-codepen: unicode("ec6f");
$ti-icon-brand-codesandbox: unicode("ed6a");
$ti-icon-brand-coinbase: unicode("f209");
$ti-icon-brand-comedy-central: unicode("f217");
$ti-icon-brand-css3: unicode("ed6b");
$ti-icon-brand-cucumber: unicode("ef6c");
$ti-icon-brand-debian: unicode("ef57");
$ti-icon-brand-deviantart: unicode("ecfb");
$ti-icon-brand-discord: unicode("ece3");
$ti-icon-brand-disney: unicode("f20a");
$ti-icon-brand-disqus: unicode("edc9");
$ti-icon-brand-docker: unicode("edca");
$ti-icon-brand-doctrine: unicode("ef6d");
$ti-icon-brand-dribbble: unicode("ec19");
$ti-icon-brand-edge: unicode("ecfc");
$ti-icon-brand-facebook: unicode("ec1a");
$ti-icon-brand-figma: unicode("ec93");
$ti-icon-brand-finder: unicode("f218");
$ti-icon-brand-firebase: unicode("ef6e");
$ti-icon-brand-firefox: unicode("ecfd");
$ti-icon-brand-flickr: unicode("ecfe");
$ti-icon-brand-flipboard: unicode("f20b");
$ti-icon-brand-foursquare: unicode("ecff");
$ti-icon-brand-framer: unicode("ec1b");
$ti-icon-brand-git: unicode("ef6f");
$ti-icon-brand-github: unicode("ec1c");
$ti-icon-brand-gitlab: unicode("ec1d");
$ti-icon-brand-gmail: unicode("efa2");
$ti-icon-brand-google: unicode("ec1f");
$ti-icon-brand-google-analytics: unicode("edcb");
$ti-icon-brand-google-drive: unicode("ec1e");
$ti-icon-brand-google-photos: unicode("f20c");
$ti-icon-brand-google-play: unicode("ed25");
$ti-icon-brand-gravatar: unicode("edcc");
$ti-icon-brand-grindr: unicode("f20d");
$ti-icon-brand-hipchat: unicode("edcd");
$ti-icon-brand-html5: unicode("ed6c");
$ti-icon-brand-instagram: unicode("ec20");
$ti-icon-brand-intercom: unicode("f1cf");
$ti-icon-brand-javascript: unicode("ef0c");
$ti-icon-brand-kickstarter: unicode("edce");
$ti-icon-brand-kotlin: unicode("ed6d");
$ti-icon-brand-lastfm: unicode("f001");
$ti-icon-brand-linkedin: unicode("ec8c");
$ti-icon-brand-linktree: unicode("f1e7");
$ti-icon-brand-loom: unicode("ef70");
$ti-icon-brand-mastercard: unicode("ef49");
$ti-icon-brand-medium: unicode("ec70");
$ti-icon-brand-mercedes: unicode("f072");
$ti-icon-brand-messenger: unicode("ec71");
$ti-icon-brand-meta: unicode("efb0");
$ti-icon-brand-monday: unicode("f219");
$ti-icon-brand-netbeans: unicode("ef71");
$ti-icon-brand-netflix: unicode("edcf");
$ti-icon-brand-nextjs: unicode("f0dd");
$ti-icon-brand-notion: unicode("ef7b");
$ti-icon-brand-nuxt: unicode("f0de");
$ti-icon-brand-nytimes: unicode("ef8d");
$ti-icon-brand-open-source: unicode("edd0");
$ti-icon-brand-opera: unicode("ec21");
$ti-icon-brand-pagekit: unicode("edd1");
$ti-icon-brand-patreon: unicode("edd2");
$ti-icon-brand-paypal: unicode("ec22");
$ti-icon-brand-php: unicode("ef72");
$ti-icon-brand-pinterest: unicode("ec8d");
$ti-icon-brand-pocket: unicode("ed00");
$ti-icon-brand-producthunt: unicode("edd3");
$ti-icon-brand-pushover: unicode("f20e");
$ti-icon-brand-python: unicode("ed01");
$ti-icon-brand-react-native: unicode("ef73");
$ti-icon-brand-reddit: unicode("ec8e");
$ti-icon-brand-safari: unicode("ec23");
$ti-icon-brand-sass: unicode("edd4");
$ti-icon-brand-sentry: unicode("edd5");
$ti-icon-brand-shazam: unicode("edd6");
$ti-icon-brand-sketch: unicode("ec24");
$ti-icon-brand-skype: unicode("ed02");
$ti-icon-brand-slack: unicode("ec72");
$ti-icon-brand-snapchat: unicode("ec25");
$ti-icon-brand-soundcloud: unicode("ed6e");
$ti-icon-brand-spotify: unicode("ed03");
$ti-icon-brand-stackoverflow: unicode("ef58");
$ti-icon-brand-steam: unicode("ed6f");
$ti-icon-brand-stripe: unicode("edd7");
$ti-icon-brand-sublime-text: unicode("ef74");
$ti-icon-brand-svelte: unicode("f0df");
$ti-icon-brand-tabler: unicode("ec8f");
$ti-icon-brand-tailwind: unicode("eca1");
$ti-icon-brand-telegram: unicode("ec26");
$ti-icon-brand-tidal: unicode("ed70");
$ti-icon-brand-tiktok: unicode("ec73");
$ti-icon-brand-tinder: unicode("ed71");
$ti-icon-brand-tripadvisor: unicode("f002");
$ti-icon-brand-tumblr: unicode("ed04");
$ti-icon-brand-twitch: unicode("ed05");
$ti-icon-brand-twitter: unicode("ec27");
$ti-icon-brand-uber: unicode("ef75");
$ti-icon-brand-ubuntu: unicode("ef59");
$ti-icon-brand-unsplash: unicode("edd8");
$ti-icon-brand-vercel: unicode("ef24");
$ti-icon-brand-vimeo: unicode("ed06");
$ti-icon-brand-vinted: unicode("f20f");
$ti-icon-brand-visual-studio: unicode("ef76");
$ti-icon-brand-vivaldi: unicode("f210");
$ti-icon-brand-vk: unicode("ed72");
$ti-icon-brand-vue: unicode("f0e0");
$ti-icon-brand-walmart: unicode("f211");
$ti-icon-brand-whatsapp: unicode("ec74");
$ti-icon-brand-windows: unicode("ecd8");
$ti-icon-brand-wish: unicode("f212");
$ti-icon-brand-xing: unicode("f21a");
$ti-icon-brand-yahoo: unicode("ed73");
$ti-icon-brand-yatse: unicode("f213");
$ti-icon-brand-ycombinator: unicode("edd9");
$ti-icon-brand-youtube: unicode("ec90");
$ti-icon-brand-youtube-kids: unicode("f214");
$ti-icon-brand-zoom: unicode("f215");
$ti-icon-brand-zwift: unicode("f216");
$ti-icon-bread: unicode("efa3");
$ti-icon-briefcase: unicode("ea46");
$ti-icon-brightness: unicode("eb7f");
$ti-icon-brightness-2: unicode("ee19");
$ti-icon-brightness-down: unicode("eb7d");
$ti-icon-brightness-half: unicode("ee1a");
$ti-icon-brightness-up: unicode("eb7e");
$ti-icon-broadcast: unicode("f1e9");
$ti-icon-broadcast-off: unicode("f1e8");
$ti-icon-browser: unicode("ebb7");
$ti-icon-browser-check: unicode("efd6");
$ti-icon-browser-off: unicode("f0c1");
$ti-icon-browser-plus: unicode("efd7");
$ti-icon-browser-x: unicode("efd8");
$ti-icon-brush: unicode("ebb8");
$ti-icon-brush-off: unicode("f0c2");
$ti-icon-bucket: unicode("ea47");
$ti-icon-bucket-off: unicode("f103");
$ti-icon-bug: unicode("ea48");
$ti-icon-bug-off: unicode("f0c3");
$ti-icon-building: unicode("ea4f");
$ti-icon-building-arch: unicode("ea49");
$ti-icon-building-bank: unicode("ebe2");
$ti-icon-building-bridge: unicode("ea4b");
$ti-icon-building-bridge-2: unicode("ea4a");
$ti-icon-building-carousel: unicode("ed87");
$ti-icon-building-castle: unicode("ed88");
$ti-icon-building-church: unicode("ea4c");
$ti-icon-building-community: unicode("ebf6");
$ti-icon-building-cottage: unicode("ee1b");
$ti-icon-building-factory: unicode("ee1c");
$ti-icon-building-factory-2: unicode("f082");
$ti-icon-building-fortress: unicode("ed89");
$ti-icon-building-hospital: unicode("ea4d");
$ti-icon-building-lighthouse: unicode("ed8a");
$ti-icon-building-monument: unicode("ed26");
$ti-icon-building-pavilon: unicode("ebf7");
$ti-icon-building-skyscraper: unicode("ec39");
$ti-icon-building-store: unicode("ea4e");
$ti-icon-building-warehouse: unicode("ebe3");
$ti-icon-bulb: unicode("ea51");
$ti-icon-bulb-off: unicode("ea50");
$ti-icon-bulldozer: unicode("ee1d");
$ti-icon-bus: unicode("ebe4");
$ti-icon-businessplan: unicode("ee1e");
$ti-icon-butterfly: unicode("efd9");
$ti-icon-c-sharp: unicode("f003");
$ti-icon-cactus: unicode("f21b");
$ti-icon-cake: unicode("f00f");
$ti-icon-cake-off: unicode("f104");
$ti-icon-calculator: unicode("eb80");
$ti-icon-calculator-off: unicode("f0c4");
$ti-icon-calendar: unicode("ea53");
$ti-icon-calendar-event: unicode("ea52");
$ti-icon-calendar-minus: unicode("ebb9");
$ti-icon-calendar-off: unicode("ee1f");
$ti-icon-calendar-plus: unicode("ebba");
$ti-icon-calendar-stats: unicode("ee20");
$ti-icon-calendar-time: unicode("ee21");
$ti-icon-camera: unicode("ea54");
$ti-icon-camera-minus: unicode("ec3a");
$ti-icon-camera-off: unicode("ecee");
$ti-icon-camera-plus: unicode("ec3b");
$ti-icon-camera-rotate: unicode("ee22");
$ti-icon-camera-selfie: unicode("ee23");
$ti-icon-candle: unicode("efc6");
$ti-icon-candy: unicode("ef0d");
$ti-icon-candy-off: unicode("f0c5");
$ti-icon-capture: unicode("ec3c");
$ti-icon-capture-off: unicode("f0c6");
$ti-icon-car: unicode("ebbb");
$ti-icon-car-crane: unicode("ef25");
$ti-icon-car-crash: unicode("efa4");
$ti-icon-car-off: unicode("f0c7");
$ti-icon-caravan: unicode("ec7c");
$ti-icon-cardboards: unicode("ed74");
$ti-icon-cardboards-off: unicode("f0c8");
$ti-icon-caret-down: unicode("eb5d");
$ti-icon-caret-left: unicode("eb5e");
$ti-icon-caret-right: unicode("eb5f");
$ti-icon-caret-up: unicode("eb60");
$ti-icon-carrot: unicode("f21c");
$ti-icon-cash: unicode("ea55");
$ti-icon-cash-banknote: unicode("ee25");
$ti-icon-cash-banknote-off: unicode("ee24");
$ti-icon-cash-off: unicode("f105");
$ti-icon-cast: unicode("ea56");
$ti-icon-cast-off: unicode("f0c9");
$ti-icon-category: unicode("f1f6");
$ti-icon-category-2: unicode("f1f5");
$ti-icon-ce: unicode("ed75");
$ti-icon-ce-off: unicode("f0ca");
$ti-icon-cell: unicode("f05f");
$ti-icon-cell-signal-1: unicode("f083");
$ti-icon-cell-signal-2: unicode("f084");
$ti-icon-cell-signal-3: unicode("f085");
$ti-icon-cell-signal-4: unicode("f086");
$ti-icon-cell-signal-5: unicode("f087");
$ti-icon-cell-signal-off: unicode("f088");
$ti-icon-certificate: unicode("ed76");
$ti-icon-certificate-2: unicode("f073");
$ti-icon-certificate-2-off: unicode("f0cb");
$ti-icon-certificate-off: unicode("f0cc");
$ti-icon-charging-pile: unicode("ee26");
$ti-icon-chart-arcs: unicode("ee28");
$ti-icon-chart-arcs-3: unicode("ee27");
$ti-icon-chart-area: unicode("ea58");
$ti-icon-chart-area-line: unicode("ea57");
$ti-icon-chart-arrows: unicode("ee2a");
$ti-icon-chart-arrows-vertical: unicode("ee29");
$ti-icon-chart-bar: unicode("ea59");
$ti-icon-chart-bubble: unicode("ec75");
$ti-icon-chart-candle: unicode("ea5a");
$ti-icon-chart-circles: unicode("ee2b");
$ti-icon-chart-donut: unicode("ea5b");
$ti-icon-chart-donut-2: unicode("ee2c");
$ti-icon-chart-donut-3: unicode("ee2d");
$ti-icon-chart-donut-4: unicode("ee2e");
$ti-icon-chart-dots: unicode("ee2f");
$ti-icon-chart-dots-2: unicode("f097");
$ti-icon-chart-dots-3: unicode("f098");
$ti-icon-chart-infographic: unicode("ee30");
$ti-icon-chart-line: unicode("ea5c");
$ti-icon-chart-pie: unicode("ea5d");
$ti-icon-chart-pie-2: unicode("ee31");
$ti-icon-chart-pie-3: unicode("ee32");
$ti-icon-chart-pie-4: unicode("ee33");
$ti-icon-chart-radar: unicode("ed77");
$ti-icon-check: unicode("ea5e");
$ti-icon-checkbox: unicode("eba6");
$ti-icon-checklist: unicode("f074");
$ti-icon-checks: unicode("ebaa");
$ti-icon-checkup-list: unicode("ef5a");
$ti-icon-cheese: unicode("ef26");
$ti-icon-chef-hat: unicode("f21d");
$ti-icon-chevron-down: unicode("ea5f");
$ti-icon-chevron-down-left: unicode("ed09");
$ti-icon-chevron-down-right: unicode("ed0a");
$ti-icon-chevron-left: unicode("ea60");
$ti-icon-chevron-right: unicode("ea61");
$ti-icon-chevron-up: unicode("ea62");
$ti-icon-chevron-up-left: unicode("ed0b");
$ti-icon-chevron-up-right: unicode("ed0c");
$ti-icon-chevrons-down: unicode("ea63");
$ti-icon-chevrons-down-left: unicode("ed0d");
$ti-icon-chevrons-down-right: unicode("ed0e");
$ti-icon-chevrons-left: unicode("ea64");
$ti-icon-chevrons-right: unicode("ea65");
$ti-icon-chevrons-up: unicode("ea66");
$ti-icon-chevrons-up-left: unicode("ed0f");
$ti-icon-chevrons-up-right: unicode("ed10");
$ti-icon-christmas-tree: unicode("ed78");
$ti-icon-circle: unicode("ea6b");
$ti-icon-circle-0: unicode("ee34");
$ti-icon-circle-1: unicode("ee35");
$ti-icon-circle-2: unicode("ee36");
$ti-icon-circle-3: unicode("ee37");
$ti-icon-circle-4: unicode("ee38");
$ti-icon-circle-5: unicode("ee39");
$ti-icon-circle-6: unicode("ee3a");
$ti-icon-circle-7: unicode("ee3b");
$ti-icon-circle-8: unicode("ee3c");
$ti-icon-circle-9: unicode("ee3d");
$ti-icon-circle-check: unicode("ea67");
$ti-icon-circle-dashed: unicode("ed27");
$ti-icon-circle-dot: unicode("efb1");
$ti-icon-circle-dotted: unicode("ed28");
$ti-icon-circle-half: unicode("ee3f");
$ti-icon-circle-half-2: unicode("eff3");
$ti-icon-circle-half-vertical: unicode("ee3e");
$ti-icon-circle-minus: unicode("ea68");
$ti-icon-circle-off: unicode("ee40");
$ti-icon-circle-plus: unicode("ea69");
$ti-icon-circle-rectangle: unicode("f010");
$ti-icon-circle-rectangle-off: unicode("f0cd");
$ti-icon-circle-square: unicode("ece4");
$ti-icon-circle-triangle: unicode("f011");
$ti-icon-circle-x: unicode("ea6a");
$ti-icon-circles: unicode("ece5");
$ti-icon-clear-all: unicode("ee41");
$ti-icon-clear-formatting: unicode("ebe5");
$ti-icon-click: unicode("ebbc");
$ti-icon-clipboard: unicode("ea6f");
$ti-icon-clipboard-check: unicode("ea6c");
$ti-icon-clipboard-list: unicode("ea6d");
$ti-icon-clipboard-off: unicode("f0ce");
$ti-icon-clipboard-plus: unicode("efb2");
$ti-icon-clipboard-text: unicode("f089");
$ti-icon-clipboard-x: unicode("ea6e");
$ti-icon-clock: unicode("ea70");
$ti-icon-clock-2: unicode("f099");
$ti-icon-clock-off: unicode("f0cf");
$ti-icon-cloud: unicode("ea76");
$ti-icon-cloud-computing: unicode("f1d0");
$ti-icon-cloud-data-connection: unicode("f1d1");
$ti-icon-cloud-download: unicode("ea71");
$ti-icon-cloud-fog: unicode("ecd9");
$ti-icon-cloud-lock: unicode("efdb");
$ti-icon-cloud-lock-open: unicode("efda");
$ti-icon-cloud-off: unicode("ed3e");
$ti-icon-cloud-rain: unicode("ea72");
$ti-icon-cloud-snow: unicode("ea73");
$ti-icon-cloud-storm: unicode("ea74");
$ti-icon-cloud-upload: unicode("ea75");
$ti-icon-clover: unicode("f1ea");
$ti-icon-clover-2: unicode("f21e");
$ti-icon-clubs: unicode("eff4");
$ti-icon-code: unicode("ea77");
$ti-icon-code-minus: unicode("ee42");
$ti-icon-code-off: unicode("f0d0");
$ti-icon-code-plus: unicode("ee43");
$ti-icon-coffee: unicode("ef0e");
$ti-icon-coffee-off: unicode("f106");
$ti-icon-coin: unicode("eb82");
$ti-icon-coin-off: unicode("f0d1");
$ti-icon-color-picker: unicode("ebe6");
$ti-icon-color-picker-off: unicode("f0d2");
$ti-icon-color-swatch: unicode("eb61");
$ti-icon-color-swatch-off: unicode("f0d3");
$ti-icon-column-insert-left: unicode("ee44");
$ti-icon-column-insert-right: unicode("ee45");
$ti-icon-columns: unicode("eb83");
$ti-icon-columns-off: unicode("f0d4");
$ti-icon-comet: unicode("ec76");
$ti-icon-command: unicode("ea78");
$ti-icon-compass: unicode("ea79");
$ti-icon-compass-off: unicode("f0d5");
$ti-icon-components: unicode("efa5");
$ti-icon-components-off: unicode("f0d6");
$ti-icon-cone: unicode("efdd");
$ti-icon-cone-2: unicode("efdc");
$ti-icon-confetti: unicode("ee46");
$ti-icon-container: unicode("ee47");
$ti-icon-container-off: unicode("f107");
$ti-icon-contrast: unicode("ec4e");
$ti-icon-contrast-2: unicode("efc7");
$ti-icon-cookie: unicode("ef0f");
$ti-icon-cookie-off: unicode("f0d7");
$ti-icon-copy: unicode("ea7a");
$ti-icon-copy-off: unicode("f0d8");
$ti-icon-copyleft: unicode("ec3d");
$ti-icon-copyleft-off: unicode("f0d9");
$ti-icon-copyright: unicode("ea7b");
$ti-icon-copyright-off: unicode("f0da");
$ti-icon-corner-down-left: unicode("ea7c");
$ti-icon-corner-down-left-double: unicode("ee48");
$ti-icon-corner-down-right: unicode("ea7d");
$ti-icon-corner-down-right-double: unicode("ee49");
$ti-icon-corner-left-down: unicode("ea7e");
$ti-icon-corner-left-down-double: unicode("ee4a");
$ti-icon-corner-left-up: unicode("ea7f");
$ti-icon-corner-left-up-double: unicode("ee4b");
$ti-icon-corner-right-down: unicode("ea80");
$ti-icon-corner-right-down-double: unicode("ee4c");
$ti-icon-corner-right-up: unicode("ea81");
$ti-icon-corner-right-up-double: unicode("ee4d");
$ti-icon-corner-up-left: unicode("ea82");
$ti-icon-corner-up-left-double: unicode("ee4e");
$ti-icon-corner-up-right: unicode("ea83");
$ti-icon-corner-up-right-double: unicode("ee4f");
$ti-icon-cpu: unicode("ef8e");
$ti-icon-cpu-2: unicode("f075");
$ti-icon-cpu-off: unicode("f108");
$ti-icon-crane: unicode("ef27");
$ti-icon-crane-off: unicode("f109");
$ti-icon-creative-commons: unicode("efb3");
$ti-icon-creative-commons-by: unicode("f21f");
$ti-icon-creative-commons-nc: unicode("f220");
$ti-icon-creative-commons-nd: unicode("f221");
$ti-icon-creative-commons-off: unicode("f10a");
$ti-icon-creative-commons-sa: unicode("f222");
$ti-icon-creative-commons-zero: unicode("f223");
$ti-icon-credit-card: unicode("ea84");
$ti-icon-credit-card-off: unicode("ed11");
$ti-icon-cricket: unicode("f09a");
$ti-icon-crop: unicode("ea85");
$ti-icon-cross: unicode("ef8f");
$ti-icon-cross-off: unicode("f10b");
$ti-icon-crosshair: unicode("ec3e");
$ti-icon-crown: unicode("ed12");
$ti-icon-crown-off: unicode("ee50");
$ti-icon-crutches: unicode("ef5b");
$ti-icon-crutches-off: unicode("f10c");
$ti-icon-cup: unicode("ef28");
$ti-icon-cup-off: unicode("f10d");
$ti-icon-curling: unicode("efc8");
$ti-icon-curly-loop: unicode("ecda");
$ti-icon-currency: unicode("efa6");
$ti-icon-currency-bahraini: unicode("ee51");
$ti-icon-currency-baht: unicode("f08a");
$ti-icon-currency-bitcoin: unicode("ebab");
$ti-icon-currency-cent: unicode("ee53");
$ti-icon-currency-dinar: unicode("ee54");
$ti-icon-currency-dirham: unicode("ee55");
$ti-icon-currency-dogecoin: unicode("ef4b");
$ti-icon-currency-dollar: unicode("eb84");
$ti-icon-currency-dollar-australian: unicode("ee56");
$ti-icon-currency-dollar-canadian: unicode("ee57");
$ti-icon-currency-dollar-singapore: unicode("ee58");
$ti-icon-currency-ethereum: unicode("ee59");
$ti-icon-currency-euro: unicode("eb85");
$ti-icon-currency-forint: unicode("ee5a");
$ti-icon-currency-frank: unicode("ee5b");
$ti-icon-currency-krone-czech: unicode("ee5c");
$ti-icon-currency-krone-danish: unicode("ee5d");
$ti-icon-currency-krone-swedish: unicode("ee5e");
$ti-icon-currency-leu: unicode("ee5f");
$ti-icon-currency-lira: unicode("ee60");
$ti-icon-currency-litecoin: unicode("ee61");
$ti-icon-currency-naira: unicode("ee62");
$ti-icon-currency-pound: unicode("ebac");
$ti-icon-currency-real: unicode("ee63");
$ti-icon-currency-renminbi: unicode("ee64");
$ti-icon-currency-ripple: unicode("ee65");
$ti-icon-currency-riyal: unicode("ee66");
$ti-icon-currency-rubel: unicode("ee67");
$ti-icon-currency-rupee: unicode("ebad");
$ti-icon-currency-shekel: unicode("ee68");
$ti-icon-currency-taka: unicode("ee69");
$ti-icon-currency-tugrik: unicode("ee6a");
$ti-icon-currency-won: unicode("ee6b");
$ti-icon-currency-yen: unicode("ebae");
$ti-icon-currency-zloty: unicode("ee6c");
$ti-icon-current-location: unicode("ecef");
$ti-icon-current-location-off: unicode("f10e");
$ti-icon-cursor-off: unicode("f10f");
$ti-icon-cursor-text: unicode("ee6d");
$ti-icon-cut: unicode("ea86");
$ti-icon-dashboard: unicode("ea87");
$ti-icon-database: unicode("ea88");
$ti-icon-database-export: unicode("ee6e");
$ti-icon-database-import: unicode("ee6f");
$ti-icon-database-off: unicode("ee70");
$ti-icon-dental: unicode("f025");
$ti-icon-dental-off: unicode("f110");
$ti-icon-details: unicode("ee71");
$ti-icon-device-analytics: unicode("ee72");
$ti-icon-device-audio-tape: unicode("ee73");
$ti-icon-device-cctv: unicode("ee74");
$ti-icon-device-computer-camera: unicode("ee76");
$ti-icon-device-computer-camera-off: unicode("ee75");
$ti-icon-device-desktop: unicode("ea89");
$ti-icon-device-desktop-analytics: unicode("ee77");
$ti-icon-device-desktop-off: unicode("ee78");
$ti-icon-device-floppy: unicode("eb62");
$ti-icon-device-gamepad: unicode("eb63");
$ti-icon-device-gamepad-2: unicode("f1d2");
$ti-icon-device-heart-monitor: unicode("f060");
$ti-icon-device-laptop: unicode("eb64");
$ti-icon-device-laptop-off: unicode("f061");
$ti-icon-device-mobile: unicode("ea8a");
$ti-icon-device-mobile-charging: unicode("f224");
$ti-icon-device-mobile-message: unicode("ee79");
$ti-icon-device-mobile-off: unicode("f062");
$ti-icon-device-mobile-rotated: unicode("ecdb");
$ti-icon-device-mobile-vibration: unicode("eb86");
$ti-icon-device-nintendo: unicode("f026");
$ti-icon-device-nintendo-off: unicode("f111");
$ti-icon-device-speaker: unicode("ea8b");
$ti-icon-device-speaker-off: unicode("f112");
$ti-icon-device-tablet: unicode("ea8c");
$ti-icon-device-tablet-off: unicode("f063");
$ti-icon-device-tv: unicode("ea8d");
$ti-icon-device-tv-off: unicode("f064");
$ti-icon-device-tv-old: unicode("f1d3");
$ti-icon-device-watch: unicode("ebf9");
$ti-icon-device-watch-off: unicode("f065");
$ti-icon-device-watch-stats: unicode("ef7d");
$ti-icon-device-watch-stats-2: unicode("ef7c");
$ti-icon-devices: unicode("eb87");
$ti-icon-devices-2: unicode("ed29");
$ti-icon-devices-off: unicode("f066");
$ti-icon-devices-pc: unicode("ee7a");
$ti-icon-devices-pc-off: unicode("f113");
$ti-icon-dialpad: unicode("f067");
$ti-icon-dialpad-off: unicode("f114");
$ti-icon-diamond: unicode("eb65");
$ti-icon-diamond-off: unicode("f115");
$ti-icon-diamonds: unicode("eff5");
$ti-icon-dice: unicode("eb66");
$ti-icon-dice-1: unicode("f08b");
$ti-icon-dice-2: unicode("f08c");
$ti-icon-dice-3: unicode("f08d");
$ti-icon-dice-4: unicode("f08e");
$ti-icon-dice-5: unicode("f08f");
$ti-icon-dice-6: unicode("f090");
$ti-icon-dimensions: unicode("ee7b");
$ti-icon-direction: unicode("ebfb");
$ti-icon-direction-horizontal: unicode("ebfa");
$ti-icon-direction-sign: unicode("f1f7");
$ti-icon-directions: unicode("ea8e");
$ti-icon-directions-off: unicode("f116");
$ti-icon-disabled: unicode("ea8f");
$ti-icon-disabled-2: unicode("ebaf");
$ti-icon-disabled-off: unicode("f117");
$ti-icon-disc: unicode("ea90");
$ti-icon-disc-off: unicode("f118");
$ti-icon-discount: unicode("ebbd");
$ti-icon-discount-2: unicode("ee7c");
$ti-icon-discount-check: unicode("f1f8");
$ti-icon-divide: unicode("ed5c");
$ti-icon-dna: unicode("ee7d");
$ti-icon-dna-2: unicode("ef5c");
$ti-icon-dna-2-off: unicode("f119");
$ti-icon-dna-off: unicode("f11a");
$ti-icon-dog-bowl: unicode("ef29");
$ti-icon-door: unicode("ef4e");
$ti-icon-door-enter: unicode("ef4c");
$ti-icon-door-exit: unicode("ef4d");
$ti-icon-door-off: unicode("f11b");
$ti-icon-dots: unicode("ea95");
$ti-icon-dots-circle-horizontal: unicode("ea91");
$ti-icon-dots-diagonal: unicode("ea93");
$ti-icon-dots-diagonal-2: unicode("ea92");
$ti-icon-dots-vertical: unicode("ea94");
$ti-icon-download: unicode("ea96");
$ti-icon-download-off: unicode("f11c");
$ti-icon-drag-drop: unicode("eb89");
$ti-icon-drag-drop-2: unicode("eb88");
$ti-icon-drone: unicode("ed79");
$ti-icon-drone-off: unicode("ee7e");
$ti-icon-drop-circle: unicode("efde");
$ti-icon-droplet: unicode("ea97");
$ti-icon-droplet-filled: unicode("ee80");
$ti-icon-droplet-filled-2: unicode("ee7f");
$ti-icon-droplet-half: unicode("ee82");
$ti-icon-droplet-half-2: unicode("ee81");
$ti-icon-droplet-off: unicode("ee83");
$ti-icon-ear: unicode("ebce");
$ti-icon-ear-off: unicode("ee84");
$ti-icon-edit: unicode("ea98");
$ti-icon-edit-circle: unicode("ee85");
$ti-icon-edit-circle-off: unicode("f11d");
$ti-icon-edit-off: unicode("f11e");
$ti-icon-egg: unicode("eb8a");
$ti-icon-egg-off: unicode("f11f");
$ti-icon-elevator: unicode("efdf");
$ti-icon-emergency-bed: unicode("ef5d");
$ti-icon-emphasis: unicode("ebcf");
$ti-icon-engine: unicode("ef7e");
$ti-icon-engine-off: unicode("f120");
$ti-icon-equal: unicode("ee87");
$ti-icon-equal-not: unicode("ee86");
$ti-icon-eraser: unicode("eb8b");
$ti-icon-eraser-off: unicode("f121");
$ti-icon-error-404: unicode("f027");
$ti-icon-error-404-off: unicode("f122");
$ti-icon-exchange: unicode("ebe7");
$ti-icon-exchange-off: unicode("f123");
$ti-icon-exclamation-mark: unicode("efb4");
$ti-icon-exclamation-mark-off: unicode("f124");
$ti-icon-exposure: unicode("eb8c");
$ti-icon-external-link: unicode("ea99");
$ti-icon-external-link-off: unicode("f125");
$ti-icon-eye: unicode("ea9a");
$ti-icon-eye-check: unicode("ee88");
$ti-icon-eye-off: unicode("ecf0");
$ti-icon-eye-table: unicode("ef5e");
$ti-icon-eyeglass: unicode("ee8a");
$ti-icon-eyeglass-2: unicode("ee89");
$ti-icon-eyeglass-off: unicode("f126");
$ti-icon-face-id: unicode("ea9b");
$ti-icon-face-id-error: unicode("efa7");
$ti-icon-face-mask: unicode("efb5");
$ti-icon-face-mask-off: unicode("f127");
$ti-icon-fall: unicode("ecb9");
$ti-icon-feather: unicode("ee8b");
$ti-icon-feather-off: unicode("f128");
$ti-icon-fence: unicode("ef2a");
$ti-icon-fence-off: unicode("f129");
$ti-icon-fidget-spinner: unicode("f068");
$ti-icon-file: unicode("eaa4");
$ti-icon-file-3d: unicode("f032");
$ti-icon-file-alert: unicode("ede6");
$ti-icon-file-analytics: unicode("ede7");
$ti-icon-file-arrow-left: unicode("f033");
$ti-icon-file-arrow-right: unicode("f034");
$ti-icon-file-barcode: unicode("f035");
$ti-icon-file-certificate: unicode("ed4d");
$ti-icon-file-chart: unicode("f036");
$ti-icon-file-check: unicode("ea9c");
$ti-icon-file-code: unicode("ebd0");
$ti-icon-file-code-2: unicode("ede8");
$ti-icon-file-database: unicode("f037");
$ti-icon-file-description: unicode("f028");
$ti-icon-file-diff: unicode("ecf1");
$ti-icon-file-digit: unicode("efa8");
$ti-icon-file-dislike: unicode("ed2a");
$ti-icon-file-dollar: unicode("efe0");
$ti-icon-file-dots: unicode("f038");
$ti-icon-file-download: unicode("ea9d");
$ti-icon-file-euro: unicode("efe1");
$ti-icon-file-export: unicode("ede9");
$ti-icon-file-horizontal: unicode("ebb0");
$ti-icon-file-import: unicode("edea");
$ti-icon-file-info: unicode("edec");
$ti-icon-file-invoice: unicode("eb67");
$ti-icon-file-like: unicode("ed2b");
$ti-icon-file-minus: unicode("ea9e");
$ti-icon-file-music: unicode("ea9f");
$ti-icon-file-off: unicode("ecf2");
$ti-icon-file-pencil: unicode("f039");
$ti-icon-file-phone: unicode("ecdc");
$ti-icon-file-plus: unicode("eaa0");
$ti-icon-file-power: unicode("f03a");
$ti-icon-file-report: unicode("eded");
$ti-icon-file-rss: unicode("f03b");
$ti-icon-file-scissors: unicode("f03c");
$ti-icon-file-search: unicode("ed5d");
$ti-icon-file-settings: unicode("f029");
$ti-icon-file-shredder: unicode("eaa1");
$ti-icon-file-signal: unicode("f03d");
$ti-icon-file-spreadsheet: unicode("f03e");
$ti-icon-file-star: unicode("f03f");
$ti-icon-file-symlink: unicode("ed53");
$ti-icon-file-text: unicode("eaa2");
$ti-icon-file-time: unicode("f040");
$ti-icon-file-typography: unicode("f041");
$ti-icon-file-unknown: unicode("f042");
$ti-icon-file-upload: unicode("ec91");
$ti-icon-file-vector: unicode("f043");
$ti-icon-file-x: unicode("eaa3");
$ti-icon-file-zip: unicode("ed4e");
$ti-icon-files: unicode("edef");
$ti-icon-files-off: unicode("edee");
$ti-icon-filter: unicode("eaa5");
$ti-icon-filter-off: unicode("ed2c");
$ti-icon-fingerprint: unicode("ebd1");
$ti-icon-fingerprint-off: unicode("f12a");
$ti-icon-firetruck: unicode("ebe8");
$ti-icon-first-aid-kit: unicode("ef5f");
$ti-icon-fish: unicode("ef2b");
$ti-icon-fish-hook: unicode("f1f9");
$ti-icon-fish-off: unicode("f12b");
$ti-icon-flag: unicode("eaa6");
$ti-icon-flag-2: unicode("ee8c");
$ti-icon-flag-2-off: unicode("f12c");
$ti-icon-flag-3: unicode("ee8d");
$ti-icon-flag-off: unicode("f12d");
$ti-icon-flame: unicode("ec2c");
$ti-icon-flame-off: unicode("f12e");
$ti-icon-flare: unicode("ee8e");
$ti-icon-flask: unicode("ebd2");
$ti-icon-flask-2: unicode("ef60");
$ti-icon-flask-2-off: unicode("f12f");
$ti-icon-flask-off: unicode("f130");
$ti-icon-flip-horizontal: unicode("eaa7");
$ti-icon-flip-vertical: unicode("eaa8");
$ti-icon-float-center: unicode("ebb1");
$ti-icon-float-left: unicode("ebb2");
$ti-icon-float-none: unicode("ed13");
$ti-icon-float-right: unicode("ebb3");
$ti-icon-flower: unicode("eff6");
$ti-icon-flower-off: unicode("f131");
$ti-icon-focus: unicode("eb8d");
$ti-icon-focus-2: unicode("ebd3");
$ti-icon-focus-centered: unicode("f02a");
$ti-icon-fold: unicode("ed56");
$ti-icon-fold-down: unicode("ed54");
$ti-icon-fold-up: unicode("ed55");
$ti-icon-folder: unicode("eaad");
$ti-icon-folder-minus: unicode("eaaa");
$ti-icon-folder-off: unicode("ed14");
$ti-icon-folder-plus: unicode("eaab");
$ti-icon-folder-x: unicode("eaac");
$ti-icon-folders: unicode("eaae");
$ti-icon-folders-off: unicode("f133");
$ti-icon-forbid: unicode("ebd5");
$ti-icon-forbid-2: unicode("ebd4");
$ti-icon-forklift: unicode("ebe9");
$ti-icon-forms: unicode("ee8f");
$ti-icon-fountain: unicode("f09b");
$ti-icon-fountain-off: unicode("f134");
$ti-icon-frame: unicode("eaaf");
$ti-icon-frame-off: unicode("f135");
$ti-icon-free-rights: unicode("efb6");
$ti-icon-fridge: unicode("f1fa");
$ti-icon-friends: unicode("eab0");
$ti-icon-friends-off: unicode("f136");
$ti-icon-function: unicode("f225");
$ti-icon-gas-station: unicode("ec7d");
$ti-icon-gas-station-off: unicode("f137");
$ti-icon-gauge: unicode("eab1");
$ti-icon-gauge-off: unicode("f138");
$ti-icon-gavel: unicode("ef90");
$ti-icon-gender-agender: unicode("f0e1");
$ti-icon-gender-androgyne: unicode("f0e2");
$ti-icon-gender-bigender: unicode("f0e3");
$ti-icon-gender-demiboy: unicode("f0e4");
$ti-icon-gender-demigirl: unicode("f0e5");
$ti-icon-gender-epicene: unicode("f0e6");
$ti-icon-gender-female: unicode("f0e7");
$ti-icon-gender-femme: unicode("f0e8");
$ti-icon-gender-genderfluid: unicode("f0e9");
$ti-icon-gender-genderless: unicode("f0ea");
$ti-icon-gender-genderqueer: unicode("f0eb");
$ti-icon-gender-hermaphrodite: unicode("f0ec");
$ti-icon-gender-intergender: unicode("f0ed");
$ti-icon-gender-male: unicode("f0ee");
$ti-icon-gender-neutrois: unicode("f0ef");
$ti-icon-gender-third: unicode("f0f0");
$ti-icon-gender-transgender: unicode("f0f1");
$ti-icon-gender-trasvesti: unicode("f0f2");
$ti-icon-geometry: unicode("ee90");
$ti-icon-ghost: unicode("eb8e");
$ti-icon-gift: unicode("eb68");
$ti-icon-git-branch: unicode("eab2");
$ti-icon-git-commit: unicode("eab3");
$ti-icon-git-compare: unicode("eab4");
$ti-icon-git-fork: unicode("eb8f");
$ti-icon-git-merge: unicode("eab5");
$ti-icon-git-pull-request: unicode("eab6");
$ti-icon-git-pull-request-closed: unicode("ef7f");
$ti-icon-git-pull-request-draft: unicode("efb7");
$ti-icon-gizmo: unicode("f02b");
$ti-icon-glass: unicode("eab8");
$ti-icon-glass-full: unicode("eab7");
$ti-icon-glass-off: unicode("ee91");
$ti-icon-globe: unicode("eab9");
$ti-icon-globe-off: unicode("f139");
$ti-icon-golf: unicode("ed8c");
$ti-icon-golf-off: unicode("f13a");
$ti-icon-gps: unicode("ed7a");
$ti-icon-grain: unicode("ee92");
$ti-icon-grid-dots: unicode("eaba");
$ti-icon-grid-pattern: unicode("efc9");
$ti-icon-grill: unicode("efa9");
$ti-icon-grill-off: unicode("f13b");
$ti-icon-grip-horizontal: unicode("ec00");
$ti-icon-grip-vertical: unicode("ec01");
$ti-icon-growth: unicode("ee93");
$ti-icon-h-1: unicode("ec94");
$ti-icon-h-2: unicode("ec95");
$ti-icon-h-3: unicode("ec96");
$ti-icon-h-4: unicode("ec97");
$ti-icon-h-5: unicode("ec98");
$ti-icon-h-6: unicode("ec99");
$ti-icon-hammer: unicode("ef91");
$ti-icon-hammer-off: unicode("f13c");
$ti-icon-hand-click: unicode("ef4f");
$ti-icon-hand-finger: unicode("ee94");
$ti-icon-hand-finger-off: unicode("f13d");
$ti-icon-hand-grab: unicode("f091");
$ti-icon-hand-little-finger: unicode("ee95");
$ti-icon-hand-middle-finger: unicode("ec2d");
$ti-icon-hand-move: unicode("ef50");
$ti-icon-hand-off: unicode("ed15");
$ti-icon-hand-ring-finger: unicode("ee96");
$ti-icon-hand-rock: unicode("ee97");
$ti-icon-hand-stop: unicode("ec2e");
$ti-icon-hand-three-fingers: unicode("ee98");
$ti-icon-hand-two-fingers: unicode("ee99");
$ti-icon-hanger: unicode("ee9a");
$ti-icon-hanger-2: unicode("f09c");
$ti-icon-hanger-off: unicode("f13e");
$ti-icon-hash: unicode("eabc");
$ti-icon-haze: unicode("efaa");
$ti-icon-heading: unicode("ee9b");
$ti-icon-heading-off: unicode("f13f");
$ti-icon-headphones: unicode("eabd");
$ti-icon-headphones-off: unicode("ed1d");
$ti-icon-headset: unicode("eb90");
$ti-icon-health-recognition: unicode("f1fb");
$ti-icon-heart: unicode("eabe");
$ti-icon-heart-broken: unicode("ecba");
$ti-icon-heart-handshake: unicode("f0f3");
$ti-icon-heart-minus: unicode("f140");
$ti-icon-heart-off: unicode("f141");
$ti-icon-heart-plus: unicode("f142");
$ti-icon-heart-rate-monitor: unicode("ef61");
$ti-icon-heartbeat: unicode("ef92");
$ti-icon-helicopter: unicode("ed8e");
$ti-icon-helicopter-landing: unicode("ed8d");
$ti-icon-helmet: unicode("efca");
$ti-icon-helmet-off: unicode("f143");
$ti-icon-help: unicode("eabf");
$ti-icon-hexagon: unicode("ec02");
$ti-icon-hexagon-off: unicode("ee9c");
$ti-icon-hexagons: unicode("f09d");
$ti-icon-hierarchy: unicode("ee9e");
$ti-icon-hierarchy-2: unicode("ee9d");
$ti-icon-highlight: unicode("ef3f");
$ti-icon-highlight-off: unicode("f144");
$ti-icon-history: unicode("ebea");
$ti-icon-history-toggle: unicode("f1fc");
$ti-icon-home: unicode("eac1");
$ti-icon-home-2: unicode("eac0");
$ti-icon-home-off: unicode("f145");
$ti-icon-hotel-service: unicode("ef80");
$ti-icon-hourglass: unicode("ef93");
$ti-icon-hourglass-empty: unicode("f146");
$ti-icon-hourglass-high: unicode("f092");
$ti-icon-hourglass-low: unicode("f093");
$ti-icon-hourglass-off: unicode("f147");
$ti-icon-ice-cream: unicode("eac2");
$ti-icon-ice-cream-2: unicode("ee9f");
$ti-icon-ice-cream-off: unicode("f148");
$ti-icon-ice-skating: unicode("efcb");
$ti-icon-icons: unicode("f1d4");
$ti-icon-id: unicode("eac3");
$ti-icon-id-badge: unicode("eff7");
$ti-icon-id-badge-2: unicode("f076");
$ti-icon-id-off: unicode("f149");
$ti-icon-inbox: unicode("eac4");
$ti-icon-inbox-off: unicode("f14a");
$ti-icon-indent-decrease: unicode("eb91");
$ti-icon-indent-increase: unicode("eb92");
$ti-icon-infinity: unicode("eb69");
$ti-icon-info-circle: unicode("eac5");
$ti-icon-info-square: unicode("eac6");
$ti-icon-italic: unicode("eb93");
$ti-icon-jewish-star: unicode("f1d5");
$ti-icon-jump-rope: unicode("ed8f");
$ti-icon-karate: unicode("ed32");
$ti-icon-kayak: unicode("f1d6");
$ti-icon-kering: unicode("efb8");
$ti-icon-key: unicode("eac7");
$ti-icon-key-off: unicode("f14b");
$ti-icon-keyboard: unicode("ebd6");
$ti-icon-keyboard-hide: unicode("ec7e");
$ti-icon-keyboard-off: unicode("eea0");
$ti-icon-keyboard-show: unicode("ec7f");
$ti-icon-ladder: unicode("efe2");
$ti-icon-ladder-off: unicode("f14c");
$ti-icon-lamp: unicode("efab");
$ti-icon-lamp-2: unicode("f09e");
$ti-icon-lamp-off: unicode("f14d");
$ti-icon-language: unicode("ebbe");
$ti-icon-language-hiragana: unicode("ef77");
$ti-icon-language-katakana: unicode("ef78");
$ti-icon-language-off: unicode("f14e");
$ti-icon-lasso: unicode("efac");
$ti-icon-lasso-off: unicode("f14f");
$ti-icon-layers-difference: unicode("eac8");
$ti-icon-layers-intersect: unicode("eac9");
$ti-icon-layers-intersect-2: unicode("eff8");
$ti-icon-layers-linked: unicode("eea1");
$ti-icon-layers-off: unicode("f150");
$ti-icon-layers-subtract: unicode("eaca");
$ti-icon-layers-union: unicode("eacb");
$ti-icon-layout: unicode("eadb");
$ti-icon-layout-2: unicode("eacc");
$ti-icon-layout-align-bottom: unicode("eacd");
$ti-icon-layout-align-center: unicode("eace");
$ti-icon-layout-align-left: unicode("eacf");
$ti-icon-layout-align-middle: unicode("ead0");
$ti-icon-layout-align-right: unicode("ead1");
$ti-icon-layout-align-top: unicode("ead2");
$ti-icon-layout-board: unicode("ef95");
$ti-icon-layout-board-split: unicode("ef94");
$ti-icon-layout-bottombar: unicode("ead3");
$ti-icon-layout-cards: unicode("ec13");
$ti-icon-layout-columns: unicode("ead4");
$ti-icon-layout-dashboard: unicode("f02c");
$ti-icon-layout-distribute-horizontal: unicode("ead5");
$ti-icon-layout-distribute-vertical: unicode("ead6");
$ti-icon-layout-grid: unicode("edba");
$ti-icon-layout-grid-add: unicode("edb9");
$ti-icon-layout-kanban: unicode("ec3f");
$ti-icon-layout-list: unicode("ec14");
$ti-icon-layout-navbar: unicode("ead7");
$ti-icon-layout-off: unicode("f151");
$ti-icon-layout-rows: unicode("ead8");
$ti-icon-layout-sidebar: unicode("eada");
$ti-icon-layout-sidebar-left-collapse: unicode("f004");
$ti-icon-layout-sidebar-left-expand: unicode("f005");
$ti-icon-layout-sidebar-right: unicode("ead9");
$ti-icon-layout-sidebar-right-collapse: unicode("f006");
$ti-icon-layout-sidebar-right-expand: unicode("f007");
$ti-icon-leaf: unicode("ed4f");
$ti-icon-leaf-off: unicode("f152");
$ti-icon-lego: unicode("eadc");
$ti-icon-lemon: unicode("ef10");
$ti-icon-lemon-2: unicode("ef81");
$ti-icon-letter-a: unicode("ec50");
$ti-icon-letter-b: unicode("ec51");
$ti-icon-letter-c: unicode("ec52");
$ti-icon-letter-case: unicode("eea5");
$ti-icon-letter-case-lower: unicode("eea2");
$ti-icon-letter-case-toggle: unicode("eea3");
$ti-icon-letter-case-upper: unicode("eea4");
$ti-icon-letter-d: unicode("ec53");
$ti-icon-letter-e: unicode("ec54");
$ti-icon-letter-f: unicode("ec55");
$ti-icon-letter-g: unicode("ec56");
$ti-icon-letter-h: unicode("ec57");
$ti-icon-letter-i: unicode("ec58");
$ti-icon-letter-j: unicode("ec59");
$ti-icon-letter-k: unicode("ec5a");
$ti-icon-letter-l: unicode("ec5b");
$ti-icon-letter-m: unicode("ec5c");
$ti-icon-letter-n: unicode("ec5d");
$ti-icon-letter-o: unicode("ec5e");
$ti-icon-letter-p: unicode("ec5f");
$ti-icon-letter-q: unicode("ec60");
$ti-icon-letter-r: unicode("ec61");
$ti-icon-letter-s: unicode("ec62");
$ti-icon-letter-spacing: unicode("eea6");
$ti-icon-letter-t: unicode("ec63");
$ti-icon-letter-u: unicode("ec64");
$ti-icon-letter-v: unicode("ec65");
$ti-icon-letter-w: unicode("ec66");
$ti-icon-letter-x: unicode("ec67");
$ti-icon-letter-y: unicode("ec68");
$ti-icon-letter-z: unicode("ec69");
$ti-icon-license: unicode("ebc0");
$ti-icon-license-off: unicode("f153");
$ti-icon-lifebuoy: unicode("eadd");
$ti-icon-lifebuoy-off: unicode("f154");
$ti-icon-line: unicode("ec40");
$ti-icon-line-dashed: unicode("eea7");
$ti-icon-line-dotted: unicode("eea8");
$ti-icon-line-height: unicode("eb94");
$ti-icon-link: unicode("eade");
$ti-icon-list: unicode("eb6b");
$ti-icon-list-check: unicode("eb6a");
$ti-icon-list-details: unicode("ef40");
$ti-icon-list-numbers: unicode("ef11");
$ti-icon-list-search: unicode("eea9");
$ti-icon-live-photo: unicode("eadf");
$ti-icon-live-view: unicode("ec6b");
$ti-icon-loader: unicode("eca3");
$ti-icon-loader-2: unicode("f226");
$ti-icon-loader-quarter: unicode("eca2");
$ti-icon-location: unicode("eae0");
$ti-icon-location-off: unicode("f155");
$ti-icon-lock: unicode("eae2");
$ti-icon-lock-access: unicode("eeaa");
$ti-icon-lock-off: unicode("ed1e");
$ti-icon-lock-open: unicode("eae1");
$ti-icon-lock-open-off: unicode("f156");
$ti-icon-lock-square: unicode("ef51");
$ti-icon-login: unicode("eba7");
$ti-icon-logout: unicode("eba8");
$ti-icon-lollipop: unicode("efcc");
$ti-icon-lollipop-off: unicode("f157");
$ti-icon-luggage: unicode("efad");
$ti-icon-luggage-off: unicode("f158");
$ti-icon-lungs: unicode("ef62");
$ti-icon-macro: unicode("eeab");
$ti-icon-magnet: unicode("eae3");
$ti-icon-magnet-off: unicode("f159");
$ti-icon-mail: unicode("eae5");
$ti-icon-mail-fast: unicode("f069");
$ti-icon-mail-forward: unicode("eeac");
$ti-icon-mail-off: unicode("f15a");
$ti-icon-mail-opened: unicode("eae4");
$ti-icon-mailbox: unicode("eead");
$ti-icon-mailbox-off: unicode("f15b");
$ti-icon-man: unicode("eae6");
$ti-icon-manual-gearbox: unicode("ed7b");
$ti-icon-map: unicode("eae9");
$ti-icon-map-2: unicode("eae7");
$ti-icon-map-off: unicode("f15c");
$ti-icon-map-pin: unicode("eae8");
$ti-icon-map-pin-off: unicode("ecf3");
$ti-icon-map-pins: unicode("ed5e");
$ti-icon-map-search: unicode("ef82");
$ti-icon-markdown: unicode("ec41");
$ti-icon-marquee: unicode("ec77");
$ti-icon-marquee-2: unicode("eeae");
$ti-icon-marquee-off: unicode("f15d");
$ti-icon-mars: unicode("ec80");
$ti-icon-mask: unicode("eeb0");
$ti-icon-mask-off: unicode("eeaf");
$ti-icon-massage: unicode("eeb1");
$ti-icon-math: unicode("ebeb");
$ti-icon-math-avg: unicode("f0f4");
$ti-icon-math-function: unicode("eeb2");
$ti-icon-math-function-off: unicode("f15e");
$ti-icon-math-max: unicode("f0f5");
$ti-icon-math-min: unicode("f0f6");
$ti-icon-math-symbols: unicode("eeb3");
$ti-icon-maximize: unicode("eaea");
$ti-icon-maximize-off: unicode("f15f");
$ti-icon-meat: unicode("ef12");
$ti-icon-medal: unicode("ec78");
$ti-icon-medal-2: unicode("efcd");
$ti-icon-medical-cross: unicode("ec2f");
$ti-icon-medical-cross-off: unicode("f160");
$ti-icon-medicine-syrup: unicode("ef63");
$ti-icon-menu: unicode("eaeb");
$ti-icon-menu-2: unicode("ec42");
$ti-icon-message: unicode("eaef");
$ti-icon-message-2: unicode("eaec");
$ti-icon-message-2-code: unicode("f012");
$ti-icon-message-2-share: unicode("f077");
$ti-icon-message-circle: unicode("eaed");
$ti-icon-message-circle-2: unicode("ed3f");
$ti-icon-message-circle-off: unicode("ed40");
$ti-icon-message-code: unicode("f013");
$ti-icon-message-dots: unicode("eaee");
$ti-icon-message-language: unicode("efae");
$ti-icon-message-off: unicode("ed41");
$ti-icon-message-plus: unicode("ec9a");
$ti-icon-message-report: unicode("ec9b");
$ti-icon-message-share: unicode("f078");
$ti-icon-messages: unicode("eb6c");
$ti-icon-messages-off: unicode("ed42");
$ti-icon-meteor: unicode("f1fd");
$ti-icon-microphone: unicode("eaf0");
$ti-icon-microphone-2: unicode("ef2c");
$ti-icon-microphone-off: unicode("ed16");
$ti-icon-microscope: unicode("ef64");
$ti-icon-military-award: unicode("f079");
$ti-icon-military-rank: unicode("efcf");
$ti-icon-milk: unicode("ef13");
$ti-icon-minimize: unicode("eaf1");
$ti-icon-minus: unicode("eaf2");
$ti-icon-minus-vertical: unicode("eeb4");
$ti-icon-mist: unicode("ec30");
$ti-icon-mood-boy: unicode("ed2d");
$ti-icon-mood-confuzed: unicode("eaf3");
$ti-icon-mood-crazy-happy: unicode("ed90");
$ti-icon-mood-cry: unicode("ecbb");
$ti-icon-mood-empty: unicode("eeb5");
$ti-icon-mood-happy: unicode("eaf4");
$ti-icon-mood-kid: unicode("ec03");
$ti-icon-mood-nervous: unicode("ef96");
$ti-icon-mood-neutral: unicode("eaf5");
$ti-icon-mood-off: unicode("f161");
$ti-icon-mood-sad: unicode("eaf6");
$ti-icon-mood-smile: unicode("eaf7");
$ti-icon-mood-suprised: unicode("ec04");
$ti-icon-mood-tongue: unicode("eb95");
$ti-icon-moon: unicode("eaf8");
$ti-icon-moon-2: unicode("ece6");
$ti-icon-moon-off: unicode("f162");
$ti-icon-moon-stars: unicode("ece7");
$ti-icon-moped: unicode("ecbc");
$ti-icon-motorbike: unicode("eeb6");
$ti-icon-mountain: unicode("ef97");
$ti-icon-mouse: unicode("eaf9");
$ti-icon-mouse-2: unicode("f1d7");
$ti-icon-mouse-off: unicode("f163");
$ti-icon-movie: unicode("eafa");
$ti-icon-movie-off: unicode("f164");
$ti-icon-mug: unicode("eafb");
$ti-icon-mug-off: unicode("f165");
$ti-icon-multiplier-0-5x: unicode("ef41");
$ti-icon-multiplier-1-5x: unicode("ef42");
$ti-icon-multiplier-1x: unicode("ef43");
$ti-icon-multiplier-2x: unicode("ef44");
$ti-icon-mushroom: unicode("ef14");
$ti-icon-music: unicode("eafc");
$ti-icon-music-off: unicode("f166");
$ti-icon-network: unicode("f09f");
$ti-icon-new-section: unicode("ebc1");
$ti-icon-news: unicode("eafd");
$ti-icon-news-off: unicode("f167");
$ti-icon-nfc: unicode("eeb7");
$ti-icon-nfc-off: unicode("f168");
$ti-icon-no-copyright: unicode("efb9");
$ti-icon-no-creative-commons: unicode("efba");
$ti-icon-no-derivatives: unicode("efbb");
$ti-icon-north-star: unicode("f014");
$ti-icon-note: unicode("eb6d");
$ti-icon-note-off: unicode("f169");
$ti-icon-notebook: unicode("eb96");
$ti-icon-notes: unicode("eb6e");
$ti-icon-notes-off: unicode("f16a");
$ti-icon-notification: unicode("eafe");
$ti-icon-notification-off: unicode("f16b");
$ti-icon-number: unicode("f1fe");
$ti-icon-number-0: unicode("edf0");
$ti-icon-number-1: unicode("edf1");
$ti-icon-number-2: unicode("edf2");
$ti-icon-number-3: unicode("edf3");
$ti-icon-number-4: unicode("edf4");
$ti-icon-number-5: unicode("edf5");
$ti-icon-number-6: unicode("edf6");
$ti-icon-number-7: unicode("edf7");
$ti-icon-number-8: unicode("edf8");
$ti-icon-number-9: unicode("edf9");
$ti-icon-numbers: unicode("f015");
$ti-icon-nurse: unicode("ef65");
$ti-icon-octagon: unicode("ecbd");
$ti-icon-octagon-off: unicode("eeb8");
$ti-icon-old: unicode("eeb9");
$ti-icon-olympics: unicode("eeba");
$ti-icon-omega: unicode("eb97");
$ti-icon-outlet: unicode("ebd7");
$ti-icon-oval: unicode("f02e");
$ti-icon-oval-vertical: unicode("f02d");
$ti-icon-overline: unicode("eebb");
$ti-icon-package: unicode("eaff");
$ti-icon-package-off: unicode("f16c");
$ti-icon-packge-export: unicode("f07a");
$ti-icon-packge-import: unicode("f07b");
$ti-icon-pacman: unicode("eebc");
$ti-icon-page-break: unicode("ec81");
$ti-icon-paint: unicode("eb00");
$ti-icon-paint-off: unicode("f16d");
$ti-icon-palette: unicode("eb01");
$ti-icon-palette-off: unicode("f16e");
$ti-icon-panorama-horizontal: unicode("ed33");
$ti-icon-panorama-vertical: unicode("ed34");
$ti-icon-paper-bag: unicode("f02f");
$ti-icon-paper-bag-off: unicode("f16f");
$ti-icon-paperclip: unicode("eb02");
$ti-icon-parachute: unicode("ed7c");
$ti-icon-parachute-off: unicode("f170");
$ti-icon-parentheses: unicode("ebd8");
$ti-icon-parentheses-off: unicode("f171");
$ti-icon-parking: unicode("eb03");
$ti-icon-parking-off: unicode("f172");
$ti-icon-paw: unicode("eff9");
$ti-icon-peace: unicode("ecbe");
$ti-icon-pencil: unicode("eb04");
$ti-icon-pencil-minus: unicode("f1eb");
$ti-icon-pencil-off: unicode("f173");
$ti-icon-pencil-plus: unicode("f1ec");
$ti-icon-pennant: unicode("ed7d");
$ti-icon-pennant-2: unicode("f06a");
$ti-icon-pennant-off: unicode("f174");
$ti-icon-pentagon: unicode("efe3");
$ti-icon-pepper: unicode("ef15");
$ti-icon-pepper-off: unicode("f175");
$ti-icon-percentage: unicode("ecf4");
$ti-icon-perspective: unicode("eebd");
$ti-icon-perspective-off: unicode("f176");
$ti-icon-phone: unicode("eb09");
$ti-icon-phone-call: unicode("eb05");
$ti-icon-phone-calling: unicode("ec43");
$ti-icon-phone-check: unicode("ec05");
$ti-icon-phone-incoming: unicode("eb06");
$ti-icon-phone-off: unicode("ecf5");
$ti-icon-phone-outgoing: unicode("eb07");
$ti-icon-phone-pause: unicode("eb08");
$ti-icon-phone-plus: unicode("ec06");
$ti-icon-phone-x: unicode("ec07");
$ti-icon-photo: unicode("eb0a");
$ti-icon-photo-off: unicode("ecf6");
$ti-icon-physotherapist: unicode("eebe");
$ti-icon-picture-in-picture: unicode("ed35");
$ti-icon-picture-in-picture-off: unicode("ed43");
$ti-icon-picture-in-picture-on: unicode("ed44");
$ti-icon-picture-in-picture-top: unicode("efe4");
$ti-icon-pig: unicode("ef52");
$ti-icon-pig-off: unicode("f177");
$ti-icon-pill: unicode("ec44");
$ti-icon-pill-off: unicode("f178");
$ti-icon-pills: unicode("ef66");
$ti-icon-pin: unicode("ec9c");
$ti-icon-pinned: unicode("ed60");
$ti-icon-pinned-off: unicode("ed5f");
$ti-icon-pizza: unicode("edbb");
$ti-icon-pizza-off: unicode("f179");
$ti-icon-plane: unicode("eb6f");
$ti-icon-plane-arrival: unicode("eb99");
$ti-icon-plane-departure: unicode("eb9a");
$ti-icon-plane-inflight: unicode("ef98");
$ti-icon-plane-off: unicode("f17a");
$ti-icon-plane-tilt: unicode("f1ed");
$ti-icon-planet: unicode("ec08");
$ti-icon-planet-off: unicode("f17b");
$ti-icon-plant: unicode("ed50");
$ti-icon-plant-2: unicode("ed7e");
$ti-icon-plant-2-off: unicode("f17c");
$ti-icon-plant-off: unicode("f17d");
$ti-icon-play-card: unicode("eebf");
$ti-icon-play-card-off: unicode("f17e");
$ti-icon-player-eject: unicode("efbc");
$ti-icon-player-pause: unicode("ed45");
$ti-icon-player-play: unicode("ed46");
$ti-icon-player-record: unicode("ed47");
$ti-icon-player-skip-back: unicode("ed48");
$ti-icon-player-skip-forward: unicode("ed49");
$ti-icon-player-stop: unicode("ed4a");
$ti-icon-player-track-next: unicode("ed4b");
$ti-icon-player-track-prev: unicode("ed4c");
$ti-icon-playlist: unicode("eec0");
$ti-icon-playlist-add: unicode("f008");
$ti-icon-playlist-off: unicode("f17f");
$ti-icon-playlist-x: unicode("f009");
$ti-icon-plug: unicode("ebd9");
$ti-icon-plug-connected: unicode("f00a");
$ti-icon-plug-connected-x: unicode("f0a0");
$ti-icon-plug-off: unicode("f180");
$ti-icon-plug-x: unicode("f0a1");
$ti-icon-plus: unicode("eb0b");
$ti-icon-podium: unicode("f1d8");
$ti-icon-point: unicode("eb0c");
$ti-icon-point-off: unicode("f181");
$ti-icon-pokeball: unicode("eec1");
$ti-icon-polaroid: unicode("eec2");
$ti-icon-polygon: unicode("efd0");
$ti-icon-polygon-off: unicode("f182");
$ti-icon-pool: unicode("ed91");
$ti-icon-power: unicode("eb0d");
$ti-icon-pray: unicode("ecbf");
$ti-icon-premium-rights: unicode("efbd");
$ti-icon-prescription: unicode("ef99");
$ti-icon-presentation: unicode("eb70");
$ti-icon-presentation-analytics: unicode("eec3");
$ti-icon-presentation-off: unicode("f183");
$ti-icon-printer: unicode("eb0e");
$ti-icon-printer-off: unicode("f184");
$ti-icon-prison: unicode("ef79");
$ti-icon-prompt: unicode("eb0f");
$ti-icon-propeller: unicode("eec4");
$ti-icon-propeller-off: unicode("f185");
$ti-icon-puzzle: unicode("eb10");
$ti-icon-puzzle-2: unicode("ef83");
$ti-icon-puzzle-off: unicode("f186");
$ti-icon-pyramid: unicode("eec5");
$ti-icon-pyramid-off: unicode("f187");
$ti-icon-qrcode: unicode("eb11");
$ti-icon-question-mark: unicode("ec9d");
$ti-icon-quote: unicode("efbe");
$ti-icon-quote-off: unicode("f188");
$ti-icon-radar: unicode("f017");
$ti-icon-radar-2: unicode("f016");
$ti-icon-radio: unicode("ef2d");
$ti-icon-radioactive: unicode("ecc0");
$ti-icon-radioactive-off: unicode("f189");
$ti-icon-radius-bottom-left: unicode("eec6");
$ti-icon-radius-bottom-right: unicode("eec7");
$ti-icon-radius-top-left: unicode("eec8");
$ti-icon-radius-top-right: unicode("eec9");
$ti-icon-rainbow: unicode("edbc");
$ti-icon-rainbow-off: unicode("f18a");
$ti-icon-receipt: unicode("edfd");
$ti-icon-receipt-2: unicode("edfa");
$ti-icon-receipt-off: unicode("edfb");
$ti-icon-receipt-refund: unicode("edfc");
$ti-icon-receipt-tax: unicode("edbd");
$ti-icon-recharging: unicode("eeca");
$ti-icon-record-mail: unicode("eb12");
$ti-icon-record-mail-off: unicode("f18b");
$ti-icon-rectangle: unicode("ed37");
$ti-icon-rectangle-vertical: unicode("ed36");
$ti-icon-recycle: unicode("eb9b");
$ti-icon-recycle-off: unicode("f18c");
$ti-icon-refresh: unicode("eb13");
$ti-icon-refresh-alert: unicode("ed57");
$ti-icon-refresh-dot: unicode("efbf");
$ti-icon-refresh-off: unicode("f18d");
$ti-icon-registered: unicode("eb14");
$ti-icon-relation-many-to-many: unicode("ed7f");
$ti-icon-relation-one-to-many: unicode("ed80");
$ti-icon-relation-one-to-one: unicode("ed81");
$ti-icon-repeat: unicode("eb72");
$ti-icon-repeat-off: unicode("f18e");
$ti-icon-repeat-once: unicode("eb71");
$ti-icon-replace: unicode("ebc7");
$ti-icon-report: unicode("eece");
$ti-icon-report-analytics: unicode("eecb");
$ti-icon-report-medical: unicode("eecc");
$ti-icon-report-money: unicode("eecd");
$ti-icon-report-off: unicode("f18f");
$ti-icon-report-search: unicode("ef84");
$ti-icon-resize: unicode("eecf");
$ti-icon-ripple: unicode("ed82");
$ti-icon-ripple-off: unicode("f190");
$ti-icon-road: unicode("f018");
$ti-icon-road-off: unicode("f191");
$ti-icon-road-sign: unicode("ecdd");
$ti-icon-robot: unicode("f00b");
$ti-icon-robot-off: unicode("f192");
$ti-icon-rocket: unicode("ec45");
$ti-icon-rocket-off: unicode("f193");
$ti-icon-roller-skating: unicode("efd1");
$ti-icon-rollercoaster: unicode("f0a2");
$ti-icon-rotate: unicode("eb16");
$ti-icon-rotate-2: unicode("ebb4");
$ti-icon-rotate-360: unicode("ef85");
$ti-icon-rotate-clockwise: unicode("eb15");
$ti-icon-rotate-clockwise-2: unicode("ebb5");
$ti-icon-rotate-dot: unicode("efe5");
$ti-icon-rotate-rectangle: unicode("ec15");
$ti-icon-route: unicode("eb17");
$ti-icon-route-off: unicode("f194");
$ti-icon-router: unicode("eb18");
$ti-icon-row-insert-bottom: unicode("eed0");
$ti-icon-row-insert-top: unicode("eed1");
$ti-icon-rss: unicode("eb19");
$ti-icon-ruler: unicode("eb1a");
$ti-icon-ruler-2: unicode("eed2");
$ti-icon-ruler-2-off: unicode("f195");
$ti-icon-ruler-off: unicode("f196");
$ti-icon-run: unicode("ec82");
$ti-icon-sailboat: unicode("ec83");
$ti-icon-salt: unicode("ef16");
$ti-icon-satellite: unicode("eed3");
$ti-icon-satellite-off: unicode("f197");
$ti-icon-sausage: unicode("ef17");
$ti-icon-scale: unicode("ebc2");
$ti-icon-scale-off: unicode("f198");
$ti-icon-scale-outline: unicode("ef53");
$ti-icon-scale-outline-off: unicode("f199");
$ti-icon-scan: unicode("ebc8");
$ti-icon-scan-eye: unicode("f1ff");
$ti-icon-schema: unicode("f200");
$ti-icon-school: unicode("ecf7");
$ti-icon-school-off: unicode("f19a");
$ti-icon-scissors: unicode("eb1b");
$ti-icon-scissors-off: unicode("f19b");
$ti-icon-scooter: unicode("ec6c");
$ti-icon-scooter-electric: unicode("ecc1");
$ti-icon-screen-share: unicode("ed18");
$ti-icon-screen-share-off: unicode("ed17");
$ti-icon-screenshot: unicode("f201");
$ti-icon-scribble: unicode("f0a3");
$ti-icon-scuba-mask: unicode("eed4");
$ti-icon-search: unicode("eb1c");
$ti-icon-search-off: unicode("f19c");
$ti-icon-section: unicode("eed5");
$ti-icon-section-sign: unicode("f019");
$ti-icon-seeding: unicode("ed51");
$ti-icon-seeding-off: unicode("f19d");
$ti-icon-select: unicode("ec9e");
$ti-icon-selector: unicode("eb1d");
$ti-icon-send: unicode("eb1e");
$ti-icon-separator: unicode("ebda");
$ti-icon-separator-horizontal: unicode("ec79");
$ti-icon-separator-vertical: unicode("ec7a");
$ti-icon-server: unicode("eb1f");
$ti-icon-server-2: unicode("f07c");
$ti-icon-server-off: unicode("f19e");
$ti-icon-servicemark: unicode("ec09");
$ti-icon-settings: unicode("eb20");
$ti-icon-settings-automation: unicode("eed6");
$ti-icon-settings-off: unicode("f19f");
$ti-icon-shadow: unicode("eed8");
$ti-icon-shadow-off: unicode("eed7");
$ti-icon-shape: unicode("eb9c");
$ti-icon-shape-2: unicode("eed9");
$ti-icon-shape-3: unicode("eeda");
$ti-icon-shape-off: unicode("f1a0");
$ti-icon-share: unicode("eb21");
$ti-icon-share-off: unicode("f1a1");
$ti-icon-shield: unicode("eb24");
$ti-icon-shield-check: unicode("eb22");
$ti-icon-shield-checkered: unicode("ef9a");
$ti-icon-shield-chevron: unicode("ef9b");
$ti-icon-shield-lock: unicode("ed58");
$ti-icon-shield-off: unicode("ecf8");
$ti-icon-shield-x: unicode("eb23");
$ti-icon-ship: unicode("ec84");
$ti-icon-shirt: unicode("ec0a");
$ti-icon-shirt-off: unicode("f1a2");
$ti-icon-shoe: unicode("efd2");
$ti-icon-shoe-off: unicode("f1a4");
$ti-icon-shopping-cart: unicode("eb25");
$ti-icon-shopping-cart-discount: unicode("eedb");
$ti-icon-shopping-cart-off: unicode("eedc");
$ti-icon-shopping-cart-plus: unicode("eedd");
$ti-icon-shopping-cart-x: unicode("eede");
$ti-icon-shovel: unicode("f1d9");
$ti-icon-shredder: unicode("eedf");
$ti-icon-sign-left: unicode("f06b");
$ti-icon-sign-right: unicode("f06c");
$ti-icon-signal-3g: unicode("f1ee");
$ti-icon-signal-4g: unicode("f1ef");
$ti-icon-signal-5g: unicode("f1f0");
$ti-icon-signature: unicode("eee0");
$ti-icon-signature-off: unicode("f1a5");
$ti-icon-sitemap: unicode("eb9d");
$ti-icon-sitemap-off: unicode("f1a6");
$ti-icon-skateboard: unicode("ecc2");
$ti-icon-sleigh: unicode("ef9c");
$ti-icon-slice: unicode("ebdb");
$ti-icon-slideshow: unicode("ebc9");
$ti-icon-smart-home: unicode("ecde");
$ti-icon-smart-home-off: unicode("f1a7");
$ti-icon-smoking: unicode("ecc4");
$ti-icon-smoking-no: unicode("ecc3");
$ti-icon-snowflake: unicode("ec0b");
$ti-icon-snowflake-off: unicode("f1a8");
$ti-icon-soccer-field: unicode("ed92");
$ti-icon-social: unicode("ebec");
$ti-icon-social-off: unicode("f1a9");
$ti-icon-sock: unicode("eee1");
$ti-icon-sofa: unicode("efaf");
$ti-icon-sort-ascending: unicode("eb26");
$ti-icon-sort-ascending-2: unicode("eee2");
$ti-icon-sort-ascending-letters: unicode("ef18");
$ti-icon-sort-ascending-numbers: unicode("ef19");
$ti-icon-sort-descending: unicode("eb27");
$ti-icon-sort-descending-2: unicode("eee3");
$ti-icon-sort-descending-letters: unicode("ef1a");
$ti-icon-sort-descending-numbers: unicode("ef1b");
$ti-icon-soup: unicode("ef2e");
$ti-icon-space: unicode("ec0c");
$ti-icon-space-off: unicode("f1aa");
$ti-icon-spacing-horizontal: unicode("ef54");
$ti-icon-spacing-vertical: unicode("ef55");
$ti-icon-spade: unicode("effa");
$ti-icon-speakerphone: unicode("ed61");
$ti-icon-speedboat: unicode("ed93");
$ti-icon-sport-billard: unicode("eee4");
$ti-icon-spy: unicode("f227");
$ti-icon-square: unicode("eb2c");
$ti-icon-square-0: unicode("eee5");
$ti-icon-square-1: unicode("eee6");
$ti-icon-square-2: unicode("eee7");
$ti-icon-square-3: unicode("eee8");
$ti-icon-square-4: unicode("eee9");
$ti-icon-square-5: unicode("eeea");
$ti-icon-square-6: unicode("eeeb");
$ti-icon-square-7: unicode("eeec");
$ti-icon-square-8: unicode("eeed");
$ti-icon-square-9: unicode("eeee");
$ti-icon-square-asterisk: unicode("f01a");
$ti-icon-square-check: unicode("eb28");
$ti-icon-square-dot: unicode("ed59");
$ti-icon-square-forbid: unicode("ed5b");
$ti-icon-square-forbid-2: unicode("ed5a");
$ti-icon-square-half: unicode("effb");
$ti-icon-square-minus: unicode("eb29");
$ti-icon-square-off: unicode("eeef");
$ti-icon-square-plus: unicode("eb2a");
$ti-icon-square-root: unicode("eef1");
$ti-icon-square-root-2: unicode("eef0");
$ti-icon-square-rotated: unicode("ecdf");
$ti-icon-square-rotated-forbid: unicode("f01c");
$ti-icon-square-rotated-forbid-2: unicode("f01b");
$ti-icon-square-rotated-off: unicode("eef2");
$ti-icon-square-toggle: unicode("eef4");
$ti-icon-square-toggle-horizontal: unicode("eef3");
$ti-icon-square-x: unicode("eb2b");
$ti-icon-squares-diagonal: unicode("eef5");
$ti-icon-squares-filled: unicode("eef6");
$ti-icon-stack: unicode("eb2d");
$ti-icon-stack-2: unicode("eef7");
$ti-icon-stack-3: unicode("ef9d");
$ti-icon-stairs: unicode("eca6");
$ti-icon-stairs-down: unicode("eca4");
$ti-icon-stairs-up: unicode("eca5");
$ti-icon-star: unicode("eb2e");
$ti-icon-star-half: unicode("ed19");
$ti-icon-star-off: unicode("ed62");
$ti-icon-stars: unicode("ed38");
$ti-icon-steering-wheel: unicode("ec7b");
$ti-icon-step-into: unicode("ece0");
$ti-icon-step-out: unicode("ece1");
$ti-icon-stethoscope: unicode("edbe");
$ti-icon-sticker: unicode("eb2f");
$ti-icon-strikethrough: unicode("eb9e");
$ti-icon-submarine: unicode("ed94");
$ti-icon-subscript: unicode("eb9f");
$ti-icon-subtask: unicode("ec9f");
$ti-icon-sum: unicode("eb73");
$ti-icon-sum-off: unicode("f1ab");
$ti-icon-sun: unicode("eb30");
$ti-icon-sun-off: unicode("ed63");
$ti-icon-sunrise: unicode("ef1c");
$ti-icon-sunset: unicode("ec31");
$ti-icon-superscript: unicode("eba0");
$ti-icon-swimming: unicode("ec92");
$ti-icon-switch: unicode("eb33");
$ti-icon-switch-2: unicode("edbf");
$ti-icon-switch-3: unicode("edc0");
$ti-icon-switch-horizontal: unicode("eb31");
$ti-icon-switch-vertical: unicode("eb32");
$ti-icon-sword: unicode("f030");
$ti-icon-sword-off: unicode("f1ac");
$ti-icon-swords: unicode("f132");
$ti-icon-table: unicode("eba1");
$ti-icon-table-export: unicode("eef8");
$ti-icon-table-import: unicode("eef9");
$ti-icon-table-off: unicode("eefa");
$ti-icon-tag: unicode("eb34");
$ti-icon-tag-off: unicode("efc0");
$ti-icon-tags: unicode("ef86");
$ti-icon-tags-off: unicode("efc1");
$ti-icon-tallymark-1: unicode("ec46");
$ti-icon-tallymark-2: unicode("ec47");
$ti-icon-tallymark-3: unicode("ec48");
$ti-icon-tallymark-4: unicode("ec49");
$ti-icon-tallymarks: unicode("ec4a");
$ti-icon-tank: unicode("ed95");
$ti-icon-target: unicode("eb35");
$ti-icon-target-off: unicode("f1ad");
$ti-icon-telescope: unicode("f07d");
$ti-icon-telescope-off: unicode("f1ae");
$ti-icon-temperature: unicode("eb38");
$ti-icon-temperature-celsius: unicode("eb36");
$ti-icon-temperature-fahrenheit: unicode("eb37");
$ti-icon-temperature-minus: unicode("ebed");
$ti-icon-temperature-off: unicode("f1af");
$ti-icon-temperature-plus: unicode("ebee");
$ti-icon-template: unicode("eb39");
$ti-icon-template-off: unicode("f1b0");
$ti-icon-tent: unicode("eefb");
$ti-icon-terminal: unicode("ebdc");
$ti-icon-terminal-2: unicode("ebef");
$ti-icon-test-pipe: unicode("eb3a");
$ti-icon-test-pipe-2: unicode("f0a4");
$ti-icon-test-pipe-off: unicode("f1b1");
$ti-icon-text-decrease: unicode("f202");
$ti-icon-text-direction-ltr: unicode("eefc");
$ti-icon-text-direction-rtl: unicode("eefd");
$ti-icon-text-increase: unicode("f203");
$ti-icon-text-recognition: unicode("f204");
$ti-icon-text-resize: unicode("ef87");
$ti-icon-text-wrap: unicode("ebdd");
$ti-icon-text-wrap-disabled: unicode("eca7");
$ti-icon-thermometer: unicode("ef67");
$ti-icon-thumb-down: unicode("eb3b");
$ti-icon-thumb-up: unicode("eb3c");
$ti-icon-ticket: unicode("eb3d");
$ti-icon-ticket-off: unicode("f1b2");
$ti-icon-tie: unicode("f07e");
$ti-icon-tilt-shift: unicode("eefe");
$ti-icon-tilt-shift-off: unicode("f1b3");
$ti-icon-timeline: unicode("f031");
$ti-icon-tir: unicode("ebf0");
$ti-icon-toggle-left: unicode("eb3e");
$ti-icon-toggle-right: unicode("eb3f");
$ti-icon-toilet-paper: unicode("efd3");
$ti-icon-toilet-paper-off: unicode("f1b4");
$ti-icon-tool: unicode("eb40");
$ti-icon-tools: unicode("ebca");
$ti-icon-tools-kitchen: unicode("ed64");
$ti-icon-tools-kitchen-2: unicode("eeff");
$ti-icon-tools-kitchen-2-off: unicode("f1b5");
$ti-icon-tools-kitchen-off: unicode("f1b6");
$ti-icon-tools-off: unicode("f1b7");
$ti-icon-tornado: unicode("ece2");
$ti-icon-tournament: unicode("ecd0");
$ti-icon-track: unicode("ef00");
$ti-icon-tractor: unicode("ec0d");
$ti-icon-trademark: unicode("ec0e");
$ti-icon-traffic-cone: unicode("ec0f");
$ti-icon-traffic-cone-off: unicode("f1b8");
$ti-icon-traffic-lights: unicode("ed39");
$ti-icon-traffic-lights-off: unicode("f1b9");
$ti-icon-train: unicode("ed96");
$ti-icon-transfer-in: unicode("ef2f");
$ti-icon-transfer-out: unicode("ef30");
$ti-icon-trash: unicode("eb41");
$ti-icon-trash-off: unicode("ed65");
$ti-icon-trash-x: unicode("ef88");
$ti-icon-tree: unicode("ef01");
$ti-icon-trees: unicode("ec10");
$ti-icon-trending-down: unicode("eb42");
$ti-icon-trending-down-2: unicode("edc1");
$ti-icon-trending-down-3: unicode("edc2");
$ti-icon-trending-up: unicode("eb43");
$ti-icon-trending-up-2: unicode("edc3");
$ti-icon-trending-up-3: unicode("edc4");
$ti-icon-triangle: unicode("eb44");
$ti-icon-triangle-inverted: unicode("f01d");
$ti-icon-triangle-off: unicode("ef02");
$ti-icon-triangle-square-circle: unicode("ece8");
$ti-icon-triangles: unicode("f0a5");
$ti-icon-trident: unicode("ecc5");
$ti-icon-trophy: unicode("eb45");
$ti-icon-truck: unicode("ebc4");
$ti-icon-truck-delivery: unicode("ec4b");
$ti-icon-truck-loading: unicode("f1da");
$ti-icon-truck-off: unicode("ef03");
$ti-icon-truck-return: unicode("ec4c");
$ti-icon-typography: unicode("ebc5");
$ti-icon-typography-off: unicode("f1ba");
$ti-icon-umbrella: unicode("ebf1");
$ti-icon-umbrella-off: unicode("f1bb");
$ti-icon-underline: unicode("eba2");
$ti-icon-unlink: unicode("eb46");
$ti-icon-upload: unicode("eb47");
$ti-icon-urgent: unicode("eb48");
$ti-icon-usb: unicode("f00c");
$ti-icon-user: unicode("eb4d");
$ti-icon-user-check: unicode("eb49");
$ti-icon-user-circle: unicode("ef68");
$ti-icon-user-exclamation: unicode("ec12");
$ti-icon-user-minus: unicode("eb4a");
$ti-icon-user-off: unicode("ecf9");
$ti-icon-user-plus: unicode("eb4b");
$ti-icon-user-search: unicode("ef89");
$ti-icon-user-x: unicode("eb4c");
$ti-icon-users: unicode("ebf2");
$ti-icon-vaccine: unicode("ef04");
$ti-icon-vaccine-bottle: unicode("ef69");
$ti-icon-vaccine-off: unicode("f1bc");
$ti-icon-variable: unicode("ef05");
$ti-icon-variable-off: unicode("f1bd");
$ti-icon-vector: unicode("eca9");
$ti-icon-vector-bezier: unicode("ef1d");
$ti-icon-vector-bezier-2: unicode("f1a3");
$ti-icon-vector-off: unicode("f1be");
$ti-icon-vector-triangle: unicode("eca8");
$ti-icon-vector-triangle-off: unicode("f1bf");
$ti-icon-venus: unicode("ec86");
$ti-icon-versions: unicode("ed52");
$ti-icon-versions-off: unicode("f1c0");
$ti-icon-video: unicode("ed22");
$ti-icon-video-minus: unicode("ed1f");
$ti-icon-video-off: unicode("ed20");
$ti-icon-video-plus: unicode("ed21");
$ti-icon-view-360: unicode("ed84");
$ti-icon-view-360-off: unicode("f1c1");
$ti-icon-viewfinder: unicode("eb4e");
$ti-icon-viewfinder-off: unicode("f1c2");
$ti-icon-viewport-narrow: unicode("ebf3");
$ti-icon-viewport-wide: unicode("ebf4");
$ti-icon-vinyl: unicode("f00d");
$ti-icon-virus: unicode("eb74");
$ti-icon-virus-off: unicode("ed66");
$ti-icon-virus-search: unicode("ed67");
$ti-icon-vocabulary: unicode("ef1e");
$ti-icon-volume: unicode("eb51");
$ti-icon-volume-2: unicode("eb4f");
$ti-icon-volume-3: unicode("eb50");
$ti-icon-volume-off: unicode("f1c3");
$ti-icon-walk: unicode("ec87");
$ti-icon-wall: unicode("ef7a");
$ti-icon-wallet: unicode("eb75");
$ti-icon-wallet-off: unicode("f1c4");
$ti-icon-wallpaper: unicode("ef56");
$ti-icon-wallpaper-off: unicode("f1c5");
$ti-icon-wand: unicode("ebcb");
$ti-icon-wand-off: unicode("f1c6");
$ti-icon-wave-saw-tool: unicode("ecd3");
$ti-icon-wave-sine: unicode("ecd4");
$ti-icon-wave-square: unicode("ecd5");
$ti-icon-webhook: unicode("f01e");
$ti-icon-wheelchair: unicode("f1db");
$ti-icon-wifi: unicode("eb52");
$ti-icon-wifi-0: unicode("eba3");
$ti-icon-wifi-1: unicode("eba4");
$ti-icon-wifi-2: unicode("eba5");
$ti-icon-wifi-off: unicode("ecfa");
$ti-icon-wind: unicode("ec34");
$ti-icon-wind-off: unicode("f1c7");
$ti-icon-windmill: unicode("ed85");
$ti-icon-windmill-off: unicode("f1c8");
$ti-icon-window: unicode("ef06");
$ti-icon-window-maximize: unicode("f1f1");
$ti-icon-window-minimize: unicode("f1f2");
$ti-icon-window-off: unicode("f1c9");
$ti-icon-windsock: unicode("f06d");
$ti-icon-wiper: unicode("ecab");
$ti-icon-wiper-wash: unicode("ecaa");
$ti-icon-woman: unicode("eb53");
$ti-icon-world: unicode("eb54");
$ti-icon-world-download: unicode("ef8a");
$ti-icon-world-latitude: unicode("ed2e");
$ti-icon-world-longitude: unicode("ed2f");
$ti-icon-world-off: unicode("f1ca");
$ti-icon-world-upload: unicode("ef8b");
$ti-icon-wrecking-ball: unicode("ed97");
$ti-icon-writing: unicode("ef08");
$ti-icon-writing-off: unicode("f1cb");
$ti-icon-writing-sign: unicode("ef07");
$ti-icon-writing-sign-off: unicode("f1cc");
$ti-icon-x: unicode("eb55");
$ti-icon-yin-yang: unicode("ec35");
$ti-icon-yoga: unicode("f01f");
$ti-icon-zodiac-aquarius: unicode("ecac");
$ti-icon-zodiac-aries: unicode("ecad");
$ti-icon-zodiac-cancer: unicode("ecae");
$ti-icon-zodiac-capricorn: unicode("ecaf");
$ti-icon-zodiac-gemini: unicode("ecb0");
$ti-icon-zodiac-leo: unicode("ecb1");
$ti-icon-zodiac-libra: unicode("ecb2");
$ti-icon-zodiac-pisces: unicode("ecb3");
$ti-icon-zodiac-sagittarius: unicode("ecb4");
$ti-icon-zodiac-scorpio: unicode("ecb5");
$ti-icon-zodiac-taurus: unicode("ecb6");
$ti-icon-zodiac-virgo: unicode("ecb7");
$ti-icon-zoom-cancel: unicode("ec4d");
$ti-icon-zoom-check: unicode("ef09");
$ti-icon-zoom-code: unicode("f07f");
$ti-icon-zoom-exclamation: unicode("f080");
$ti-icon-zoom-in: unicode("eb56");
$ti-icon-zoom-in-area: unicode("f1dc");
$ti-icon-zoom-money: unicode("ef0a");
$ti-icon-zoom-out: unicode("eb57");
$ti-icon-zoom-out-area: unicode("f1dd");
$ti-icon-zoom-pan: unicode("f1de");
$ti-icon-zoom-question: unicode("edeb");
$ti-icon-zzz: unicode("f228");

.#{$ti-prefix}-2fa:before {
  content: $ti-icon-2fa;
}
.#{$ti-prefix}-3d-cube-sphere:before {
  content: $ti-icon-3d-cube-sphere;
}
.#{$ti-prefix}-3d-rotate:before {
  content: $ti-icon-3d-rotate;
}
.#{$ti-prefix}-a-b:before {
  content: $ti-icon-a-b;
}
.#{$ti-prefix}-a-b-off:before {
  content: $ti-icon-a-b-off;
}
.#{$ti-prefix}-abacus:before {
  content: $ti-icon-abacus;
}
.#{$ti-prefix}-access-point:before {
  content: $ti-icon-access-point;
}
.#{$ti-prefix}-access-point-off:before {
  content: $ti-icon-access-point-off;
}
.#{$ti-prefix}-accessible:before {
  content: $ti-icon-accessible;
}
.#{$ti-prefix}-accessible-off:before {
  content: $ti-icon-accessible-off;
}
.#{$ti-prefix}-activity:before {
  content: $ti-icon-activity;
}
.#{$ti-prefix}-activity-heartbeat:before {
  content: $ti-icon-activity-heartbeat;
}
.#{$ti-prefix}-ad:before {
  content: $ti-icon-ad;
}
.#{$ti-prefix}-ad-2:before {
  content: $ti-icon-ad-2;
}
.#{$ti-prefix}-address-book:before {
  content: $ti-icon-address-book;
}
.#{$ti-prefix}-adjustments:before {
  content: $ti-icon-adjustments;
}
.#{$ti-prefix}-adjustments-alt:before {
  content: $ti-icon-adjustments-alt;
}
.#{$ti-prefix}-adjustments-horizontal:before {
  content: $ti-icon-adjustments-horizontal;
}
.#{$ti-prefix}-adjustments-off:before {
  content: $ti-icon-adjustments-off;
}
.#{$ti-prefix}-aerial-lift:before {
  content: $ti-icon-aerial-lift;
}
.#{$ti-prefix}-affiliate:before {
  content: $ti-icon-affiliate;
}
.#{$ti-prefix}-alarm:before {
  content: $ti-icon-alarm;
}
.#{$ti-prefix}-alarm-off:before {
  content: $ti-icon-alarm-off;
}
.#{$ti-prefix}-album:before {
  content: $ti-icon-album;
}
.#{$ti-prefix}-alert-circle:before {
  content: $ti-icon-alert-circle;
}
.#{$ti-prefix}-alert-octagon:before {
  content: $ti-icon-alert-octagon;
}
.#{$ti-prefix}-alert-triangle:before {
  content: $ti-icon-alert-triangle;
}
.#{$ti-prefix}-alien:before {
  content: $ti-icon-alien;
}
.#{$ti-prefix}-align-center:before {
  content: $ti-icon-align-center;
}
.#{$ti-prefix}-align-justified:before {
  content: $ti-icon-align-justified;
}
.#{$ti-prefix}-align-left:before {
  content: $ti-icon-align-left;
}
.#{$ti-prefix}-align-right:before {
  content: $ti-icon-align-right;
}
.#{$ti-prefix}-alphabet-cyrillic:before {
  content: $ti-icon-alphabet-cyrillic;
}
.#{$ti-prefix}-alphabet-greek:before {
  content: $ti-icon-alphabet-greek;
}
.#{$ti-prefix}-alphabet-latin:before {
  content: $ti-icon-alphabet-latin;
}
.#{$ti-prefix}-ambulance:before {
  content: $ti-icon-ambulance;
}
.#{$ti-prefix}-anchor:before {
  content: $ti-icon-anchor;
}
.#{$ti-prefix}-anchor-off:before {
  content: $ti-icon-anchor-off;
}
.#{$ti-prefix}-angle:before {
  content: $ti-icon-angle;
}
.#{$ti-prefix}-ankh:before {
  content: $ti-icon-ankh;
}
.#{$ti-prefix}-antenna:before {
  content: $ti-icon-antenna;
}
.#{$ti-prefix}-antenna-bars-1:before {
  content: $ti-icon-antenna-bars-1;
}
.#{$ti-prefix}-antenna-bars-2:before {
  content: $ti-icon-antenna-bars-2;
}
.#{$ti-prefix}-antenna-bars-3:before {
  content: $ti-icon-antenna-bars-3;
}
.#{$ti-prefix}-antenna-bars-4:before {
  content: $ti-icon-antenna-bars-4;
}
.#{$ti-prefix}-antenna-bars-5:before {
  content: $ti-icon-antenna-bars-5;
}
.#{$ti-prefix}-antenna-bars-off:before {
  content: $ti-icon-antenna-bars-off;
}
.#{$ti-prefix}-aperture:before {
  content: $ti-icon-aperture;
}
.#{$ti-prefix}-api:before {
  content: $ti-icon-api;
}
.#{$ti-prefix}-api-app:before {
  content: $ti-icon-api-app;
}
.#{$ti-prefix}-api-app-off:before {
  content: $ti-icon-api-app-off;
}
.#{$ti-prefix}-api-off:before {
  content: $ti-icon-api-off;
}
.#{$ti-prefix}-app-window:before {
  content: $ti-icon-app-window;
}
.#{$ti-prefix}-apple:before {
  content: $ti-icon-apple;
}
.#{$ti-prefix}-apps:before {
  content: $ti-icon-apps;
}
.#{$ti-prefix}-apps-off:before {
  content: $ti-icon-apps-off;
}
.#{$ti-prefix}-archive:before {
  content: $ti-icon-archive;
}
.#{$ti-prefix}-archive-off:before {
  content: $ti-icon-archive-off;
}
.#{$ti-prefix}-armchair:before {
  content: $ti-icon-armchair;
}
.#{$ti-prefix}-armchair-2:before {
  content: $ti-icon-armchair-2;
}
.#{$ti-prefix}-arrow-autofit-content:before {
  content: $ti-icon-arrow-autofit-content;
}
.#{$ti-prefix}-arrow-autofit-down:before {
  content: $ti-icon-arrow-autofit-down;
}
.#{$ti-prefix}-arrow-autofit-height:before {
  content: $ti-icon-arrow-autofit-height;
}
.#{$ti-prefix}-arrow-autofit-left:before {
  content: $ti-icon-arrow-autofit-left;
}
.#{$ti-prefix}-arrow-autofit-right:before {
  content: $ti-icon-arrow-autofit-right;
}
.#{$ti-prefix}-arrow-autofit-up:before {
  content: $ti-icon-arrow-autofit-up;
}
.#{$ti-prefix}-arrow-autofit-width:before {
  content: $ti-icon-arrow-autofit-width;
}
.#{$ti-prefix}-arrow-back:before {
  content: $ti-icon-arrow-back;
}
.#{$ti-prefix}-arrow-back-up:before {
  content: $ti-icon-arrow-back-up;
}
.#{$ti-prefix}-arrow-bar-down:before {
  content: $ti-icon-arrow-bar-down;
}
.#{$ti-prefix}-arrow-bar-left:before {
  content: $ti-icon-arrow-bar-left;
}
.#{$ti-prefix}-arrow-bar-right:before {
  content: $ti-icon-arrow-bar-right;
}
.#{$ti-prefix}-arrow-bar-to-down:before {
  content: $ti-icon-arrow-bar-to-down;
}
.#{$ti-prefix}-arrow-bar-to-left:before {
  content: $ti-icon-arrow-bar-to-left;
}
.#{$ti-prefix}-arrow-bar-to-right:before {
  content: $ti-icon-arrow-bar-to-right;
}
.#{$ti-prefix}-arrow-bar-to-up:before {
  content: $ti-icon-arrow-bar-to-up;
}
.#{$ti-prefix}-arrow-bar-up:before {
  content: $ti-icon-arrow-bar-up;
}
.#{$ti-prefix}-arrow-bear-left:before {
  content: $ti-icon-arrow-bear-left;
}
.#{$ti-prefix}-arrow-bear-left-2:before {
  content: $ti-icon-arrow-bear-left-2;
}
.#{$ti-prefix}-arrow-bear-right:before {
  content: $ti-icon-arrow-bear-right;
}
.#{$ti-prefix}-arrow-bear-right-2:before {
  content: $ti-icon-arrow-bear-right-2;
}
.#{$ti-prefix}-arrow-big-down:before {
  content: $ti-icon-arrow-big-down;
}
.#{$ti-prefix}-arrow-big-down-line:before {
  content: $ti-icon-arrow-big-down-line;
}
.#{$ti-prefix}-arrow-big-down-lines:before {
  content: $ti-icon-arrow-big-down-lines;
}
.#{$ti-prefix}-arrow-big-left:before {
  content: $ti-icon-arrow-big-left;
}
.#{$ti-prefix}-arrow-big-left-line:before {
  content: $ti-icon-arrow-big-left-line;
}
.#{$ti-prefix}-arrow-big-left-lines:before {
  content: $ti-icon-arrow-big-left-lines;
}
.#{$ti-prefix}-arrow-big-right:before {
  content: $ti-icon-arrow-big-right;
}
.#{$ti-prefix}-arrow-big-right-line:before {
  content: $ti-icon-arrow-big-right-line;
}
.#{$ti-prefix}-arrow-big-right-lines:before {
  content: $ti-icon-arrow-big-right-lines;
}
.#{$ti-prefix}-arrow-big-top:before {
  content: $ti-icon-arrow-big-top;
}
.#{$ti-prefix}-arrow-big-up-line:before {
  content: $ti-icon-arrow-big-up-line;
}
.#{$ti-prefix}-arrow-big-up-lines:before {
  content: $ti-icon-arrow-big-up-lines;
}
.#{$ti-prefix}-arrow-bottom-bar:before {
  content: $ti-icon-arrow-bottom-bar;
}
.#{$ti-prefix}-arrow-bottom-circle:before {
  content: $ti-icon-arrow-bottom-circle;
}
.#{$ti-prefix}-arrow-bottom-square:before {
  content: $ti-icon-arrow-bottom-square;
}
.#{$ti-prefix}-arrow-bottom-tail:before {
  content: $ti-icon-arrow-bottom-tail;
}
.#{$ti-prefix}-arrow-curve-left:before {
  content: $ti-icon-arrow-curve-left;
}
.#{$ti-prefix}-arrow-curve-right:before {
  content: $ti-icon-arrow-curve-right;
}
.#{$ti-prefix}-arrow-down:before {
  content: $ti-icon-arrow-down;
}
.#{$ti-prefix}-arrow-down-circle:before {
  content: $ti-icon-arrow-down-circle;
}
.#{$ti-prefix}-arrow-down-left:before {
  content: $ti-icon-arrow-down-left;
}
.#{$ti-prefix}-arrow-down-left-circle:before {
  content: $ti-icon-arrow-down-left-circle;
}
.#{$ti-prefix}-arrow-down-right:before {
  content: $ti-icon-arrow-down-right;
}
.#{$ti-prefix}-arrow-down-right-circle:before {
  content: $ti-icon-arrow-down-right-circle;
}
.#{$ti-prefix}-arrow-fork:before {
  content: $ti-icon-arrow-fork;
}
.#{$ti-prefix}-arrow-forward:before {
  content: $ti-icon-arrow-forward;
}
.#{$ti-prefix}-arrow-forward-up:before {
  content: $ti-icon-arrow-forward-up;
}
.#{$ti-prefix}-arrow-left:before {
  content: $ti-icon-arrow-left;
}
.#{$ti-prefix}-arrow-left-bar:before {
  content: $ti-icon-arrow-left-bar;
}
.#{$ti-prefix}-arrow-left-circle:before {
  content: $ti-icon-arrow-left-circle;
}
.#{$ti-prefix}-arrow-left-right:before {
  content: $ti-icon-arrow-left-right;
}
.#{$ti-prefix}-arrow-left-square:before {
  content: $ti-icon-arrow-left-square;
}
.#{$ti-prefix}-arrow-left-tail:before {
  content: $ti-icon-arrow-left-tail;
}
.#{$ti-prefix}-arrow-loop-left:before {
  content: $ti-icon-arrow-loop-left;
}
.#{$ti-prefix}-arrow-loop-left-2:before {
  content: $ti-icon-arrow-loop-left-2;
}
.#{$ti-prefix}-arrow-loop-right:before {
  content: $ti-icon-arrow-loop-right;
}
.#{$ti-prefix}-arrow-loop-right-2:before {
  content: $ti-icon-arrow-loop-right-2;
}
.#{$ti-prefix}-arrow-merge:before {
  content: $ti-icon-arrow-merge;
}
.#{$ti-prefix}-arrow-narrow-down:before {
  content: $ti-icon-arrow-narrow-down;
}
.#{$ti-prefix}-arrow-narrow-left:before {
  content: $ti-icon-arrow-narrow-left;
}
.#{$ti-prefix}-arrow-narrow-right:before {
  content: $ti-icon-arrow-narrow-right;
}
.#{$ti-prefix}-arrow-narrow-up:before {
  content: $ti-icon-arrow-narrow-up;
}
.#{$ti-prefix}-arrow-ramp-left:before {
  content: $ti-icon-arrow-ramp-left;
}
.#{$ti-prefix}-arrow-ramp-left-2:before {
  content: $ti-icon-arrow-ramp-left-2;
}
.#{$ti-prefix}-arrow-ramp-left-3:before {
  content: $ti-icon-arrow-ramp-left-3;
}
.#{$ti-prefix}-arrow-ramp-right:before {
  content: $ti-icon-arrow-ramp-right;
}
.#{$ti-prefix}-arrow-ramp-right-2:before {
  content: $ti-icon-arrow-ramp-right-2;
}
.#{$ti-prefix}-arrow-ramp-right-3:before {
  content: $ti-icon-arrow-ramp-right-3;
}
.#{$ti-prefix}-arrow-right:before {
  content: $ti-icon-arrow-right;
}
.#{$ti-prefix}-arrow-right-bar:before {
  content: $ti-icon-arrow-right-bar;
}
.#{$ti-prefix}-arrow-right-circle:before {
  content: $ti-icon-arrow-right-circle;
}
.#{$ti-prefix}-arrow-right-square:before {
  content: $ti-icon-arrow-right-square;
}
.#{$ti-prefix}-arrow-right-tail:before {
  content: $ti-icon-arrow-right-tail;
}
.#{$ti-prefix}-arrow-rotary-first-left:before {
  content: $ti-icon-arrow-rotary-first-left;
}
.#{$ti-prefix}-arrow-rotary-first-right:before {
  content: $ti-icon-arrow-rotary-first-right;
}
.#{$ti-prefix}-arrow-rotary-last-left:before {
  content: $ti-icon-arrow-rotary-last-left;
}
.#{$ti-prefix}-arrow-rotary-last-right:before {
  content: $ti-icon-arrow-rotary-last-right;
}
.#{$ti-prefix}-arrow-rotary-left:before {
  content: $ti-icon-arrow-rotary-left;
}
.#{$ti-prefix}-arrow-rotary-right:before {
  content: $ti-icon-arrow-rotary-right;
}
.#{$ti-prefix}-arrow-rotary-straight:before {
  content: $ti-icon-arrow-rotary-straight;
}
.#{$ti-prefix}-arrow-sharp-turn-left:before {
  content: $ti-icon-arrow-sharp-turn-left;
}
.#{$ti-prefix}-arrow-sharp-turn-right:before {
  content: $ti-icon-arrow-sharp-turn-right;
}
.#{$ti-prefix}-arrow-top-bar:before {
  content: $ti-icon-arrow-top-bar;
}
.#{$ti-prefix}-arrow-top-circle:before {
  content: $ti-icon-arrow-top-circle;
}
.#{$ti-prefix}-arrow-top-square:before {
  content: $ti-icon-arrow-top-square;
}
.#{$ti-prefix}-arrow-top-tail:before {
  content: $ti-icon-arrow-top-tail;
}
.#{$ti-prefix}-arrow-up:before {
  content: $ti-icon-arrow-up;
}
.#{$ti-prefix}-arrow-up-circle:before {
  content: $ti-icon-arrow-up-circle;
}
.#{$ti-prefix}-arrow-up-left:before {
  content: $ti-icon-arrow-up-left;
}
.#{$ti-prefix}-arrow-up-left-circle:before {
  content: $ti-icon-arrow-up-left-circle;
}
.#{$ti-prefix}-arrow-up-right:before {
  content: $ti-icon-arrow-up-right;
}
.#{$ti-prefix}-arrow-up-right-circle:before {
  content: $ti-icon-arrow-up-right-circle;
}
.#{$ti-prefix}-arrow-wave-left-down:before {
  content: $ti-icon-arrow-wave-left-down;
}
.#{$ti-prefix}-arrow-wave-left-up:before {
  content: $ti-icon-arrow-wave-left-up;
}
.#{$ti-prefix}-arrow-wave-right-down:before {
  content: $ti-icon-arrow-wave-right-down;
}
.#{$ti-prefix}-arrow-wave-right-up:before {
  content: $ti-icon-arrow-wave-right-up;
}
.#{$ti-prefix}-arrows-cross:before {
  content: $ti-icon-arrows-cross;
}
.#{$ti-prefix}-arrows-diagonal:before {
  content: $ti-icon-arrows-diagonal;
}
.#{$ti-prefix}-arrows-diagonal-2:before {
  content: $ti-icon-arrows-diagonal-2;
}
.#{$ti-prefix}-arrows-diagonal-minimize:before {
  content: $ti-icon-arrows-diagonal-minimize;
}
.#{$ti-prefix}-arrows-diagonal-minimize-2:before {
  content: $ti-icon-arrows-diagonal-minimize-2;
}
.#{$ti-prefix}-arrows-double-ne-sw:before {
  content: $ti-icon-arrows-double-ne-sw;
}
.#{$ti-prefix}-arrows-double-nw-se:before {
  content: $ti-icon-arrows-double-nw-se;
}
.#{$ti-prefix}-arrows-double-se-nw:before {
  content: $ti-icon-arrows-double-se-nw;
}
.#{$ti-prefix}-arrows-double-sw-ne:before {
  content: $ti-icon-arrows-double-sw-ne;
}
.#{$ti-prefix}-arrows-down:before {
  content: $ti-icon-arrows-down;
}
.#{$ti-prefix}-arrows-down-up:before {
  content: $ti-icon-arrows-down-up;
}
.#{$ti-prefix}-arrows-exchange:before {
  content: $ti-icon-arrows-exchange;
}
.#{$ti-prefix}-arrows-exchange-2:before {
  content: $ti-icon-arrows-exchange-2;
}
.#{$ti-prefix}-arrows-horizontal:before {
  content: $ti-icon-arrows-horizontal;
}
.#{$ti-prefix}-arrows-join:before {
  content: $ti-icon-arrows-join;
}
.#{$ti-prefix}-arrows-join-2:before {
  content: $ti-icon-arrows-join-2;
}
.#{$ti-prefix}-arrows-left:before {
  content: $ti-icon-arrows-left;
}
.#{$ti-prefix}-arrows-left-down:before {
  content: $ti-icon-arrows-left-down;
}
.#{$ti-prefix}-arrows-left-right:before {
  content: $ti-icon-arrows-left-right;
}
.#{$ti-prefix}-arrows-maximize:before {
  content: $ti-icon-arrows-maximize;
}
.#{$ti-prefix}-arrows-minimize:before {
  content: $ti-icon-arrows-minimize;
}
.#{$ti-prefix}-arrows-random:before {
  content: $ti-icon-arrows-random;
}
.#{$ti-prefix}-arrows-right:before {
  content: $ti-icon-arrows-right;
}
.#{$ti-prefix}-arrows-right-down:before {
  content: $ti-icon-arrows-right-down;
}
.#{$ti-prefix}-arrows-right-left:before {
  content: $ti-icon-arrows-right-left;
}
.#{$ti-prefix}-arrows-shuffle:before {
  content: $ti-icon-arrows-shuffle;
}
.#{$ti-prefix}-arrows-shuffle-2:before {
  content: $ti-icon-arrows-shuffle-2;
}
.#{$ti-prefix}-arrows-sort:before {
  content: $ti-icon-arrows-sort;
}
.#{$ti-prefix}-arrows-split:before {
  content: $ti-icon-arrows-split;
}
.#{$ti-prefix}-arrows-split-2:before {
  content: $ti-icon-arrows-split-2;
}
.#{$ti-prefix}-arrows-up:before {
  content: $ti-icon-arrows-up;
}
.#{$ti-prefix}-arrows-up-down:before {
  content: $ti-icon-arrows-up-down;
}
.#{$ti-prefix}-arrows-up-left:before {
  content: $ti-icon-arrows-up-left;
}
.#{$ti-prefix}-arrows-up-right:before {
  content: $ti-icon-arrows-up-right;
}
.#{$ti-prefix}-arrows-vertical:before {
  content: $ti-icon-arrows-vertical;
}
.#{$ti-prefix}-artboard:before {
  content: $ti-icon-artboard;
}
.#{$ti-prefix}-artboard-off:before {
  content: $ti-icon-artboard-off;
}
.#{$ti-prefix}-article:before {
  content: $ti-icon-article;
}
.#{$ti-prefix}-aspect-ratio:before {
  content: $ti-icon-aspect-ratio;
}
.#{$ti-prefix}-aspect-ratio-off:before {
  content: $ti-icon-aspect-ratio-off;
}
.#{$ti-prefix}-asset:before {
  content: $ti-icon-asset;
}
.#{$ti-prefix}-asterisk:before {
  content: $ti-icon-asterisk;
}
.#{$ti-prefix}-asterisk-simple:before {
  content: $ti-icon-asterisk-simple;
}
.#{$ti-prefix}-at:before {
  content: $ti-icon-at;
}
.#{$ti-prefix}-at-off:before {
  content: $ti-icon-at-off;
}
.#{$ti-prefix}-atom:before {
  content: $ti-icon-atom;
}
.#{$ti-prefix}-atom-2:before {
  content: $ti-icon-atom-2;
}
.#{$ti-prefix}-atom-off:before {
  content: $ti-icon-atom-off;
}
.#{$ti-prefix}-augmented-reality:before {
  content: $ti-icon-augmented-reality;
}
.#{$ti-prefix}-award:before {
  content: $ti-icon-award;
}
.#{$ti-prefix}-award-off:before {
  content: $ti-icon-award-off;
}
.#{$ti-prefix}-axe:before {
  content: $ti-icon-axe;
}
.#{$ti-prefix}-axis-x:before {
  content: $ti-icon-axis-x;
}
.#{$ti-prefix}-axis-y:before {
  content: $ti-icon-axis-y;
}
.#{$ti-prefix}-baby-carriage:before {
  content: $ti-icon-baby-carriage;
}
.#{$ti-prefix}-backhoe:before {
  content: $ti-icon-backhoe;
}
.#{$ti-prefix}-backpack:before {
  content: $ti-icon-backpack;
}
.#{$ti-prefix}-backspace:before {
  content: $ti-icon-backspace;
}
.#{$ti-prefix}-badge:before {
  content: $ti-icon-badge;
}
.#{$ti-prefix}-badge-off:before {
  content: $ti-icon-badge-off;
}
.#{$ti-prefix}-badges:before {
  content: $ti-icon-badges;
}
.#{$ti-prefix}-badges-off:before {
  content: $ti-icon-badges-off;
}
.#{$ti-prefix}-ball-american-football:before {
  content: $ti-icon-ball-american-football;
}
.#{$ti-prefix}-ball-baseball:before {
  content: $ti-icon-ball-baseball;
}
.#{$ti-prefix}-ball-basketball:before {
  content: $ti-icon-ball-basketball;
}
.#{$ti-prefix}-ball-bowling:before {
  content: $ti-icon-ball-bowling;
}
.#{$ti-prefix}-ball-football:before {
  content: $ti-icon-ball-football;
}
.#{$ti-prefix}-ball-football-off:before {
  content: $ti-icon-ball-football-off;
}
.#{$ti-prefix}-ball-tennis:before {
  content: $ti-icon-ball-tennis;
}
.#{$ti-prefix}-ball-volleyball:before {
  content: $ti-icon-ball-volleyball;
}
.#{$ti-prefix}-ballon:before {
  content: $ti-icon-ballon;
}
.#{$ti-prefix}-ballon-off:before {
  content: $ti-icon-ballon-off;
}
.#{$ti-prefix}-ballpen:before {
  content: $ti-icon-ballpen;
}
.#{$ti-prefix}-ballpen-off:before {
  content: $ti-icon-ballpen-off;
}
.#{$ti-prefix}-ban:before {
  content: $ti-icon-ban;
}
.#{$ti-prefix}-bandage:before {
  content: $ti-icon-bandage;
}
.#{$ti-prefix}-barbell:before {
  content: $ti-icon-barbell;
}
.#{$ti-prefix}-barbell-off:before {
  content: $ti-icon-barbell-off;
}
.#{$ti-prefix}-barcode:before {
  content: $ti-icon-barcode;
}
.#{$ti-prefix}-barcode-off:before {
  content: $ti-icon-barcode-off;
}
.#{$ti-prefix}-barrel:before {
  content: $ti-icon-barrel;
}
.#{$ti-prefix}-barrel-off:before {
  content: $ti-icon-barrel-off;
}
.#{$ti-prefix}-barrier-block:before {
  content: $ti-icon-barrier-block;
}
.#{$ti-prefix}-barrier-block-off:before {
  content: $ti-icon-barrier-block-off;
}
.#{$ti-prefix}-baseline:before {
  content: $ti-icon-baseline;
}
.#{$ti-prefix}-basket:before {
  content: $ti-icon-basket;
}
.#{$ti-prefix}-basket-off:before {
  content: $ti-icon-basket-off;
}
.#{$ti-prefix}-bath:before {
  content: $ti-icon-bath;
}
.#{$ti-prefix}-bath-off:before {
  content: $ti-icon-bath-off;
}
.#{$ti-prefix}-battery:before {
  content: $ti-icon-battery;
}
.#{$ti-prefix}-battery-1:before {
  content: $ti-icon-battery-1;
}
.#{$ti-prefix}-battery-2:before {
  content: $ti-icon-battery-2;
}
.#{$ti-prefix}-battery-3:before {
  content: $ti-icon-battery-3;
}
.#{$ti-prefix}-battery-4:before {
  content: $ti-icon-battery-4;
}
.#{$ti-prefix}-battery-automotive:before {
  content: $ti-icon-battery-automotive;
}
.#{$ti-prefix}-battery-charging:before {
  content: $ti-icon-battery-charging;
}
.#{$ti-prefix}-battery-charging-2:before {
  content: $ti-icon-battery-charging-2;
}
.#{$ti-prefix}-battery-eco:before {
  content: $ti-icon-battery-eco;
}
.#{$ti-prefix}-battery-off:before {
  content: $ti-icon-battery-off;
}
.#{$ti-prefix}-beach:before {
  content: $ti-icon-beach;
}
.#{$ti-prefix}-beach-off:before {
  content: $ti-icon-beach-off;
}
.#{$ti-prefix}-bed:before {
  content: $ti-icon-bed;
}
.#{$ti-prefix}-bed-off:before {
  content: $ti-icon-bed-off;
}
.#{$ti-prefix}-beer:before {
  content: $ti-icon-beer;
}
.#{$ti-prefix}-beer-off:before {
  content: $ti-icon-beer-off;
}
.#{$ti-prefix}-bell:before {
  content: $ti-icon-bell;
}
.#{$ti-prefix}-bell-minus:before {
  content: $ti-icon-bell-minus;
}
.#{$ti-prefix}-bell-off:before {
  content: $ti-icon-bell-off;
}
.#{$ti-prefix}-bell-plus:before {
  content: $ti-icon-bell-plus;
}
.#{$ti-prefix}-bell-ringing:before {
  content: $ti-icon-bell-ringing;
}
.#{$ti-prefix}-bell-ringing-2:before {
  content: $ti-icon-bell-ringing-2;
}
.#{$ti-prefix}-bell-school:before {
  content: $ti-icon-bell-school;
}
.#{$ti-prefix}-bell-x:before {
  content: $ti-icon-bell-x;
}
.#{$ti-prefix}-bell-z:before {
  content: $ti-icon-bell-z;
}
.#{$ti-prefix}-bible:before {
  content: $ti-icon-bible;
}
.#{$ti-prefix}-bike:before {
  content: $ti-icon-bike;
}
.#{$ti-prefix}-bike-off:before {
  content: $ti-icon-bike-off;
}
.#{$ti-prefix}-binary:before {
  content: $ti-icon-binary;
}
.#{$ti-prefix}-biohazard:before {
  content: $ti-icon-biohazard;
}
.#{$ti-prefix}-biohazard-off:before {
  content: $ti-icon-biohazard-off;
}
.#{$ti-prefix}-blockquote:before {
  content: $ti-icon-blockquote;
}
.#{$ti-prefix}-bluetooth:before {
  content: $ti-icon-bluetooth;
}
.#{$ti-prefix}-bluetooth-connected:before {
  content: $ti-icon-bluetooth-connected;
}
.#{$ti-prefix}-bluetooth-off:before {
  content: $ti-icon-bluetooth-off;
}
.#{$ti-prefix}-bluetooth-x:before {
  content: $ti-icon-bluetooth-x;
}
.#{$ti-prefix}-blur:before {
  content: $ti-icon-blur;
}
.#{$ti-prefix}-bold:before {
  content: $ti-icon-bold;
}
.#{$ti-prefix}-bold-off:before {
  content: $ti-icon-bold-off;
}
.#{$ti-prefix}-bolt:before {
  content: $ti-icon-bolt;
}
.#{$ti-prefix}-bolt-off:before {
  content: $ti-icon-bolt-off;
}
.#{$ti-prefix}-bone:before {
  content: $ti-icon-bone;
}
.#{$ti-prefix}-bone-off:before {
  content: $ti-icon-bone-off;
}
.#{$ti-prefix}-book:before {
  content: $ti-icon-book;
}
.#{$ti-prefix}-book-2:before {
  content: $ti-icon-book-2;
}
.#{$ti-prefix}-book-download:before {
  content: $ti-icon-book-download;
}
.#{$ti-prefix}-book-off:before {
  content: $ti-icon-book-off;
}
.#{$ti-prefix}-book-upload:before {
  content: $ti-icon-book-upload;
}
.#{$ti-prefix}-bookmark:before {
  content: $ti-icon-bookmark;
}
.#{$ti-prefix}-bookmark-off:before {
  content: $ti-icon-bookmark-off;
}
.#{$ti-prefix}-bookmarks:before {
  content: $ti-icon-bookmarks;
}
.#{$ti-prefix}-bookmarks-off:before {
  content: $ti-icon-bookmarks-off;
}
.#{$ti-prefix}-books:before {
  content: $ti-icon-books;
}
.#{$ti-prefix}-books-off:before {
  content: $ti-icon-books-off;
}
.#{$ti-prefix}-border-all:before {
  content: $ti-icon-border-all;
}
.#{$ti-prefix}-border-bottom:before {
  content: $ti-icon-border-bottom;
}
.#{$ti-prefix}-border-horizontal:before {
  content: $ti-icon-border-horizontal;
}
.#{$ti-prefix}-border-inner:before {
  content: $ti-icon-border-inner;
}
.#{$ti-prefix}-border-left:before {
  content: $ti-icon-border-left;
}
.#{$ti-prefix}-border-none:before {
  content: $ti-icon-border-none;
}
.#{$ti-prefix}-border-outer:before {
  content: $ti-icon-border-outer;
}
.#{$ti-prefix}-border-radius:before {
  content: $ti-icon-border-radius;
}
.#{$ti-prefix}-border-right:before {
  content: $ti-icon-border-right;
}
.#{$ti-prefix}-border-style:before {
  content: $ti-icon-border-style;
}
.#{$ti-prefix}-border-style-2:before {
  content: $ti-icon-border-style-2;
}
.#{$ti-prefix}-border-top:before {
  content: $ti-icon-border-top;
}
.#{$ti-prefix}-border-vertical:before {
  content: $ti-icon-border-vertical;
}
.#{$ti-prefix}-bottle:before {
  content: $ti-icon-bottle;
}
.#{$ti-prefix}-bow:before {
  content: $ti-icon-bow;
}
.#{$ti-prefix}-box:before {
  content: $ti-icon-box;
}
.#{$ti-prefix}-box-margin:before {
  content: $ti-icon-box-margin;
}
.#{$ti-prefix}-box-model:before {
  content: $ti-icon-box-model;
}
.#{$ti-prefix}-box-model-2:before {
  content: $ti-icon-box-model-2;
}
.#{$ti-prefix}-box-multiple:before {
  content: $ti-icon-box-multiple;
}
.#{$ti-prefix}-box-multiple-0:before {
  content: $ti-icon-box-multiple-0;
}
.#{$ti-prefix}-box-multiple-1:before {
  content: $ti-icon-box-multiple-1;
}
.#{$ti-prefix}-box-multiple-2:before {
  content: $ti-icon-box-multiple-2;
}
.#{$ti-prefix}-box-multiple-3:before {
  content: $ti-icon-box-multiple-3;
}
.#{$ti-prefix}-box-multiple-4:before {
  content: $ti-icon-box-multiple-4;
}
.#{$ti-prefix}-box-multiple-5:before {
  content: $ti-icon-box-multiple-5;
}
.#{$ti-prefix}-box-multiple-6:before {
  content: $ti-icon-box-multiple-6;
}
.#{$ti-prefix}-box-multiple-7:before {
  content: $ti-icon-box-multiple-7;
}
.#{$ti-prefix}-box-multiple-8:before {
  content: $ti-icon-box-multiple-8;
}
.#{$ti-prefix}-box-multiple-9:before {
  content: $ti-icon-box-multiple-9;
}
.#{$ti-prefix}-box-off:before {
  content: $ti-icon-box-off;
}
.#{$ti-prefix}-box-padding:before {
  content: $ti-icon-box-padding;
}
.#{$ti-prefix}-braces:before {
  content: $ti-icon-braces;
}
.#{$ti-prefix}-braces-off:before {
  content: $ti-icon-braces-off;
}
.#{$ti-prefix}-brackets:before {
  content: $ti-icon-brackets;
}
.#{$ti-prefix}-brackets-contain:before {
  content: $ti-icon-brackets-contain;
}
.#{$ti-prefix}-brackets-contain-end:before {
  content: $ti-icon-brackets-contain-end;
}
.#{$ti-prefix}-brackets-contain-start:before {
  content: $ti-icon-brackets-contain-start;
}
.#{$ti-prefix}-brackets-off:before {
  content: $ti-icon-brackets-off;
}
.#{$ti-prefix}-brand-adobe:before {
  content: $ti-icon-brand-adobe;
}
.#{$ti-prefix}-brand-airbnb:before {
  content: $ti-icon-brand-airbnb;
}
.#{$ti-prefix}-brand-airtable:before {
  content: $ti-icon-brand-airtable;
}
.#{$ti-prefix}-brand-amongus:before {
  content: $ti-icon-brand-amongus;
}
.#{$ti-prefix}-brand-android:before {
  content: $ti-icon-brand-android;
}
.#{$ti-prefix}-brand-angular:before {
  content: $ti-icon-brand-angular;
}
.#{$ti-prefix}-brand-apple:before {
  content: $ti-icon-brand-apple;
}
.#{$ti-prefix}-brand-apple-arcade:before {
  content: $ti-icon-brand-apple-arcade;
}
.#{$ti-prefix}-brand-apple-podcast:before {
  content: $ti-icon-brand-apple-podcast;
}
.#{$ti-prefix}-brand-appstore:before {
  content: $ti-icon-brand-appstore;
}
.#{$ti-prefix}-brand-asana:before {
  content: $ti-icon-brand-asana;
}
.#{$ti-prefix}-brand-badoo:before {
  content: $ti-icon-brand-badoo;
}
.#{$ti-prefix}-brand-bandcamp:before {
  content: $ti-icon-brand-bandcamp;
}
.#{$ti-prefix}-brand-beats:before {
  content: $ti-icon-brand-beats;
}
.#{$ti-prefix}-brand-behance:before {
  content: $ti-icon-brand-behance;
}
.#{$ti-prefix}-brand-bing:before {
  content: $ti-icon-brand-bing;
}
.#{$ti-prefix}-brand-bitbucket:before {
  content: $ti-icon-brand-bitbucket;
}
.#{$ti-prefix}-brand-booking:before {
  content: $ti-icon-brand-booking;
}
.#{$ti-prefix}-brand-bootstrap:before {
  content: $ti-icon-brand-bootstrap;
}
.#{$ti-prefix}-brand-chrome:before {
  content: $ti-icon-brand-chrome;
}
.#{$ti-prefix}-brand-codepen:before {
  content: $ti-icon-brand-codepen;
}
.#{$ti-prefix}-brand-codesandbox:before {
  content: $ti-icon-brand-codesandbox;
}
.#{$ti-prefix}-brand-coinbase:before {
  content: $ti-icon-brand-coinbase;
}
.#{$ti-prefix}-brand-comedy-central:before {
  content: $ti-icon-brand-comedy-central;
}
.#{$ti-prefix}-brand-css3:before {
  content: $ti-icon-brand-css3;
}
.#{$ti-prefix}-brand-cucumber:before {
  content: $ti-icon-brand-cucumber;
}
.#{$ti-prefix}-brand-debian:before {
  content: $ti-icon-brand-debian;
}
.#{$ti-prefix}-brand-deviantart:before {
  content: $ti-icon-brand-deviantart;
}
.#{$ti-prefix}-brand-discord:before {
  content: $ti-icon-brand-discord;
}
.#{$ti-prefix}-brand-disney:before {
  content: $ti-icon-brand-disney;
}
.#{$ti-prefix}-brand-disqus:before {
  content: $ti-icon-brand-disqus;
}
.#{$ti-prefix}-brand-docker:before {
  content: $ti-icon-brand-docker;
}
.#{$ti-prefix}-brand-doctrine:before {
  content: $ti-icon-brand-doctrine;
}
.#{$ti-prefix}-brand-dribbble:before {
  content: $ti-icon-brand-dribbble;
}
.#{$ti-prefix}-brand-edge:before {
  content: $ti-icon-brand-edge;
}
.#{$ti-prefix}-brand-facebook:before {
  content: $ti-icon-brand-facebook;
}
.#{$ti-prefix}-brand-figma:before {
  content: $ti-icon-brand-figma;
}
.#{$ti-prefix}-brand-finder:before {
  content: $ti-icon-brand-finder;
}
.#{$ti-prefix}-brand-firebase:before {
  content: $ti-icon-brand-firebase;
}
.#{$ti-prefix}-brand-firefox:before {
  content: $ti-icon-brand-firefox;
}
.#{$ti-prefix}-brand-flickr:before {
  content: $ti-icon-brand-flickr;
}
.#{$ti-prefix}-brand-flipboard:before {
  content: $ti-icon-brand-flipboard;
}
.#{$ti-prefix}-brand-foursquare:before {
  content: $ti-icon-brand-foursquare;
}
.#{$ti-prefix}-brand-framer:before {
  content: $ti-icon-brand-framer;
}
.#{$ti-prefix}-brand-git:before {
  content: $ti-icon-brand-git;
}
.#{$ti-prefix}-brand-github:before {
  content: $ti-icon-brand-github;
}
.#{$ti-prefix}-brand-gitlab:before {
  content: $ti-icon-brand-gitlab;
}
.#{$ti-prefix}-brand-gmail:before {
  content: $ti-icon-brand-gmail;
}
.#{$ti-prefix}-brand-google:before {
  content: $ti-icon-brand-google;
}
.#{$ti-prefix}-brand-google-analytics:before {
  content: $ti-icon-brand-google-analytics;
}
.#{$ti-prefix}-brand-google-drive:before {
  content: $ti-icon-brand-google-drive;
}
.#{$ti-prefix}-brand-google-photos:before {
  content: $ti-icon-brand-google-photos;
}
.#{$ti-prefix}-brand-google-play:before {
  content: $ti-icon-brand-google-play;
}
.#{$ti-prefix}-brand-gravatar:before {
  content: $ti-icon-brand-gravatar;
}
.#{$ti-prefix}-brand-grindr:before {
  content: $ti-icon-brand-grindr;
}
.#{$ti-prefix}-brand-hipchat:before {
  content: $ti-icon-brand-hipchat;
}
.#{$ti-prefix}-brand-html5:before {
  content: $ti-icon-brand-html5;
}
.#{$ti-prefix}-brand-instagram:before {
  content: $ti-icon-brand-instagram;
}
.#{$ti-prefix}-brand-intercom:before {
  content: $ti-icon-brand-intercom;
}
.#{$ti-prefix}-brand-javascript:before {
  content: $ti-icon-brand-javascript;
}
.#{$ti-prefix}-brand-kickstarter:before {
  content: $ti-icon-brand-kickstarter;
}
.#{$ti-prefix}-brand-kotlin:before {
  content: $ti-icon-brand-kotlin;
}
.#{$ti-prefix}-brand-lastfm:before {
  content: $ti-icon-brand-lastfm;
}
.#{$ti-prefix}-brand-linkedin:before {
  content: $ti-icon-brand-linkedin;
}
.#{$ti-prefix}-brand-linktree:before {
  content: $ti-icon-brand-linktree;
}
.#{$ti-prefix}-brand-loom:before {
  content: $ti-icon-brand-loom;
}
.#{$ti-prefix}-brand-mastercard:before {
  content: $ti-icon-brand-mastercard;
}
.#{$ti-prefix}-brand-medium:before {
  content: $ti-icon-brand-medium;
}
.#{$ti-prefix}-brand-mercedes:before {
  content: $ti-icon-brand-mercedes;
}
.#{$ti-prefix}-brand-messenger:before {
  content: $ti-icon-brand-messenger;
}
.#{$ti-prefix}-brand-meta:before {
  content: $ti-icon-brand-meta;
}
.#{$ti-prefix}-brand-monday:before {
  content: $ti-icon-brand-monday;
}
.#{$ti-prefix}-brand-netbeans:before {
  content: $ti-icon-brand-netbeans;
}
.#{$ti-prefix}-brand-netflix:before {
  content: $ti-icon-brand-netflix;
}
.#{$ti-prefix}-brand-nextjs:before {
  content: $ti-icon-brand-nextjs;
}
.#{$ti-prefix}-brand-notion:before {
  content: $ti-icon-brand-notion;
}
.#{$ti-prefix}-brand-nuxt:before {
  content: $ti-icon-brand-nuxt;
}
.#{$ti-prefix}-brand-nytimes:before {
  content: $ti-icon-brand-nytimes;
}
.#{$ti-prefix}-brand-open-source:before {
  content: $ti-icon-brand-open-source;
}
.#{$ti-prefix}-brand-opera:before {
  content: $ti-icon-brand-opera;
}
.#{$ti-prefix}-brand-pagekit:before {
  content: $ti-icon-brand-pagekit;
}
.#{$ti-prefix}-brand-patreon:before {
  content: $ti-icon-brand-patreon;
}
.#{$ti-prefix}-brand-paypal:before {
  content: $ti-icon-brand-paypal;
}
.#{$ti-prefix}-brand-php:before {
  content: $ti-icon-brand-php;
}
.#{$ti-prefix}-brand-pinterest:before {
  content: $ti-icon-brand-pinterest;
}
.#{$ti-prefix}-brand-pocket:before {
  content: $ti-icon-brand-pocket;
}
.#{$ti-prefix}-brand-producthunt:before {
  content: $ti-icon-brand-producthunt;
}
.#{$ti-prefix}-brand-pushover:before {
  content: $ti-icon-brand-pushover;
}
.#{$ti-prefix}-brand-python:before {
  content: $ti-icon-brand-python;
}
.#{$ti-prefix}-brand-react-native:before {
  content: $ti-icon-brand-react-native;
}
.#{$ti-prefix}-brand-reddit:before {
  content: $ti-icon-brand-reddit;
}
.#{$ti-prefix}-brand-safari:before {
  content: $ti-icon-brand-safari;
}
.#{$ti-prefix}-brand-sass:before {
  content: $ti-icon-brand-sass;
}
.#{$ti-prefix}-brand-sentry:before {
  content: $ti-icon-brand-sentry;
}
.#{$ti-prefix}-brand-shazam:before {
  content: $ti-icon-brand-shazam;
}
.#{$ti-prefix}-brand-sketch:before {
  content: $ti-icon-brand-sketch;
}
.#{$ti-prefix}-brand-skype:before {
  content: $ti-icon-brand-skype;
}
.#{$ti-prefix}-brand-slack:before {
  content: $ti-icon-brand-slack;
}
.#{$ti-prefix}-brand-snapchat:before {
  content: $ti-icon-brand-snapchat;
}
.#{$ti-prefix}-brand-soundcloud:before {
  content: $ti-icon-brand-soundcloud;
}
.#{$ti-prefix}-brand-spotify:before {
  content: $ti-icon-brand-spotify;
}
.#{$ti-prefix}-brand-stackoverflow:before {
  content: $ti-icon-brand-stackoverflow;
}
.#{$ti-prefix}-brand-steam:before {
  content: $ti-icon-brand-steam;
}
.#{$ti-prefix}-brand-stripe:before {
  content: $ti-icon-brand-stripe;
}
.#{$ti-prefix}-brand-sublime-text:before {
  content: $ti-icon-brand-sublime-text;
}
.#{$ti-prefix}-brand-svelte:before {
  content: $ti-icon-brand-svelte;
}
.#{$ti-prefix}-brand-tabler:before {
  content: $ti-icon-brand-tabler;
}
.#{$ti-prefix}-brand-tailwind:before {
  content: $ti-icon-brand-tailwind;
}
.#{$ti-prefix}-brand-telegram:before {
  content: $ti-icon-brand-telegram;
}
.#{$ti-prefix}-brand-tidal:before {
  content: $ti-icon-brand-tidal;
}
.#{$ti-prefix}-brand-tiktok:before {
  content: $ti-icon-brand-tiktok;
}
.#{$ti-prefix}-brand-tinder:before {
  content: $ti-icon-brand-tinder;
}
.#{$ti-prefix}-brand-tripadvisor:before {
  content: $ti-icon-brand-tripadvisor;
}
.#{$ti-prefix}-brand-tumblr:before {
  content: $ti-icon-brand-tumblr;
}
.#{$ti-prefix}-brand-twitch:before {
  content: $ti-icon-brand-twitch;
}
.#{$ti-prefix}-brand-twitter:before {
  content: $ti-icon-brand-twitter;
}
.#{$ti-prefix}-brand-uber:before {
  content: $ti-icon-brand-uber;
}
.#{$ti-prefix}-brand-ubuntu:before {
  content: $ti-icon-brand-ubuntu;
}
.#{$ti-prefix}-brand-unsplash:before {
  content: $ti-icon-brand-unsplash;
}
.#{$ti-prefix}-brand-vercel:before {
  content: $ti-icon-brand-vercel;
}
.#{$ti-prefix}-brand-vimeo:before {
  content: $ti-icon-brand-vimeo;
}
.#{$ti-prefix}-brand-vinted:before {
  content: $ti-icon-brand-vinted;
}
.#{$ti-prefix}-brand-visual-studio:before {
  content: $ti-icon-brand-visual-studio;
}
.#{$ti-prefix}-brand-vivaldi:before {
  content: $ti-icon-brand-vivaldi;
}
.#{$ti-prefix}-brand-vk:before {
  content: $ti-icon-brand-vk;
}
.#{$ti-prefix}-brand-vue:before {
  content: $ti-icon-brand-vue;
}
.#{$ti-prefix}-brand-walmart:before {
  content: $ti-icon-brand-walmart;
}
.#{$ti-prefix}-brand-whatsapp:before {
  content: $ti-icon-brand-whatsapp;
}
.#{$ti-prefix}-brand-windows:before {
  content: $ti-icon-brand-windows;
}
.#{$ti-prefix}-brand-wish:before {
  content: $ti-icon-brand-wish;
}
.#{$ti-prefix}-brand-xing:before {
  content: $ti-icon-brand-xing;
}
.#{$ti-prefix}-brand-yahoo:before {
  content: $ti-icon-brand-yahoo;
}
.#{$ti-prefix}-brand-yatse:before {
  content: $ti-icon-brand-yatse;
}
.#{$ti-prefix}-brand-ycombinator:before {
  content: $ti-icon-brand-ycombinator;
}
.#{$ti-prefix}-brand-youtube:before {
  content: $ti-icon-brand-youtube;
}
.#{$ti-prefix}-brand-youtube-kids:before {
  content: $ti-icon-brand-youtube-kids;
}
.#{$ti-prefix}-brand-zoom:before {
  content: $ti-icon-brand-zoom;
}
.#{$ti-prefix}-brand-zwift:before {
  content: $ti-icon-brand-zwift;
}
.#{$ti-prefix}-bread:before {
  content: $ti-icon-bread;
}
.#{$ti-prefix}-briefcase:before {
  content: $ti-icon-briefcase;
}
.#{$ti-prefix}-brightness:before {
  content: $ti-icon-brightness;
}
.#{$ti-prefix}-brightness-2:before {
  content: $ti-icon-brightness-2;
}
.#{$ti-prefix}-brightness-down:before {
  content: $ti-icon-brightness-down;
}
.#{$ti-prefix}-brightness-half:before {
  content: $ti-icon-brightness-half;
}
.#{$ti-prefix}-brightness-up:before {
  content: $ti-icon-brightness-up;
}
.#{$ti-prefix}-broadcast:before {
  content: $ti-icon-broadcast;
}
.#{$ti-prefix}-broadcast-off:before {
  content: $ti-icon-broadcast-off;
}
.#{$ti-prefix}-browser:before {
  content: $ti-icon-browser;
}
.#{$ti-prefix}-browser-check:before {
  content: $ti-icon-browser-check;
}
.#{$ti-prefix}-browser-off:before {
  content: $ti-icon-browser-off;
}
.#{$ti-prefix}-browser-plus:before {
  content: $ti-icon-browser-plus;
}
.#{$ti-prefix}-browser-x:before {
  content: $ti-icon-browser-x;
}
.#{$ti-prefix}-brush:before {
  content: $ti-icon-brush;
}
.#{$ti-prefix}-brush-off:before {
  content: $ti-icon-brush-off;
}
.#{$ti-prefix}-bucket:before {
  content: $ti-icon-bucket;
}
.#{$ti-prefix}-bucket-off:before {
  content: $ti-icon-bucket-off;
}
.#{$ti-prefix}-bug:before {
  content: $ti-icon-bug;
}
.#{$ti-prefix}-bug-off:before {
  content: $ti-icon-bug-off;
}
.#{$ti-prefix}-building:before {
  content: $ti-icon-building;
}
.#{$ti-prefix}-building-arch:before {
  content: $ti-icon-building-arch;
}
.#{$ti-prefix}-building-bank:before {
  content: $ti-icon-building-bank;
}
.#{$ti-prefix}-building-bridge:before {
  content: $ti-icon-building-bridge;
}
.#{$ti-prefix}-building-bridge-2:before {
  content: $ti-icon-building-bridge-2;
}
.#{$ti-prefix}-building-carousel:before {
  content: $ti-icon-building-carousel;
}
.#{$ti-prefix}-building-castle:before {
  content: $ti-icon-building-castle;
}
.#{$ti-prefix}-building-church:before {
  content: $ti-icon-building-church;
}
.#{$ti-prefix}-building-community:before {
  content: $ti-icon-building-community;
}
.#{$ti-prefix}-building-cottage:before {
  content: $ti-icon-building-cottage;
}
.#{$ti-prefix}-building-factory:before {
  content: $ti-icon-building-factory;
}
.#{$ti-prefix}-building-factory-2:before {
  content: $ti-icon-building-factory-2;
}
.#{$ti-prefix}-building-fortress:before {
  content: $ti-icon-building-fortress;
}
.#{$ti-prefix}-building-hospital:before {
  content: $ti-icon-building-hospital;
}
.#{$ti-prefix}-building-lighthouse:before {
  content: $ti-icon-building-lighthouse;
}
.#{$ti-prefix}-building-monument:before {
  content: $ti-icon-building-monument;
}
.#{$ti-prefix}-building-pavilon:before {
  content: $ti-icon-building-pavilon;
}
.#{$ti-prefix}-building-skyscraper:before {
  content: $ti-icon-building-skyscraper;
}
.#{$ti-prefix}-building-store:before {
  content: $ti-icon-building-store;
}
.#{$ti-prefix}-building-warehouse:before {
  content: $ti-icon-building-warehouse;
}
.#{$ti-prefix}-bulb:before {
  content: $ti-icon-bulb;
}
.#{$ti-prefix}-bulb-off:before {
  content: $ti-icon-bulb-off;
}
.#{$ti-prefix}-bulldozer:before {
  content: $ti-icon-bulldozer;
}
.#{$ti-prefix}-bus:before {
  content: $ti-icon-bus;
}
.#{$ti-prefix}-businessplan:before {
  content: $ti-icon-businessplan;
}
.#{$ti-prefix}-butterfly:before {
  content: $ti-icon-butterfly;
}
.#{$ti-prefix}-c-sharp:before {
  content: $ti-icon-c-sharp;
}
.#{$ti-prefix}-cactus:before {
  content: $ti-icon-cactus;
}
.#{$ti-prefix}-cake:before {
  content: $ti-icon-cake;
}
.#{$ti-prefix}-cake-off:before {
  content: $ti-icon-cake-off;
}
.#{$ti-prefix}-calculator:before {
  content: $ti-icon-calculator;
}
.#{$ti-prefix}-calculator-off:before {
  content: $ti-icon-calculator-off;
}
.#{$ti-prefix}-calendar:before {
  content: $ti-icon-calendar;
}
.#{$ti-prefix}-calendar-event:before {
  content: $ti-icon-calendar-event;
}
.#{$ti-prefix}-calendar-minus:before {
  content: $ti-icon-calendar-minus;
}
.#{$ti-prefix}-calendar-off:before {
  content: $ti-icon-calendar-off;
}
.#{$ti-prefix}-calendar-plus:before {
  content: $ti-icon-calendar-plus;
}
.#{$ti-prefix}-calendar-stats:before {
  content: $ti-icon-calendar-stats;
}
.#{$ti-prefix}-calendar-time:before {
  content: $ti-icon-calendar-time;
}
.#{$ti-prefix}-camera:before {
  content: $ti-icon-camera;
}
.#{$ti-prefix}-camera-minus:before {
  content: $ti-icon-camera-minus;
}
.#{$ti-prefix}-camera-off:before {
  content: $ti-icon-camera-off;
}
.#{$ti-prefix}-camera-plus:before {
  content: $ti-icon-camera-plus;
}
.#{$ti-prefix}-camera-rotate:before {
  content: $ti-icon-camera-rotate;
}
.#{$ti-prefix}-camera-selfie:before {
  content: $ti-icon-camera-selfie;
}
.#{$ti-prefix}-candle:before {
  content: $ti-icon-candle;
}
.#{$ti-prefix}-candy:before {
  content: $ti-icon-candy;
}
.#{$ti-prefix}-candy-off:before {
  content: $ti-icon-candy-off;
}
.#{$ti-prefix}-capture:before {
  content: $ti-icon-capture;
}
.#{$ti-prefix}-capture-off:before {
  content: $ti-icon-capture-off;
}
.#{$ti-prefix}-car:before {
  content: $ti-icon-car;
}
.#{$ti-prefix}-car-crane:before {
  content: $ti-icon-car-crane;
}
.#{$ti-prefix}-car-crash:before {
  content: $ti-icon-car-crash;
}
.#{$ti-prefix}-car-off:before {
  content: $ti-icon-car-off;
}
.#{$ti-prefix}-caravan:before {
  content: $ti-icon-caravan;
}
.#{$ti-prefix}-cardboards:before {
  content: $ti-icon-cardboards;
}
.#{$ti-prefix}-cardboards-off:before {
  content: $ti-icon-cardboards-off;
}
.#{$ti-prefix}-caret-down:before {
  content: $ti-icon-caret-down;
}
.#{$ti-prefix}-caret-left:before {
  content: $ti-icon-caret-left;
}
.#{$ti-prefix}-caret-right:before {
  content: $ti-icon-caret-right;
}
.#{$ti-prefix}-caret-up:before {
  content: $ti-icon-caret-up;
}
.#{$ti-prefix}-carrot:before {
  content: $ti-icon-carrot;
}
.#{$ti-prefix}-cash:before {
  content: $ti-icon-cash;
}
.#{$ti-prefix}-cash-banknote:before {
  content: $ti-icon-cash-banknote;
}
.#{$ti-prefix}-cash-banknote-off:before {
  content: $ti-icon-cash-banknote-off;
}
.#{$ti-prefix}-cash-off:before {
  content: $ti-icon-cash-off;
}
.#{$ti-prefix}-cast:before {
  content: $ti-icon-cast;
}
.#{$ti-prefix}-cast-off:before {
  content: $ti-icon-cast-off;
}
.#{$ti-prefix}-category:before {
  content: $ti-icon-category;
}
.#{$ti-prefix}-category-2:before {
  content: $ti-icon-category-2;
}
.#{$ti-prefix}-ce:before {
  content: $ti-icon-ce;
}
.#{$ti-prefix}-ce-off:before {
  content: $ti-icon-ce-off;
}
.#{$ti-prefix}-cell:before {
  content: $ti-icon-cell;
}
.#{$ti-prefix}-cell-signal-1:before {
  content: $ti-icon-cell-signal-1;
}
.#{$ti-prefix}-cell-signal-2:before {
  content: $ti-icon-cell-signal-2;
}
.#{$ti-prefix}-cell-signal-3:before {
  content: $ti-icon-cell-signal-3;
}
.#{$ti-prefix}-cell-signal-4:before {
  content: $ti-icon-cell-signal-4;
}
.#{$ti-prefix}-cell-signal-5:before {
  content: $ti-icon-cell-signal-5;
}
.#{$ti-prefix}-cell-signal-off:before {
  content: $ti-icon-cell-signal-off;
}
.#{$ti-prefix}-certificate:before {
  content: $ti-icon-certificate;
}
.#{$ti-prefix}-certificate-2:before {
  content: $ti-icon-certificate-2;
}
.#{$ti-prefix}-certificate-2-off:before {
  content: $ti-icon-certificate-2-off;
}
.#{$ti-prefix}-certificate-off:before {
  content: $ti-icon-certificate-off;
}
.#{$ti-prefix}-charging-pile:before {
  content: $ti-icon-charging-pile;
}
.#{$ti-prefix}-chart-arcs:before {
  content: $ti-icon-chart-arcs;
}
.#{$ti-prefix}-chart-arcs-3:before {
  content: $ti-icon-chart-arcs-3;
}
.#{$ti-prefix}-chart-area:before {
  content: $ti-icon-chart-area;
}
.#{$ti-prefix}-chart-area-line:before {
  content: $ti-icon-chart-area-line;
}
.#{$ti-prefix}-chart-arrows:before {
  content: $ti-icon-chart-arrows;
}
.#{$ti-prefix}-chart-arrows-vertical:before {
  content: $ti-icon-chart-arrows-vertical;
}
.#{$ti-prefix}-chart-bar:before {
  content: $ti-icon-chart-bar;
}
.#{$ti-prefix}-chart-bubble:before {
  content: $ti-icon-chart-bubble;
}
.#{$ti-prefix}-chart-candle:before {
  content: $ti-icon-chart-candle;
}
.#{$ti-prefix}-chart-circles:before {
  content: $ti-icon-chart-circles;
}
.#{$ti-prefix}-chart-donut:before {
  content: $ti-icon-chart-donut;
}
.#{$ti-prefix}-chart-donut-2:before {
  content: $ti-icon-chart-donut-2;
}
.#{$ti-prefix}-chart-donut-3:before {
  content: $ti-icon-chart-donut-3;
}
.#{$ti-prefix}-chart-donut-4:before {
  content: $ti-icon-chart-donut-4;
}
.#{$ti-prefix}-chart-dots:before {
  content: $ti-icon-chart-dots;
}
.#{$ti-prefix}-chart-dots-2:before {
  content: $ti-icon-chart-dots-2;
}
.#{$ti-prefix}-chart-dots-3:before {
  content: $ti-icon-chart-dots-3;
}
.#{$ti-prefix}-chart-infographic:before {
  content: $ti-icon-chart-infographic;
}
.#{$ti-prefix}-chart-line:before {
  content: $ti-icon-chart-line;
}
.#{$ti-prefix}-chart-pie:before {
  content: $ti-icon-chart-pie;
}
.#{$ti-prefix}-chart-pie-2:before {
  content: $ti-icon-chart-pie-2;
}
.#{$ti-prefix}-chart-pie-3:before {
  content: $ti-icon-chart-pie-3;
}
.#{$ti-prefix}-chart-pie-4:before {
  content: $ti-icon-chart-pie-4;
}
.#{$ti-prefix}-chart-radar:before {
  content: $ti-icon-chart-radar;
}
.#{$ti-prefix}-check:before {
  content: $ti-icon-check;
}
.#{$ti-prefix}-checkbox:before {
  content: $ti-icon-checkbox;
}
.#{$ti-prefix}-checklist:before {
  content: $ti-icon-checklist;
}
.#{$ti-prefix}-checks:before {
  content: $ti-icon-checks;
}
.#{$ti-prefix}-checkup-list:before {
  content: $ti-icon-checkup-list;
}
.#{$ti-prefix}-cheese:before {
  content: $ti-icon-cheese;
}
.#{$ti-prefix}-chef-hat:before {
  content: $ti-icon-chef-hat;
}
.#{$ti-prefix}-chevron-down:before {
  content: $ti-icon-chevron-down;
}
.#{$ti-prefix}-chevron-down-left:before {
  content: $ti-icon-chevron-down-left;
}
.#{$ti-prefix}-chevron-down-right:before {
  content: $ti-icon-chevron-down-right;
}
.#{$ti-prefix}-chevron-left:before {
  content: $ti-icon-chevron-left;
}
.#{$ti-prefix}-chevron-right:before {
  content: $ti-icon-chevron-right;
}
.#{$ti-prefix}-chevron-up:before {
  content: $ti-icon-chevron-up;
}
.#{$ti-prefix}-chevron-up-left:before {
  content: $ti-icon-chevron-up-left;
}
.#{$ti-prefix}-chevron-up-right:before {
  content: $ti-icon-chevron-up-right;
}
.#{$ti-prefix}-chevrons-down:before {
  content: $ti-icon-chevrons-down;
}
.#{$ti-prefix}-chevrons-down-left:before {
  content: $ti-icon-chevrons-down-left;
}
.#{$ti-prefix}-chevrons-down-right:before {
  content: $ti-icon-chevrons-down-right;
}
.#{$ti-prefix}-chevrons-left:before {
  content: $ti-icon-chevrons-left;
}
.#{$ti-prefix}-chevrons-right:before {
  content: $ti-icon-chevrons-right;
}
.#{$ti-prefix}-chevrons-up:before {
  content: $ti-icon-chevrons-up;
}
.#{$ti-prefix}-chevrons-up-left:before {
  content: $ti-icon-chevrons-up-left;
}
.#{$ti-prefix}-chevrons-up-right:before {
  content: $ti-icon-chevrons-up-right;
}
.#{$ti-prefix}-christmas-tree:before {
  content: $ti-icon-christmas-tree;
}
.#{$ti-prefix}-circle:before {
  content: $ti-icon-circle;
}
.#{$ti-prefix}-circle-0:before {
  content: $ti-icon-circle-0;
}
.#{$ti-prefix}-circle-1:before {
  content: $ti-icon-circle-1;
}
.#{$ti-prefix}-circle-2:before {
  content: $ti-icon-circle-2;
}
.#{$ti-prefix}-circle-3:before {
  content: $ti-icon-circle-3;
}
.#{$ti-prefix}-circle-4:before {
  content: $ti-icon-circle-4;
}
.#{$ti-prefix}-circle-5:before {
  content: $ti-icon-circle-5;
}
.#{$ti-prefix}-circle-6:before {
  content: $ti-icon-circle-6;
}
.#{$ti-prefix}-circle-7:before {
  content: $ti-icon-circle-7;
}
.#{$ti-prefix}-circle-8:before {
  content: $ti-icon-circle-8;
}
.#{$ti-prefix}-circle-9:before {
  content: $ti-icon-circle-9;
}
.#{$ti-prefix}-circle-check:before {
  content: $ti-icon-circle-check;
}
.#{$ti-prefix}-circle-dashed:before {
  content: $ti-icon-circle-dashed;
}
.#{$ti-prefix}-circle-dot:before {
  content: $ti-icon-circle-dot;
}
.#{$ti-prefix}-circle-dotted:before {
  content: $ti-icon-circle-dotted;
}
.#{$ti-prefix}-circle-half:before {
  content: $ti-icon-circle-half;
}
.#{$ti-prefix}-circle-half-2:before {
  content: $ti-icon-circle-half-2;
}
.#{$ti-prefix}-circle-half-vertical:before {
  content: $ti-icon-circle-half-vertical;
}
.#{$ti-prefix}-circle-minus:before {
  content: $ti-icon-circle-minus;
}
.#{$ti-prefix}-circle-off:before {
  content: $ti-icon-circle-off;
}
.#{$ti-prefix}-circle-plus:before {
  content: $ti-icon-circle-plus;
}
.#{$ti-prefix}-circle-rectangle:before {
  content: $ti-icon-circle-rectangle;
}
.#{$ti-prefix}-circle-rectangle-off:before {
  content: $ti-icon-circle-rectangle-off;
}
.#{$ti-prefix}-circle-square:before {
  content: $ti-icon-circle-square;
}
.#{$ti-prefix}-circle-triangle:before {
  content: $ti-icon-circle-triangle;
}
.#{$ti-prefix}-circle-x:before {
  content: $ti-icon-circle-x;
}
.#{$ti-prefix}-circles:before {
  content: $ti-icon-circles;
}
.#{$ti-prefix}-clear-all:before {
  content: $ti-icon-clear-all;
}
.#{$ti-prefix}-clear-formatting:before {
  content: $ti-icon-clear-formatting;
}
.#{$ti-prefix}-click:before {
  content: $ti-icon-click;
}
.#{$ti-prefix}-clipboard:before {
  content: $ti-icon-clipboard;
}
.#{$ti-prefix}-clipboard-check:before {
  content: $ti-icon-clipboard-check;
}
.#{$ti-prefix}-clipboard-list:before {
  content: $ti-icon-clipboard-list;
}
.#{$ti-prefix}-clipboard-off:before {
  content: $ti-icon-clipboard-off;
}
.#{$ti-prefix}-clipboard-plus:before {
  content: $ti-icon-clipboard-plus;
}
.#{$ti-prefix}-clipboard-text:before {
  content: $ti-icon-clipboard-text;
}
.#{$ti-prefix}-clipboard-x:before {
  content: $ti-icon-clipboard-x;
}
.#{$ti-prefix}-clock:before {
  content: $ti-icon-clock;
}
.#{$ti-prefix}-clock-2:before {
  content: $ti-icon-clock-2;
}
.#{$ti-prefix}-clock-off:before {
  content: $ti-icon-clock-off;
}
.#{$ti-prefix}-cloud:before {
  content: $ti-icon-cloud;
}
.#{$ti-prefix}-cloud-computing:before {
  content: $ti-icon-cloud-computing;
}
.#{$ti-prefix}-cloud-data-connection:before {
  content: $ti-icon-cloud-data-connection;
}
.#{$ti-prefix}-cloud-download:before {
  content: $ti-icon-cloud-download;
}
.#{$ti-prefix}-cloud-fog:before {
  content: $ti-icon-cloud-fog;
}
.#{$ti-prefix}-cloud-lock:before {
  content: $ti-icon-cloud-lock;
}
.#{$ti-prefix}-cloud-lock-open:before {
  content: $ti-icon-cloud-lock-open;
}
.#{$ti-prefix}-cloud-off:before {
  content: $ti-icon-cloud-off;
}
.#{$ti-prefix}-cloud-rain:before {
  content: $ti-icon-cloud-rain;
}
.#{$ti-prefix}-cloud-snow:before {
  content: $ti-icon-cloud-snow;
}
.#{$ti-prefix}-cloud-storm:before {
  content: $ti-icon-cloud-storm;
}
.#{$ti-prefix}-cloud-upload:before {
  content: $ti-icon-cloud-upload;
}
.#{$ti-prefix}-clover:before {
  content: $ti-icon-clover;
}
.#{$ti-prefix}-clover-2:before {
  content: $ti-icon-clover-2;
}
.#{$ti-prefix}-clubs:before {
  content: $ti-icon-clubs;
}
.#{$ti-prefix}-code:before {
  content: $ti-icon-code;
}
.#{$ti-prefix}-code-minus:before {
  content: $ti-icon-code-minus;
}
.#{$ti-prefix}-code-off:before {
  content: $ti-icon-code-off;
}
.#{$ti-prefix}-code-plus:before {
  content: $ti-icon-code-plus;
}
.#{$ti-prefix}-coffee:before {
  content: $ti-icon-coffee;
}
.#{$ti-prefix}-coffee-off:before {
  content: $ti-icon-coffee-off;
}
.#{$ti-prefix}-coin:before {
  content: $ti-icon-coin;
}
.#{$ti-prefix}-coin-off:before {
  content: $ti-icon-coin-off;
}
.#{$ti-prefix}-color-picker:before {
  content: $ti-icon-color-picker;
}
.#{$ti-prefix}-color-picker-off:before {
  content: $ti-icon-color-picker-off;
}
.#{$ti-prefix}-color-swatch:before {
  content: $ti-icon-color-swatch;
}
.#{$ti-prefix}-color-swatch-off:before {
  content: $ti-icon-color-swatch-off;
}
.#{$ti-prefix}-column-insert-left:before {
  content: $ti-icon-column-insert-left;
}
.#{$ti-prefix}-column-insert-right:before {
  content: $ti-icon-column-insert-right;
}
.#{$ti-prefix}-columns:before {
  content: $ti-icon-columns;
}
.#{$ti-prefix}-columns-off:before {
  content: $ti-icon-columns-off;
}
.#{$ti-prefix}-comet:before {
  content: $ti-icon-comet;
}
.#{$ti-prefix}-command:before {
  content: $ti-icon-command;
}
.#{$ti-prefix}-compass:before {
  content: $ti-icon-compass;
}
.#{$ti-prefix}-compass-off:before {
  content: $ti-icon-compass-off;
}
.#{$ti-prefix}-components:before {
  content: $ti-icon-components;
}
.#{$ti-prefix}-components-off:before {
  content: $ti-icon-components-off;
}
.#{$ti-prefix}-cone:before {
  content: $ti-icon-cone;
}
.#{$ti-prefix}-cone-2:before {
  content: $ti-icon-cone-2;
}
.#{$ti-prefix}-confetti:before {
  content: $ti-icon-confetti;
}
.#{$ti-prefix}-container:before {
  content: $ti-icon-container;
}
.#{$ti-prefix}-container-off:before {
  content: $ti-icon-container-off;
}
.#{$ti-prefix}-contrast:before {
  content: $ti-icon-contrast;
}
.#{$ti-prefix}-contrast-2:before {
  content: $ti-icon-contrast-2;
}
.#{$ti-prefix}-cookie:before {
  content: $ti-icon-cookie;
}
.#{$ti-prefix}-cookie-off:before {
  content: $ti-icon-cookie-off;
}
.#{$ti-prefix}-copy:before {
  content: $ti-icon-copy;
}
.#{$ti-prefix}-copy-off:before {
  content: $ti-icon-copy-off;
}
.#{$ti-prefix}-copyleft:before {
  content: $ti-icon-copyleft;
}
.#{$ti-prefix}-copyleft-off:before {
  content: $ti-icon-copyleft-off;
}
.#{$ti-prefix}-copyright:before {
  content: $ti-icon-copyright;
}
.#{$ti-prefix}-copyright-off:before {
  content: $ti-icon-copyright-off;
}
.#{$ti-prefix}-corner-down-left:before {
  content: $ti-icon-corner-down-left;
}
.#{$ti-prefix}-corner-down-left-double:before {
  content: $ti-icon-corner-down-left-double;
}
.#{$ti-prefix}-corner-down-right:before {
  content: $ti-icon-corner-down-right;
}
.#{$ti-prefix}-corner-down-right-double:before {
  content: $ti-icon-corner-down-right-double;
}
.#{$ti-prefix}-corner-left-down:before {
  content: $ti-icon-corner-left-down;
}
.#{$ti-prefix}-corner-left-down-double:before {
  content: $ti-icon-corner-left-down-double;
}
.#{$ti-prefix}-corner-left-up:before {
  content: $ti-icon-corner-left-up;
}
.#{$ti-prefix}-corner-left-up-double:before {
  content: $ti-icon-corner-left-up-double;
}
.#{$ti-prefix}-corner-right-down:before {
  content: $ti-icon-corner-right-down;
}
.#{$ti-prefix}-corner-right-down-double:before {
  content: $ti-icon-corner-right-down-double;
}
.#{$ti-prefix}-corner-right-up:before {
  content: $ti-icon-corner-right-up;
}
.#{$ti-prefix}-corner-right-up-double:before {
  content: $ti-icon-corner-right-up-double;
}
.#{$ti-prefix}-corner-up-left:before {
  content: $ti-icon-corner-up-left;
}
.#{$ti-prefix}-corner-up-left-double:before {
  content: $ti-icon-corner-up-left-double;
}
.#{$ti-prefix}-corner-up-right:before {
  content: $ti-icon-corner-up-right;
}
.#{$ti-prefix}-corner-up-right-double:before {
  content: $ti-icon-corner-up-right-double;
}
.#{$ti-prefix}-cpu:before {
  content: $ti-icon-cpu;
}
.#{$ti-prefix}-cpu-2:before {
  content: $ti-icon-cpu-2;
}
.#{$ti-prefix}-cpu-off:before {
  content: $ti-icon-cpu-off;
}
.#{$ti-prefix}-crane:before {
  content: $ti-icon-crane;
}
.#{$ti-prefix}-crane-off:before {
  content: $ti-icon-crane-off;
}
.#{$ti-prefix}-creative-commons:before {
  content: $ti-icon-creative-commons;
}
.#{$ti-prefix}-creative-commons-by:before {
  content: $ti-icon-creative-commons-by;
}
.#{$ti-prefix}-creative-commons-nc:before {
  content: $ti-icon-creative-commons-nc;
}
.#{$ti-prefix}-creative-commons-nd:before {
  content: $ti-icon-creative-commons-nd;
}
.#{$ti-prefix}-creative-commons-off:before {
  content: $ti-icon-creative-commons-off;
}
.#{$ti-prefix}-creative-commons-sa:before {
  content: $ti-icon-creative-commons-sa;
}
.#{$ti-prefix}-creative-commons-zero:before {
  content: $ti-icon-creative-commons-zero;
}
.#{$ti-prefix}-credit-card:before {
  content: $ti-icon-credit-card;
}
.#{$ti-prefix}-credit-card-off:before {
  content: $ti-icon-credit-card-off;
}
.#{$ti-prefix}-cricket:before {
  content: $ti-icon-cricket;
}
.#{$ti-prefix}-crop:before {
  content: $ti-icon-crop;
}
.#{$ti-prefix}-cross:before {
  content: $ti-icon-cross;
}
.#{$ti-prefix}-cross-off:before {
  content: $ti-icon-cross-off;
}
.#{$ti-prefix}-crosshair:before {
  content: $ti-icon-crosshair;
}
.#{$ti-prefix}-crown:before {
  content: $ti-icon-crown;
}
.#{$ti-prefix}-crown-off:before {
  content: $ti-icon-crown-off;
}
.#{$ti-prefix}-crutches:before {
  content: $ti-icon-crutches;
}
.#{$ti-prefix}-crutches-off:before {
  content: $ti-icon-crutches-off;
}
.#{$ti-prefix}-cup:before {
  content: $ti-icon-cup;
}
.#{$ti-prefix}-cup-off:before {
  content: $ti-icon-cup-off;
}
.#{$ti-prefix}-curling:before {
  content: $ti-icon-curling;
}
.#{$ti-prefix}-curly-loop:before {
  content: $ti-icon-curly-loop;
}
.#{$ti-prefix}-currency:before {
  content: $ti-icon-currency;
}
.#{$ti-prefix}-currency-bahraini:before {
  content: $ti-icon-currency-bahraini;
}
.#{$ti-prefix}-currency-baht:before {
  content: $ti-icon-currency-baht;
}
.#{$ti-prefix}-currency-bitcoin:before {
  content: $ti-icon-currency-bitcoin;
}
.#{$ti-prefix}-currency-cent:before {
  content: $ti-icon-currency-cent;
}
.#{$ti-prefix}-currency-dinar:before {
  content: $ti-icon-currency-dinar;
}
.#{$ti-prefix}-currency-dirham:before {
  content: $ti-icon-currency-dirham;
}
.#{$ti-prefix}-currency-dogecoin:before {
  content: $ti-icon-currency-dogecoin;
}
.#{$ti-prefix}-currency-dollar:before {
  content: $ti-icon-currency-dollar;
}
.#{$ti-prefix}-currency-dollar-australian:before {
  content: $ti-icon-currency-dollar-australian;
}
.#{$ti-prefix}-currency-dollar-canadian:before {
  content: $ti-icon-currency-dollar-canadian;
}
.#{$ti-prefix}-currency-dollar-singapore:before {
  content: $ti-icon-currency-dollar-singapore;
}
.#{$ti-prefix}-currency-ethereum:before {
  content: $ti-icon-currency-ethereum;
}
.#{$ti-prefix}-currency-euro:before {
  content: $ti-icon-currency-euro;
}
.#{$ti-prefix}-currency-forint:before {
  content: $ti-icon-currency-forint;
}
.#{$ti-prefix}-currency-frank:before {
  content: $ti-icon-currency-frank;
}
.#{$ti-prefix}-currency-krone-czech:before {
  content: $ti-icon-currency-krone-czech;
}
.#{$ti-prefix}-currency-krone-danish:before {
  content: $ti-icon-currency-krone-danish;
}
.#{$ti-prefix}-currency-krone-swedish:before {
  content: $ti-icon-currency-krone-swedish;
}
.#{$ti-prefix}-currency-leu:before {
  content: $ti-icon-currency-leu;
}
.#{$ti-prefix}-currency-lira:before {
  content: $ti-icon-currency-lira;
}
.#{$ti-prefix}-currency-litecoin:before {
  content: $ti-icon-currency-litecoin;
}
.#{$ti-prefix}-currency-naira:before {
  content: $ti-icon-currency-naira;
}
.#{$ti-prefix}-currency-pound:before {
  content: $ti-icon-currency-pound;
}
.#{$ti-prefix}-currency-real:before {
  content: $ti-icon-currency-real;
}
.#{$ti-prefix}-currency-renminbi:before {
  content: $ti-icon-currency-renminbi;
}
.#{$ti-prefix}-currency-ripple:before {
  content: $ti-icon-currency-ripple;
}
.#{$ti-prefix}-currency-riyal:before {
  content: $ti-icon-currency-riyal;
}
.#{$ti-prefix}-currency-rubel:before {
  content: $ti-icon-currency-rubel;
}
.#{$ti-prefix}-currency-rupee:before {
  content: $ti-icon-currency-rupee;
}
.#{$ti-prefix}-currency-shekel:before {
  content: $ti-icon-currency-shekel;
}
.#{$ti-prefix}-currency-taka:before {
  content: $ti-icon-currency-taka;
}
.#{$ti-prefix}-currency-tugrik:before {
  content: $ti-icon-currency-tugrik;
}
.#{$ti-prefix}-currency-won:before {
  content: $ti-icon-currency-won;
}
.#{$ti-prefix}-currency-yen:before {
  content: $ti-icon-currency-yen;
}
.#{$ti-prefix}-currency-zloty:before {
  content: $ti-icon-currency-zloty;
}
.#{$ti-prefix}-current-location:before {
  content: $ti-icon-current-location;
}
.#{$ti-prefix}-current-location-off:before {
  content: $ti-icon-current-location-off;
}
.#{$ti-prefix}-cursor-off:before {
  content: $ti-icon-cursor-off;
}
.#{$ti-prefix}-cursor-text:before {
  content: $ti-icon-cursor-text;
}
.#{$ti-prefix}-cut:before {
  content: $ti-icon-cut;
}
.#{$ti-prefix}-dashboard:before {
  content: $ti-icon-dashboard;
}
.#{$ti-prefix}-database:before {
  content: $ti-icon-database;
}
.#{$ti-prefix}-database-export:before {
  content: $ti-icon-database-export;
}
.#{$ti-prefix}-database-import:before {
  content: $ti-icon-database-import;
}
.#{$ti-prefix}-database-off:before {
  content: $ti-icon-database-off;
}
.#{$ti-prefix}-dental:before {
  content: $ti-icon-dental;
}
.#{$ti-prefix}-dental-off:before {
  content: $ti-icon-dental-off;
}
.#{$ti-prefix}-details:before {
  content: $ti-icon-details;
}
.#{$ti-prefix}-device-analytics:before {
  content: $ti-icon-device-analytics;
}
.#{$ti-prefix}-device-audio-tape:before {
  content: $ti-icon-device-audio-tape;
}
.#{$ti-prefix}-device-cctv:before {
  content: $ti-icon-device-cctv;
}
.#{$ti-prefix}-device-computer-camera:before {
  content: $ti-icon-device-computer-camera;
}
.#{$ti-prefix}-device-computer-camera-off:before {
  content: $ti-icon-device-computer-camera-off;
}
.#{$ti-prefix}-device-desktop:before {
  content: $ti-icon-device-desktop;
}
.#{$ti-prefix}-device-desktop-analytics:before {
  content: $ti-icon-device-desktop-analytics;
}
.#{$ti-prefix}-device-desktop-off:before {
  content: $ti-icon-device-desktop-off;
}
.#{$ti-prefix}-device-floppy:before {
  content: $ti-icon-device-floppy;
}
.#{$ti-prefix}-device-gamepad:before {
  content: $ti-icon-device-gamepad;
}
.#{$ti-prefix}-device-gamepad-2:before {
  content: $ti-icon-device-gamepad-2;
}
.#{$ti-prefix}-device-heart-monitor:before {
  content: $ti-icon-device-heart-monitor;
}
.#{$ti-prefix}-device-laptop:before {
  content: $ti-icon-device-laptop;
}
.#{$ti-prefix}-device-laptop-off:before {
  content: $ti-icon-device-laptop-off;
}
.#{$ti-prefix}-device-mobile:before {
  content: $ti-icon-device-mobile;
}
.#{$ti-prefix}-device-mobile-charging:before {
  content: $ti-icon-device-mobile-charging;
}
.#{$ti-prefix}-device-mobile-message:before {
  content: $ti-icon-device-mobile-message;
}
.#{$ti-prefix}-device-mobile-off:before {
  content: $ti-icon-device-mobile-off;
}
.#{$ti-prefix}-device-mobile-rotated:before {
  content: $ti-icon-device-mobile-rotated;
}
.#{$ti-prefix}-device-mobile-vibration:before {
  content: $ti-icon-device-mobile-vibration;
}
.#{$ti-prefix}-device-nintendo:before {
  content: $ti-icon-device-nintendo;
}
.#{$ti-prefix}-device-nintendo-off:before {
  content: $ti-icon-device-nintendo-off;
}
.#{$ti-prefix}-device-speaker:before {
  content: $ti-icon-device-speaker;
}
.#{$ti-prefix}-device-speaker-off:before {
  content: $ti-icon-device-speaker-off;
}
.#{$ti-prefix}-device-tablet:before {
  content: $ti-icon-device-tablet;
}
.#{$ti-prefix}-device-tablet-off:before {
  content: $ti-icon-device-tablet-off;
}
.#{$ti-prefix}-device-tv:before {
  content: $ti-icon-device-tv;
}
.#{$ti-prefix}-device-tv-off:before {
  content: $ti-icon-device-tv-off;
}
.#{$ti-prefix}-device-tv-old:before {
  content: $ti-icon-device-tv-old;
}
.#{$ti-prefix}-device-watch:before {
  content: $ti-icon-device-watch;
}
.#{$ti-prefix}-device-watch-off:before {
  content: $ti-icon-device-watch-off;
}
.#{$ti-prefix}-device-watch-stats:before {
  content: $ti-icon-device-watch-stats;
}
.#{$ti-prefix}-device-watch-stats-2:before {
  content: $ti-icon-device-watch-stats-2;
}
.#{$ti-prefix}-devices:before {
  content: $ti-icon-devices;
}
.#{$ti-prefix}-devices-2:before {
  content: $ti-icon-devices-2;
}
.#{$ti-prefix}-devices-off:before {
  content: $ti-icon-devices-off;
}
.#{$ti-prefix}-devices-pc:before {
  content: $ti-icon-devices-pc;
}
.#{$ti-prefix}-devices-pc-off:before {
  content: $ti-icon-devices-pc-off;
}
.#{$ti-prefix}-dialpad:before {
  content: $ti-icon-dialpad;
}
.#{$ti-prefix}-dialpad-off:before {
  content: $ti-icon-dialpad-off;
}
.#{$ti-prefix}-diamond:before {
  content: $ti-icon-diamond;
}
.#{$ti-prefix}-diamond-off:before {
  content: $ti-icon-diamond-off;
}
.#{$ti-prefix}-diamonds:before {
  content: $ti-icon-diamonds;
}
.#{$ti-prefix}-dice:before {
  content: $ti-icon-dice;
}
.#{$ti-prefix}-dice-1:before {
  content: $ti-icon-dice-1;
}
.#{$ti-prefix}-dice-2:before {
  content: $ti-icon-dice-2;
}
.#{$ti-prefix}-dice-3:before {
  content: $ti-icon-dice-3;
}
.#{$ti-prefix}-dice-4:before {
  content: $ti-icon-dice-4;
}
.#{$ti-prefix}-dice-5:before {
  content: $ti-icon-dice-5;
}
.#{$ti-prefix}-dice-6:before {
  content: $ti-icon-dice-6;
}
.#{$ti-prefix}-dimensions:before {
  content: $ti-icon-dimensions;
}
.#{$ti-prefix}-direction:before {
  content: $ti-icon-direction;
}
.#{$ti-prefix}-direction-horizontal:before {
  content: $ti-icon-direction-horizontal;
}
.#{$ti-prefix}-direction-sign:before {
  content: $ti-icon-direction-sign;
}
.#{$ti-prefix}-directions:before {
  content: $ti-icon-directions;
}
.#{$ti-prefix}-directions-off:before {
  content: $ti-icon-directions-off;
}
.#{$ti-prefix}-disabled:before {
  content: $ti-icon-disabled;
}
.#{$ti-prefix}-disabled-2:before {
  content: $ti-icon-disabled-2;
}
.#{$ti-prefix}-disabled-off:before {
  content: $ti-icon-disabled-off;
}
.#{$ti-prefix}-disc:before {
  content: $ti-icon-disc;
}
.#{$ti-prefix}-disc-off:before {
  content: $ti-icon-disc-off;
}
.#{$ti-prefix}-discount:before {
  content: $ti-icon-discount;
}
.#{$ti-prefix}-discount-2:before {
  content: $ti-icon-discount-2;
}
.#{$ti-prefix}-discount-check:before {
  content: $ti-icon-discount-check;
}
.#{$ti-prefix}-divide:before {
  content: $ti-icon-divide;
}
.#{$ti-prefix}-dna:before {
  content: $ti-icon-dna;
}
.#{$ti-prefix}-dna-2:before {
  content: $ti-icon-dna-2;
}
.#{$ti-prefix}-dna-2-off:before {
  content: $ti-icon-dna-2-off;
}
.#{$ti-prefix}-dna-off:before {
  content: $ti-icon-dna-off;
}
.#{$ti-prefix}-dog-bowl:before {
  content: $ti-icon-dog-bowl;
}
.#{$ti-prefix}-door:before {
  content: $ti-icon-door;
}
.#{$ti-prefix}-door-enter:before {
  content: $ti-icon-door-enter;
}
.#{$ti-prefix}-door-exit:before {
  content: $ti-icon-door-exit;
}
.#{$ti-prefix}-door-off:before {
  content: $ti-icon-door-off;
}
.#{$ti-prefix}-dots:before {
  content: $ti-icon-dots;
}
.#{$ti-prefix}-dots-circle-horizontal:before {
  content: $ti-icon-dots-circle-horizontal;
}
.#{$ti-prefix}-dots-diagonal:before {
  content: $ti-icon-dots-diagonal;
}
.#{$ti-prefix}-dots-diagonal-2:before {
  content: $ti-icon-dots-diagonal-2;
}
.#{$ti-prefix}-dots-vertical:before {
  content: $ti-icon-dots-vertical;
}
.#{$ti-prefix}-download:before {
  content: $ti-icon-download;
}
.#{$ti-prefix}-download-off:before {
  content: $ti-icon-download-off;
}
.#{$ti-prefix}-drag-drop:before {
  content: $ti-icon-drag-drop;
}
.#{$ti-prefix}-drag-drop-2:before {
  content: $ti-icon-drag-drop-2;
}
.#{$ti-prefix}-drone:before {
  content: $ti-icon-drone;
}
.#{$ti-prefix}-drone-off:before {
  content: $ti-icon-drone-off;
}
.#{$ti-prefix}-drop-circle:before {
  content: $ti-icon-drop-circle;
}
.#{$ti-prefix}-droplet:before {
  content: $ti-icon-droplet;
}
.#{$ti-prefix}-droplet-filled:before {
  content: $ti-icon-droplet-filled;
}
.#{$ti-prefix}-droplet-filled-2:before {
  content: $ti-icon-droplet-filled-2;
}
.#{$ti-prefix}-droplet-half:before {
  content: $ti-icon-droplet-half;
}
.#{$ti-prefix}-droplet-half-2:before {
  content: $ti-icon-droplet-half-2;
}
.#{$ti-prefix}-droplet-off:before {
  content: $ti-icon-droplet-off;
}
.#{$ti-prefix}-ear:before {
  content: $ti-icon-ear;
}
.#{$ti-prefix}-ear-off:before {
  content: $ti-icon-ear-off;
}
.#{$ti-prefix}-edit:before {
  content: $ti-icon-edit;
}
.#{$ti-prefix}-edit-circle:before {
  content: $ti-icon-edit-circle;
}
.#{$ti-prefix}-edit-circle-off:before {
  content: $ti-icon-edit-circle-off;
}
.#{$ti-prefix}-edit-off:before {
  content: $ti-icon-edit-off;
}
.#{$ti-prefix}-egg:before {
  content: $ti-icon-egg;
}
.#{$ti-prefix}-egg-off:before {
  content: $ti-icon-egg-off;
}
.#{$ti-prefix}-elevator:before {
  content: $ti-icon-elevator;
}
.#{$ti-prefix}-emergency-bed:before {
  content: $ti-icon-emergency-bed;
}
.#{$ti-prefix}-emphasis:before {
  content: $ti-icon-emphasis;
}
.#{$ti-prefix}-engine:before {
  content: $ti-icon-engine;
}
.#{$ti-prefix}-engine-off:before {
  content: $ti-icon-engine-off;
}
.#{$ti-prefix}-equal:before {
  content: $ti-icon-equal;
}
.#{$ti-prefix}-equal-not:before {
  content: $ti-icon-equal-not;
}
.#{$ti-prefix}-eraser:before {
  content: $ti-icon-eraser;
}
.#{$ti-prefix}-eraser-off:before {
  content: $ti-icon-eraser-off;
}
.#{$ti-prefix}-error-404:before {
  content: $ti-icon-error-404;
}
.#{$ti-prefix}-error-404-off:before {
  content: $ti-icon-error-404-off;
}
.#{$ti-prefix}-exchange:before {
  content: $ti-icon-exchange;
}
.#{$ti-prefix}-exchange-off:before {
  content: $ti-icon-exchange-off;
}
.#{$ti-prefix}-exclamation-mark:before {
  content: $ti-icon-exclamation-mark;
}
.#{$ti-prefix}-exclamation-mark-off:before {
  content: $ti-icon-exclamation-mark-off;
}
.#{$ti-prefix}-exposure:before {
  content: $ti-icon-exposure;
}
.#{$ti-prefix}-external-link:before {
  content: $ti-icon-external-link;
}
.#{$ti-prefix}-external-link-off:before {
  content: $ti-icon-external-link-off;
}
.#{$ti-prefix}-eye:before {
  content: $ti-icon-eye;
}
.#{$ti-prefix}-eye-check:before {
  content: $ti-icon-eye-check;
}
.#{$ti-prefix}-eye-off:before {
  content: $ti-icon-eye-off;
}
.#{$ti-prefix}-eye-table:before {
  content: $ti-icon-eye-table;
}
.#{$ti-prefix}-eyeglass:before {
  content: $ti-icon-eyeglass;
}
.#{$ti-prefix}-eyeglass-2:before {
  content: $ti-icon-eyeglass-2;
}
.#{$ti-prefix}-eyeglass-off:before {
  content: $ti-icon-eyeglass-off;
}
.#{$ti-prefix}-face-id:before {
  content: $ti-icon-face-id;
}
.#{$ti-prefix}-face-id-error:before {
  content: $ti-icon-face-id-error;
}
.#{$ti-prefix}-face-mask:before {
  content: $ti-icon-face-mask;
}
.#{$ti-prefix}-face-mask-off:before {
  content: $ti-icon-face-mask-off;
}
.#{$ti-prefix}-fall:before {
  content: $ti-icon-fall;
}
.#{$ti-prefix}-feather:before {
  content: $ti-icon-feather;
}
.#{$ti-prefix}-feather-off:before {
  content: $ti-icon-feather-off;
}
.#{$ti-prefix}-fence:before {
  content: $ti-icon-fence;
}
.#{$ti-prefix}-fence-off:before {
  content: $ti-icon-fence-off;
}
.#{$ti-prefix}-fidget-spinner:before {
  content: $ti-icon-fidget-spinner;
}
.#{$ti-prefix}-file:before {
  content: $ti-icon-file;
}
.#{$ti-prefix}-file-3d:before {
  content: $ti-icon-file-3d;
}
.#{$ti-prefix}-file-alert:before {
  content: $ti-icon-file-alert;
}
.#{$ti-prefix}-file-analytics:before {
  content: $ti-icon-file-analytics;
}
.#{$ti-prefix}-file-arrow-left:before {
  content: $ti-icon-file-arrow-left;
}
.#{$ti-prefix}-file-arrow-right:before {
  content: $ti-icon-file-arrow-right;
}
.#{$ti-prefix}-file-barcode:before {
  content: $ti-icon-file-barcode;
}
.#{$ti-prefix}-file-certificate:before {
  content: $ti-icon-file-certificate;
}
.#{$ti-prefix}-file-chart:before {
  content: $ti-icon-file-chart;
}
.#{$ti-prefix}-file-check:before {
  content: $ti-icon-file-check;
}
.#{$ti-prefix}-file-code:before {
  content: $ti-icon-file-code;
}
.#{$ti-prefix}-file-code-2:before {
  content: $ti-icon-file-code-2;
}
.#{$ti-prefix}-file-database:before {
  content: $ti-icon-file-database;
}
.#{$ti-prefix}-file-description:before {
  content: $ti-icon-file-description;
}
.#{$ti-prefix}-file-diff:before {
  content: $ti-icon-file-diff;
}
.#{$ti-prefix}-file-digit:before {
  content: $ti-icon-file-digit;
}
.#{$ti-prefix}-file-dislike:before {
  content: $ti-icon-file-dislike;
}
.#{$ti-prefix}-file-dollar:before {
  content: $ti-icon-file-dollar;
}
.#{$ti-prefix}-file-dots:before {
  content: $ti-icon-file-dots;
}
.#{$ti-prefix}-file-download:before {
  content: $ti-icon-file-download;
}
.#{$ti-prefix}-file-euro:before {
  content: $ti-icon-file-euro;
}
.#{$ti-prefix}-file-export:before {
  content: $ti-icon-file-export;
}
.#{$ti-prefix}-file-horizontal:before {
  content: $ti-icon-file-horizontal;
}
.#{$ti-prefix}-file-import:before {
  content: $ti-icon-file-import;
}
.#{$ti-prefix}-file-info:before {
  content: $ti-icon-file-info;
}
.#{$ti-prefix}-file-invoice:before {
  content: $ti-icon-file-invoice;
}
.#{$ti-prefix}-file-like:before {
  content: $ti-icon-file-like;
}
.#{$ti-prefix}-file-minus:before {
  content: $ti-icon-file-minus;
}
.#{$ti-prefix}-file-music:before {
  content: $ti-icon-file-music;
}
.#{$ti-prefix}-file-off:before {
  content: $ti-icon-file-off;
}
.#{$ti-prefix}-file-pencil:before {
  content: $ti-icon-file-pencil;
}
.#{$ti-prefix}-file-phone:before {
  content: $ti-icon-file-phone;
}
.#{$ti-prefix}-file-plus:before {
  content: $ti-icon-file-plus;
}
.#{$ti-prefix}-file-power:before {
  content: $ti-icon-file-power;
}
.#{$ti-prefix}-file-report:before {
  content: $ti-icon-file-report;
}
.#{$ti-prefix}-file-rss:before {
  content: $ti-icon-file-rss;
}
.#{$ti-prefix}-file-scissors:before {
  content: $ti-icon-file-scissors;
}
.#{$ti-prefix}-file-search:before {
  content: $ti-icon-file-search;
}
.#{$ti-prefix}-file-settings:before {
  content: $ti-icon-file-settings;
}
.#{$ti-prefix}-file-shredder:before {
  content: $ti-icon-file-shredder;
}
.#{$ti-prefix}-file-signal:before {
  content: $ti-icon-file-signal;
}
.#{$ti-prefix}-file-spreadsheet:before {
  content: $ti-icon-file-spreadsheet;
}
.#{$ti-prefix}-file-star:before {
  content: $ti-icon-file-star;
}
.#{$ti-prefix}-file-symlink:before {
  content: $ti-icon-file-symlink;
}
.#{$ti-prefix}-file-text:before {
  content: $ti-icon-file-text;
}
.#{$ti-prefix}-file-time:before {
  content: $ti-icon-file-time;
}
.#{$ti-prefix}-file-typography:before {
  content: $ti-icon-file-typography;
}
.#{$ti-prefix}-file-unknown:before {
  content: $ti-icon-file-unknown;
}
.#{$ti-prefix}-file-upload:before {
  content: $ti-icon-file-upload;
}
.#{$ti-prefix}-file-vector:before {
  content: $ti-icon-file-vector;
}
.#{$ti-prefix}-file-x:before {
  content: $ti-icon-file-x;
}
.#{$ti-prefix}-file-zip:before {
  content: $ti-icon-file-zip;
}
.#{$ti-prefix}-files:before {
  content: $ti-icon-files;
}
.#{$ti-prefix}-files-off:before {
  content: $ti-icon-files-off;
}
.#{$ti-prefix}-filter:before {
  content: $ti-icon-filter;
}
.#{$ti-prefix}-filter-off:before {
  content: $ti-icon-filter-off;
}
.#{$ti-prefix}-fingerprint:before {
  content: $ti-icon-fingerprint;
}
.#{$ti-prefix}-fingerprint-off:before {
  content: $ti-icon-fingerprint-off;
}
.#{$ti-prefix}-firetruck:before {
  content: $ti-icon-firetruck;
}
.#{$ti-prefix}-first-aid-kit:before {
  content: $ti-icon-first-aid-kit;
}
.#{$ti-prefix}-fish:before {
  content: $ti-icon-fish;
}
.#{$ti-prefix}-fish-hook:before {
  content: $ti-icon-fish-hook;
}
.#{$ti-prefix}-fish-off:before {
  content: $ti-icon-fish-off;
}
.#{$ti-prefix}-flag:before {
  content: $ti-icon-flag;
}
.#{$ti-prefix}-flag-2:before {
  content: $ti-icon-flag-2;
}
.#{$ti-prefix}-flag-2-off:before {
  content: $ti-icon-flag-2-off;
}
.#{$ti-prefix}-flag-3:before {
  content: $ti-icon-flag-3;
}
.#{$ti-prefix}-flag-off:before {
  content: $ti-icon-flag-off;
}
.#{$ti-prefix}-flame:before {
  content: $ti-icon-flame;
}
.#{$ti-prefix}-flame-off:before {
  content: $ti-icon-flame-off;
}
.#{$ti-prefix}-flare:before {
  content: $ti-icon-flare;
}
.#{$ti-prefix}-flask:before {
  content: $ti-icon-flask;
}
.#{$ti-prefix}-flask-2:before {
  content: $ti-icon-flask-2;
}
.#{$ti-prefix}-flask-2-off:before {
  content: $ti-icon-flask-2-off;
}
.#{$ti-prefix}-flask-off:before {
  content: $ti-icon-flask-off;
}
.#{$ti-prefix}-flip-horizontal:before {
  content: $ti-icon-flip-horizontal;
}
.#{$ti-prefix}-flip-vertical:before {
  content: $ti-icon-flip-vertical;
}
.#{$ti-prefix}-float-center:before {
  content: $ti-icon-float-center;
}
.#{$ti-prefix}-float-left:before {
  content: $ti-icon-float-left;
}
.#{$ti-prefix}-float-none:before {
  content: $ti-icon-float-none;
}
.#{$ti-prefix}-float-right:before {
  content: $ti-icon-float-right;
}
.#{$ti-prefix}-flower:before {
  content: $ti-icon-flower;
}
.#{$ti-prefix}-flower-off:before {
  content: $ti-icon-flower-off;
}
.#{$ti-prefix}-focus:before {
  content: $ti-icon-focus;
}
.#{$ti-prefix}-focus-2:before {
  content: $ti-icon-focus-2;
}
.#{$ti-prefix}-focus-centered:before {
  content: $ti-icon-focus-centered;
}
.#{$ti-prefix}-fold:before {
  content: $ti-icon-fold;
}
.#{$ti-prefix}-fold-down:before {
  content: $ti-icon-fold-down;
}
.#{$ti-prefix}-fold-up:before {
  content: $ti-icon-fold-up;
}
.#{$ti-prefix}-folder:before {
  content: $ti-icon-folder;
}
.#{$ti-prefix}-folder-minus:before {
  content: $ti-icon-folder-minus;
}
.#{$ti-prefix}-folder-off:before {
  content: $ti-icon-folder-off;
}
.#{$ti-prefix}-folder-plus:before {
  content: $ti-icon-folder-plus;
}
.#{$ti-prefix}-folder-x:before {
  content: $ti-icon-folder-x;
}
.#{$ti-prefix}-folders:before {
  content: $ti-icon-folders;
}
.#{$ti-prefix}-folders-off:before {
  content: $ti-icon-folders-off;
}
.#{$ti-prefix}-forbid:before {
  content: $ti-icon-forbid;
}
.#{$ti-prefix}-forbid-2:before {
  content: $ti-icon-forbid-2;
}
.#{$ti-prefix}-forklift:before {
  content: $ti-icon-forklift;
}
.#{$ti-prefix}-forms:before {
  content: $ti-icon-forms;
}
.#{$ti-prefix}-fountain:before {
  content: $ti-icon-fountain;
}
.#{$ti-prefix}-fountain-off:before {
  content: $ti-icon-fountain-off;
}
.#{$ti-prefix}-frame:before {
  content: $ti-icon-frame;
}
.#{$ti-prefix}-frame-off:before {
  content: $ti-icon-frame-off;
}
.#{$ti-prefix}-free-rights:before {
  content: $ti-icon-free-rights;
}
.#{$ti-prefix}-fridge:before {
  content: $ti-icon-fridge;
}
.#{$ti-prefix}-friends:before {
  content: $ti-icon-friends;
}
.#{$ti-prefix}-friends-off:before {
  content: $ti-icon-friends-off;
}
.#{$ti-prefix}-function:before {
  content: $ti-icon-function;
}
.#{$ti-prefix}-gas-station:before {
  content: $ti-icon-gas-station;
}
.#{$ti-prefix}-gas-station-off:before {
  content: $ti-icon-gas-station-off;
}
.#{$ti-prefix}-gauge:before {
  content: $ti-icon-gauge;
}
.#{$ti-prefix}-gauge-off:before {
  content: $ti-icon-gauge-off;
}
.#{$ti-prefix}-gavel:before {
  content: $ti-icon-gavel;
}
.#{$ti-prefix}-gender-agender:before {
  content: $ti-icon-gender-agender;
}
.#{$ti-prefix}-gender-androgyne:before {
  content: $ti-icon-gender-androgyne;
}
.#{$ti-prefix}-gender-bigender:before {
  content: $ti-icon-gender-bigender;
}
.#{$ti-prefix}-gender-demiboy:before {
  content: $ti-icon-gender-demiboy;
}
.#{$ti-prefix}-gender-demigirl:before {
  content: $ti-icon-gender-demigirl;
}
.#{$ti-prefix}-gender-epicene:before {
  content: $ti-icon-gender-epicene;
}
.#{$ti-prefix}-gender-female:before {
  content: $ti-icon-gender-female;
}
.#{$ti-prefix}-gender-femme:before {
  content: $ti-icon-gender-femme;
}
.#{$ti-prefix}-gender-genderfluid:before {
  content: $ti-icon-gender-genderfluid;
}
.#{$ti-prefix}-gender-genderless:before {
  content: $ti-icon-gender-genderless;
}
.#{$ti-prefix}-gender-genderqueer:before {
  content: $ti-icon-gender-genderqueer;
}
.#{$ti-prefix}-gender-hermaphrodite:before {
  content: $ti-icon-gender-hermaphrodite;
}
.#{$ti-prefix}-gender-intergender:before {
  content: $ti-icon-gender-intergender;
}
.#{$ti-prefix}-gender-male:before {
  content: $ti-icon-gender-male;
}
.#{$ti-prefix}-gender-neutrois:before {
  content: $ti-icon-gender-neutrois;
}
.#{$ti-prefix}-gender-third:before {
  content: $ti-icon-gender-third;
}
.#{$ti-prefix}-gender-transgender:before {
  content: $ti-icon-gender-transgender;
}
.#{$ti-prefix}-gender-trasvesti:before {
  content: $ti-icon-gender-trasvesti;
}
.#{$ti-prefix}-geometry:before {
  content: $ti-icon-geometry;
}
.#{$ti-prefix}-ghost:before {
  content: $ti-icon-ghost;
}
.#{$ti-prefix}-gift:before {
  content: $ti-icon-gift;
}
.#{$ti-prefix}-git-branch:before {
  content: $ti-icon-git-branch;
}
.#{$ti-prefix}-git-commit:before {
  content: $ti-icon-git-commit;
}
.#{$ti-prefix}-git-compare:before {
  content: $ti-icon-git-compare;
}
.#{$ti-prefix}-git-fork:before {
  content: $ti-icon-git-fork;
}
.#{$ti-prefix}-git-merge:before {
  content: $ti-icon-git-merge;
}
.#{$ti-prefix}-git-pull-request:before {
  content: $ti-icon-git-pull-request;
}
.#{$ti-prefix}-git-pull-request-closed:before {
  content: $ti-icon-git-pull-request-closed;
}
.#{$ti-prefix}-git-pull-request-draft:before {
  content: $ti-icon-git-pull-request-draft;
}
.#{$ti-prefix}-gizmo:before {
  content: $ti-icon-gizmo;
}
.#{$ti-prefix}-glass:before {
  content: $ti-icon-glass;
}
.#{$ti-prefix}-glass-full:before {
  content: $ti-icon-glass-full;
}
.#{$ti-prefix}-glass-off:before {
  content: $ti-icon-glass-off;
}
.#{$ti-prefix}-globe:before {
  content: $ti-icon-globe;
}
.#{$ti-prefix}-globe-off:before {
  content: $ti-icon-globe-off;
}
.#{$ti-prefix}-golf:before {
  content: $ti-icon-golf;
}
.#{$ti-prefix}-golf-off:before {
  content: $ti-icon-golf-off;
}
.#{$ti-prefix}-gps:before {
  content: $ti-icon-gps;
}
.#{$ti-prefix}-grain:before {
  content: $ti-icon-grain;
}
.#{$ti-prefix}-grid-dots:before {
  content: $ti-icon-grid-dots;
}
.#{$ti-prefix}-grid-pattern:before {
  content: $ti-icon-grid-pattern;
}
.#{$ti-prefix}-grill:before {
  content: $ti-icon-grill;
}
.#{$ti-prefix}-grill-off:before {
  content: $ti-icon-grill-off;
}
.#{$ti-prefix}-grip-horizontal:before {
  content: $ti-icon-grip-horizontal;
}
.#{$ti-prefix}-grip-vertical:before {
  content: $ti-icon-grip-vertical;
}
.#{$ti-prefix}-growth:before {
  content: $ti-icon-growth;
}
.#{$ti-prefix}-h-1:before {
  content: $ti-icon-h-1;
}
.#{$ti-prefix}-h-2:before {
  content: $ti-icon-h-2;
}
.#{$ti-prefix}-h-3:before {
  content: $ti-icon-h-3;
}
.#{$ti-prefix}-h-4:before {
  content: $ti-icon-h-4;
}
.#{$ti-prefix}-h-5:before {
  content: $ti-icon-h-5;
}
.#{$ti-prefix}-h-6:before {
  content: $ti-icon-h-6;
}
.#{$ti-prefix}-hammer:before {
  content: $ti-icon-hammer;
}
.#{$ti-prefix}-hammer-off:before {
  content: $ti-icon-hammer-off;
}
.#{$ti-prefix}-hand-click:before {
  content: $ti-icon-hand-click;
}
.#{$ti-prefix}-hand-finger:before {
  content: $ti-icon-hand-finger;
}
.#{$ti-prefix}-hand-finger-off:before {
  content: $ti-icon-hand-finger-off;
}
.#{$ti-prefix}-hand-grab:before {
  content: $ti-icon-hand-grab;
}
.#{$ti-prefix}-hand-little-finger:before {
  content: $ti-icon-hand-little-finger;
}
.#{$ti-prefix}-hand-middle-finger:before {
  content: $ti-icon-hand-middle-finger;
}
.#{$ti-prefix}-hand-move:before {
  content: $ti-icon-hand-move;
}
.#{$ti-prefix}-hand-off:before {
  content: $ti-icon-hand-off;
}
.#{$ti-prefix}-hand-ring-finger:before {
  content: $ti-icon-hand-ring-finger;
}
.#{$ti-prefix}-hand-rock:before {
  content: $ti-icon-hand-rock;
}
.#{$ti-prefix}-hand-stop:before {
  content: $ti-icon-hand-stop;
}
.#{$ti-prefix}-hand-three-fingers:before {
  content: $ti-icon-hand-three-fingers;
}
.#{$ti-prefix}-hand-two-fingers:before {
  content: $ti-icon-hand-two-fingers;
}
.#{$ti-prefix}-hanger:before {
  content: $ti-icon-hanger;
}
.#{$ti-prefix}-hanger-2:before {
  content: $ti-icon-hanger-2;
}
.#{$ti-prefix}-hanger-off:before {
  content: $ti-icon-hanger-off;
}
.#{$ti-prefix}-hash:before {
  content: $ti-icon-hash;
}
.#{$ti-prefix}-haze:before {
  content: $ti-icon-haze;
}
.#{$ti-prefix}-heading:before {
  content: $ti-icon-heading;
}
.#{$ti-prefix}-heading-off:before {
  content: $ti-icon-heading-off;
}
.#{$ti-prefix}-headphones:before {
  content: $ti-icon-headphones;
}
.#{$ti-prefix}-headphones-off:before {
  content: $ti-icon-headphones-off;
}
.#{$ti-prefix}-headset:before {
  content: $ti-icon-headset;
}
.#{$ti-prefix}-health-recognition:before {
  content: $ti-icon-health-recognition;
}
.#{$ti-prefix}-heart:before {
  content: $ti-icon-heart;
}
.#{$ti-prefix}-heart-broken:before {
  content: $ti-icon-heart-broken;
}
.#{$ti-prefix}-heart-handshake:before {
  content: $ti-icon-heart-handshake;
}
.#{$ti-prefix}-heart-minus:before {
  content: $ti-icon-heart-minus;
}
.#{$ti-prefix}-heart-off:before {
  content: $ti-icon-heart-off;
}
.#{$ti-prefix}-heart-plus:before {
  content: $ti-icon-heart-plus;
}
.#{$ti-prefix}-heart-rate-monitor:before {
  content: $ti-icon-heart-rate-monitor;
}
.#{$ti-prefix}-heartbeat:before {
  content: $ti-icon-heartbeat;
}
.#{$ti-prefix}-helicopter:before {
  content: $ti-icon-helicopter;
}
.#{$ti-prefix}-helicopter-landing:before {
  content: $ti-icon-helicopter-landing;
}
.#{$ti-prefix}-helmet:before {
  content: $ti-icon-helmet;
}
.#{$ti-prefix}-helmet-off:before {
  content: $ti-icon-helmet-off;
}
.#{$ti-prefix}-help:before {
  content: $ti-icon-help;
}
.#{$ti-prefix}-hexagon:before {
  content: $ti-icon-hexagon;
}
.#{$ti-prefix}-hexagon-off:before {
  content: $ti-icon-hexagon-off;
}
.#{$ti-prefix}-hexagons:before {
  content: $ti-icon-hexagons;
}
.#{$ti-prefix}-hierarchy:before {
  content: $ti-icon-hierarchy;
}
.#{$ti-prefix}-hierarchy-2:before {
  content: $ti-icon-hierarchy-2;
}
.#{$ti-prefix}-highlight:before {
  content: $ti-icon-highlight;
}
.#{$ti-prefix}-highlight-off:before {
  content: $ti-icon-highlight-off;
}
.#{$ti-prefix}-history:before {
  content: $ti-icon-history;
}
.#{$ti-prefix}-history-toggle:before {
  content: $ti-icon-history-toggle;
}
.#{$ti-prefix}-home:before {
  content: $ti-icon-home;
}
.#{$ti-prefix}-home-2:before {
  content: $ti-icon-home-2;
}
.#{$ti-prefix}-home-off:before {
  content: $ti-icon-home-off;
}
.#{$ti-prefix}-hotel-service:before {
  content: $ti-icon-hotel-service;
}
.#{$ti-prefix}-hourglass:before {
  content: $ti-icon-hourglass;
}
.#{$ti-prefix}-hourglass-empty:before {
  content: $ti-icon-hourglass-empty;
}
.#{$ti-prefix}-hourglass-high:before {
  content: $ti-icon-hourglass-high;
}
.#{$ti-prefix}-hourglass-low:before {
  content: $ti-icon-hourglass-low;
}
.#{$ti-prefix}-hourglass-off:before {
  content: $ti-icon-hourglass-off;
}
.#{$ti-prefix}-ice-cream:before {
  content: $ti-icon-ice-cream;
}
.#{$ti-prefix}-ice-cream-2:before {
  content: $ti-icon-ice-cream-2;
}
.#{$ti-prefix}-ice-cream-off:before {
  content: $ti-icon-ice-cream-off;
}
.#{$ti-prefix}-ice-skating:before {
  content: $ti-icon-ice-skating;
}
.#{$ti-prefix}-icons:before {
  content: $ti-icon-icons;
}
.#{$ti-prefix}-id:before {
  content: $ti-icon-id;
}
.#{$ti-prefix}-id-badge:before {
  content: $ti-icon-id-badge;
}
.#{$ti-prefix}-id-badge-2:before {
  content: $ti-icon-id-badge-2;
}
.#{$ti-prefix}-id-off:before {
  content: $ti-icon-id-off;
}
.#{$ti-prefix}-inbox:before {
  content: $ti-icon-inbox;
}
.#{$ti-prefix}-inbox-off:before {
  content: $ti-icon-inbox-off;
}
.#{$ti-prefix}-indent-decrease:before {
  content: $ti-icon-indent-decrease;
}
.#{$ti-prefix}-indent-increase:before {
  content: $ti-icon-indent-increase;
}
.#{$ti-prefix}-infinity:before {
  content: $ti-icon-infinity;
}
.#{$ti-prefix}-info-circle:before {
  content: $ti-icon-info-circle;
}
.#{$ti-prefix}-info-square:before {
  content: $ti-icon-info-square;
}
.#{$ti-prefix}-italic:before {
  content: $ti-icon-italic;
}
.#{$ti-prefix}-jewish-star:before {
  content: $ti-icon-jewish-star;
}
.#{$ti-prefix}-jump-rope:before {
  content: $ti-icon-jump-rope;
}
.#{$ti-prefix}-karate:before {
  content: $ti-icon-karate;
}
.#{$ti-prefix}-kayak:before {
  content: $ti-icon-kayak;
}
.#{$ti-prefix}-kering:before {
  content: $ti-icon-kering;
}
.#{$ti-prefix}-key:before {
  content: $ti-icon-key;
}
.#{$ti-prefix}-key-off:before {
  content: $ti-icon-key-off;
}
.#{$ti-prefix}-keyboard:before {
  content: $ti-icon-keyboard;
}
.#{$ti-prefix}-keyboard-hide:before {
  content: $ti-icon-keyboard-hide;
}
.#{$ti-prefix}-keyboard-off:before {
  content: $ti-icon-keyboard-off;
}
.#{$ti-prefix}-keyboard-show:before {
  content: $ti-icon-keyboard-show;
}
.#{$ti-prefix}-ladder:before {
  content: $ti-icon-ladder;
}
.#{$ti-prefix}-ladder-off:before {
  content: $ti-icon-ladder-off;
}
.#{$ti-prefix}-lamp:before {
  content: $ti-icon-lamp;
}
.#{$ti-prefix}-lamp-2:before {
  content: $ti-icon-lamp-2;
}
.#{$ti-prefix}-lamp-off:before {
  content: $ti-icon-lamp-off;
}
.#{$ti-prefix}-language:before {
  content: $ti-icon-language;
}
.#{$ti-prefix}-language-hiragana:before {
  content: $ti-icon-language-hiragana;
}
.#{$ti-prefix}-language-katakana:before {
  content: $ti-icon-language-katakana;
}
.#{$ti-prefix}-language-off:before {
  content: $ti-icon-language-off;
}
.#{$ti-prefix}-lasso:before {
  content: $ti-icon-lasso;
}
.#{$ti-prefix}-lasso-off:before {
  content: $ti-icon-lasso-off;
}
.#{$ti-prefix}-layers-difference:before {
  content: $ti-icon-layers-difference;
}
.#{$ti-prefix}-layers-intersect:before {
  content: $ti-icon-layers-intersect;
}
.#{$ti-prefix}-layers-intersect-2:before {
  content: $ti-icon-layers-intersect-2;
}
.#{$ti-prefix}-layers-linked:before {
  content: $ti-icon-layers-linked;
}
.#{$ti-prefix}-layers-off:before {
  content: $ti-icon-layers-off;
}
.#{$ti-prefix}-layers-subtract:before {
  content: $ti-icon-layers-subtract;
}
.#{$ti-prefix}-layers-union:before {
  content: $ti-icon-layers-union;
}
.#{$ti-prefix}-layout:before {
  content: $ti-icon-layout;
}
.#{$ti-prefix}-layout-2:before {
  content: $ti-icon-layout-2;
}
.#{$ti-prefix}-layout-align-bottom:before {
  content: $ti-icon-layout-align-bottom;
}
.#{$ti-prefix}-layout-align-center:before {
  content: $ti-icon-layout-align-center;
}
.#{$ti-prefix}-layout-align-left:before {
  content: $ti-icon-layout-align-left;
}
.#{$ti-prefix}-layout-align-middle:before {
  content: $ti-icon-layout-align-middle;
}
.#{$ti-prefix}-layout-align-right:before {
  content: $ti-icon-layout-align-right;
}
.#{$ti-prefix}-layout-align-top:before {
  content: $ti-icon-layout-align-top;
}
.#{$ti-prefix}-layout-board:before {
  content: $ti-icon-layout-board;
}
.#{$ti-prefix}-layout-board-split:before {
  content: $ti-icon-layout-board-split;
}
.#{$ti-prefix}-layout-bottombar:before {
  content: $ti-icon-layout-bottombar;
}
.#{$ti-prefix}-layout-cards:before {
  content: $ti-icon-layout-cards;
}
.#{$ti-prefix}-layout-columns:before {
  content: $ti-icon-layout-columns;
}
.#{$ti-prefix}-layout-dashboard:before {
  content: $ti-icon-layout-dashboard;
}
.#{$ti-prefix}-layout-distribute-horizontal:before {
  content: $ti-icon-layout-distribute-horizontal;
}
.#{$ti-prefix}-layout-distribute-vertical:before {
  content: $ti-icon-layout-distribute-vertical;
}
.#{$ti-prefix}-layout-grid:before {
  content: $ti-icon-layout-grid;
}
.#{$ti-prefix}-layout-grid-add:before {
  content: $ti-icon-layout-grid-add;
}
.#{$ti-prefix}-layout-kanban:before {
  content: $ti-icon-layout-kanban;
}
.#{$ti-prefix}-layout-list:before {
  content: $ti-icon-layout-list;
}
.#{$ti-prefix}-layout-navbar:before {
  content: $ti-icon-layout-navbar;
}
.#{$ti-prefix}-layout-off:before {
  content: $ti-icon-layout-off;
}
.#{$ti-prefix}-layout-rows:before {
  content: $ti-icon-layout-rows;
}
.#{$ti-prefix}-layout-sidebar:before {
  content: $ti-icon-layout-sidebar;
}
.#{$ti-prefix}-layout-sidebar-left-collapse:before {
  content: $ti-icon-layout-sidebar-left-collapse;
}
.#{$ti-prefix}-layout-sidebar-left-expand:before {
  content: $ti-icon-layout-sidebar-left-expand;
}
.#{$ti-prefix}-layout-sidebar-right:before {
  content: $ti-icon-layout-sidebar-right;
}
.#{$ti-prefix}-layout-sidebar-right-collapse:before {
  content: $ti-icon-layout-sidebar-right-collapse;
}
.#{$ti-prefix}-layout-sidebar-right-expand:before {
  content: $ti-icon-layout-sidebar-right-expand;
}
.#{$ti-prefix}-leaf:before {
  content: $ti-icon-leaf;
}
.#{$ti-prefix}-leaf-off:before {
  content: $ti-icon-leaf-off;
}
.#{$ti-prefix}-lego:before {
  content: $ti-icon-lego;
}
.#{$ti-prefix}-lemon:before {
  content: $ti-icon-lemon;
}
.#{$ti-prefix}-lemon-2:before {
  content: $ti-icon-lemon-2;
}
.#{$ti-prefix}-letter-a:before {
  content: $ti-icon-letter-a;
}
.#{$ti-prefix}-letter-b:before {
  content: $ti-icon-letter-b;
}
.#{$ti-prefix}-letter-c:before {
  content: $ti-icon-letter-c;
}
.#{$ti-prefix}-letter-case:before {
  content: $ti-icon-letter-case;
}
.#{$ti-prefix}-letter-case-lower:before {
  content: $ti-icon-letter-case-lower;
}
.#{$ti-prefix}-letter-case-toggle:before {
  content: $ti-icon-letter-case-toggle;
}
.#{$ti-prefix}-letter-case-upper:before {
  content: $ti-icon-letter-case-upper;
}
.#{$ti-prefix}-letter-d:before {
  content: $ti-icon-letter-d;
}
.#{$ti-prefix}-letter-e:before {
  content: $ti-icon-letter-e;
}
.#{$ti-prefix}-letter-f:before {
  content: $ti-icon-letter-f;
}
.#{$ti-prefix}-letter-g:before {
  content: $ti-icon-letter-g;
}
.#{$ti-prefix}-letter-h:before {
  content: $ti-icon-letter-h;
}
.#{$ti-prefix}-letter-i:before {
  content: $ti-icon-letter-i;
}
.#{$ti-prefix}-letter-j:before {
  content: $ti-icon-letter-j;
}
.#{$ti-prefix}-letter-k:before {
  content: $ti-icon-letter-k;
}
.#{$ti-prefix}-letter-l:before {
  content: $ti-icon-letter-l;
}
.#{$ti-prefix}-letter-m:before {
  content: $ti-icon-letter-m;
}
.#{$ti-prefix}-letter-n:before {
  content: $ti-icon-letter-n;
}
.#{$ti-prefix}-letter-o:before {
  content: $ti-icon-letter-o;
}
.#{$ti-prefix}-letter-p:before {
  content: $ti-icon-letter-p;
}
.#{$ti-prefix}-letter-q:before {
  content: $ti-icon-letter-q;
}
.#{$ti-prefix}-letter-r:before {
  content: $ti-icon-letter-r;
}
.#{$ti-prefix}-letter-s:before {
  content: $ti-icon-letter-s;
}
.#{$ti-prefix}-letter-spacing:before {
  content: $ti-icon-letter-spacing;
}
.#{$ti-prefix}-letter-t:before {
  content: $ti-icon-letter-t;
}
.#{$ti-prefix}-letter-u:before {
  content: $ti-icon-letter-u;
}
.#{$ti-prefix}-letter-v:before {
  content: $ti-icon-letter-v;
}
.#{$ti-prefix}-letter-w:before {
  content: $ti-icon-letter-w;
}
.#{$ti-prefix}-letter-x:before {
  content: $ti-icon-letter-x;
}
.#{$ti-prefix}-letter-y:before {
  content: $ti-icon-letter-y;
}
.#{$ti-prefix}-letter-z:before {
  content: $ti-icon-letter-z;
}
.#{$ti-prefix}-license:before {
  content: $ti-icon-license;
}
.#{$ti-prefix}-license-off:before {
  content: $ti-icon-license-off;
}
.#{$ti-prefix}-lifebuoy:before {
  content: $ti-icon-lifebuoy;
}
.#{$ti-prefix}-lifebuoy-off:before {
  content: $ti-icon-lifebuoy-off;
}
.#{$ti-prefix}-line:before {
  content: $ti-icon-line;
}
.#{$ti-prefix}-line-dashed:before {
  content: $ti-icon-line-dashed;
}
.#{$ti-prefix}-line-dotted:before {
  content: $ti-icon-line-dotted;
}
.#{$ti-prefix}-line-height:before {
  content: $ti-icon-line-height;
}
.#{$ti-prefix}-link:before {
  content: $ti-icon-link;
}
.#{$ti-prefix}-list:before {
  content: $ti-icon-list;
}
.#{$ti-prefix}-list-check:before {
  content: $ti-icon-list-check;
}
.#{$ti-prefix}-list-details:before {
  content: $ti-icon-list-details;
}
.#{$ti-prefix}-list-numbers:before {
  content: $ti-icon-list-numbers;
}
.#{$ti-prefix}-list-search:before {
  content: $ti-icon-list-search;
}
.#{$ti-prefix}-live-photo:before {
  content: $ti-icon-live-photo;
}
.#{$ti-prefix}-live-view:before {
  content: $ti-icon-live-view;
}
.#{$ti-prefix}-loader:before {
  content: $ti-icon-loader;
}
.#{$ti-prefix}-loader-2:before {
  content: $ti-icon-loader-2;
}
.#{$ti-prefix}-loader-quarter:before {
  content: $ti-icon-loader-quarter;
}
.#{$ti-prefix}-location:before {
  content: $ti-icon-location;
}
.#{$ti-prefix}-location-off:before {
  content: $ti-icon-location-off;
}
.#{$ti-prefix}-lock:before {
  content: $ti-icon-lock;
}
.#{$ti-prefix}-lock-access:before {
  content: $ti-icon-lock-access;
}
.#{$ti-prefix}-lock-off:before {
  content: $ti-icon-lock-off;
}
.#{$ti-prefix}-lock-open:before {
  content: $ti-icon-lock-open;
}
.#{$ti-prefix}-lock-open-off:before {
  content: $ti-icon-lock-open-off;
}
.#{$ti-prefix}-lock-square:before {
  content: $ti-icon-lock-square;
}
.#{$ti-prefix}-login:before {
  content: $ti-icon-login;
}
.#{$ti-prefix}-logout:before {
  content: $ti-icon-logout;
}
.#{$ti-prefix}-lollipop:before {
  content: $ti-icon-lollipop;
}
.#{$ti-prefix}-lollipop-off:before {
  content: $ti-icon-lollipop-off;
}
.#{$ti-prefix}-luggage:before {
  content: $ti-icon-luggage;
}
.#{$ti-prefix}-luggage-off:before {
  content: $ti-icon-luggage-off;
}
.#{$ti-prefix}-lungs:before {
  content: $ti-icon-lungs;
}
.#{$ti-prefix}-macro:before {
  content: $ti-icon-macro;
}
.#{$ti-prefix}-magnet:before {
  content: $ti-icon-magnet;
}
.#{$ti-prefix}-magnet-off:before {
  content: $ti-icon-magnet-off;
}
.#{$ti-prefix}-mail:before {
  content: $ti-icon-mail;
}
.#{$ti-prefix}-mail-fast:before {
  content: $ti-icon-mail-fast;
}
.#{$ti-prefix}-mail-forward:before {
  content: $ti-icon-mail-forward;
}
.#{$ti-prefix}-mail-off:before {
  content: $ti-icon-mail-off;
}
.#{$ti-prefix}-mail-opened:before {
  content: $ti-icon-mail-opened;
}
.#{$ti-prefix}-mailbox:before {
  content: $ti-icon-mailbox;
}
.#{$ti-prefix}-mailbox-off:before {
  content: $ti-icon-mailbox-off;
}
.#{$ti-prefix}-man:before {
  content: $ti-icon-man;
}
.#{$ti-prefix}-manual-gearbox:before {
  content: $ti-icon-manual-gearbox;
}
.#{$ti-prefix}-map:before {
  content: $ti-icon-map;
}
.#{$ti-prefix}-map-2:before {
  content: $ti-icon-map-2;
}
.#{$ti-prefix}-map-off:before {
  content: $ti-icon-map-off;
}
.#{$ti-prefix}-map-pin:before {
  content: $ti-icon-map-pin;
}
.#{$ti-prefix}-map-pin-off:before {
  content: $ti-icon-map-pin-off;
}
.#{$ti-prefix}-map-pins:before {
  content: $ti-icon-map-pins;
}
.#{$ti-prefix}-map-search:before {
  content: $ti-icon-map-search;
}
.#{$ti-prefix}-markdown:before {
  content: $ti-icon-markdown;
}
.#{$ti-prefix}-marquee:before {
  content: $ti-icon-marquee;
}
.#{$ti-prefix}-marquee-2:before {
  content: $ti-icon-marquee-2;
}
.#{$ti-prefix}-marquee-off:before {
  content: $ti-icon-marquee-off;
}
.#{$ti-prefix}-mars:before {
  content: $ti-icon-mars;
}
.#{$ti-prefix}-mask:before {
  content: $ti-icon-mask;
}
.#{$ti-prefix}-mask-off:before {
  content: $ti-icon-mask-off;
}
.#{$ti-prefix}-massage:before {
  content: $ti-icon-massage;
}
.#{$ti-prefix}-math:before {
  content: $ti-icon-math;
}
.#{$ti-prefix}-math-avg:before {
  content: $ti-icon-math-avg;
}
.#{$ti-prefix}-math-function:before {
  content: $ti-icon-math-function;
}
.#{$ti-prefix}-math-function-off:before {
  content: $ti-icon-math-function-off;
}
.#{$ti-prefix}-math-max:before {
  content: $ti-icon-math-max;
}
.#{$ti-prefix}-math-min:before {
  content: $ti-icon-math-min;
}
.#{$ti-prefix}-math-symbols:before {
  content: $ti-icon-math-symbols;
}
.#{$ti-prefix}-maximize:before {
  content: $ti-icon-maximize;
}
.#{$ti-prefix}-maximize-off:before {
  content: $ti-icon-maximize-off;
}
.#{$ti-prefix}-meat:before {
  content: $ti-icon-meat;
}
.#{$ti-prefix}-medal:before {
  content: $ti-icon-medal;
}
.#{$ti-prefix}-medal-2:before {
  content: $ti-icon-medal-2;
}
.#{$ti-prefix}-medical-cross:before {
  content: $ti-icon-medical-cross;
}
.#{$ti-prefix}-medical-cross-off:before {
  content: $ti-icon-medical-cross-off;
}
.#{$ti-prefix}-medicine-syrup:before {
  content: $ti-icon-medicine-syrup;
}
.#{$ti-prefix}-menu:before {
  content: $ti-icon-menu;
}
.#{$ti-prefix}-menu-2:before {
  content: $ti-icon-menu-2;
}
.#{$ti-prefix}-message:before {
  content: $ti-icon-message;
}
.#{$ti-prefix}-message-2:before {
  content: $ti-icon-message-2;
}
.#{$ti-prefix}-message-2-code:before {
  content: $ti-icon-message-2-code;
}
.#{$ti-prefix}-message-2-share:before {
  content: $ti-icon-message-2-share;
}
.#{$ti-prefix}-message-circle:before {
  content: $ti-icon-message-circle;
}
.#{$ti-prefix}-message-circle-2:before {
  content: $ti-icon-message-circle-2;
}
.#{$ti-prefix}-message-circle-off:before {
  content: $ti-icon-message-circle-off;
}
.#{$ti-prefix}-message-code:before {
  content: $ti-icon-message-code;
}
.#{$ti-prefix}-message-dots:before {
  content: $ti-icon-message-dots;
}
.#{$ti-prefix}-message-language:before {
  content: $ti-icon-message-language;
}
.#{$ti-prefix}-message-off:before {
  content: $ti-icon-message-off;
}
.#{$ti-prefix}-message-plus:before {
  content: $ti-icon-message-plus;
}
.#{$ti-prefix}-message-report:before {
  content: $ti-icon-message-report;
}
.#{$ti-prefix}-message-share:before {
  content: $ti-icon-message-share;
}
.#{$ti-prefix}-messages:before {
  content: $ti-icon-messages;
}
.#{$ti-prefix}-messages-off:before {
  content: $ti-icon-messages-off;
}
.#{$ti-prefix}-meteor:before {
  content: $ti-icon-meteor;
}
.#{$ti-prefix}-microphone:before {
  content: $ti-icon-microphone;
}
.#{$ti-prefix}-microphone-2:before {
  content: $ti-icon-microphone-2;
}
.#{$ti-prefix}-microphone-off:before {
  content: $ti-icon-microphone-off;
}
.#{$ti-prefix}-microscope:before {
  content: $ti-icon-microscope;
}
.#{$ti-prefix}-military-award:before {
  content: $ti-icon-military-award;
}
.#{$ti-prefix}-military-rank:before {
  content: $ti-icon-military-rank;
}
.#{$ti-prefix}-milk:before {
  content: $ti-icon-milk;
}
.#{$ti-prefix}-minimize:before {
  content: $ti-icon-minimize;
}
.#{$ti-prefix}-minus:before {
  content: $ti-icon-minus;
}
.#{$ti-prefix}-minus-vertical:before {
  content: $ti-icon-minus-vertical;
}
.#{$ti-prefix}-mist:before {
  content: $ti-icon-mist;
}
.#{$ti-prefix}-mood-boy:before {
  content: $ti-icon-mood-boy;
}
.#{$ti-prefix}-mood-confuzed:before {
  content: $ti-icon-mood-confuzed;
}
.#{$ti-prefix}-mood-crazy-happy:before {
  content: $ti-icon-mood-crazy-happy;
}
.#{$ti-prefix}-mood-cry:before {
  content: $ti-icon-mood-cry;
}
.#{$ti-prefix}-mood-empty:before {
  content: $ti-icon-mood-empty;
}
.#{$ti-prefix}-mood-happy:before {
  content: $ti-icon-mood-happy;
}
.#{$ti-prefix}-mood-kid:before {
  content: $ti-icon-mood-kid;
}
.#{$ti-prefix}-mood-nervous:before {
  content: $ti-icon-mood-nervous;
}
.#{$ti-prefix}-mood-neutral:before {
  content: $ti-icon-mood-neutral;
}
.#{$ti-prefix}-mood-off:before {
  content: $ti-icon-mood-off;
}
.#{$ti-prefix}-mood-sad:before {
  content: $ti-icon-mood-sad;
}
.#{$ti-prefix}-mood-smile:before {
  content: $ti-icon-mood-smile;
}
.#{$ti-prefix}-mood-suprised:before {
  content: $ti-icon-mood-suprised;
}
.#{$ti-prefix}-mood-tongue:before {
  content: $ti-icon-mood-tongue;
}
.#{$ti-prefix}-moon:before {
  content: $ti-icon-moon;
}
.#{$ti-prefix}-moon-2:before {
  content: $ti-icon-moon-2;
}
.#{$ti-prefix}-moon-off:before {
  content: $ti-icon-moon-off;
}
.#{$ti-prefix}-moon-stars:before {
  content: $ti-icon-moon-stars;
}
.#{$ti-prefix}-moped:before {
  content: $ti-icon-moped;
}
.#{$ti-prefix}-motorbike:before {
  content: $ti-icon-motorbike;
}
.#{$ti-prefix}-mountain:before {
  content: $ti-icon-mountain;
}
.#{$ti-prefix}-mouse:before {
  content: $ti-icon-mouse;
}
.#{$ti-prefix}-mouse-2:before {
  content: $ti-icon-mouse-2;
}
.#{$ti-prefix}-mouse-off:before {
  content: $ti-icon-mouse-off;
}
.#{$ti-prefix}-movie:before {
  content: $ti-icon-movie;
}
.#{$ti-prefix}-movie-off:before {
  content: $ti-icon-movie-off;
}
.#{$ti-prefix}-mug:before {
  content: $ti-icon-mug;
}
.#{$ti-prefix}-mug-off:before {
  content: $ti-icon-mug-off;
}
.#{$ti-prefix}-multiplier-0-5x:before {
  content: $ti-icon-multiplier-0-5x;
}
.#{$ti-prefix}-multiplier-1-5x:before {
  content: $ti-icon-multiplier-1-5x;
}
.#{$ti-prefix}-multiplier-1x:before {
  content: $ti-icon-multiplier-1x;
}
.#{$ti-prefix}-multiplier-2x:before {
  content: $ti-icon-multiplier-2x;
}
.#{$ti-prefix}-mushroom:before {
  content: $ti-icon-mushroom;
}
.#{$ti-prefix}-music:before {
  content: $ti-icon-music;
}
.#{$ti-prefix}-music-off:before {
  content: $ti-icon-music-off;
}
.#{$ti-prefix}-network:before {
  content: $ti-icon-network;
}
.#{$ti-prefix}-new-section:before {
  content: $ti-icon-new-section;
}
.#{$ti-prefix}-news:before {
  content: $ti-icon-news;
}
.#{$ti-prefix}-news-off:before {
  content: $ti-icon-news-off;
}
.#{$ti-prefix}-nfc:before {
  content: $ti-icon-nfc;
}
.#{$ti-prefix}-nfc-off:before {
  content: $ti-icon-nfc-off;
}
.#{$ti-prefix}-no-copyright:before {
  content: $ti-icon-no-copyright;
}
.#{$ti-prefix}-no-creative-commons:before {
  content: $ti-icon-no-creative-commons;
}
.#{$ti-prefix}-no-derivatives:before {
  content: $ti-icon-no-derivatives;
}
.#{$ti-prefix}-north-star:before {
  content: $ti-icon-north-star;
}
.#{$ti-prefix}-note:before {
  content: $ti-icon-note;
}
.#{$ti-prefix}-note-off:before {
  content: $ti-icon-note-off;
}
.#{$ti-prefix}-notebook:before {
  content: $ti-icon-notebook;
}
.#{$ti-prefix}-notes:before {
  content: $ti-icon-notes;
}
.#{$ti-prefix}-notes-off:before {
  content: $ti-icon-notes-off;
}
.#{$ti-prefix}-notification:before {
  content: $ti-icon-notification;
}
.#{$ti-prefix}-notification-off:before {
  content: $ti-icon-notification-off;
}
.#{$ti-prefix}-number:before {
  content: $ti-icon-number;
}
.#{$ti-prefix}-number-0:before {
  content: $ti-icon-number-0;
}
.#{$ti-prefix}-number-1:before {
  content: $ti-icon-number-1;
}
.#{$ti-prefix}-number-2:before {
  content: $ti-icon-number-2;
}
.#{$ti-prefix}-number-3:before {
  content: $ti-icon-number-3;
}
.#{$ti-prefix}-number-4:before {
  content: $ti-icon-number-4;
}
.#{$ti-prefix}-number-5:before {
  content: $ti-icon-number-5;
}
.#{$ti-prefix}-number-6:before {
  content: $ti-icon-number-6;
}
.#{$ti-prefix}-number-7:before {
  content: $ti-icon-number-7;
}
.#{$ti-prefix}-number-8:before {
  content: $ti-icon-number-8;
}
.#{$ti-prefix}-number-9:before {
  content: $ti-icon-number-9;
}
.#{$ti-prefix}-numbers:before {
  content: $ti-icon-numbers;
}
.#{$ti-prefix}-nurse:before {
  content: $ti-icon-nurse;
}
.#{$ti-prefix}-octagon:before {
  content: $ti-icon-octagon;
}
.#{$ti-prefix}-octagon-off:before {
  content: $ti-icon-octagon-off;
}
.#{$ti-prefix}-old:before {
  content: $ti-icon-old;
}
.#{$ti-prefix}-olympics:before {
  content: $ti-icon-olympics;
}
.#{$ti-prefix}-omega:before {
  content: $ti-icon-omega;
}
.#{$ti-prefix}-outlet:before {
  content: $ti-icon-outlet;
}
.#{$ti-prefix}-oval:before {
  content: $ti-icon-oval;
}
.#{$ti-prefix}-oval-vertical:before {
  content: $ti-icon-oval-vertical;
}
.#{$ti-prefix}-overline:before {
  content: $ti-icon-overline;
}
.#{$ti-prefix}-package:before {
  content: $ti-icon-package;
}
.#{$ti-prefix}-package-off:before {
  content: $ti-icon-package-off;
}
.#{$ti-prefix}-packge-export:before {
  content: $ti-icon-packge-export;
}
.#{$ti-prefix}-packge-import:before {
  content: $ti-icon-packge-import;
}
.#{$ti-prefix}-pacman:before {
  content: $ti-icon-pacman;
}
.#{$ti-prefix}-page-break:before {
  content: $ti-icon-page-break;
}
.#{$ti-prefix}-paint:before {
  content: $ti-icon-paint;
}
.#{$ti-prefix}-paint-off:before {
  content: $ti-icon-paint-off;
}
.#{$ti-prefix}-palette:before {
  content: $ti-icon-palette;
}
.#{$ti-prefix}-palette-off:before {
  content: $ti-icon-palette-off;
}
.#{$ti-prefix}-panorama-horizontal:before {
  content: $ti-icon-panorama-horizontal;
}
.#{$ti-prefix}-panorama-vertical:before {
  content: $ti-icon-panorama-vertical;
}
.#{$ti-prefix}-paper-bag:before {
  content: $ti-icon-paper-bag;
}
.#{$ti-prefix}-paper-bag-off:before {
  content: $ti-icon-paper-bag-off;
}
.#{$ti-prefix}-paperclip:before {
  content: $ti-icon-paperclip;
}
.#{$ti-prefix}-parachute:before {
  content: $ti-icon-parachute;
}
.#{$ti-prefix}-parachute-off:before {
  content: $ti-icon-parachute-off;
}
.#{$ti-prefix}-parentheses:before {
  content: $ti-icon-parentheses;
}
.#{$ti-prefix}-parentheses-off:before {
  content: $ti-icon-parentheses-off;
}
.#{$ti-prefix}-parking:before {
  content: $ti-icon-parking;
}
.#{$ti-prefix}-parking-off:before {
  content: $ti-icon-parking-off;
}
.#{$ti-prefix}-paw:before {
  content: $ti-icon-paw;
}
.#{$ti-prefix}-peace:before {
  content: $ti-icon-peace;
}
.#{$ti-prefix}-pencil:before {
  content: $ti-icon-pencil;
}
.#{$ti-prefix}-pencil-minus:before {
  content: $ti-icon-pencil-minus;
}
.#{$ti-prefix}-pencil-off:before {
  content: $ti-icon-pencil-off;
}
.#{$ti-prefix}-pencil-plus:before {
  content: $ti-icon-pencil-plus;
}
.#{$ti-prefix}-pennant:before {
  content: $ti-icon-pennant;
}
.#{$ti-prefix}-pennant-2:before {
  content: $ti-icon-pennant-2;
}
.#{$ti-prefix}-pennant-off:before {
  content: $ti-icon-pennant-off;
}
.#{$ti-prefix}-pentagon:before {
  content: $ti-icon-pentagon;
}
.#{$ti-prefix}-pepper:before {
  content: $ti-icon-pepper;
}
.#{$ti-prefix}-pepper-off:before {
  content: $ti-icon-pepper-off;
}
.#{$ti-prefix}-percentage:before {
  content: $ti-icon-percentage;
}
.#{$ti-prefix}-perspective:before {
  content: $ti-icon-perspective;
}
.#{$ti-prefix}-perspective-off:before {
  content: $ti-icon-perspective-off;
}
.#{$ti-prefix}-phone:before {
  content: $ti-icon-phone;
}
.#{$ti-prefix}-phone-call:before {
  content: $ti-icon-phone-call;
}
.#{$ti-prefix}-phone-calling:before {
  content: $ti-icon-phone-calling;
}
.#{$ti-prefix}-phone-check:before {
  content: $ti-icon-phone-check;
}
.#{$ti-prefix}-phone-incoming:before {
  content: $ti-icon-phone-incoming;
}
.#{$ti-prefix}-phone-off:before {
  content: $ti-icon-phone-off;
}
.#{$ti-prefix}-phone-outgoing:before {
  content: $ti-icon-phone-outgoing;
}
.#{$ti-prefix}-phone-pause:before {
  content: $ti-icon-phone-pause;
}
.#{$ti-prefix}-phone-plus:before {
  content: $ti-icon-phone-plus;
}
.#{$ti-prefix}-phone-x:before {
  content: $ti-icon-phone-x;
}
.#{$ti-prefix}-photo:before {
  content: $ti-icon-photo;
}
.#{$ti-prefix}-photo-off:before {
  content: $ti-icon-photo-off;
}
.#{$ti-prefix}-physotherapist:before {
  content: $ti-icon-physotherapist;
}
.#{$ti-prefix}-picture-in-picture:before {
  content: $ti-icon-picture-in-picture;
}
.#{$ti-prefix}-picture-in-picture-off:before {
  content: $ti-icon-picture-in-picture-off;
}
.#{$ti-prefix}-picture-in-picture-on:before {
  content: $ti-icon-picture-in-picture-on;
}
.#{$ti-prefix}-picture-in-picture-top:before {
  content: $ti-icon-picture-in-picture-top;
}
.#{$ti-prefix}-pig:before {
  content: $ti-icon-pig;
}
.#{$ti-prefix}-pig-off:before {
  content: $ti-icon-pig-off;
}
.#{$ti-prefix}-pill:before {
  content: $ti-icon-pill;
}
.#{$ti-prefix}-pill-off:before {
  content: $ti-icon-pill-off;
}
.#{$ti-prefix}-pills:before {
  content: $ti-icon-pills;
}
.#{$ti-prefix}-pin:before {
  content: $ti-icon-pin;
}
.#{$ti-prefix}-pinned:before {
  content: $ti-icon-pinned;
}
.#{$ti-prefix}-pinned-off:before {
  content: $ti-icon-pinned-off;
}
.#{$ti-prefix}-pizza:before {
  content: $ti-icon-pizza;
}
.#{$ti-prefix}-pizza-off:before {
  content: $ti-icon-pizza-off;
}
.#{$ti-prefix}-plane:before {
  content: $ti-icon-plane;
}
.#{$ti-prefix}-plane-arrival:before {
  content: $ti-icon-plane-arrival;
}
.#{$ti-prefix}-plane-departure:before {
  content: $ti-icon-plane-departure;
}
.#{$ti-prefix}-plane-inflight:before {
  content: $ti-icon-plane-inflight;
}
.#{$ti-prefix}-plane-off:before {
  content: $ti-icon-plane-off;
}
.#{$ti-prefix}-plane-tilt:before {
  content: $ti-icon-plane-tilt;
}
.#{$ti-prefix}-planet:before {
  content: $ti-icon-planet;
}
.#{$ti-prefix}-planet-off:before {
  content: $ti-icon-planet-off;
}
.#{$ti-prefix}-plant:before {
  content: $ti-icon-plant;
}
.#{$ti-prefix}-plant-2:before {
  content: $ti-icon-plant-2;
}
.#{$ti-prefix}-plant-2-off:before {
  content: $ti-icon-plant-2-off;
}
.#{$ti-prefix}-plant-off:before {
  content: $ti-icon-plant-off;
}
.#{$ti-prefix}-play-card:before {
  content: $ti-icon-play-card;
}
.#{$ti-prefix}-play-card-off:before {
  content: $ti-icon-play-card-off;
}
.#{$ti-prefix}-player-eject:before {
  content: $ti-icon-player-eject;
}
.#{$ti-prefix}-player-pause:before {
  content: $ti-icon-player-pause;
}
.#{$ti-prefix}-player-play:before {
  content: $ti-icon-player-play;
}
.#{$ti-prefix}-player-record:before {
  content: $ti-icon-player-record;
}
.#{$ti-prefix}-player-skip-back:before {
  content: $ti-icon-player-skip-back;
}
.#{$ti-prefix}-player-skip-forward:before {
  content: $ti-icon-player-skip-forward;
}
.#{$ti-prefix}-player-stop:before {
  content: $ti-icon-player-stop;
}
.#{$ti-prefix}-player-track-next:before {
  content: $ti-icon-player-track-next;
}
.#{$ti-prefix}-player-track-prev:before {
  content: $ti-icon-player-track-prev;
}
.#{$ti-prefix}-playlist:before {
  content: $ti-icon-playlist;
}
.#{$ti-prefix}-playlist-add:before {
  content: $ti-icon-playlist-add;
}
.#{$ti-prefix}-playlist-off:before {
  content: $ti-icon-playlist-off;
}
.#{$ti-prefix}-playlist-x:before {
  content: $ti-icon-playlist-x;
}
.#{$ti-prefix}-plug:before {
  content: $ti-icon-plug;
}
.#{$ti-prefix}-plug-connected:before {
  content: $ti-icon-plug-connected;
}
.#{$ti-prefix}-plug-connected-x:before {
  content: $ti-icon-plug-connected-x;
}
.#{$ti-prefix}-plug-off:before {
  content: $ti-icon-plug-off;
}
.#{$ti-prefix}-plug-x:before {
  content: $ti-icon-plug-x;
}
.#{$ti-prefix}-plus:before {
  content: $ti-icon-plus;
}
.#{$ti-prefix}-podium:before {
  content: $ti-icon-podium;
}
.#{$ti-prefix}-point:before {
  content: $ti-icon-point;
}
.#{$ti-prefix}-point-off:before {
  content: $ti-icon-point-off;
}
.#{$ti-prefix}-pokeball:before {
  content: $ti-icon-pokeball;
}
.#{$ti-prefix}-polaroid:before {
  content: $ti-icon-polaroid;
}
.#{$ti-prefix}-polygon:before {
  content: $ti-icon-polygon;
}
.#{$ti-prefix}-polygon-off:before {
  content: $ti-icon-polygon-off;
}
.#{$ti-prefix}-pool:before {
  content: $ti-icon-pool;
}
.#{$ti-prefix}-power:before {
  content: $ti-icon-power;
}
.#{$ti-prefix}-pray:before {
  content: $ti-icon-pray;
}
.#{$ti-prefix}-premium-rights:before {
  content: $ti-icon-premium-rights;
}
.#{$ti-prefix}-prescription:before {
  content: $ti-icon-prescription;
}
.#{$ti-prefix}-presentation:before {
  content: $ti-icon-presentation;
}
.#{$ti-prefix}-presentation-analytics:before {
  content: $ti-icon-presentation-analytics;
}
.#{$ti-prefix}-presentation-off:before {
  content: $ti-icon-presentation-off;
}
.#{$ti-prefix}-printer:before {
  content: $ti-icon-printer;
}
.#{$ti-prefix}-printer-off:before {
  content: $ti-icon-printer-off;
}
.#{$ti-prefix}-prison:before {
  content: $ti-icon-prison;
}
.#{$ti-prefix}-prompt:before {
  content: $ti-icon-prompt;
}
.#{$ti-prefix}-propeller:before {
  content: $ti-icon-propeller;
}
.#{$ti-prefix}-propeller-off:before {
  content: $ti-icon-propeller-off;
}
.#{$ti-prefix}-puzzle:before {
  content: $ti-icon-puzzle;
}
.#{$ti-prefix}-puzzle-2:before {
  content: $ti-icon-puzzle-2;
}
.#{$ti-prefix}-puzzle-off:before {
  content: $ti-icon-puzzle-off;
}
.#{$ti-prefix}-pyramid:before {
  content: $ti-icon-pyramid;
}
.#{$ti-prefix}-pyramid-off:before {
  content: $ti-icon-pyramid-off;
}
.#{$ti-prefix}-qrcode:before {
  content: $ti-icon-qrcode;
}
.#{$ti-prefix}-question-mark:before {
  content: $ti-icon-question-mark;
}
.#{$ti-prefix}-quote:before {
  content: $ti-icon-quote;
}
.#{$ti-prefix}-quote-off:before {
  content: $ti-icon-quote-off;
}
.#{$ti-prefix}-radar:before {
  content: $ti-icon-radar;
}
.#{$ti-prefix}-radar-2:before {
  content: $ti-icon-radar-2;
}
.#{$ti-prefix}-radio:before {
  content: $ti-icon-radio;
}
.#{$ti-prefix}-radioactive:before {
  content: $ti-icon-radioactive;
}
.#{$ti-prefix}-radioactive-off:before {
  content: $ti-icon-radioactive-off;
}
.#{$ti-prefix}-radius-bottom-left:before {
  content: $ti-icon-radius-bottom-left;
}
.#{$ti-prefix}-radius-bottom-right:before {
  content: $ti-icon-radius-bottom-right;
}
.#{$ti-prefix}-radius-top-left:before {
  content: $ti-icon-radius-top-left;
}
.#{$ti-prefix}-radius-top-right:before {
  content: $ti-icon-radius-top-right;
}
.#{$ti-prefix}-rainbow:before {
  content: $ti-icon-rainbow;
}
.#{$ti-prefix}-rainbow-off:before {
  content: $ti-icon-rainbow-off;
}
.#{$ti-prefix}-receipt:before {
  content: $ti-icon-receipt;
}
.#{$ti-prefix}-receipt-2:before {
  content: $ti-icon-receipt-2;
}
.#{$ti-prefix}-receipt-off:before {
  content: $ti-icon-receipt-off;
}
.#{$ti-prefix}-receipt-refund:before {
  content: $ti-icon-receipt-refund;
}
.#{$ti-prefix}-receipt-tax:before {
  content: $ti-icon-receipt-tax;
}
.#{$ti-prefix}-recharging:before {
  content: $ti-icon-recharging;
}
.#{$ti-prefix}-record-mail:before {
  content: $ti-icon-record-mail;
}
.#{$ti-prefix}-record-mail-off:before {
  content: $ti-icon-record-mail-off;
}
.#{$ti-prefix}-rectangle:before {
  content: $ti-icon-rectangle;
}
.#{$ti-prefix}-rectangle-vertical:before {
  content: $ti-icon-rectangle-vertical;
}
.#{$ti-prefix}-recycle:before {
  content: $ti-icon-recycle;
}
.#{$ti-prefix}-recycle-off:before {
  content: $ti-icon-recycle-off;
}
.#{$ti-prefix}-refresh:before {
  content: $ti-icon-refresh;
}
.#{$ti-prefix}-refresh-alert:before {
  content: $ti-icon-refresh-alert;
}
.#{$ti-prefix}-refresh-dot:before {
  content: $ti-icon-refresh-dot;
}
.#{$ti-prefix}-refresh-off:before {
  content: $ti-icon-refresh-off;
}
.#{$ti-prefix}-registered:before {
  content: $ti-icon-registered;
}
.#{$ti-prefix}-relation-many-to-many:before {
  content: $ti-icon-relation-many-to-many;
}
.#{$ti-prefix}-relation-one-to-many:before {
  content: $ti-icon-relation-one-to-many;
}
.#{$ti-prefix}-relation-one-to-one:before {
  content: $ti-icon-relation-one-to-one;
}
.#{$ti-prefix}-repeat:before {
  content: $ti-icon-repeat;
}
.#{$ti-prefix}-repeat-off:before {
  content: $ti-icon-repeat-off;
}
.#{$ti-prefix}-repeat-once:before {
  content: $ti-icon-repeat-once;
}
.#{$ti-prefix}-replace:before {
  content: $ti-icon-replace;
}
.#{$ti-prefix}-report:before {
  content: $ti-icon-report;
}
.#{$ti-prefix}-report-analytics:before {
  content: $ti-icon-report-analytics;
}
.#{$ti-prefix}-report-medical:before {
  content: $ti-icon-report-medical;
}
.#{$ti-prefix}-report-money:before {
  content: $ti-icon-report-money;
}
.#{$ti-prefix}-report-off:before {
  content: $ti-icon-report-off;
}
.#{$ti-prefix}-report-search:before {
  content: $ti-icon-report-search;
}
.#{$ti-prefix}-resize:before {
  content: $ti-icon-resize;
}
.#{$ti-prefix}-ripple:before {
  content: $ti-icon-ripple;
}
.#{$ti-prefix}-ripple-off:before {
  content: $ti-icon-ripple-off;
}
.#{$ti-prefix}-road:before {
  content: $ti-icon-road;
}
.#{$ti-prefix}-road-off:before {
  content: $ti-icon-road-off;
}
.#{$ti-prefix}-road-sign:before {
  content: $ti-icon-road-sign;
}
.#{$ti-prefix}-robot:before {
  content: $ti-icon-robot;
}
.#{$ti-prefix}-robot-off:before {
  content: $ti-icon-robot-off;
}
.#{$ti-prefix}-rocket:before {
  content: $ti-icon-rocket;
}
.#{$ti-prefix}-rocket-off:before {
  content: $ti-icon-rocket-off;
}
.#{$ti-prefix}-roller-skating:before {
  content: $ti-icon-roller-skating;
}
.#{$ti-prefix}-rollercoaster:before {
  content: $ti-icon-rollercoaster;
}
.#{$ti-prefix}-rotate:before {
  content: $ti-icon-rotate;
}
.#{$ti-prefix}-rotate-2:before {
  content: $ti-icon-rotate-2;
}
.#{$ti-prefix}-rotate-360:before {
  content: $ti-icon-rotate-360;
}
.#{$ti-prefix}-rotate-clockwise:before {
  content: $ti-icon-rotate-clockwise;
}
.#{$ti-prefix}-rotate-clockwise-2:before {
  content: $ti-icon-rotate-clockwise-2;
}
.#{$ti-prefix}-rotate-dot:before {
  content: $ti-icon-rotate-dot;
}
.#{$ti-prefix}-rotate-rectangle:before {
  content: $ti-icon-rotate-rectangle;
}
.#{$ti-prefix}-route:before {
  content: $ti-icon-route;
}
.#{$ti-prefix}-route-off:before {
  content: $ti-icon-route-off;
}
.#{$ti-prefix}-router:before {
  content: $ti-icon-router;
}
.#{$ti-prefix}-row-insert-bottom:before {
  content: $ti-icon-row-insert-bottom;
}
.#{$ti-prefix}-row-insert-top:before {
  content: $ti-icon-row-insert-top;
}
.#{$ti-prefix}-rss:before {
  content: $ti-icon-rss;
}
.#{$ti-prefix}-ruler:before {
  content: $ti-icon-ruler;
}
.#{$ti-prefix}-ruler-2:before {
  content: $ti-icon-ruler-2;
}
.#{$ti-prefix}-ruler-2-off:before {
  content: $ti-icon-ruler-2-off;
}
.#{$ti-prefix}-ruler-off:before {
  content: $ti-icon-ruler-off;
}
.#{$ti-prefix}-run:before {
  content: $ti-icon-run;
}
.#{$ti-prefix}-sailboat:before {
  content: $ti-icon-sailboat;
}
.#{$ti-prefix}-salt:before {
  content: $ti-icon-salt;
}
.#{$ti-prefix}-satellite:before {
  content: $ti-icon-satellite;
}
.#{$ti-prefix}-satellite-off:before {
  content: $ti-icon-satellite-off;
}
.#{$ti-prefix}-sausage:before {
  content: $ti-icon-sausage;
}
.#{$ti-prefix}-scale:before {
  content: $ti-icon-scale;
}
.#{$ti-prefix}-scale-off:before {
  content: $ti-icon-scale-off;
}
.#{$ti-prefix}-scale-outline:before {
  content: $ti-icon-scale-outline;
}
.#{$ti-prefix}-scale-outline-off:before {
  content: $ti-icon-scale-outline-off;
}
.#{$ti-prefix}-scan:before {
  content: $ti-icon-scan;
}
.#{$ti-prefix}-scan-eye:before {
  content: $ti-icon-scan-eye;
}
.#{$ti-prefix}-schema:before {
  content: $ti-icon-schema;
}
.#{$ti-prefix}-school:before {
  content: $ti-icon-school;
}
.#{$ti-prefix}-school-off:before {
  content: $ti-icon-school-off;
}
.#{$ti-prefix}-scissors:before {
  content: $ti-icon-scissors;
}
.#{$ti-prefix}-scissors-off:before {
  content: $ti-icon-scissors-off;
}
.#{$ti-prefix}-scooter:before {
  content: $ti-icon-scooter;
}
.#{$ti-prefix}-scooter-electric:before {
  content: $ti-icon-scooter-electric;
}
.#{$ti-prefix}-screen-share:before {
  content: $ti-icon-screen-share;
}
.#{$ti-prefix}-screen-share-off:before {
  content: $ti-icon-screen-share-off;
}
.#{$ti-prefix}-screenshot:before {
  content: $ti-icon-screenshot;
}
.#{$ti-prefix}-scribble:before {
  content: $ti-icon-scribble;
}
.#{$ti-prefix}-scuba-mask:before {
  content: $ti-icon-scuba-mask;
}
.#{$ti-prefix}-search:before {
  content: $ti-icon-search;
}
.#{$ti-prefix}-search-off:before {
  content: $ti-icon-search-off;
}
.#{$ti-prefix}-section:before {
  content: $ti-icon-section;
}
.#{$ti-prefix}-section-sign:before {
  content: $ti-icon-section-sign;
}
.#{$ti-prefix}-seeding:before {
  content: $ti-icon-seeding;
}
.#{$ti-prefix}-seeding-off:before {
  content: $ti-icon-seeding-off;
}
.#{$ti-prefix}-select:before {
  content: $ti-icon-select;
}
.#{$ti-prefix}-selector:before {
  content: $ti-icon-selector;
}
.#{$ti-prefix}-send:before {
  content: $ti-icon-send;
}
.#{$ti-prefix}-separator:before {
  content: $ti-icon-separator;
}
.#{$ti-prefix}-separator-horizontal:before {
  content: $ti-icon-separator-horizontal;
}
.#{$ti-prefix}-separator-vertical:before {
  content: $ti-icon-separator-vertical;
}
.#{$ti-prefix}-server:before {
  content: $ti-icon-server;
}
.#{$ti-prefix}-server-2:before {
  content: $ti-icon-server-2;
}
.#{$ti-prefix}-server-off:before {
  content: $ti-icon-server-off;
}
.#{$ti-prefix}-servicemark:before {
  content: $ti-icon-servicemark;
}
.#{$ti-prefix}-settings:before {
  content: $ti-icon-settings;
}
.#{$ti-prefix}-settings-automation:before {
  content: $ti-icon-settings-automation;
}
.#{$ti-prefix}-settings-off:before {
  content: $ti-icon-settings-off;
}
.#{$ti-prefix}-shadow:before {
  content: $ti-icon-shadow;
}
.#{$ti-prefix}-shadow-off:before {
  content: $ti-icon-shadow-off;
}
.#{$ti-prefix}-shape:before {
  content: $ti-icon-shape;
}
.#{$ti-prefix}-shape-2:before {
  content: $ti-icon-shape-2;
}
.#{$ti-prefix}-shape-3:before {
  content: $ti-icon-shape-3;
}
.#{$ti-prefix}-shape-off:before {
  content: $ti-icon-shape-off;
}
.#{$ti-prefix}-share:before {
  content: $ti-icon-share;
}
.#{$ti-prefix}-share-off:before {
  content: $ti-icon-share-off;
}
.#{$ti-prefix}-shield:before {
  content: $ti-icon-shield;
}
.#{$ti-prefix}-shield-check:before {
  content: $ti-icon-shield-check;
}
.#{$ti-prefix}-shield-checkered:before {
  content: $ti-icon-shield-checkered;
}
.#{$ti-prefix}-shield-chevron:before {
  content: $ti-icon-shield-chevron;
}
.#{$ti-prefix}-shield-lock:before {
  content: $ti-icon-shield-lock;
}
.#{$ti-prefix}-shield-off:before {
  content: $ti-icon-shield-off;
}
.#{$ti-prefix}-shield-x:before {
  content: $ti-icon-shield-x;
}
.#{$ti-prefix}-ship:before {
  content: $ti-icon-ship;
}
.#{$ti-prefix}-shirt:before {
  content: $ti-icon-shirt;
}
.#{$ti-prefix}-shirt-off:before {
  content: $ti-icon-shirt-off;
}
.#{$ti-prefix}-shoe:before {
  content: $ti-icon-shoe;
}
.#{$ti-prefix}-shoe-off:before {
  content: $ti-icon-shoe-off;
}
.#{$ti-prefix}-shopping-cart:before {
  content: $ti-icon-shopping-cart;
}
.#{$ti-prefix}-shopping-cart-discount:before {
  content: $ti-icon-shopping-cart-discount;
}
.#{$ti-prefix}-shopping-cart-off:before {
  content: $ti-icon-shopping-cart-off;
}
.#{$ti-prefix}-shopping-cart-plus:before {
  content: $ti-icon-shopping-cart-plus;
}
.#{$ti-prefix}-shopping-cart-x:before {
  content: $ti-icon-shopping-cart-x;
}
.#{$ti-prefix}-shovel:before {
  content: $ti-icon-shovel;
}
.#{$ti-prefix}-shredder:before {
  content: $ti-icon-shredder;
}
.#{$ti-prefix}-sign-left:before {
  content: $ti-icon-sign-left;
}
.#{$ti-prefix}-sign-right:before {
  content: $ti-icon-sign-right;
}
.#{$ti-prefix}-signal-3g:before {
  content: $ti-icon-signal-3g;
}
.#{$ti-prefix}-signal-4g:before {
  content: $ti-icon-signal-4g;
}
.#{$ti-prefix}-signal-5g:before {
  content: $ti-icon-signal-5g;
}
.#{$ti-prefix}-signature:before {
  content: $ti-icon-signature;
}
.#{$ti-prefix}-signature-off:before {
  content: $ti-icon-signature-off;
}
.#{$ti-prefix}-sitemap:before {
  content: $ti-icon-sitemap;
}
.#{$ti-prefix}-sitemap-off:before {
  content: $ti-icon-sitemap-off;
}
.#{$ti-prefix}-skateboard:before {
  content: $ti-icon-skateboard;
}
.#{$ti-prefix}-sleigh:before {
  content: $ti-icon-sleigh;
}
.#{$ti-prefix}-slice:before {
  content: $ti-icon-slice;
}
.#{$ti-prefix}-slideshow:before {
  content: $ti-icon-slideshow;
}
.#{$ti-prefix}-smart-home:before {
  content: $ti-icon-smart-home;
}
.#{$ti-prefix}-smart-home-off:before {
  content: $ti-icon-smart-home-off;
}
.#{$ti-prefix}-smoking:before {
  content: $ti-icon-smoking;
}
.#{$ti-prefix}-smoking-no:before {
  content: $ti-icon-smoking-no;
}
.#{$ti-prefix}-snowflake:before {
  content: $ti-icon-snowflake;
}
.#{$ti-prefix}-snowflake-off:before {
  content: $ti-icon-snowflake-off;
}
.#{$ti-prefix}-soccer-field:before {
  content: $ti-icon-soccer-field;
}
.#{$ti-prefix}-social:before {
  content: $ti-icon-social;
}
.#{$ti-prefix}-social-off:before {
  content: $ti-icon-social-off;
}
.#{$ti-prefix}-sock:before {
  content: $ti-icon-sock;
}
.#{$ti-prefix}-sofa:before {
  content: $ti-icon-sofa;
}
.#{$ti-prefix}-sort-ascending:before {
  content: $ti-icon-sort-ascending;
}
.#{$ti-prefix}-sort-ascending-2:before {
  content: $ti-icon-sort-ascending-2;
}
.#{$ti-prefix}-sort-ascending-letters:before {
  content: $ti-icon-sort-ascending-letters;
}
.#{$ti-prefix}-sort-ascending-numbers:before {
  content: $ti-icon-sort-ascending-numbers;
}
.#{$ti-prefix}-sort-descending:before {
  content: $ti-icon-sort-descending;
}
.#{$ti-prefix}-sort-descending-2:before {
  content: $ti-icon-sort-descending-2;
}
.#{$ti-prefix}-sort-descending-letters:before {
  content: $ti-icon-sort-descending-letters;
}
.#{$ti-prefix}-sort-descending-numbers:before {
  content: $ti-icon-sort-descending-numbers;
}
.#{$ti-prefix}-soup:before {
  content: $ti-icon-soup;
}
.#{$ti-prefix}-space:before {
  content: $ti-icon-space;
}
.#{$ti-prefix}-space-off:before {
  content: $ti-icon-space-off;
}
.#{$ti-prefix}-spacing-horizontal:before {
  content: $ti-icon-spacing-horizontal;
}
.#{$ti-prefix}-spacing-vertical:before {
  content: $ti-icon-spacing-vertical;
}
.#{$ti-prefix}-spade:before {
  content: $ti-icon-spade;
}
.#{$ti-prefix}-speakerphone:before {
  content: $ti-icon-speakerphone;
}
.#{$ti-prefix}-speedboat:before {
  content: $ti-icon-speedboat;
}
.#{$ti-prefix}-sport-billard:before {
  content: $ti-icon-sport-billard;
}
.#{$ti-prefix}-spy:before {
  content: $ti-icon-spy;
}
.#{$ti-prefix}-square:before {
  content: $ti-icon-square;
}
.#{$ti-prefix}-square-0:before {
  content: $ti-icon-square-0;
}
.#{$ti-prefix}-square-1:before {
  content: $ti-icon-square-1;
}
.#{$ti-prefix}-square-2:before {
  content: $ti-icon-square-2;
}
.#{$ti-prefix}-square-3:before {
  content: $ti-icon-square-3;
}
.#{$ti-prefix}-square-4:before {
  content: $ti-icon-square-4;
}
.#{$ti-prefix}-square-5:before {
  content: $ti-icon-square-5;
}
.#{$ti-prefix}-square-6:before {
  content: $ti-icon-square-6;
}
.#{$ti-prefix}-square-7:before {
  content: $ti-icon-square-7;
}
.#{$ti-prefix}-square-8:before {
  content: $ti-icon-square-8;
}
.#{$ti-prefix}-square-9:before {
  content: $ti-icon-square-9;
}
.#{$ti-prefix}-square-asterisk:before {
  content: $ti-icon-square-asterisk;
}
.#{$ti-prefix}-square-check:before {
  content: $ti-icon-square-check;
}
.#{$ti-prefix}-square-dot:before {
  content: $ti-icon-square-dot;
}
.#{$ti-prefix}-square-forbid:before {
  content: $ti-icon-square-forbid;
}
.#{$ti-prefix}-square-forbid-2:before {
  content: $ti-icon-square-forbid-2;
}
.#{$ti-prefix}-square-half:before {
  content: $ti-icon-square-half;
}
.#{$ti-prefix}-square-minus:before {
  content: $ti-icon-square-minus;
}
.#{$ti-prefix}-square-off:before {
  content: $ti-icon-square-off;
}
.#{$ti-prefix}-square-plus:before {
  content: $ti-icon-square-plus;
}
.#{$ti-prefix}-square-root:before {
  content: $ti-icon-square-root;
}
.#{$ti-prefix}-square-root-2:before {
  content: $ti-icon-square-root-2;
}
.#{$ti-prefix}-square-rotated:before {
  content: $ti-icon-square-rotated;
}
.#{$ti-prefix}-square-rotated-forbid:before {
  content: $ti-icon-square-rotated-forbid;
}
.#{$ti-prefix}-square-rotated-forbid-2:before {
  content: $ti-icon-square-rotated-forbid-2;
}
.#{$ti-prefix}-square-rotated-off:before {
  content: $ti-icon-square-rotated-off;
}
.#{$ti-prefix}-square-toggle:before {
  content: $ti-icon-square-toggle;
}
.#{$ti-prefix}-square-toggle-horizontal:before {
  content: $ti-icon-square-toggle-horizontal;
}
.#{$ti-prefix}-square-x:before {
  content: $ti-icon-square-x;
}
.#{$ti-prefix}-squares-diagonal:before {
  content: $ti-icon-squares-diagonal;
}
.#{$ti-prefix}-squares-filled:before {
  content: $ti-icon-squares-filled;
}
.#{$ti-prefix}-stack:before {
  content: $ti-icon-stack;
}
.#{$ti-prefix}-stack-2:before {
  content: $ti-icon-stack-2;
}
.#{$ti-prefix}-stack-3:before {
  content: $ti-icon-stack-3;
}
.#{$ti-prefix}-stairs:before {
  content: $ti-icon-stairs;
}
.#{$ti-prefix}-stairs-down:before {
  content: $ti-icon-stairs-down;
}
.#{$ti-prefix}-stairs-up:before {
  content: $ti-icon-stairs-up;
}
.#{$ti-prefix}-star:before {
  content: $ti-icon-star;
}
.#{$ti-prefix}-star-half:before {
  content: $ti-icon-star-half;
}
.#{$ti-prefix}-star-off:before {
  content: $ti-icon-star-off;
}
.#{$ti-prefix}-stars:before {
  content: $ti-icon-stars;
}
.#{$ti-prefix}-steering-wheel:before {
  content: $ti-icon-steering-wheel;
}
.#{$ti-prefix}-step-into:before {
  content: $ti-icon-step-into;
}
.#{$ti-prefix}-step-out:before {
  content: $ti-icon-step-out;
}
.#{$ti-prefix}-stethoscope:before {
  content: $ti-icon-stethoscope;
}
.#{$ti-prefix}-sticker:before {
  content: $ti-icon-sticker;
}
.#{$ti-prefix}-strikethrough:before {
  content: $ti-icon-strikethrough;
}
.#{$ti-prefix}-submarine:before {
  content: $ti-icon-submarine;
}
.#{$ti-prefix}-subscript:before {
  content: $ti-icon-subscript;
}
.#{$ti-prefix}-subtask:before {
  content: $ti-icon-subtask;
}
.#{$ti-prefix}-sum:before {
  content: $ti-icon-sum;
}
.#{$ti-prefix}-sum-off:before {
  content: $ti-icon-sum-off;
}
.#{$ti-prefix}-sun:before {
  content: $ti-icon-sun;
}
.#{$ti-prefix}-sun-off:before {
  content: $ti-icon-sun-off;
}
.#{$ti-prefix}-sunrise:before {
  content: $ti-icon-sunrise;
}
.#{$ti-prefix}-sunset:before {
  content: $ti-icon-sunset;
}
.#{$ti-prefix}-superscript:before {
  content: $ti-icon-superscript;
}
.#{$ti-prefix}-swimming:before {
  content: $ti-icon-swimming;
}
.#{$ti-prefix}-switch:before {
  content: $ti-icon-switch;
}
.#{$ti-prefix}-switch-2:before {
  content: $ti-icon-switch-2;
}
.#{$ti-prefix}-switch-3:before {
  content: $ti-icon-switch-3;
}
.#{$ti-prefix}-switch-horizontal:before {
  content: $ti-icon-switch-horizontal;
}
.#{$ti-prefix}-switch-vertical:before {
  content: $ti-icon-switch-vertical;
}
.#{$ti-prefix}-sword:before {
  content: $ti-icon-sword;
}
.#{$ti-prefix}-sword-off:before {
  content: $ti-icon-sword-off;
}
.#{$ti-prefix}-swords:before {
  content: $ti-icon-swords;
}
.#{$ti-prefix}-table:before {
  content: $ti-icon-table;
}
.#{$ti-prefix}-table-export:before {
  content: $ti-icon-table-export;
}
.#{$ti-prefix}-table-import:before {
  content: $ti-icon-table-import;
}
.#{$ti-prefix}-table-off:before {
  content: $ti-icon-table-off;
}
.#{$ti-prefix}-tag:before {
  content: $ti-icon-tag;
}
.#{$ti-prefix}-tag-off:before {
  content: $ti-icon-tag-off;
}
.#{$ti-prefix}-tags:before {
  content: $ti-icon-tags;
}
.#{$ti-prefix}-tags-off:before {
  content: $ti-icon-tags-off;
}
.#{$ti-prefix}-tallymark-1:before {
  content: $ti-icon-tallymark-1;
}
.#{$ti-prefix}-tallymark-2:before {
  content: $ti-icon-tallymark-2;
}
.#{$ti-prefix}-tallymark-3:before {
  content: $ti-icon-tallymark-3;
}
.#{$ti-prefix}-tallymark-4:before {
  content: $ti-icon-tallymark-4;
}
.#{$ti-prefix}-tallymarks:before {
  content: $ti-icon-tallymarks;
}
.#{$ti-prefix}-tank:before {
  content: $ti-icon-tank;
}
.#{$ti-prefix}-target:before {
  content: $ti-icon-target;
}
.#{$ti-prefix}-target-off:before {
  content: $ti-icon-target-off;
}
.#{$ti-prefix}-telescope:before {
  content: $ti-icon-telescope;
}
.#{$ti-prefix}-telescope-off:before {
  content: $ti-icon-telescope-off;
}
.#{$ti-prefix}-temperature:before {
  content: $ti-icon-temperature;
}
.#{$ti-prefix}-temperature-celsius:before {
  content: $ti-icon-temperature-celsius;
}
.#{$ti-prefix}-temperature-fahrenheit:before {
  content: $ti-icon-temperature-fahrenheit;
}
.#{$ti-prefix}-temperature-minus:before {
  content: $ti-icon-temperature-minus;
}
.#{$ti-prefix}-temperature-off:before {
  content: $ti-icon-temperature-off;
}
.#{$ti-prefix}-temperature-plus:before {
  content: $ti-icon-temperature-plus;
}
.#{$ti-prefix}-template:before {
  content: $ti-icon-template;
}
.#{$ti-prefix}-template-off:before {
  content: $ti-icon-template-off;
}
.#{$ti-prefix}-tent:before {
  content: $ti-icon-tent;
}
.#{$ti-prefix}-terminal:before {
  content: $ti-icon-terminal;
}
.#{$ti-prefix}-terminal-2:before {
  content: $ti-icon-terminal-2;
}
.#{$ti-prefix}-test-pipe:before {
  content: $ti-icon-test-pipe;
}
.#{$ti-prefix}-test-pipe-2:before {
  content: $ti-icon-test-pipe-2;
}
.#{$ti-prefix}-test-pipe-off:before {
  content: $ti-icon-test-pipe-off;
}
.#{$ti-prefix}-text-decrease:before {
  content: $ti-icon-text-decrease;
}
.#{$ti-prefix}-text-direction-ltr:before {
  content: $ti-icon-text-direction-ltr;
}
.#{$ti-prefix}-text-direction-rtl:before {
  content: $ti-icon-text-direction-rtl;
}
.#{$ti-prefix}-text-increase:before {
  content: $ti-icon-text-increase;
}
.#{$ti-prefix}-text-recognition:before {
  content: $ti-icon-text-recognition;
}
.#{$ti-prefix}-text-resize:before {
  content: $ti-icon-text-resize;
}
.#{$ti-prefix}-text-wrap:before {
  content: $ti-icon-text-wrap;
}
.#{$ti-prefix}-text-wrap-disabled:before {
  content: $ti-icon-text-wrap-disabled;
}
.#{$ti-prefix}-thermometer:before {
  content: $ti-icon-thermometer;
}
.#{$ti-prefix}-thumb-down:before {
  content: $ti-icon-thumb-down;
}
.#{$ti-prefix}-thumb-up:before {
  content: $ti-icon-thumb-up;
}
.#{$ti-prefix}-ticket:before {
  content: $ti-icon-ticket;
}
.#{$ti-prefix}-ticket-off:before {
  content: $ti-icon-ticket-off;
}
.#{$ti-prefix}-tie:before {
  content: $ti-icon-tie;
}
.#{$ti-prefix}-tilt-shift:before {
  content: $ti-icon-tilt-shift;
}
.#{$ti-prefix}-tilt-shift-off:before {
  content: $ti-icon-tilt-shift-off;
}
.#{$ti-prefix}-timeline:before {
  content: $ti-icon-timeline;
}
.#{$ti-prefix}-tir:before {
  content: $ti-icon-tir;
}
.#{$ti-prefix}-toggle-left:before {
  content: $ti-icon-toggle-left;
}
.#{$ti-prefix}-toggle-right:before {
  content: $ti-icon-toggle-right;
}
.#{$ti-prefix}-toilet-paper:before {
  content: $ti-icon-toilet-paper;
}
.#{$ti-prefix}-toilet-paper-off:before {
  content: $ti-icon-toilet-paper-off;
}
.#{$ti-prefix}-tool:before {
  content: $ti-icon-tool;
}
.#{$ti-prefix}-tools:before {
  content: $ti-icon-tools;
}
.#{$ti-prefix}-tools-kitchen:before {
  content: $ti-icon-tools-kitchen;
}
.#{$ti-prefix}-tools-kitchen-2:before {
  content: $ti-icon-tools-kitchen-2;
}
.#{$ti-prefix}-tools-kitchen-2-off:before {
  content: $ti-icon-tools-kitchen-2-off;
}
.#{$ti-prefix}-tools-kitchen-off:before {
  content: $ti-icon-tools-kitchen-off;
}
.#{$ti-prefix}-tools-off:before {
  content: $ti-icon-tools-off;
}
.#{$ti-prefix}-tornado:before {
  content: $ti-icon-tornado;
}
.#{$ti-prefix}-tournament:before {
  content: $ti-icon-tournament;
}
.#{$ti-prefix}-track:before {
  content: $ti-icon-track;
}
.#{$ti-prefix}-tractor:before {
  content: $ti-icon-tractor;
}
.#{$ti-prefix}-trademark:before {
  content: $ti-icon-trademark;
}
.#{$ti-prefix}-traffic-cone:before {
  content: $ti-icon-traffic-cone;
}
.#{$ti-prefix}-traffic-cone-off:before {
  content: $ti-icon-traffic-cone-off;
}
.#{$ti-prefix}-traffic-lights:before {
  content: $ti-icon-traffic-lights;
}
.#{$ti-prefix}-traffic-lights-off:before {
  content: $ti-icon-traffic-lights-off;
}
.#{$ti-prefix}-train:before {
  content: $ti-icon-train;
}
.#{$ti-prefix}-transfer-in:before {
  content: $ti-icon-transfer-in;
}
.#{$ti-prefix}-transfer-out:before {
  content: $ti-icon-transfer-out;
}
.#{$ti-prefix}-trash:before {
  content: $ti-icon-trash;
}
.#{$ti-prefix}-trash-off:before {
  content: $ti-icon-trash-off;
}
.#{$ti-prefix}-trash-x:before {
  content: $ti-icon-trash-x;
}
.#{$ti-prefix}-tree:before {
  content: $ti-icon-tree;
}
.#{$ti-prefix}-trees:before {
  content: $ti-icon-trees;
}
.#{$ti-prefix}-trending-down:before {
  content: $ti-icon-trending-down;
}
.#{$ti-prefix}-trending-down-2:before {
  content: $ti-icon-trending-down-2;
}
.#{$ti-prefix}-trending-down-3:before {
  content: $ti-icon-trending-down-3;
}
.#{$ti-prefix}-trending-up:before {
  content: $ti-icon-trending-up;
}
.#{$ti-prefix}-trending-up-2:before {
  content: $ti-icon-trending-up-2;
}
.#{$ti-prefix}-trending-up-3:before {
  content: $ti-icon-trending-up-3;
}
.#{$ti-prefix}-triangle:before {
  content: $ti-icon-triangle;
}
.#{$ti-prefix}-triangle-inverted:before {
  content: $ti-icon-triangle-inverted;
}
.#{$ti-prefix}-triangle-off:before {
  content: $ti-icon-triangle-off;
}
.#{$ti-prefix}-triangle-square-circle:before {
  content: $ti-icon-triangle-square-circle;
}
.#{$ti-prefix}-triangles:before {
  content: $ti-icon-triangles;
}
.#{$ti-prefix}-trident:before {
  content: $ti-icon-trident;
}
.#{$ti-prefix}-trophy:before {
  content: $ti-icon-trophy;
}
.#{$ti-prefix}-truck:before {
  content: $ti-icon-truck;
}
.#{$ti-prefix}-truck-delivery:before {
  content: $ti-icon-truck-delivery;
}
.#{$ti-prefix}-truck-loading:before {
  content: $ti-icon-truck-loading;
}
.#{$ti-prefix}-truck-off:before {
  content: $ti-icon-truck-off;
}
.#{$ti-prefix}-truck-return:before {
  content: $ti-icon-truck-return;
}
.#{$ti-prefix}-typography:before {
  content: $ti-icon-typography;
}
.#{$ti-prefix}-typography-off:before {
  content: $ti-icon-typography-off;
}
.#{$ti-prefix}-umbrella:before {
  content: $ti-icon-umbrella;
}
.#{$ti-prefix}-umbrella-off:before {
  content: $ti-icon-umbrella-off;
}
.#{$ti-prefix}-underline:before {
  content: $ti-icon-underline;
}
.#{$ti-prefix}-unlink:before {
  content: $ti-icon-unlink;
}
.#{$ti-prefix}-upload:before {
  content: $ti-icon-upload;
}
.#{$ti-prefix}-urgent:before {
  content: $ti-icon-urgent;
}
.#{$ti-prefix}-usb:before {
  content: $ti-icon-usb;
}
.#{$ti-prefix}-user:before {
  content: $ti-icon-user;
}
.#{$ti-prefix}-user-check:before {
  content: $ti-icon-user-check;
}
.#{$ti-prefix}-user-circle:before {
  content: $ti-icon-user-circle;
}
.#{$ti-prefix}-user-exclamation:before {
  content: $ti-icon-user-exclamation;
}
.#{$ti-prefix}-user-minus:before {
  content: $ti-icon-user-minus;
}
.#{$ti-prefix}-user-off:before {
  content: $ti-icon-user-off;
}
.#{$ti-prefix}-user-plus:before {
  content: $ti-icon-user-plus;
}
.#{$ti-prefix}-user-search:before {
  content: $ti-icon-user-search;
}
.#{$ti-prefix}-user-x:before {
  content: $ti-icon-user-x;
}
.#{$ti-prefix}-users:before {
  content: $ti-icon-users;
}
.#{$ti-prefix}-vaccine:before {
  content: $ti-icon-vaccine;
}
.#{$ti-prefix}-vaccine-bottle:before {
  content: $ti-icon-vaccine-bottle;
}
.#{$ti-prefix}-vaccine-off:before {
  content: $ti-icon-vaccine-off;
}
.#{$ti-prefix}-variable:before {
  content: $ti-icon-variable;
}
.#{$ti-prefix}-variable-off:before {
  content: $ti-icon-variable-off;
}
.#{$ti-prefix}-vector:before {
  content: $ti-icon-vector;
}
.#{$ti-prefix}-vector-bezier:before {
  content: $ti-icon-vector-bezier;
}
.#{$ti-prefix}-vector-bezier-2:before {
  content: $ti-icon-vector-bezier-2;
}
.#{$ti-prefix}-vector-off:before {
  content: $ti-icon-vector-off;
}
.#{$ti-prefix}-vector-triangle:before {
  content: $ti-icon-vector-triangle;
}
.#{$ti-prefix}-vector-triangle-off:before {
  content: $ti-icon-vector-triangle-off;
}
.#{$ti-prefix}-venus:before {
  content: $ti-icon-venus;
}
.#{$ti-prefix}-versions:before {
  content: $ti-icon-versions;
}
.#{$ti-prefix}-versions-off:before {
  content: $ti-icon-versions-off;
}
.#{$ti-prefix}-video:before {
  content: $ti-icon-video;
}
.#{$ti-prefix}-video-minus:before {
  content: $ti-icon-video-minus;
}
.#{$ti-prefix}-video-off:before {
  content: $ti-icon-video-off;
}
.#{$ti-prefix}-video-plus:before {
  content: $ti-icon-video-plus;
}
.#{$ti-prefix}-view-360:before {
  content: $ti-icon-view-360;
}
.#{$ti-prefix}-view-360-off:before {
  content: $ti-icon-view-360-off;
}
.#{$ti-prefix}-viewfinder:before {
  content: $ti-icon-viewfinder;
}
.#{$ti-prefix}-viewfinder-off:before {
  content: $ti-icon-viewfinder-off;
}
.#{$ti-prefix}-viewport-narrow:before {
  content: $ti-icon-viewport-narrow;
}
.#{$ti-prefix}-viewport-wide:before {
  content: $ti-icon-viewport-wide;
}
.#{$ti-prefix}-vinyl:before {
  content: $ti-icon-vinyl;
}
.#{$ti-prefix}-virus:before {
  content: $ti-icon-virus;
}
.#{$ti-prefix}-virus-off:before {
  content: $ti-icon-virus-off;
}
.#{$ti-prefix}-virus-search:before {
  content: $ti-icon-virus-search;
}
.#{$ti-prefix}-vocabulary:before {
  content: $ti-icon-vocabulary;
}
.#{$ti-prefix}-volume:before {
  content: $ti-icon-volume;
}
.#{$ti-prefix}-volume-2:before {
  content: $ti-icon-volume-2;
}
.#{$ti-prefix}-volume-3:before {
  content: $ti-icon-volume-3;
}
.#{$ti-prefix}-volume-off:before {
  content: $ti-icon-volume-off;
}
.#{$ti-prefix}-walk:before {
  content: $ti-icon-walk;
}
.#{$ti-prefix}-wall:before {
  content: $ti-icon-wall;
}
.#{$ti-prefix}-wallet:before {
  content: $ti-icon-wallet;
}
.#{$ti-prefix}-wallet-off:before {
  content: $ti-icon-wallet-off;
}
.#{$ti-prefix}-wallpaper:before {
  content: $ti-icon-wallpaper;
}
.#{$ti-prefix}-wallpaper-off:before {
  content: $ti-icon-wallpaper-off;
}
.#{$ti-prefix}-wand:before {
  content: $ti-icon-wand;
}
.#{$ti-prefix}-wand-off:before {
  content: $ti-icon-wand-off;
}
.#{$ti-prefix}-wave-saw-tool:before {
  content: $ti-icon-wave-saw-tool;
}
.#{$ti-prefix}-wave-sine:before {
  content: $ti-icon-wave-sine;
}
.#{$ti-prefix}-wave-square:before {
  content: $ti-icon-wave-square;
}
.#{$ti-prefix}-webhook:before {
  content: $ti-icon-webhook;
}
.#{$ti-prefix}-wheelchair:before {
  content: $ti-icon-wheelchair;
}
.#{$ti-prefix}-wifi:before {
  content: $ti-icon-wifi;
}
.#{$ti-prefix}-wifi-0:before {
  content: $ti-icon-wifi-0;
}
.#{$ti-prefix}-wifi-1:before {
  content: $ti-icon-wifi-1;
}
.#{$ti-prefix}-wifi-2:before {
  content: $ti-icon-wifi-2;
}
.#{$ti-prefix}-wifi-off:before {
  content: $ti-icon-wifi-off;
}
.#{$ti-prefix}-wind:before {
  content: $ti-icon-wind;
}
.#{$ti-prefix}-wind-off:before {
  content: $ti-icon-wind-off;
}
.#{$ti-prefix}-windmill:before {
  content: $ti-icon-windmill;
}
.#{$ti-prefix}-windmill-off:before {
  content: $ti-icon-windmill-off;
}
.#{$ti-prefix}-window:before {
  content: $ti-icon-window;
}
.#{$ti-prefix}-window-maximize:before {
  content: $ti-icon-window-maximize;
}
.#{$ti-prefix}-window-minimize:before {
  content: $ti-icon-window-minimize;
}
.#{$ti-prefix}-window-off:before {
  content: $ti-icon-window-off;
}
.#{$ti-prefix}-windsock:before {
  content: $ti-icon-windsock;
}
.#{$ti-prefix}-wiper:before {
  content: $ti-icon-wiper;
}
.#{$ti-prefix}-wiper-wash:before {
  content: $ti-icon-wiper-wash;
}
.#{$ti-prefix}-woman:before {
  content: $ti-icon-woman;
}
.#{$ti-prefix}-world:before {
  content: $ti-icon-world;
}
.#{$ti-prefix}-world-download:before {
  content: $ti-icon-world-download;
}
.#{$ti-prefix}-world-latitude:before {
  content: $ti-icon-world-latitude;
}
.#{$ti-prefix}-world-longitude:before {
  content: $ti-icon-world-longitude;
}
.#{$ti-prefix}-world-off:before {
  content: $ti-icon-world-off;
}
.#{$ti-prefix}-world-upload:before {
  content: $ti-icon-world-upload;
}
.#{$ti-prefix}-wrecking-ball:before {
  content: $ti-icon-wrecking-ball;
}
.#{$ti-prefix}-writing:before {
  content: $ti-icon-writing;
}
.#{$ti-prefix}-writing-off:before {
  content: $ti-icon-writing-off;
}
.#{$ti-prefix}-writing-sign:before {
  content: $ti-icon-writing-sign;
}
.#{$ti-prefix}-writing-sign-off:before {
  content: $ti-icon-writing-sign-off;
}
.#{$ti-prefix}-x:before {
  content: $ti-icon-x;
}
.#{$ti-prefix}-yin-yang:before {
  content: $ti-icon-yin-yang;
}
.#{$ti-prefix}-yoga:before {
  content: $ti-icon-yoga;
}
.#{$ti-prefix}-zodiac-aquarius:before {
  content: $ti-icon-zodiac-aquarius;
}
.#{$ti-prefix}-zodiac-aries:before {
  content: $ti-icon-zodiac-aries;
}
.#{$ti-prefix}-zodiac-cancer:before {
  content: $ti-icon-zodiac-cancer;
}
.#{$ti-prefix}-zodiac-capricorn:before {
  content: $ti-icon-zodiac-capricorn;
}
.#{$ti-prefix}-zodiac-gemini:before {
  content: $ti-icon-zodiac-gemini;
}
.#{$ti-prefix}-zodiac-leo:before {
  content: $ti-icon-zodiac-leo;
}
.#{$ti-prefix}-zodiac-libra:before {
  content: $ti-icon-zodiac-libra;
}
.#{$ti-prefix}-zodiac-pisces:before {
  content: $ti-icon-zodiac-pisces;
}
.#{$ti-prefix}-zodiac-sagittarius:before {
  content: $ti-icon-zodiac-sagittarius;
}
.#{$ti-prefix}-zodiac-scorpio:before {
  content: $ti-icon-zodiac-scorpio;
}
.#{$ti-prefix}-zodiac-taurus:before {
  content: $ti-icon-zodiac-taurus;
}
.#{$ti-prefix}-zodiac-virgo:before {
  content: $ti-icon-zodiac-virgo;
}
.#{$ti-prefix}-zoom-cancel:before {
  content: $ti-icon-zoom-cancel;
}
.#{$ti-prefix}-zoom-check:before {
  content: $ti-icon-zoom-check;
}
.#{$ti-prefix}-zoom-code:before {
  content: $ti-icon-zoom-code;
}
.#{$ti-prefix}-zoom-exclamation:before {
  content: $ti-icon-zoom-exclamation;
}
.#{$ti-prefix}-zoom-in:before {
  content: $ti-icon-zoom-in;
}
.#{$ti-prefix}-zoom-in-area:before {
  content: $ti-icon-zoom-in-area;
}
.#{$ti-prefix}-zoom-money:before {
  content: $ti-icon-zoom-money;
}
.#{$ti-prefix}-zoom-out:before {
  content: $ti-icon-zoom-out;
}
.#{$ti-prefix}-zoom-out-area:before {
  content: $ti-icon-zoom-out-area;
}
.#{$ti-prefix}-zoom-pan:before {
  content: $ti-icon-zoom-pan;
}
.#{$ti-prefix}-zoom-question:before {
  content: $ti-icon-zoom-question;
}
.#{$ti-prefix}-zzz:before {
  content: $ti-icon-zzz;
}
