/*---------------------------------------------------------------*/
/*--------------- layout-header-content-block.scss --------------*/
/*---------------------------------------------------------------*/
@use "layout-header-content-block-variables";

body > header {
  display: flex;
  flex-direction: column;
  min-height: var(--layout-header-content-block-min-height);

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--layout-header-content-block-gap);
    padding-top: var(--layout-header-content-block-padding-top);
    padding-right: var(--layout-header-content-block-padding-right);
    padding-bottom: var(--layout-header-content-block-padding-bottom);
    padding-left: var(--layout-header-content-block-padding-left);
    box-sizing: border-box;
    align-self: center;
    max-width: var(--layout-header-content-block-max-width);
  }
}
