/*---------------------------------------------------------------*/
/*------------- components/layout-column-3.scss -----------------*/
/*---------------------------------------------------------------*/
@use "layout-column-3-variables";

$breakpoint-1: 42rem !default;
$breakpoint-2: 60rem !default;

.column-3 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (min-width: $breakpoint-1) {
  .column-3 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: $breakpoint-2) {
  .column-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
