body>header {
  div.horizontal-view {
    @media (max-width: 56rem) {
      position: relative;
      padding: 0;

      .logo {
        position: absolute;
        top: 0.75rem;
        left: 0.5rem;
      }

      .collapsible-menu {
        padding-top: 1rem;

        .secondary-menu {
          width: 100%;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  nav:not(.breadcrumb-bar) {
    min-height: 4rem;
    align-items: center;

    a.logo {
      border: 0;
      background-color: #000;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;


      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        padding:0.25rem;
        box-sizing: border-box;
      }
    }

    .dropdown {
      > ul {
        top: 100%;
        background-color: #fff;
        padding-top: 0.25rem;
        border-radius: 0.25rem;

        > li {
          width: 100%;

          > a {
            color: black;
            width: 100%;
            padding-top: var(--button-base-padding-top);
            padding-right: var(--button-base-padding-right);
            padding-bottom: var(--button-base-padding-bottom);
            padding-left: var(--button-base-padding-left);
          }
        }
      }
    }

    @media (max-width: 56rem) {
      .dropdown {
        width: 100%;

        &-button {
          text-align: left;
          padding-left: 0;
          width: 100%;
        }

        >ul {
          >li {
            max-width: 100%;
          }
        }
      }
    }

    select {
      background-color: transparent;
      color: inherit;
      font-size: inherit;
      border-radius: 1rem;
      padding: .5rem;
    }

    ul {
      align-items: center;
    }

    a {
      border-bottom: 4px solid transparent;
    }

    a[aria-current="page"],
    a:hover {
      border-bottom: 4px solid var(--branding-color-1-darker);
    }

    .secondary-menu {
      margin-left: auto;
    }
  }
}
