div {
  &.explanation {
    &.help_text {
      margin: 0 0 auto auto;
      float: none;
    }
  }

  &.confirmation {
    margin-top: 0;
  }
}

.recommendation {
  padding: var(--critical-informational-labels-padding);
    min-width: var(--critical-informational-labels-min-width);
    text-align: var(--critical-informational-labels-text-align);
    display: inline-block;
  background-color: #c3ddf6;
}
