:root {
  /* Color 1 */
  --tags-color-1-light-background-color: var(--tags-soft-blue-light-background-color);
  --tags-color-1-light-text-color: var(--tags-soft-blue-light-text-color);
  --tags-color-1-light-border-color: var(--tags-soft-blue-light-border-color);

  --tags-color-1-medium-background-color: var(--tags-soft-blue-medium-background-color);
  --tags-color-1-medium-text-color: var(--tags-soft-blue-medium-text-color);
  --tags-color-1-medium-border-color: var(--tags-soft-blue-medium-border-color);

  --tags-color-1-dark-background-color: var(--tags-soft-blue-dark-background-color);
  --tags-color-1-dark-text-color: var(--tags-soft-blue-dark-text-color);
  --tags-color-1-dark-border-color: var(--tags-soft-blue-dark-border-color);

  /* Color 2 */
  --tags-color-2-light-background-color: var(--tags-soft-green-light-background-color);
  --tags-color-2-light-text-color: var(--tags-soft-green-light-text-color);
  --tags-color-2-light-border-color: var(--tags-soft-green-light-border-color);

  --tags-color-2-medium-background-color: var(--tags-soft-green-medium-background-color);
  --tags-color-2-medium-text-color: var(--tags-soft-green-medium-text-color);
  --tags-color-2-medium-border-color: var(--tags-soft-green-medium-border-color);

  --tags-color-2-dark-background-color: var(--tags-soft-green-dark-background-color);
  --tags-color-2-dark-text-color: var(--tags-soft-green-dark-text-color);
  --tags-color-2-dark-border-color: var(--tags-soft-green-dark-border-color);

  /* Color 3 */
  --tags-color-3-light-background-color: var(--tags-soft-yellow-light-background-color);
  --tags-color-3-light-text-color: var(--tags-soft-yellow-light-text-color);
  --tags-color-3-light-border-color: var(--tags-soft-yellow-light-border-color);

  --tags-color-3-medium-background-color: var(--tags-soft-yellow-medium-background-color);
  --tags-color-3-medium-text-color: var(--tags-soft-yellow-medium-text-color);
  --tags-color-3-medium-border-color: var(--tags-soft-yellow-medium-border-color);

  --tags-color-3-dark-background-color: var(--tags-soft-yellow-dark-background-color);
  --tags-color-3-dark-text-color: var(--tags-soft-yellow-dark-text-color);
  --tags-color-3-dark-border-color: var(--tags-soft-yellow-dark-border-color);

  /* Color 4 */
  --tags-color-4-light-background-color: var(--tags-soft-orange-light-background-color);
  --tags-color-4-light-text-color: var(--tags-soft-orange-light-text-color);
  --tags-color-4-light-border-color: var(--tags-soft-orange-light-border-color);

  --tags-color-4-medium-background-color: var(--tags-soft-orange-medium-background-color);
  --tags-color-4-medium-text-color: var(--tags-soft-orange-medium-text-color);
  --tags-color-4-medium-border-color: var(--tags-soft-orange-medium-border-color);

  --tags-color-4-dark-background-color: var(--tags-soft-orange-dark-background-color);
  --tags-color-4-dark-text-color: var(--tags-soft-orange-dark-text-color);
  --tags-color-4-dark-border-color: var(--tags-soft-orange-dark-border-color);

  /* Color 5 */
  --tags-color-5-light-background-color: var(--tags-soft-red-light-background-color);
  --tags-color-5-light-text-color: var(--tags-soft-red-light-text-color);
  --tags-color-5-light-border-color: var(--tags-soft-red-light-border-color);

  --tags-color-5-medium-background-color: var(--tags-soft-red-medium-background-color);
  --tags-color-5-medium-text-color: var(--tags-soft-red-medium-text-color);
  --tags-color-5-medium-border-color: var(--tags-soft-red-medium-border-color);

  --tags-color-5-dark-background-color: var(--tags-soft-red-dark-background-color);
  --tags-color-5-dark-text-color: var(--tags-soft-red-dark-text-color);
  --tags-color-5-dark-border-color: var(--tags-soft-red-dark-border-color);

  /* Color 6 */
  --tags-color-6-light-background-color: var(--tags-soft-violet-light-background-color);
  --tags-color-6-light-text-color: var(--tags-soft-violet-light-text-color);
  --tags-color-6-light-border-color: var(--tags-soft-violet-light-border-color);

  --tags-color-6-medium-background-color: var(--tags-soft-violet-medium-background-color);
  --tags-color-6-medium-text-color: var(--tags-soft-violet-medium-text-color);
  --tags-color-6-medium-border-color: var(--tags-soft-violet-medium-border-color);

  --tags-color-6-dark-background-color: var(--tags-soft-violet-dark-background-color);
  --tags-color-6-dark-text-color: var(--tags-soft-violet-dark-text-color);
  --tags-color-6-dark-border-color: var(--tags-soft-violet-dark-border-color);
}
