/* Table - Variables */

:root {
	/* Base */
	--table-base-background-color: #fff;
	--table-base-text-color: var(--application-base-text-color);
	--table-base-foot-background-color: #f5f5f5;
	--table-base-body-head-cell-background-color: var(  --application-base-accent-color);

	/* Table header */
	--table-base-head-background-color: #fff;
	--table-base-head-border-width: 0 0 2px 0;
  	--table-base-head-border-style: solid;
  	--table-base-head-border-color: var(--branding-color-1-darker);

  	/* th */
  	--table-base-head-cell-font-size: 1rem;

  	/* Table cell */
  	--table-base-cell-border-width: 0 0 1px 0;
  	--table-base-cell-border-style: solid;
  	--table-base-cell-border-color: #ccc;
  	--table-base-cell-padding: 0.5rem 1rem;
  	--table-base-cells-padding: 0.25rem 1rem;

  	/* Actions */
  	--table-action-buttons-button-font-size: var(--icon-base-font-size);
}
