/*---------------------------------------------------------------*/
/*----------------- tile-groups-variables.scss ------------------*/
/*---------------------------------------------------------------*/
:root {
  --tile-groups-flex-direction: row;
  /* --tile-groups-gap: ; */
  --tile-groups-border-width: 1px 0 0 0;
  --tile-groups-border-style: solid;
  --tile-groups-border-color: #f0f0f0;
  --tile-groups-padding-top: 1rem;
  --tile-groups-padding-right: 0;
  --tile-groups-padding-bottom: 0;
  --tile-groups-padding-left: 0;
}
