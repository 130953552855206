/*-----------------------------------------------------------*/
/*----- components/skip-to-content-base-variables.scss ------*/
/*-----------------------------------------------------------*/
:root {
  /* --skip-to-content-base-display: ; */
  /* --skip-to-content-base-top: ; */
  /* --skip-to-content-base-right: ; */
  /* --skip-to-content-base-bottom: ; */
  /* --skip-to-content-base-left: ; */
  --skip-to-content-base-background-color: var(--application-base-accent-color);
  --skip-to-content-base-text-color: var(
    --application-base-accent-color-text-color
  );
  /* --skip-to-content-base-text-align: ; */
  /* --skip-to-content-base-padding: ; */
  /* --skip-to-content-base-min-height: ; */ /* Default is set to a minimum of 2.75rem for accessibility */

  /* Focus */
  /* --skip-to-content-base-border-width: ; */
  /* --skip-to-content-base-border-style: ; */
  --skip-to-content-base-border-color: var(--application-base-accent-color);

  /* Hover */
  --skip-to-content-base-hover-background-color: var(
    --application-base-accent-color-hover
  );
  --skip-to-content-base-hover-text-color: var(
    --application-base-accent-color-hover-text-color
  );
  /* --skip-to-content-base-hover-border-width: ; */
  /* --skip-to-content-base-hover-border-style: ; */
  --skip-to-content-base-hover-border-color: var(
    --application-base-accent-color-hover
  );
}
