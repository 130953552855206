/*---------------------------------------------------------------*/
/*--------------------- text/nota-bene.scss ---------------------*/
/*---------------------------------------------------------------*/
@use "nota-bene-variables";

.nota-bene {
  margin: 0;
  line-height: var(--nota-bene-line-height, inherit);
  font-size: var(--nota-bene-font-size, inherit);
  font-weight: var(--nota-bene-font-weight, inherit);
  color: var(--nota-bene-text-color, inherit);
  background-color: var(--nota-bene-background-color, transparent);
}
