/*---------------------------------------------------------------*/
/*------------------ login-meta-variables.scss ------------------*/
/*---------------------------------------------------------------*/
:root {
  --login-meta-flex-direction: row;
  --login-meta-justify-content: flex-end;
  --login-meta-align-items: center;
  --login-meta-padding-top: 0.5rem;
  --login-meta-padding-right: 0.5rem;
  --login-meta-padding-bottom: 0.5rem;
  --login-meta-padding-left: 0.5rem;
  --login-meta-gap: 1rem;
  --login-meta-background-color: #f0f0f0;
  --login-meta-color: #fff;
}
