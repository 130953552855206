/*---------------------------------------------------------------*/
/*----------------- components/spot-large.scss ------------------*/
/*---------------------------------------------------------------*/
@use "spot-large-variables";

%branding-color-1,
.branding-color-1 {
	background-color: var(--branding-color-1-background-color);
	color: var(--branding-color-1-text-color);

	a {
		color: var(--branding-color-1-link-text-color);
		
		&:active {
			color: var(--branding-color-1-link-active-text-color);
		}

		&:visited {
			color: var(--branding-color-1-link-visited-text-color);

			&:hover {
				color: var(--branding-color-1-link-visited-hover-text-color);
			}
		}

		&:hover {
			color: var(--branding-color-1-link-hover-text-color);
		}
	}
}

%branding-color-2,
.branding-color-2 {
	background-color: var(--branding-color-2-background-color);
	color: var(--branding-color-2-text-color);

	a {
		color: var(--branding-color-2-link-text-color);
		
		&:active {
			color: var(--branding-color-2-link-active-text-color);
		}

		&:visited {
			color: var(--branding-color-2-link-visited-text-color);

			&:hover {
				color: var(--branding-color-2-link-visited-hover-text-color);
			}
		}

		&:hover {
			color: var(--branding-color-2-link-hover-text-color);
		}
	}
}

%branding-color-accent-1,
.branding-color-accent-1 {
	background-color: var(--branding-color-accent-1-background-color);
	color: var(--branding-color-accent-1-text-color);

	a {
		color: var(--branding-color-accent-1-link-text-color);
		
		&:active {
			color: var(--branding-color-accent-1-link-active-text-color);
		}

		&:visited {
			color: var(--branding-color-accent-1-link-visited-text-color);

			&:hover {
				color: var(--branding-color-accent-1-link-visited-hover-text-color);
			}
		}

		&:hover {
			color: var(--branding-color-accent-1-link-hover-text-color);
		}
	}
}

%branding-color-accent-2,
.branding-color-accent-2 {
	background-color: var(--branding-color-accent-2-background-color);
	color: var(--branding-color-accent-2-text-color);

	a {
		color: var(--branding-color-accent-2-link-text-color);
		
		&:active {
			color: var(--branding-color-accent-2-link-active-text-color);
		}

		&:visited {
			color: var(--branding-color-accent-2-link-visited-text-color);

			&:hover {
				color: var(--branding-color-accent-2-link-visited-hover-text-color);
			}
		}

		&:hover {
			color: var(--branding-color-accent-2-link-hover-text-color);
		}
	}
}

%branding-color-accent-3,
.branding-color-accent-3 {
	background-color: var(--branding-color-accent-3-background-color);
	color: var(--branding-color-accent-3-text-color);

	a {
		color: var(--branding-color-accent-3-link-text-color);
		
		&:active {
			color: var(--branding-color-accent-3-link-active-text-color);
		}

		&:visited {
			color: var(--branding-color-accent-3-link-visited-text-color);

			&:hover {
				color: var(--branding-color-accent-3-link-visited-hover-text-color);
			}
		}

		&:hover {
			color: var(--branding-color-accent-3-link-hover-text-color);
		}
	}
}

%branding-color-accent-4,
.branding-color-accent-4 {
	background-color: var(--branding-color-accent-4-background-color);
	color: var(--branding-color-accent-4-text-color);

	a {
		color: var(--branding-color-accent-4-link-text-color);
		
		&:active {
			color: var(--branding-color-accent-4-link-active-text-color);
		}

		&:visited {
			color: var(--branding-color-accent-4-link-visited-text-color);

			&:hover {
				color: var(--branding-color-accent-4-link-visited-hover-text-color);
			}
		}

		&:hover {
			color: var(--branding-color-accent-4-link-hover-text-color);
		}
	}
}

%branding-color-accent-5,
.branding-color-accent-5 {
	background-color: var(--branding-color-accent-5-background-color);
	color: var(--branding-color-accent-5-text-color);

	a {
		color: var(--branding-color-accent-5-link-text-color);
		
		&:active {
			color: var(--branding-color-accent-5-link-active-text-color);
		}

		&:visited {
			color: var(--branding-color-accent-5-link-visited-text-color);

			&:hover {
				color: var(--branding-color-accent-5-link-visited-hover-text-color);
			}
		}

		&:hover {
			color: var(--branding-color-accent-5-link-hover-text-color);
		}
	}
}
