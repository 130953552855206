/*---------------------------------------------------------------*/
/*------------------- text/headings-variables.scss --------------*/
/*---------------------------------------------------------------*/
:root {
  /* Available variables to use within all heading types */
  --headings-font-family: var(--headings-base-set-font-family, var(--application-base-font-family));
  --headings-font-size: 1.5rem;
  --headings-font-weight: bold;
  --headings-line-height: var(--application-base-line-height);
  --headings-text-color: var(--application-base-text-color);
  --headings-margin: 1rem;

  /* h1 */
  --h1-font-family: var(--headings-font-family);
  --h1-font-size: var(--heading-xxl-font-size);
  --h1-font-weight: var(--heading-xxl-font-weight);
  --h1-line-height: var(--heading-xxl-line-height);
  --h1-text-color: var(--heading-xxl-text-color);
  --h1-margin: var(--heading-xxl-margin);

  /* h2 */
  --h2-font-family: var(--headings-font-family);
  --h2-font-size: var(--heading-xl-font-size);
  --h2-text-color: var(--heading-xl-text-color);
  --h2-font-weight: var(--heading-xl-font-weight);
  --h2-line-height: var(--heading-xl-line-height);
  --h2-margin: var(--heading-xl-margin);

  /* h3 */
  --h3-font-family: var(--headings-font-family);
  --h3-font-size: var(--heading-large-font-size);
  --h3-text-color: var(--heading-large-text-color);
  --h3-font-weight: var(--heading-large-font-weight);
  --h3-line-height: var(--heading-large-line-height);
  --h3-margin: var(--heading-large-margin);

  /* h4 */
  --h4-font-family: var(--headings-font-family);
  --h4-font-size: var(--heading-normal-font-size);
  --h4-text-color: var(--heading-normal-text-color);
  --h4-font-weight: var(--heading-normal-font-weight);
  --h4-line-height: var(--heading-normal-line-height);
  --h4-margin: var(--heading-normal-margin);

  /* h5 */
  --h5-font-family: var(--headings-font-family);
  --h5-font-size: var(--heading-small-font-size);
  --h5-text-color: var(--heading-small-text-color);
  --h5-font-weight: var(--heading-small-font-weight);
  --h5-line-height: var(--heading-small-line-height);
  --h5-margin: var(--heading-small-margin);

  /* h6 */
  --h6-font-family: var(--headings-font-family);
  --h6-font-size: var(--heading-xs-font-size);
  --h6-text-color: var(--heading-xs-text-color);
  --h6-font-weight: var(--heading-xs-font-weight);
  --h6-line-height: var(--heading-xs-line-height);
  --h6-margin: var(--heading-xs-margin);
}
