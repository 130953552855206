/*---------------------------------------------------------------*/
/*-------------------- horizontal-view.scss ---------------------*/
/*---------------------------------------------------------------*/
@use "horizontal-view-variables";

$breakpoint: 50rem !default;

%horizontal-view,
.horizontal-view {
  display: flex;
  flex-direction: row !important; /* Preventing specificity issues */
  align-items: center;
  justify-content: var(--horizontal-view-justify-content);
  flex-wrap: wrap;
  gap: var(--horizontal-view-gap);

  @media (min-width: $breakpoint) {
    flex-wrap: nowrap;
  }
}

.horizontal-view-group > div {
  @extend %horizontal-view;
}
