/*---------------------------------------------------------------*/
/*-------------------------- text/p.scss ------------------------*/
/*---------------------------------------------------------------*/
@use "p-variables";

p {
  margin: 0;
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
  color: inherit;
  background-color: var(--p-background-color);
}
