/*---------------------------------------------------------------*/
/*----------------- components/textarea.scss --------------------*/
/*---------------------------------------------------------------*/
@use "form-textarea-variables";

form {
  textarea {
    width: var(--form-textarea-width);
    resize: var(--form-textarea-resize);
    box-sizing: border-box;
    padding: var(--form-textarea-padding);
    font-size: var(--form-textarea-font-size, inherit);
    font-family: var(--form-textarea-font-family, inherit);
    color: var(--form-textarea-text-color, inherit);
    background-color: var(--form-textarea-background-color);
    min-height: var(--form-textarea-min-height);
    border-width: var(--form-textarea-border-width);
    border-style: var(--form-textarea-border-style);
    border-color: var(--form-textarea-border-color);
  }
}
