table td span {
  &:before {
    position: static;
    font-family: var(--icon-base-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Firefox */
  }

  &.new {
    &:before {
      content: "\ea91";
      color: var(--color-alert-informative);
    }
  }

  &.active {
    &:before {
      content: "\ea67";
      color: var(--color-alert-positive);
    }
  }

  &.blocked {
    &:before {
      content: "\ea6a";
      color: var(--color-alert-negative);
    }
  }
}
