/*---------------------------------------------------------------*/
/*--------------- components/table/table-base.scss --------------*/
/*---------------------------------------------------------------*/
@use "table-base-variables";

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
  text-align: var(--table-base-text-align, inherit);
  overflow: auto;
  margin: 0;
  font-size: var(--table-base-font-size, inherit);
  line-height: var(--table-base-line-height, inherit);
  font-weight: var(--table-base-font-weight, inherit);
  color: var(--table-base-text-color, inherit);
  background-color: var(--table-base-background-color, transparent);
  border-width: var(--table-base-border-width);
  border-style: var(--table-base-border-style);
  border-color: var(--table-base-border-color);

  thead {
    background-color: var(--table-base-head-background-color, inherit);
    color: var(--table-base-head-text-color, inherit);
    border-width: var(--table-base-head-border-width);
    border-style: var(--table-base-head-border-style);
    border-color: var(--table-base-head-border-color);

    th {
      padding: var(--table-base-head-cell-padding);
      border-width: var(--table-base-head-cell-border-width);
      border-style: var(--table-base-head-cell-border-style);
      border-color: var(--table-base-head-cell-border-color);
      font-weight: var(--table-base-head-cell-font-weight);
      font-size: var(--table-base-head-cell-font-size);
    }

    a,
    a:visited,
    a:hover {
      color: inherit;

      &:before {
        color: inherit;
      }
    }
  }

  tbody {
    th {
      padding: var(--table-base-body-head-cell-padding);
      border-width: var(--table-base-body-head-cell-border-width);
      border-style: var(--table-base-body-head-cell-border-style);
      border-color: var(--table-base-body-head-cell-border-color);
      background-color: var(--table-base-body-head-cell-background-color);
      font-weight: var(--table-base-body-head-cell-font-weight);
      font-size: var(--table-base-body-head-cell-font-size);
    }
  }

  td {
    padding: var(--table-base-cell-padding);
    gap: var(--table-base-cell-gap);
    background-color: var(--table-base-cell-background-color, inherit);
    color: var(--table-base-cell-text-color, inherit);
    border-width: var(--table-base-cell-border-width);
    border-style: var(--table-base-cell-border-style);
    border-color: var(--table-base-cell-border-color);
  }

  tr {
    /* Zebra striping with opacity to keep background color. e.g for warnings or errors */
    &:nth-child(even):not(.odd),
    &:nth-child(odd).even {
      background-color: var(
        --table-base-row-background-color-striping,
        inherit
      );
    }

    &:first-of-type td {
      border-width: var(--table-base-first-row-cell-border-width);
      border-style: var(--table-base-first-row-cell-border-style);
      border-color: var(--table-base-first-row-cell-border-color);
    }

    &:last-of-type td {
      border-width: var(--table-base-last-row-cell-border-width);
      border-style: var(--table-base-last-row-cell-border-style);
      border-color: var(--table-base-last-row-cell-border-color);
    }
  }

  tfoot {
    background-color: var(--table-base-foot-background-color, inherit);
    color: var(--table-base-foot-text-color, inherit);
    font-size: var(--table-base-foot-font-size, inherit);
    font-weight: var(--table-base-foot-font-weight, inherit);
    padding: var(--table-base-foot-padding);
    border-width: var(--table-base-foot-border-width);
    border-style: var(--table-base-foot-border-style);
    border-color: var(--table-base-foot-border-color);

    th {
      background-color: var(--table-base-foot-header-background-color, inherit);
      color: var(--table-base-foot-header-text-color, inherit);
      border-width: var(--table-base-foot-header-border-width);
      border-style: var(--table-base-foot-header-border-style);
      border-color: var(--table-base-foot-header-border-color);
      padding: var(--table-base-foot-header-padding);
      font-weight: var(--table-base-foot-header-font-weight, inherit);
      font-size: var(--table-base-foot-header-font-size, inherit);
    }

    td {
      background-color: var(--table-foot-cell-background-color, inherit);
      color: var(--table-foot-cell-text-color, inherit);
      border-width: var(--table-base-foot-cell-border-width);
      border-style: var(--table-base-foot-cell-border-style);
      border-color: var(--table-base-foot-cell-border-color);
      padding: var(--table-base-foot-cell-padding);
      font-weight: var(--table-base-foot-cell-font-weight, inherit);
      font-size: var(--table-base-foot-cell-font-size, inherit);
    }
  }
}
