/*---------------------------------------------------------------*/
/*---------------- layout-main-content-block.scss ---------------*/
/*---------------------------------------------------------------*/
@use "layout-main-content-block-variables";

body > main {
  display: flex;
  flex-direction: column;
  gap: var(--layout-main-content-block-gap);
  justify-content: center;

  > section,
  > article,
  > div {
    display: flex;
    flex-direction: column;

    > div,
    > article,
    > section {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: var(--layout-main-content-block-gap);
      padding-top: var(--layout-main-content-block-padding-top);
      padding-right: var(--layout-main-content-block-padding-right);
      padding-bottom: var(--layout-main-content-block-padding-bottom);
      padding-left: var(--layout-main-content-block-padding-left);
      box-sizing: border-box;
      align-self: center;
      max-width: var(--layout-main-content-block-max-width);
    }

    &:first-child {
      padding-top: var(--layout-main-content-block-first-padding-top);
      box-sizing: border-box;
    }

    &:last-child {
      padding-bottom: var(--layout-main-content-block-last-padding-bottom);
      box-sizing: border-box;
    }
  }
}
