/*------------------------------------------------------------------------*/
/*------------------------- form-input-variables.scss ---------------------*/
/*------------------------------------------------------------------------*/
:root {
  --form-input-padding-top: 0.5rem;
  --form-input-padding-right: 1rem;
  --form-input-padding-bottom: 0.5rem;
  --form-input-padding-left: 1rem;
  --form-input-background-color: #fff;
  /* --form-input-text-color: ; */
  /* --form-input-font-size: ; */
  /* --form-input-line-height: ; */
  /* --form-input-font-weight: ; */
  --form-input-min-height: 2.75rem;
  /* --form-input-border-width: ; */
  /* --form-input-border-style: ; */
  /* --form-input-border-color: ; */
  /* --form-input-border-radius: var(--application-base-border-radius); */
}
