/*------------------------------------------------------------------------*/
/*---------------------- form-checkbox-variables.scss --------------------*/
/*------------------------------------------------------------------------*/
:root {
  --form-checkbox-margin-right: 0.75rem;
  --form-checkbox-align-self: flex-start;
  --form-checkbox-vertical-align: top;
  --form-checkbox-width: 1.25rem;
  --form-checkbox-height: 1.25rem;
  --form-checkbox-cursor: pointer;
}
