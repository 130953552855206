/*---------------------------------------------------------------*/
/*-------- components/layout-two-thirds-one-third.scss ----------*/
/*---------------------------------------------------------------*/
@use "layout-two-thirds-one-third-variables";

$breakpoint: 42rem !default;

div,
section,
article,
header nav,
footer {
  &.two-thirds-one-third {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: var(--layout-two-thirds-one-third-gap);

    > * {
      width: 100%;
    }

    @media (min-width: $breakpoint) {
      gap: var(--layout-two-thirds-one-third-breakpoint-1-gap);

      > * {
        width: 33.33%;

        &:nth-child(odd) {
          width: calc(66.66% - var(--layout-two-thirds-one-third-gap));
        }
      }
    }
  }
}
