.ooi_summary__risk-rating {
  h5 {
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
  }

  & > span {
    display: block;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
  }
}
