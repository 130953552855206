/*---------------------------------------------------------------*/
/*-------------------------- tags-6-3.scss ----------------------*/
/*---------------------------------------------------------------*/
@use "tags-6-3-variables";

%tags-color-1-light,
.tags-color-1-light {
	background-color: var(--tags-color-1-light-background-color);
	color: var(--tags-color-1-light-text-color);
	border-color: var(--tags-color-1-light-border-color);
}

%tags-color-1-medium,
.tags-color-1-medium {
	background-color: var(--tags-color-1-medium-background-color);
	color: var(--tags-color-1-medium-text-color);
	border-color: var(--tags-color-1-medium-border-color);
}

%tags-color-1-dark,
.tags-color-1-dark {
	background-color: var(--tags-color-1-dark-background-color);
	color: var(--tags-color-1-dark-text-color);
	border-color: var(--tags-color-1-dark-border-color);
}

%tags-color-2-light,
.tags-color-2-light {
	background-color: var(--tags-color-2-light-background-color);
	color: var(--tags-color-2-light-text-color);
	border-color: var(--tags-color-2-light-border-color);
}

%tags-color-2-medium,
.tags-color-2-medium {
	background-color: var(--tags-color-2-medium-background-color);
	color: var(--tags-color-2-medium-text-color);
	border-color: var(--tags-color-2-medium-border-color);
}

%tags-color-2-dark,
.tags-color-2-dark {
	background-color: var(--tags-color-2-dark-background-color);
	color: var(--tags-color-2-dark-text-color);
	border-color: var(--tags-color-2-dark-border-color);
}

%tags-color-3-light,
.tags-color-3-light {
	background-color: var(--tags-color-3-light-background-color);
	color: var(--tags-color-3-light-text-color);
	border-color: var(--tags-color-3-light-border-color);
}

%tags-color-3-medium,
.tags-color-3-medium {
	background-color: var(--tags-color-3-medium-background-color);
	color: var(--tags-color-3-medium-text-color);
	border-color: var(--tags-color-3-medium-border-color);
}

%tags-color-3-dark,
.tags-color-3-dark {
	background-color: var(--tags-color-3-dark-background-color);
	color: var(--tags-color-3-dark-text-color);
	border-color: var(--tags-color-3-dark-border-color);
}

%tags-color-4-light,
.tags-color-4-light {
	background-color: var(--tags-color-4-light-background-color);
	color: var(--tags-color-4-light-text-color);
	border-color: var(--tags-color-4-light-border-color);
}

%tags-color-4-medium,
.tags-color-4-medium {
	background-color: var(--tags-color-4-medium-background-color);
	color: var(--tags-color-4-medium-text-color);
	border-color: var(--tags-color-4-medium-border-color);
}

%tags-color-4-dark,
.tags-color-4-dark {
	background-color: var(--tags-color-4-dark-background-color);
	color: var(--tags-color-4-dark-text-color);
	border-color: var(--tags-color-4-dark-border-color);
}

%tags-color-5-light,
.tags-color-5-light {
	background-color: var(--tags-color-5-light-background-color);
	color: var(--tags-color-5-light-text-color);
	border-color: var(--tags-color-5-light-border-color);
}

%tags-color-5-medium,
.tags-color-5-medium {
	background-color: var(--tags-color-5-medium-background-color);
	color: var(--tags-color-5-medium-text-color);
	border-color: var(--tags-color-5-medium-border-color);
}

%tags-color-5-dark,
.tags-color-5-dark {
	background-color: var(--tags-color-5-dark-background-color);
	color: var(--tags-color-5-dark-text-color);
	border-color: var(--tags-color-5-dark-border-color);
}

%tags-color-6-light,
.tags-color-6-light {
	background-color: var(--tags-color-6-light-background-color);
	color: var(--tags-color-6-light-text-color);
	border-color: var(--tags-color-6-light-border-color);
}

%tags-color-6-medium,
.tags-color-6-medium {
	background-color: var(--tags-color-6-medium-background-color);
	color: var(--tags-color-6-medium-text-color);
	border-color: var(--tags-color-6-medium-border-color);
}

%tags-color-6-dark,
.tags-color-6-dark {
	background-color: var(--tags-color-6-dark-background-color);
	color: var(--tags-color-6-dark-text-color);
	border-color: var(--tags-color-6-dark-border-color);
}
