/* Tabs - Variables */

:root {
	/* Tab */
	--tabs-border-color: #cccccc;

	/* Active tab */
	--tabs-item-active-text-color: var(--branding-color-1-darker);
	--tabs-item-active-border-color: var(--branding-color-1-darker);
}
