:root {

  /* Color sets */
  --link-text-color: #006FB3;
  --link-hover-text-color: #005C94;

  --link-visited-text-color: #5b109f;
  --link-visited-hover-text-color: #430e73;

  /* Spot large */
  /* Main colors */

    /* Lavender */
    --branding-color-1: #EEECF8;

    /* Bright yellow */
    --branding-color-2: #FECF48;

  /* Accent colors */

    /* Dark grey */
    --branding-color-accent-1: #3B3B3B;

    /* Bright blue */
    --branding-color-accent-2: #007EBB;

    /* Bright green */
    --branding-color-accent-3: #4CBA51;

    /* Bright orange */
    --branding-color-accent-4: #F58900;

    /* Salmon */
    --branding-color-accent-5: #FE7E6D;

  /* Branding color combinations.
  e.g if a branding color is used as a background-color. What should the text
  and link colors be.

  /* Branding color 1 */
  --branding-color-1-background-color: var(--branding-color-1);
  --branding-color-1-text-color: black;

  --branding-color-1-link-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-active-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-visited-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-hover-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-visited-hover-text-color: var(--branding-color-1-text-color);

  --branding-color-1-darker: #8C7CD2;

  /* Branding color 2 */
  --branding-color-2-background-color: var(--branding-color-2);
  --branding-color-2-text-color: black;

  --branding-color-2-link-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-active-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-visited-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-hover-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-visited-hover-text-color: var(--branding-color-2-text-color);

  /* Accent color 1 */
  --branding-color-accent-1-background-color: var(--branding-color-accent-1);
  --branding-color-accent-1-text-color: white;

  --branding-color-accent-1-link-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-active-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-visited-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-hover-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-visited-hover-text-color: var(--branding-color-accent-1-text-color);

  /* Accent color 2 */
  --branding-color-accent-2-background-color: var(--branding-color-accent-2);
  --branding-color-accent-2-text-color: white;

  --branding-color-accent-2-link-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-active-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-visited-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-hover-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-visited-hover-text-color: var(--branding-color-accent-2-text-color);

  /* Accent color 3 */
  --branding-color-accent-3-background-color: var(--branding-color-accent-3);
  --branding-color-accent-3-text-color: white;

  --branding-color-accent-3-link-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-active-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-visited-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-hover-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-visited-hover-text-color: var(--branding-color-accent-3-text-color);

  /* Accent color 4 */
  --branding-color-accent-4-background-color: var(--branding-color-accent-4);
  --branding-color-accent-4-text-color: white;

  --branding-color-accent-4-link-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-active-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-visited-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-hover-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-visited-hover-text-color: var(--branding-color-accent-4-text-color);

  /* Accent color 5 */
  --branding-color-accent-5-background-color: var(--branding-color-accent-5);
  --branding-color-accent-5-text-color: white;

  --branding-color-accent-5-link-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-active-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-visited-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-hover-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-visited-hover-text-color: var(--branding-color-accent-5-text-color);
}
