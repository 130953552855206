/*---------------------------------------------------------------*/
/*-------------------- text/de-emphasized.scss ------------------*/
/*---------------------------------------------------------------*/
@use "emphasized-variables";

.emphasized {
  font-size: var(--emphasized-font-size, inherit);
  font-weight: var(--emphasized-font-weight, inherit);
  line-height: var(--emphasized-line-height, inherit);
  color: var(--emphasized-text-color, inherit);
}
