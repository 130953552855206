/*---------------------------------------------------------------*/
/*----------------- components/select.scss ----------------------*/
/*---------------------------------------------------------------*/
@use "form-select-variables";

form {
  select {
    width: var(--form-select-width);
  }
}
