/*---------------------------------------------------------------*/
/*------------------------- tag-variables.scss ------------------*/
/*---------------------------------------------------------------*/
:root {
  --tag-font-size: var(--application-base-font-size);
  --tag-font-weight: bold;
  --tag-line-height: var(--application-base-line-height);
  --tag-padding: 0.25rem 0.5rem;
  --tag-border-radius: 0.25rem;
  --tag-border-width: 1px;
}
