/*------------------------------------------------------------------------*/
/*---------------------- form-textarea-variables.scss --------------------*/
/*------------------------------------------------------------------------*/
:root {
  --form-textarea-width: 100%;
  --form-textarea-resize: vertical;
  --form-textarea-min-height: 2.75rem;
  --form-textarea-padding: 0.75rem;
  /* --form-textarea-font-size: ; */
  /* --form-textarea-font-family: ; */
  /* --form-textarea-text-color: ; */
  --form-textarea-background-color: white;
  --form-textarea-border-width: 1px;
  --form-textarea-border-style: solid;
  --form-textarea-border-color: black;
}
