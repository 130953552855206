/* Filter - Variables */

:root {
  --filter-button-font-size: 1rem;
  --filter-button-font-weight: normal;
  --filter-button-text-color: var(--application-base-accent-color-text-color);
  --filter-intro-text-color: var(--application-base-text-color);
  --filter-button-icon-before-open-content: "\ea5f";
  --filter-button-icon-before-close-content: "\ea62";
}
