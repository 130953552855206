/* Layout - Variables */

:root {
	--layout-block-gap: 3rem;
	--layout-base-max-width: 80rem;
	--layout-base-content-block-gap: 1.5rem;
	--layout-base-main-padding-top: 5rem;
	--layout-footer-content-block-gap: var(--layout-block-gap);
	--layout-footer-block-max-width: var(--layout-base-max-width);
	--layout-footer-content-block-gap: var(--layout-base-content-block-gap);
	--layout-header-gap: 0;
	--layout-header-padding-top: var(--layout-base-padding-top);
	--layout-header-padding-bottom: var(--layout-base-padding-bottom);
	--layout-header-padding-left: var(--layout-base-padding-left);
	--layout-header-block-max-width: var(--layout-base-max-width);
	--layout-main-gap: var(--layout-block-gap);
	--layout-main-first-padding-top: 5rem;
}
