$grey: #cccccc;
$offwhite: #fafafa;
$lightgrey: #f0f0f0;
$pink: #ca005d;
$blue: #01689b;

// Alert colors
:root {
  --color-alert-informative: #006FB3;
  --color-alert-warning: #7D6900;
  --color-alert-positive: #157B31;
  --color-alert-negative: #C3372C;
}
