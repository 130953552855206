/* Navigation - Variables */

:root {
  --breadcrumb-bar-background-color: var(--branding-color-1-background-color);
  --breadcrumb-bar-text-color: var(--branding-color-1-text-color);
  --breadcrumb-bar-content-block-icon: "\ea61";
  --breadcrumb-bar-padding-right: 3%;
  --breadcrumb-bar-padding-left: 3%;
  --breadcrumb-bar-list-vertical-align: center;
  --breadcrumb-bar-icon-font-family: var(--icon-base-font-family);
  --breadcrumb-bar-icon: "\ea61";
}
