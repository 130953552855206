/*------------------------------------------------------------------------*/
/*---------------------- main-base-variables.scss ------------------------*/
/*------------------------------------------------------------------------*/
:root {
  --main-justify-content: flex-start;
  /* --main-align-items: ; */
  --main-base-padding-top: 5rem;
  --main-base-padding-bottom: 5rem;
  /* --main-background-color: ; */
  /* --main-text-color: ; */
}
