:root {
  /* Blue */
  --tags-soft-blue-light-background-color: #EEF8FE;
  --tags-soft-blue-light-text-color: #006FB3;
  --tags-soft-blue-light-border-color: #006FB3;

  --tags-soft-blue-medium-background-color: #ABDBF8;
  --tags-soft-blue-medium-text-color: #005C94;
  --tags-soft-blue-medium-border-color: #005C94;

  --tags-soft-blue-dark-background-color: #40ADEF;
  --tags-soft-blue-dark-text-color: #003E64;
  --tags-soft-blue-dark-border-color: #003E64;

  /* Green */
  --tags-soft-green-light-background-color: #EBFAEF;
  --tags-soft-green-light-text-color: #157B31;
  --tags-soft-green-light-border-color: #157B31;

  --tags-soft-green-medium-background-color: #94E6AB;
  --tags-soft-green-medium-text-color: #116629;
  --tags-soft-green-medium-border-color: #1B9D3F;

  --tags-soft-green-dark-background-color: #20BD4C;
  --tags-soft-green-dark-text-color: #134421;
  --tags-soft-green-dark-border-color: #116629;

  /* Yellow */
  --tags-soft-yellow-light-background-color: #FFF7CF;
  --tags-soft-yellow-light-text-color: #7D6900;
  --tags-soft-yellow-light-border-color: #7D6900;

  --tags-soft-yellow-medium-background-color: #FECF48;
  --tags-soft-yellow-medium-text-color: #685700;
  --tags-soft-yellow-medium-border-color: #A08700;

  --tags-soft-yellow-dark-background-color: #DDB900;
  --tags-soft-yellow-dark-text-color: #584A00;
  --tags-soft-yellow-dark-border-color: #685700;

  /* Orange */
  --tags-soft-orange-light-background-color: #FFF5ED;
  --tags-soft-orange-light-text-color: #A75312;
  --tags-soft-orange-light-border-color: #A75312;

  --tags-soft-orange-medium-background-color: #FFCAA1;
  --tags-soft-orange-medium-text-color: #8B450F;
  --tags-soft-orange-medium-border-color: #D66A17;

  --tags-soft-orange-dark-background-color: #FFA865;
  --tags-soft-orange-dark-text-color: #5E2F0A;
  --tags-soft-orange-dark-border-color: #A75312;

  /* Red */
  --tags-soft-red-light-background-color: #FFF4F3;
  --tags-soft-red-light-text-color: #C3372C;
  --tags-soft-red-light-border-color: #FFA49D;

  --tags-soft-red-medium-background-color: #FFC7C3;
  --tags-soft-red-medium-text-color: #A22E25;
  --tags-soft-red-medium-border-color: #F84638;

  --tags-soft-red-dark-background-color: #FF7D73;
  --tags-soft-red-dark-text-color: #6E1F19;
  --tags-soft-red-dark-border-color: #A22E25;

  /* Violet */
  --tags-soft-violet-light-background-color: #F7F6FC;
  --tags-soft-violet-light-text-color: #6E5FAE;
  --tags-soft-violet-light-border-color: #BFB6E6;

  --tags-soft-violet-medium-background-color: #D6D1EF;
  --tags-soft-violet-medium-text-color: #5B4F90;
  --tags-soft-violet-medium-border-color: #8C7CD2;

  --tags-soft-violet-dark-background-color: #A89CDD;
  --tags-soft-violet-dark-text-color: #3D3560;
  --tags-soft-violet-dark-border-color: #5B4F90;
}
