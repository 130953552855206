/* Button - Variables */

:root {
	/* Button */
	--button-base-background-color: var(--branding-color-2-background-color);
	--button-base-text-color: var(--branding-color-2-text-color);
	--button-base-border-radius: 1rem;
	--button-ghost-border-width: 1px;


	/* Text */
	--button-base-font-size: 1rem;
	--button-base-font-weight: bold;
	--button-base-line-height: 1;

	/* States */
		/* Hover */
		--button-base-hover-background-color: #DDB900;
		--button-base-hover-text-color: #000;

		/* Active */
		--button-base-active-background-color: #DDB900;
		--button-base-active-text-color: #000;

		/* Focus */
		--button-base-focus-background-color: #DDB900;
		--button-base-focus-text-color: #000;
}
