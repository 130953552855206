/*---------------------------------------------------------------*/
/*---------- components/header-navigation-base.scss -------------*/
/*---------------------------------------------------------------*/
@use "header-navigation-base-variables";

$breakpoint-1: 24rem;
$breakpoint-2: 42rem;

header nav {
  background-color: var(--header-navigation-base-background-color);
  color: var(--header-navigation-base-text-color);

  > div {
    display: flex;
    flex-direction: column;
    align-items: var(--header-navigation-base-align-items);
    flex-grow: 1;
    padding-top: var(--header-navigation-base-padding-top);
    padding-right: var(--header-navigation-base-padding-right);
    padding-bottom: var(--header-navigation-base-padding-bottom);
    padding-left: var(--header-navigation-base-padding-left);
    box-sizing: border-box;
    max-width: var(--header-navigation-base-max-width);
    margin: 0 auto;
    gap: var(--header-navigation-base-gap);
  }

  @media (min-width: $breakpoint-1) {
    padding: var(--header-navigation-base-breakpoint-1-padding);
  }

  @media (min-width: $breakpoint-2) {
    padding: var(--header-navigation-base-breakpoint-2-padding);
  }
}
