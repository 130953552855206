/*---------------------------------------------------------------*/
/*----------- components/button-ghost-variables.scss -------------*/
/*---------------------------------------------------------------*/
:root {
  /* Button */
  /* --button-ghost-outline-style: ; */
  /* --button-ghost-outline-color: ; */
  /* --button-ghost-outline-width: ; */
  /* --button-ghost-outline-offset: ; */
  --button-ghost-background-color: transparent;
  --button-ghost-text-color: var(--application-base-accent-color);
  --button-ghost-border-width: 2px;
  --button-ghost-border-style: solid;
  --button-ghost-border-color: var(--application-base-accent-color);
  --button-ghost-border-radius: var(--button-base-border-radius);

  /* Hover */
  /* --button-ghost-hover-outline-style: ; */
  /* --button-ghost-hover-outline-color: ; */
  /* --button-ghost-hover-outline-width: ; */
  /* --button-ghost-hover-outline-offset: ; */
  --button-ghost-hover-background-color: var(--application-base-accent-color);
  --button-ghost-hover-text-color: var(
    --application-base-accent-color-text-color
  );
  --button-ghost-hover-border-width: var(--button-ghost-border-width);
  --button-ghost-hover-border-style: var(--button-ghost-border-style);
  --button-ghost-hover-border-color: var(--button-ghost-border-color);
  --button-ghost-hover-border-radius: var(--button-ghost-border-radius);

  /* Active */
  /* --button-ghost-active-outline-style: ; */
  /* --button-ghost-active-outline-color: ; */
  /* --button-ghost-active-outline-width: ; */
  /* --button-ghost-active-outline-offset: ; */
  --button-ghost-active-border-width: var(--button-ghost-border-width);
  --button-ghost-active-border-style: var(--button-ghost-border-style);
  --button-ghost-active-border-color: var(--button-ghost-border-color);
  --button-ghost-active-background-color: var(--button-ghost-background-color);
  --button-ghost-active-text-color: var(--button-ghost-text-color);

  /* Focus */
  /* --button-ghost-focus-outline-style: ; */
  /* --button-ghost-focus-outline-color: ; */
  /* --button-ghost-focus-outline-width: ; */
  /* --button-ghost-focus-outline-offset: ; */
  --button-ghost-focus-border-width: var(--button-ghost-border-width);
  --button-ghost-focus-border-style: var(--button-ghost-border-style);
  --button-ghost-focus-border-color: var(--button-ghost-border-color);
  --button-ghost-focus-background-color: var(--button-ghost-background-color);
  --button-ghost-focus-text-color: var(--button-ghost-text-color);
}
