/*---------------------------------------------------------------*/
/*--------- layout-header-content-block-variables.scss ----------*/
/*---------------------------------------------------------------*/
:root {
  /* --layout-header-content-block-min-height: ; */
  --layout-header-content-block-gap: 0;
  --layout-header-content-block-padding-top: 0;
  --layout-header-content-block-padding-right: var(--layout-base-padding-right);
  --layout-header-content-block-padding-bottom: 0;
  --layout-header-content-block-padding-left: var(--layout-base-padding-left);
  --layout-header-content-block-max-width: var(--layout-base-max-width);
}
