/*
Steps within a flow can be displayed using this partial.
*/
.stepper {
  border-top: 1px solid $grey;

  > ul {
    padding: 1rem 0;

    > li {
      font-weight: 400;
      list-style-type: none;
      opacity: 0.4;

      > a {
        color: black;
        margin: 0;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      a:visited {
        color: black;
        text-decoration: none;
      }
    }

    > li:not(li[aria-label="current-step"] ~ li) {
      font-weight: 700;
      opacity: 1;
    }
  }

  @media (min-width: 56rem) {
    > ul {
      display: flex;
      flex-direction: row;
      padding: 2rem 0;

      > li {
        &:before {
          content: "\ea61";
          display: inline-flex;
          font-family: "tabler-icons";
          font-size: 1rem;
          font-weight: 400;
          margin: 0 2rem 0 1rem;
        }

        &:first-child {
          &:before {
            content: none;
          }
        }
      }
    }
  }
}
