/*---------------------------------------------------------------------*/
/*------------------------- icon-base.scss ----------------------------*/
/*---------------------------------------------------------------------*/
@use "icon-base-variables";

%icon-text-styling {
  position: static;
  font-family: var(--icon-base-font-family);
  font-size: var(--icon-base-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  font-smoothing: antialiased;
}

.icon {
  &:before,
  :after {
    @extend %icon-text-styling;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--icon-base-before-font-size);
  }
}

ul.icons {
  li {
    &:before,
    :after {
      @extend %icon-text-styling;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: var(--icon-base-before-font-size);
    }
  }
}

img.icon {
  object-position: center;
  width: var(--icon-base-image-width);
  min-width: var(--icon-base-image-min-width);
  max-width: var(--icon-base-image-max-width);
  height: var(--icon-base-image-height);
  min-height: var(--icon-base-image-min-height);
  max-height: var(--icon-base-image-max-height);
  padding: var(--icon-base-image-padding);
}
