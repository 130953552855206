/*---------------------------------------------------------------*/
/*------------------- form-help-variables.scss ------------------*/
/*---------------------------------------------------------------*/
:root {
  /* form */
  --form-help-padding-right: 3rem;

  /* Button */
  --form-help-button-width: 1rem;
  --form-help-button-height: 1rem;

  --form-help-button-padding-top: 0.5rem;
  --form-help-button-padding-right: 0.5rem;
  --form-help-button-padding-bottom: 0.5rem;
  --form-help-button-padding-left: 0.5rem;

  --form-help-button-top: 0.75rem;
  --form-help-button-right: -2.5rem;

  --form-help-button-border-width: 2px;
  --form-help-button-border-style: solid;
  --form-help-button-border-color: #3f51b5;
  --form-help-button-border-radius: 50%;

  --form-help-button-background-color: #3f51b5;
  --form-help-button-text-color: #fff;

  /* Hover */
  --form-help-button-hover-background-color: var(
    --form-help-button-background-color
  );
  --form-help-button-hover-text-color: var(--form-help-button-text-color);

  /* Button icon */
  --form-help-button-icon-content: "?";
  --form-help-button-icon-font-size: 0.9rem;
  --form-help-button-icon-font-weight: bold;

  /* To center text within the button set the line-height to 1 */
  --form-help-button-icon-line-height: 1;
}
