/*---------------------------------------------------------------*/
/*--------- form-horizontal-view-fieldset-variables.scss --------*/
/*---------------------------------------------------------------*/
:root {
  /* Label */
  --form-horizontal-view-fieldset-label-margin-top: 0.75rem;

  /* nota-bene */
  --form-horizontal-view-fieldset-nota-bene-required-margin-bottom: 1rem;
  
  /* button */
  --form-horizontal-view-fieldset-button-margin-left: var(--form-horizontal-view-label-max-width);
}
