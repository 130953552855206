/*---------------------------------------------------------------*/
/*------------- components/list-base-variables.scss -------------*/
/*---------------------------------------------------------------*/
:root {
  /* list */
  /* --list-base-margin: ; */
  /* --list-base-padding: ; */
  /* --list-base-gap: ; */
  --list-base-line-height: 2.25; /* Increased line height for readabilty */
  /* --list-base-font-size: ; */
  /* --list-base-font-weight: ; */
  /* --list-base-text-color: ; */
  /* --list-base-background-color: ; */

  /* List item */
  /* --list-base-item-padding: ; */
  /* --list-base-item-background-color: ; */
  /* --list-base-item-border-width: ; */
  /* --list-base-item-border-style: ; */
  /* --list-base-item-border-color: ; */
  /* --list-base-item-style: ; */
}
