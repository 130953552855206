.language {
  width: 100%;
  background-color: #f5f5f5;

  form {
    padding: 0.5rem;
    background-color: transparent;

    button {
      color: #000;
      min-height: 0;
      background-color: transparent;
      min-width: 0;
      padding: 0;
      border-radius: 0;
      border-bottom: 4px solid transparent;

      &[aria-current="true"],
      &:hover {
        border-bottom: 4px solid var(--branding-color-1-darker);
      }
    }
  }
}
