/*---------------------------------------------------------------*/
/*---------------------- form-radio.scss ---------------------*/
/*---------------------------------------------------------------*/
@use "form-radio-variables";

form {
  input[type="radio"] {
    margin: var(--form-radio-margin-right, 0);
    padding: 0;
    vertical-align: var(--form-radio-vertical-align);
    cursor: var(--form-radio-cursor);
    width: var(--form-radio-width);
    min-width: var(--form-radio-width);
    height: var(--form-radio-height);
    align-self: var(--form-radio-align-self);
    accent-color: var(--form-radio-background-color);

    & + label {
      width: auto;
      cursor: pointer;
      margin-top: 0;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}
