/*---------------------------------------------------------------*/
/*-------------------- navigation-variables.scss ----------------*/
/*---------------------------------------------------------------*/
:root {
  /* Navigation layout */
  --navigation-gap: 0, 5rem;
  --navigation-align-content: flex-end;
  /* --navigation-min-height: ; */
  --navigation-width: 100%;
  --navigation-max-width: 100%;
  --navigation-padding-top: 1rem;
  --navigation-padding-right: var(--layout-base-padding-right);
  --navigation-padding-bottom: 1rem;
  --navigation-padding-left: var(--layout-base-padding-left);
  --navigation-margin: 0;
  /* --navigation-background-color: var(--application-base-accent-color); */
  /* --navigation-text-color: var(--application-base-accent-color-text-color); */

  /* Content block - Usualy a div to match main content alignment */
  --navigation-content-block-padding-top: var(
    --layout-base-content-block-padding-top
  );
  --navigation-content-block-padding-right: var(
    --layout-base-content-block-padding-right
  );
  --navigation-content-block-padding-bottom: var(
    --layout-base-content-block-padding-bottom
  );
  --navigation-content-block-padding-left: var(
    --layout-base-content-block-padding-left
  );
  --navigation-content-block-max-width: var(
    --layout-base-content-block-max-width
  );

  /* List */
  /*--navigation-list-gap: ; */
  --navigation-list-justify-content: flex-end;

  /* List item */
  /* --navigation-list-item-justify-content: center; */
  /* --navigation-breadcrumbs-list-item-gap */

  /* Link */
  /* --navigation-link-text-decoration: ; */

  /* Link hover */
  --navigation-link-hover-text-decoration: underline;
}
