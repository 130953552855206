/*----------------------------------------------------------------------------------*/
/*---------------- application-base/application-base-variables.scss ----------------*/
/*----------------------------------------------------------------------------------*/
:root {
  /* Application background color */
  --application-base-background-color: #fff;
  --application-base-text-color: #000;

  --application-base-tint-1-background-color: #f5f5f5;
  --application-base-tint-1-text-color: #000;

  --application-base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, "Roboto Sans", "Noto Sans", sans-serif;
  --application-base-font-size: 1.125rem; /* Default text size */
  --application-base-line-height: 1.5;
  --application-base-font-weight: normal;
  --application-base-text-align: left;
  --application-base-padding: 1.5rem;
  --application-base-border-radius: 0;
  --application-base-gap: 1rem;

  /* Accent color */
  --application-base-accent-color: #931171;
  --application-base-accent-color-text-color: #fff;

  /* Accent color hover */
  --application-base-accent-color-hover: hotpink;
  --application-base-accent-color-hover-text-color: black;

  /* Accent color active */
  --application-base-accent-color-active: hotpink;
  --application-base-accent-color-active-text-color: black;

  /* Accent color focus */
  --application-base-accent-color-focus: hotpink;
  --application-base-accent-color-focus-text-color: black;

  /* Accent color selected */
  --application-base-accent-color-selected: hotpink;
  --application-base-accent-color-selected-text-color: black;

  /* Call to action color */
  --cta-background-color: green;
  --cta-text-color: #fff;
}
