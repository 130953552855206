/*------------------------------------------------------------------------*/
/*------------------------- form-base-variables.scss ---------------------*/
/*------------------------------------------------------------------------*/
:root {
  --form-base-flex-align-items: flex-start;
  --form-base-justify-content: flex-start;
  --form-base-padding-top: 2rem;
  --form-base-padding-right: 2rem;
  --form-base-padding-bottom: 2rem;
  --form-base-padding-left: 2rem;
  --form-base-background-color: var(
    --application-base-tint-1-background-color,
    var(--application-base-background-color)
  );
  --form-base-text-color: var(
    --application-base-tint-1-text-color,
    var(--application-base-text-color)
  );
  /* --form-base-font-size: ; */
  /* --form-base-line-height: ; */
  /* --form-base-font-weight: ; */
  --form-base-gap: 1.25rem;
  /* --form-base-border-width: ; */
  /* --form-base-border-style: ; */
  /* --form-base-border-color: ; */

  /* Input fields shared styling */
  /* --form-base-input-text-color: ; */
}
