/*---------------------------------------------------------------*/
/*--------------------- logo-variables.scss ---------------------*/
/*---------------------------------------------------------------*/
:root {
  /* Logo */
  --logo-min-width: 2.65rem;
  /* --logo-width: ; */
  /* --logo-max-width: ; */

  /* Logo image */
  /* --logo-img-height: ; */
  --logo-img-min-height: 2rem;
  /* --logo-img-height: ; */
  --logo-img-max-width: 100%;
  --logo-img-max-height: 2.5rem;
  --logo-img-object-position: center;
  --logo-img-object-fit: contain;
}
