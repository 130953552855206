/*---------------------------------------------------------------*/
/*---------- components/notification-paragraph.scss -------------*/
/*---------------------------------------------------------------*/
@use "notification-paragraph-variables";

p {
  &.notification,
  &.error,
  &.warning,
  &.confirmation,
  &.explanation,
  &.system {
    display: inline-block;
    box-sizing: border-box;
    width: var(--notification-paragraph-width);
    padding-top: var(--notification-paragraph-padding-top);
    padding-right: var(--notification-paragraph-padding-right);
    padding-bottom: var(--notification-paragraph-padding-bottom);
    padding-left: var(--notification-paragraph-padding-left);

    > span:first-of-type {
      font-weight: var(--notification-paragraph-span-font-weight);
    }
  }
}
