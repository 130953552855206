header > section > div {
	gap: 0;
}

body > header nav:not(.breadcrumb-bar) {
	position: relative;

	a.logo {
		position: absolute;

		@media (min-width: 56rem) {
			position: static;
		}

		&:hover {
			border-bottom: 0;
		}
	}
}
