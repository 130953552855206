$boefje-bg: #275937;
$bit-bg: #42145f;
$normalizer-bg: #e17000;

.plugins {
  &.tiles {
    img {
      object-fit: cover;
      object-position: center;
      height: 15rem;
    }
  }

  /* Tile */
  > div {
    position: relative;
    margin-top: 1.75rem;

    /* States */
    &.required {
      .nota-bene {
        display: inline;
        position: absolute; /* Removed form the flow to keep the flex stretch option on the plugins so they stay the same height */
        top: -1.75rem;
        margin: 0;
      }
    }

    /* interaction */
    > .nota-bene {
      display: none;
    }

    /* Checkbox */
    > input[type="checkbox"] {
      left: 1rem;
      margin-top: 0;
      position: absolute;
      top: 1rem;
    }

    .tile-label {
      background-color: #262431;
      color: #fff;
      font-size: 1.125rem;
      margin: 0;
      padding: 0.25rem 1rem;
      position: absolute;
      right: 0;
      top: 1rem;
      width: auto;
    }

    &.boefje {
      .tile-label {
        background-color: $boefje-bg;
      }
    }

    &.bit {
      .tile-label {
        background-color: $bit-bg;
      }
    }

    &.normalizer {
      .tile-label {
        background-color: $normalizer-bg;
      }
    }
  }
}
