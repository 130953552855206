/*
Adds infoblock on hover over table td

Markup:
<table>
  <tbody>
    <tr>
      <td class="has-hover-block">
        Going about your normal business.

        <div class="hover-block">
          Some extra info
        </div>
      </td>
    <tr>
  </tbody>
</table>

*/
tbody td.has-hover-block {
  position: relative;
}

tbody td.has-hover-block .hover-block {
  background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
  display: none;
  top: calc(100% - 0.25em);
  left: 15%;
  opacity: 1;
  padding: 0.5em;
  position: absolute;
  width: 480px;
  z-index: 9;
}

tbody td.has-hover-block:hover .hover-block,
tbody td.has-hover-block.is-active .hover-block {
  display: block;
}
