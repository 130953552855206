/*---------------------------------------------------------------*/
/*---------- components/layout-authentication.scss --------------*/
/*---------------------------------------------------------------*/
@use "layout-authentication-variables";

$breakpoint-1: 24rem !default;
$breakpoint-2: 42rem !default;

section,
article,
header nav,
footer,
div,
form {
  &.layout-authentication {
    padding: var(--layout-authentication-padding);
    max-width: var(--layout-authentication-max-width);
    margin: 0 auto;
    background-color: var(--layout-authentication-background-color);
    color: var(--layout-authentication-text-color);

    @media (min-width: $breakpoint-1) {
      padding: var(--layout-authentication-breakpoint-1-padding);
    }

    @media (min-width: $breakpoint-2) {
      padding: var(--layout-authentication-breakpoint-2-padding);
    }
  }
}
