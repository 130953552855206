/*---------------------------------------------------------------*/
/*----------- components/button-cta-variables.scss --------------*/
/*---------------------------------------------------------------*/
:root {
  /* Button */
  /* --button-cta-active-outline-style: ; */
  /* --button-cta-active-outline-color: ; */
  /* --button-cta-active-outline-width: ; */
  /* --button-cta-active-outline-offset: ; */
  --button-cta-background-color: var(--cta-background-color);
  --button-cta-text-color: var(--cta-text-color);
  --button-cta-border-width: 2px;
  --button-cta-border-style: solid;
  --button-cta-border-color: var(--cta-background-color);
  --button-cta-border-radius: var(--button-base-border-radius);

  /* Hover */
  /* --button-cta-active-outline-style: ; */
  /* --button-cta-active-outline-color: ; */
  /* --button-cta-active-outline-width: ; */
  /* --button-cta-active-outline-offset: ; */
  --button-cta-hover-background-color: var(
    --cta-background-color-accent,
    var(--button-cta-background-color)
  );
  --button-cta-hover-text-color: var(
    --cta-text-color,
    var(--button-cta-text-color)
  );
  --button-cta-hover-border-width: var(--button-cta-border-width);
  --button-cta-hover-border-style: var(--button-cta-border-style);
  --button-cta-hover-border-color: var(--button-cta-border-color);
  --button-cta-hover-border-radius: var(--button-cta-border-radius);

  /* Active */
  /* --button-cta-active-outline-style: ; */
  /* --button-cta-active-outline-color: ; */
  /* --button-cta-active-outline-width: ; */
  /* --button-cta-active-outline-offset: ; */
  --button-cta-active-border-width: var(--button-cta-border-width);
  --button-cta-active-border-style: var(--button-cta-border-style);
  --button-cta-active-border-color: var(--button-cta-border-color);
  --button-cta-active-background-color: var(--button-cta-background-color);
  --button-cta-active-text-color: var(--button-cta-text-color);

  /* Focus */
  /* --button-cta-focus-outline-style: ; */
  /* --button-cta-focus-outline-color: ; */
  /* --button-cta-focus-outline-width: ; */
  /* --button-cta-focus-outline-offset: ; */
  --button-cta-focus-border-width: var(--button-cta-border-width);
  --button-cta-focus-border-style: var(--button-cta-border-style);
  --button-cta-focus-border-color: var(--button-cta-border-color);
  --button-cta-focus-background-color: var(--button-cta-background-color);
  --button-cta-focus-text-color: var(--button-cta-text-color);
}
