/*------------------------------------------------------------------------*/
/*---------------------- form-radio-variables.scss --------------------*/
/*------------------------------------------------------------------------*/
:root {
  --form-radio-margin-right: 0.75rem;
  --form-radio-align-self: flex-start;
  --form-radio-vertical-align: top;
  --form-radio-width: 1.25rem;
  --form-radio-height: 1.25rem;
  --form-radio-cursor: pointer;
  --form-radio-background-color: var(--application-base-accent-color);
}
