/*---------------------------------------------------------------*/
/*--------------- tile-image-cover-variables.scss ---------------*/
/*---------------------------------------------------------------*/
:root {
  /* --tile-cover-image-object-position: ; */
  --tile-image-cover-max-height: 12rem;

  /* Overwriting any padding that the tile may have */
  --tile-image-cover-margin-top: var(--tile-padding-top);
  --tile-image-cover-margin-right: var(--tile-padding-right);
  --tile-image-cover-margin-left: var(--tile-padding-left);
}
