/*-----------------------------------------------------------*/
/*---------- components/spot-large-variables.scss -----------*/
/*-----------------------------------------------------------*/
:root {
  --branding-color-1: LightSalmon;
  --branding-color-2: SeaShell;
  --branding-color-accent-1: LightSeaGreen;
  --branding-color-accent-2: MediumVioletRed;
  --branding-color-accent-3: SlateBlue;
  --branding-color-accent-4: Purple;
  --branding-color-accent-5: OliveDrab;

  /* Branding color combinations. 
  e.g if a branding color is used as a background-color. What should the text
  and link colors be.

  /* Branding color 1 */
  --branding-color-1-background-color: var(--branding-color-1);
  --branding-color-1-text-color: black;

  --branding-color-1-link-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-active-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-visited-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-hover-text-color: var(--branding-color-1-text-color);
  --branding-color-1-link-visited-hover-text-color: var(--branding-color-1-text-color);

  /* Branding color 2 */
  --branding-color-2-background-color: var(--branding-color-2);
  --branding-color-2-text-color: black;

  --branding-color-2-link-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-active-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-visited-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-hover-text-color: var(--branding-color-2-text-color);
  --branding-color-2-link-visited-hover-text-color: var(--branding-color-2-text-color);

  /* Accent color 1 */
  --branding-color-accent-1-background-color: var(--branding-color-accent-1);
  --branding-color-accent-1-text-color: white;

  --branding-color-accent-1-link-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-active-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-visited-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-hover-text-color: var(--branding-color-accent-1-text-color);
  --branding-color-accent-1-link-visited-hover-text-color: var(--branding-color-accent-1-text-color);

  /* Accent color 2 */
  --branding-color-accent-2-background-color: var(--branding-color-accent-2);
  --branding-color-accent-2-text-color: white;

  --branding-color-accent-2-link-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-active-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-visited-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-hover-text-color: var(--branding-color-accent-2-text-color);
  --branding-color-accent-2-link-visited-hover-text-color: var(--branding-color-accent-2-text-color);

  /* Accent color 3 */
  --branding-color-accent-3-background-color: var(--branding-color-accent-3);
  --branding-color-accent-3-text-color: white;

  --branding-color-accent-3-link-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-active-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-visited-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-hover-text-color: var(--branding-color-accent-3-text-color);
  --branding-color-accent-3-link-visited-hover-text-color: var(--branding-color-accent-3-text-color);

  /* Accent color 4 */
  --branding-color-accent-4-background-color: var(--branding-color-accent-4);
  --branding-color-accent-4-text-color: white;

  --branding-color-accent-4-link-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-active-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-visited-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-hover-text-color: var(--branding-color-accent-4-text-color);
  --branding-color-accent-4-link-visited-hover-text-color: var(--branding-color-accent-4-text-color);

  /* Accent color 5 */
  --branding-color-accent-5-background-color: var(--branding-color-accent-5);
  --branding-color-accent-5-text-color: white;

  --branding-color-accent-5-link-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-active-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-visited-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-hover-text-color: var(--branding-color-accent-5-text-color);
  --branding-color-accent-5-link-visited-hover-text-color: var(--branding-color-accent-5-text-color);
}
