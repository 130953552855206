/*---------------------------------------------------------------*/
/*------------- components/layout-centered.scss -----------------*/
/*---------------------------------------------------------------*/
@use "layout-centered-variables";

.centered {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  > div,
  > section,
  > article {
    width: auto;
  }
}
