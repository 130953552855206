/*---------------------------------------------------------------*/
/*------------ table/expando-rows-variables.scss ----------------*/
/*---------------------------------------------------------------*/
:root {
  /* Table cell */
  --expando-rows-table-cell-background-color: #e5e5e5;
  --expando-rows-table-cell-padding: 2rem 1rem;

  /* Table exando content title */
  --expando-rows-table-content-title-font-size: 1.25rem;
  --expando-rows-table-content-title-font-weight: bolder;

  /* Table exando content subtitle */
  --expando-rows-table-content-subtitle-font-size: 1rem;
  --expando-rows-table-content-subtitle-font-weight: bolder;

  /* After breakpoint */
  --expando-rows-table-cell-after-breakpoint-padding: 2rem 3rem;
}
