/*------------------------------------------------------------------------*/
/*-------------- form-button-grouped-horizontal-variables.scss -----------*/
/*------------------------------------------------------------------------*/
:root {
  /* To align the grouped buttons to the right add a margin-left 	*/
  /* set to "auto" to the first button. 							*/
  /* To align the buttons to the left put this variable in a 		*/
  /* comment or add "unset" 										*/
  /* This is a bit of workaround until cleaner methods are found 	*/

  --form-button-grouped-horizontal-first-child-margin-left: auto;
}
