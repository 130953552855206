/*---------------------------------------------------------------*/
/*-------------------- notification-system.scss -----------------*/
/*---------------------------------------------------------------*/
@use "notification-base";
@use "notification-system-message-variables";

p.system,
span.system,
section.system,
div.system {
  @extend %notification;
  background-color: var(--notification-system-background-color);
  border-width: var(--notification-system-border-width);
  border-style: var(--notification-system-border-style);
  border-color: var(--notification-system-border-color);

  &:before {
    content: var(--notification-system-icon-before-content);
  }

  &:after {
    content: var(--notification-system-icon-after-content);
  }
}

textarea.system,
select.system,
input.system {
  border-width: var(--notification-system-border-width);
  border-style: var(--notification-system-border-style);
  border-color: var(--notification-system-border-color);

  &:before {
    content: var(--notification-system-icon-before-content);
  }

  &:after {
    content: var(--notification-system-icon-after-content);
  }
}
