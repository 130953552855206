/*---------------------------------------------------------------*/
/*------------------- notification-warning.scss -----------------*/
/*---------------------------------------------------------------*/
@use "notification-base";
@use "notification-warning-variables";

p.warning,
span.warning,
section.warning,
div.warning {
  @extend %notification;
  background-color: var(--notification-warning-background-color);
  border-width: var(--notification-warning-border-width);
  border-style: var(--notification-warning-border-style);
  border-color: var(--notification-warning-border-color);

  &:before {
    content: var(--notification-warning-icon-before-content);
  }

  &:after {
    content: var(--notification-warning-icon-after-content);
  }
}

textarea.warning,
select.warning,
input.warning {
  border-width: var(--notification-warning-border-width);
  border-style: var(--notification-warning-border-style);
  border-color: var(--notification-warning-border-color);

  &:before {
    content: var(--notification-warning-icon-before-content);
  }

  &:after {
    content: var(--notification-warning-icon-after-content);
  }
}
