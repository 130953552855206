/*---------------------------------------------------------------*/
/*------------- form-button-grouped-horizontal.scss -------------*/
/*---------------------------------------------------------------*/
@use "form-button-grouped-horizontal-variables";

form {
  .horizontal-view {
    button:first-child {
      margin-left: var(
        --form-button-grouped-horizontal-first-child-margin-left
      );
    }
  }
}
