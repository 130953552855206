/*---------------------------------------------------------------*/
/*-------------- components/buttons/base.scss -------------------*/
/*---------------------------------------------------------------*/
@use "button-base-variables";

button,
a.button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  display: var(--button-base-flex);
  box-sizing: border-box;
  align-items: center;
  align-self: flex-start;
  justify-content: var(--button-base-justify-content);
  gap: var(--button-base-gap);
  padding-top: var(--button-base-padding-top);
  padding-right: var(--button-base-padding-right);
  padding-bottom: var(--button-base-padding-bottom);
  padding-left: var(--button-base-padding-left);
  min-width: var(--button-base-min-width);
  min-height: var(--button-base-min-height);
  background-color: var(--button-base-background-color);
  border-width: var(--button-base-border-width);
  border-style: var(--button-base-border-style);
  border-color: var(--button-base-border-color);
  color: var(--button-base-text-color, inherit);
  font-size: var(--button-base-font-size, inherit);
  font-weight: var(--button-base-font-weight, inherit);
  line-height: var(--button-base-line-height, inherit);
  text-decoration: var(--button-base-text-decoration);
  border-radius: var(--button-base-border-radius, inherit);
  webkit-border-radius: var(
    --button-base-border-radius
  ); /* Reset ios button styling */
  -webkit-appearance: var(
    --button-base-webkit-appearance
  ); /* Reset ios button styling */
  cursor: pointer;
  width: auto;

  &:visited {
    color: var(--button-base-text-color);
  }

  &:hover,
  &.hover {
    background-color: var(--button-base-hover-background-color);
    color: var(--button-base-hover-text-color);
    border-color: var(--button-base-hover-background-color);
  }

  &:active,
  &.active {
    outline-style: var(--button-base-active-outline-style);
    outline-color: var(--button-base-active-outline-color);
    outline-width: var(--button-base-active-outline-width);
    outline-offset: var(--button-base-active-outline-offset);
    background-color: var(--button-base-active-background-color);
    color: var(--button-base-active-text-color);
    border-style: var(--button-base-active-border-style);
    border-color: var(
      --button-base-active-border-color,
      var(--application-base-accent-color-accent)
    );
    border-width: var(--button-base-active-border-width);
  }

  &:focus,
  &.focus {
    outline-style: var(--button-base-focus-outline-style);
    outline-color: var(--button-base-focus-outline-color);
    outline-width: var(--button-base-focus-outline-width);
    outline-offset: var(--button-base-focus-outline-offset);
    border-style: var(--button-base-focus-border-style);
    border-color: var(
      --button-base-focus-border-color,
      var(--application-base-accent-color-accent)
    );
    border-width: var(--button-base-focus-border-width);
    background-color: var(
      --button-base-focus-background-color,
      var(--application-base-accent-color-accent)
    );
    color: var(
      --button-base-focus-text-color,
      var(--application-text-color-accent)
    );
  }

  &.selected {
    outline-style: var(--button-base-focus-outline-style);
    outline-color: var(--button-base-focus-outline-color);
    outline-width: var(--button-base-focus-outline-width);
    outline-offset: var(--button-base-focus-outline-offset);
    border-style: var(--button-base-focus-border-style);
    border-color: var(
      --button-base-focus-border-color,
      var(--application-base-accent-color-accent)
    );
    border-width: var(--button-base-focus-border-width);
    background-color: var(
      --button-base-selected-background-color,
      var(--application-base-accent-color-accent)
    );
    color: var(
      --button-base-selected-text-color,
      var(--application-text-color-accent)
    );
  }

  > img {
    max-width: var(
      --button-base-image-max-width
    ); /* Limiting the use of images within buttons for readability. */
  }
}
