/*-----------------------------------------------------------*/
/*--------------- headings-base-set-variables.scss ----------*/
/*-----------------------------------------------------------*/
:root { 
   /* Available variables to use within all heading types */
  --headings-base-set-font-family: var(--application-base-font-family);
  --headings-base-set-font-size: 1.5rem;
  --headings-base-set-font-weight: bold;
  --headings-base-set-line-height: var(--application-base-line-height);
  --headings-base-set-text-color: var(--application-base-text-color);
  --headings-base-set-margin: 0;

  /* Heading XXL */
  --heading-xxl-font-family: var(--headings-base-set-font-family);
  --heading-xxl-font-size: 2.5rem;
  --heading-xxl-font-weight: var(--headings-base-set-font-weight);
  --heading-xxl-line-height: var(--headings-base-set-line-height);
  --heading-xxl-text-color: var(--headings-base-set-text-color);
  --heading-xxl-margin: var(--headings-base-set-margin);

  /* Heading XL */
  --heading-xl-font-family: var(--headings-base-set-font-family);
  --heading-xl-font-size: 2rem;
  --heading-xl-font-weight: var(--headings-base-set-font-weight);
  --heading-xl-line-height: var(--headings-base-set-line-height);

  --heading-xl-text-color: var(--headings-base-set-text-color);
  --heading-xl-margin: var(--headings-base-set-margin);

  /* Heading large */
  --heading-large-font-family: var(--headings-base-set-font-family);
  --heading-large-font-size: 1.5rem;
  --heading-large-font-weight: var(--headings-base-set-font-weight);
  --heading-large-line-height: var(--headings-base-set-line-height);
  --heading-large-text-color: var(--headings-base-set-text-color);
  --heading-large-margin: var(--headings-base-set-margin);

  /* Heading normal */
  --heading-normal-font-family: var(--headings-base-set-font-family);
  --heading-normal-font-size: 1rem;
  --heading-normal-font-weight: var(--headings-base-set-font-weight);
  --heading-normal-line-height: var(--headings-base-set-line-height);
  --heading-normal-text-color: var(--headings-base-set-text-color);
  --heading-normal-margin: var(--headings-base-set-margin);

  /* Heading small */
  --heading-small-font-family: var(--headings-base-set-font-family);
  --heading-small-font-size: 0.8rem;
  --heading-small-font-weight: var(--headings-base-set-font-weight);
  --heading-small-line-height: var(--headings-base-set-line-height);
  --heading-small-text-color: var(--headings-base-set-text-color);
  --heading-small-margin: var(--headings-base-set-margin);

  /* Heading XS */
  --heading-xs-font-family: var(--headings-base-set-font-family);
  --heading-xs-font-size: 0.7rem;
  --heading-xs-font-weight: var(--headings-base-set-font-weight);
  --heading-xs-line-height: var(--headings-base-set-line-height);
  --heading-xs-text-color: var(--headings-base-set-text-color);
  --heading-xs-margin: var(--headings-base-set-margin);
}
