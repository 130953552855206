/*---------------------------------------------------------------*/
/*------ form-fieldset-visually-hidden-horizontal-view.scss -----*/
/*---------------------------------------------------------------*/
@use "form-horizontal-view-variables";
/* Uses form-fieldset-visually-hidden */

$breakpoint-1: 24rem !default;

form.horizontal-view {
  > fieldset > fieldset {
    legend {
      font-weight: inherit;
      margin: 0;
      box-sizing: border-box;
      width: var(--form-horizontal-view-label-max-width);
      float: left;
      padding-right: var(--form-horizontal-view-gap);
      margin-top: var(--form-horizontal-view-fieldset-label-margin-top);
    }

    > * {
      @media (min-width: $breakpoint-1) {
        width: var(--form-horizontal-view-input-max-width);
        float: right;
      }
    }
  }
}
