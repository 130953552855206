/*----------------------------------------------------------------------------------*/
/*------------------------------- link-variables.scss ------------------------------*/
/*----------------------------------------------------------------------------------*/
:root {
  /* Link */
  --link-background-color: unset;
  --link-text-color: #0000c2;
  --link-hover-text-color: #000088;

  /* Visted link */
  --link-visited-text-color: #5b109f;
  --link-visited-hover-text-color: #400b6f;
}
