/*---------------------------------------------------------------*/
/*----------------- form-horizontal-view.scss -------------------*/
/*---------------------------------------------------------------*/
@use "form-horizontal-view-variables";

$breakpoint: 24rem !default;

form.horizontal-view {
  flex-direction: column;
  gap: var(--form-horizontal-view-gap);
  flex-wrap: wrap;

  > button,
  > a.button,
  > input[type="button"],
  > input[type="submit"],
  > input[type="reset"] {
    margin-left: var(--form-horizontal-view-button-margin-left);
  }

  > div,
  > section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: var(--form-horizontal-view-justify-content);
    flex-wrap: wrap;
    gap: var(--form-horizontal-view-gap);

    &.checkbox {
      gap: var(--form-checkbox-margin-right);

      %horizontal-view-checkbox,
      input[type="checkbox"] {
        /* Align checkbox with input fields */
        margin-left: calc(var(--form-horizontal-view-label-max-width) + var(--form-horizontal-view-gap));
        flex-grow: 0;
        margin-right: 0;

        & + label {
          max-width: 100%;
        }
      }

      > div {
        @extend %horizontal-view-checkbox;
        gap: 0;

        input[type="checkbox"] {
          margin-left: 0;
        }
      }
    }

    &.radio-button {
      gap: var(--form-radio-margin-right);

      %horizontal-view-radio,
      input[type="radio"] {
        /* Align radio with input fields */
        margin-left: calc(var(--form-horizontal-view-label-max-width) + var(--form-horizontal-view-gap));
        flex-grow: 0;
        margin-right: 0;

        & + label {
          max-width: 100%;
        }
      }

      > div {
        @extend %horizontal-view-radio;

        input[type="radio"] {
          margin-left: 0;
        }
      }
    }

    input[type="color"] {
      max-width: var(--form-input-color-max-width);
    }

    > * {
      flex-grow: 1;
      box-sizing: border-box;
    }

    > button,
    > a.button,
    > input[type="button"],
    > input[type="submit"],
    > input[type="reset"] {
      flex-grow: 0;
    }

    @media (min-width: $breakpoint) {
      flex-wrap: nowrap;

      > * {
        max-width: var(--form-horizontal-view-input-max-width);
      }

      label {
        width: 100%;
        max-width: var(--form-horizontal-view-label-max-width);
      }
    }
  }

  @media (min-width: $breakpoint) {
    > div.horizontal-view {
      max-width: var(--form-horizontal-view-input-max-width);
      margin-left: auto;
      justify-content: flex-end;
      flex-wrap: wrap;

      > * {
        width: 100%;
        max-width: 100%;
      }

      > button,
      > a.button,
      > input[type="button"],
      > input[type="submit"],
      > input[type="reset"] {
        flex-grow: 0;
        width: auto;
      }
    }
  }
}
