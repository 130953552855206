/*---------------------------------------------------------------*/
/*---------------- components/accordion.scss --------------------*/
/*---------------------------------------------------------------*/
@use "accordion-variables";
%accordion {
  display: flex;
  flex-direction: column;
  gap: var(--accordion-gap);
}

%accordion-button {
  width: 100%;
  padding: var(--accordion-button-padding);
  font-size: var(--accordion-font-size, inherit);
  justify-content: var(--accordion-button-justify-content);
  text-decoration: none;
  line-height: var(--accordion-button-line-height, inherit);
  color: var(--accordion-button-text-color, inherit);
  background-color: var(--accordion-button-background-color, inherit);
}

%accordion-content {
  display: flex;
  flex-direction: column;
  gap: var(--accordion-content-gap);
  padding: var(--accordion-content-padding);
  font-size: var(--accordion-content-font-size, inherit);
  font-weight: var(--accordion-content-font-weight, inherit);
  line-height: var(--accordion-content-line-height, inherit);
  border-width: var(--accordion-content-border-width);
  border-style: var(--accordion-content-border-style);
  border-color: var(--accordion-content-border-color);
  background-color: var(--accordion-content-background-color);
  color: var(--accordion-content-text-color, inherit);

  > *:first-child {
    margin-top: 0;
  }
}

ul.accordion {
  padding-left: 0;
  width: 100%;
  @extend %accordion;

  > li {
    list-style: none;
    width: 100%;

    > button {
      @extend %accordion-button;
    }

    > div {
      @extend %accordion-content;
    }
  }
}

section.accordion {
  > div {
    gap: 0;
  }
}

.accordion {
  @extend %accordion;

  /* Accordion item */
  > div {
    width: 100%;

    > button {
      @extend %accordion-button;
    }

    > div {
      @extend %accordion-content;
    }
  }
}

/* The expanded state */

/* Collapsed state (only when JavaScript is loaded) */
.js-accordion-loaded {
  /* The collapsed state */
  .accordion {
    button[aria-expanded="false"] {
      /* Open icon */
      &:before {
        content: var(--accordion-button-icon-before-open-content, none);
        font-family: var(--accordion-button-icon-font-family, inherit);
        font-size: var(--accordion-button-icon-before-font-size);
        font-weight: var(--accordion-button-icon-before-font-weight);
        white-space: var(--accordion-button-icon-before-white-space);
      }

      &:after {
        content: var(--accordion-button-icon-after-open-content, none);
        font-family: var(--accordion-button-icon-font-family, inherit);
        font-size: var(--accordion-button-icon-after-font-size);
        font-weight: var(--accordion-button-icon-after-font-weight);
        white-space: var(--accordion-button-icon-after-white-space);
        margin-left: auto;
      }

      & + div {
        display: none;
      }
    }

    /* The expanded state */
    button[aria-expanded="true"] {
      /* Close icon */
      &:before {
        content: var(--accordion-button-icon-before-close-content, none);
        font-family: var(--accordion-button-icon-font-family, inherit);
        font-size: var(--accordion-button-icon-before-font-size);
        font-weight: var(--accordion-button-icon-before-font-weight);
        white-space: var(--accordion-button-icon-before-white-space);
      }

      &:after {
        content: var(--accordion-button-icon-after-close-content, none);
        font-family: var(--accordion-button-icon-font-family, inherit);
        font-size: var(--accordion-button-icon-after-font-size);
        font-weight: var(--accordion-button-icon-after-font-weight);
        white-space: var(--accordion-button-icon-after-white-space);
        margin-left: var(--accordion-button-icon-after-margin-left);
      }
    }
  }
}
