/*---------------------------------------------------------------*/
/*------------- description-list-variables.scss -----------------*/
/*---------------------------------------------------------------*/
:root {
  /* Description-list */
  /* --description-list-width: auto; */
  /* --description-list-max-width: ; */

  /* Description list item */
  --description-list-item-padding: 0.5rem 1rem;
  --description-list-item-odd-background-color: var(
    --application-base-tint-1-background-color,
    #f0f0f0
  );
  /* --description-list-item-gap: ; */

  /* Description list item title (dt) */
  /* --description-list-item-title-font-weight: ; */
  /* --description-list-item-title-background-color: ; */
  /* --description-list-item-title-text-color: ; */

  /* Description list item title (dd) */
  /* --description-list-item-data-background-color: ; */
  /* --description-list-item-data-text-color: ; */
}
