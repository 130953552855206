/*---------------------------------------------------------------*/
/*------------ components/skip-to-content-base.scss -------------*/
/*---------------------------------------------------------------*/
@use "skip-to-content-base-variables";

.skip-to-content,
a.button.skip-to-content {
  /* Visually removing the objects height. Without removing the object
	from the flow. */
  display: block;
  min-height: 0;
  font-size: 0;
  padding: 0;
  background-color: var(--skip-to-content-base-background-color);
  color: var(--skip-to-content-base-text-color);

  &:hover {
    background-color: var(--skip-to-content-base-hover-background-color);
    color: var(--skip-to-content-base-hover-text-color);
    border-width: var(--skip-to-content-base-hover-border-width);
    border-style: var(--skip-to-content-base-hover-border-style);
    border-color: var(--skip-to-content-base-hover-border-color);
  }

  &:focus {
    position: static;
    display: flex;
    height: auto;
    width: auto;
    padding: var(--skip-to-content-base-padding, 0.5rem);
    text-align: var(--skip-to-content-base-text-align, center);
    font-size: var(--application-base-font-size);
    min-height: var(--skip-to-content-base-min-height, 2.75rem);
    border-width: var(--skip-to-content-base-border-width);
    border-style: var(--skip-to-content-base-border-style);
    border-color: var(--skip-to-content-base-border-color);
  }
}
