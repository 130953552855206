/*---------------------------------------------------------------*/
/*--------------------- filter-variables.scss -------------------*/
/*---------------------------------------------------------------*/
:root {
  --filter-intro-padding: 0.5rem 0;
  --filter-intro-border-width: 0 0 1px 0;
  --filter-intro-border-style: solid;
  --filter-intro-border-color: #ccc;
  /* --filter-intro-font-size: ; */
  --filter-intro-text-color: #6c6c6c;

  /* Span */
  --filter-intro-span-font-size: 1.5rem;
  --filter-intro-span-text-color: #000;

  /* Button */
  /* --filter-button-background-color: ; */
  /* --filter-button-text-color: ; */
  /* --filter-button-border-width: ; */
  /* --filter-button-border-style: ; */
  /* --filter-button-border-color: ; */
  --filter-button-padding: 0 0.5rem;
  /* --filter-button-min-height: ; */
  --filter-button-margin-left: auto;
  --filter-button-font-size: 0;

  /* Button icon */
  --filter-button-icon-font-family: var(--icon-base-font-family);
  --filter-button-icon-font-size: var(--icon-base-font-size);
  --filter-button-icon-font-weight: bold;

  /* To center text within the button set the line-height to 1 */
  --filter-button-icon-line-height: 1;

  /* content on the button when the menu is collapsed */
  --filter-button-icon-before-open-content: "v";

  /* content on the button when the menu is expanded */
  --filter-button-icon-before-close-content: "^";
}
