/*------------------------------------------------------------------------*/
/*-------------------------- section-variables.scss ----------------------*/
/*------------------------------------------------------------------------*/

:root {
  --section-align-items: var(--layout-base-content-block-align-items);
  --section-gap: var(--layout-base-within-content-block-gap);
  /* --section-background-color: ; */
  /* --section-text-color: ; */

  /* Section title */
  --section-title-margin: var(--layout-base-content-block-title-margin);

  /* Section subtitle */
  --section-subtitle-margin: var(--layout-base-content-block-subtitle-margin);

  /* Content block within sections */
  --section-content-block-gap: var(--section-gap);
}
