/*----------------------------------------------------------------------------------*/
/*---------------------------------- hero.scss -------------------------------------*/
/*----------------------------------------------------------------------------------*/
@use "hero-variables";

.hero {
  display: flex;
  width: 100%;
  min-height: var(--hero-min-height);
  height: var(--hero-height);
  max-height: var(--hero-max-height);
  position: relative;

  img {
    object-fit: cover;
    object-position: var(--hero-image-object-position);
    width: 100%;
    max-width: 100%;
    padding: 0;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
  }

  h1 {
    font-size: var(--hero-title-font-size);
  }
}
