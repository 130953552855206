/*----------------------------------------------------------------------------------*/
/*-------------------------------- hero-variables.scss -----------------------------*/
/*----------------------------------------------------------------------------------*/
:root {
  /* --hero-min-height: ; */
  --hero-height: 30rem;
  /* --hero-max-height: ; */

  /* Title */
  --hero-title-font-size: 2rem;

  /* Image */
  --hero-image-object-position: center;
}
